import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { useInView } from 'react-hook-inview';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { ResizableWrapper } from '../../ResizableWrapper';
import { InputSettings } from './InputSettings';
import MainElement from '../MainElements/MainElement';


const defaultProps = {
  style: {},
  actionState: 'default',
  default: {
    background:'#FFF',
    overflowWrap: 'anywhere',
    className: '',
    href: null,
    src: null,
    width: null,
    height: null,
    maxWidth: null,
    minWidth: null,
    display: null,
    color: '#161717',
    margin: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    padding: null,
    paddingTop: null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight: null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: 'solid',
    borderRadius: '1.5vmin',
    overflow: null,
    fontSize: '22px',
    textTransform: null,
    fontWeight: null,
    lineHeight: null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily: null,
    textAlign: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor: null,
    shadowBlur: '1px',
    transition: null,
    isBasic: false,
    readOnly: false,
    lineHeight: null,
    inputValue: null,
    placeholder: 'This is an input',
    isTextArea: true,
    name: null,
    type: 'text',
  },
  hovered: {
    shadowX: null,
    shadowY: '1px',
    shadowColor: null,
    shadowBlur: '1px',
  },
  active: {
    shadowX: null,
    shadowY: '1px',
    shadowColor: null,
    shadowBlur: '1px',
  }

};


export const Input = ({ style = {}, isBasic = false, ...defaultProps }) => {
  const {
    node,
    connectors: { connect },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
    node: node
  }));

  const {
    nodes,
    enabled,
    isDragged,
    actions: { setProp }
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    isDragged: node.events.dragged,
    nodes: state.nodes,
  }));

  const {
    type,
    name,
    href,
    placeholder,
    readOnly,
    inputValue,
    className,
  } = defaultProps.default

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);


  const textStyle = {
    color: defaultProps.default.color,
    fontSize: defaultProps.default.fontSize,
    textTransform: defaultProps.default.textTransform,
    fontWeight: defaultProps.default.fontWeight,
    lineHeight: defaultProps.default.lineHeight,
    fontStyle: defaultProps.default.fontStyle,
    textDecoration: defaultProps.default.textDecoration,
    fontFamily: defaultProps.default.fontFamily,
    textAlign: defaultProps.default.textAlign,
    background:'transparent',
  };



  const memoizedProps = useMemo(() => ({
    ...style,
    cursor: href && !enabled ? 'pointer' : '',
  }), [href, enabled]);

  const handleMouseEnter = (e) => {
    e.stopPropagation()
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation()
    setIsHovered(false);
  };


  return (
    <>
      <MainElement
      input={true}
        activeBackground={activeBackground}
        connect={connect}
        enabled={enabled}
        selected={selected}
        mergedRef={mergedRef}
        defaultProps={defaultProps['default']}
        hoverProperties={defaultProps['hovered']}
        activeProperties={defaultProps['active']}
        isDragged={isDragged}
        isHovered={isHovered}
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
        memoizedProps={memoizedProps}

        // onClick={handleClick}
      >
        <input
          // style={textStyle}
        className={`${className} ${(enabled && 'helloworldsParentChildren')}`}
          value={inputValue}
          placeholder={placeholder}
          name={name}
          type={type}
          readOnly={readOnly === 'true' ? true : false}
          onChange={(e) => {
            setProp(node.id, (prop) => (prop.inputValue = e.target.value), 500);
          }}
        />


        <ResizableWrapper
          selected={selected}
          childRef={mergedRef}
          setActiveBackground={setActiveBackground}
          setIsHovered={setIsHovered}
          isHovered={isHovered} />
      </MainElement>
    </>
  );
};

Input.craft = {
  displayName: 'Input',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: InputSettings,
  },
};



