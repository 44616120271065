/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { ContainerSettings } from './ContainerSettings.js';
import { Element, ROOT_NODE, useEditor, useNode } from '@craftjs/core';
import { useAppProps } from '../../DarkModeContext.js';
import { ArrowDown, SettingsIcon } from '../editor/Viewport/Editor-icons/svgArray.js';
import { AddPageIcon } from '../editor/Viewport/Editor-icons/EditorSvg.js';
import MainItem from '../selectors/basic/MainLayouts/MainItem.js';
import { Text } from '../selectors/basic/Text/index.js';
import { Layout2 } from '../selectors/basic/Layout/Layout2.js';
import { useActiveIdContext } from '../../ActiveIdContext.js';
import LayoutOption from './LayoutOption.js';

const defaultProps = {
  actionState: 'default',
  child: false,
  startImage: false,

  default: {
    minHeight:'fit-content',
    width: '100%',
    flexWrap:null,
minWidth:null,
margin:null,
borderWidth:null,
borderColor:null,
borderRadius:'1vmin',
cursor:null,
    borderStyle: 'solid',
    transitionDuration: null,
    transitionTimingFunction: null,
    transitionDelay: null,
    transitionProperty: null,
    cursor: null,
    filterOptions: null,
    filterValue: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor: null,
    shadowBlur: '1px',
    filterOptions: null,
    filterValue: null,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fillSpace: 'no',
    // background: '#e9e9e9',
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,
    transformStyle: 'translate',
    shapePos: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },
  },
  hovered: {

    shadowX: null,
    shadowY: null,
    shadowColor: null,
    shadowBlur: null,
    width: null,
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,
    transformStyle: 'translate',
    shapePos: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  },
  active: {

    shadowX: null,
    shadowY: null,
    shadowColor: null,
    shadowBlur: null,
    width: null,
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,
    transformStyle: 'translate',
    shapePos: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  }

};

export const Container = ({
  gridTemplateColumns='',
  gridTemplateRows='',
  style = {},
  className = '',
  transitionDuration,
  transitionTimingFunction,
  transitionDelay,
  transitionProperty,
  cursor,
  filterOptions,
  filterValue,
  shapePos = null,

  shapePositions = {
    Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
    Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
    Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
    Top: { top: 0, left: 0, transform: '' },
  },

  shapes = {
    Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
    Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
    Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
    Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
  },
  child,
  children, columns, name, displayText,
  flexDirection, background, maxWidth, padding, height, width,
  margin, display, notMain, props, ...defaultProps
}) => {

  const { id } = useNode()

  const {
    connectors: { connect },
    selected,
    parent,
    node,
    actions : {setProp}
  } = useNode(node => ({
    selected: node.events.selected,
    parent: node.data.parent,
    node: node
  }));

  const [layoutSelection, setLayoutSelection] = useState(false)
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const { enabled, isActive, query, } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),
    enabled: state.options.enabled,
  }));
  

  const { actions,  actions: { selectNode, replaceNodes }, json } = useEditor((state, query) => {
    return {
      json: query.serialize(),
    };
  });



  const { setConnect, setActiveComponents, isDarkMode, childrenCount, setChildrenCount, allNodes,
    setAllNodes,} = useAppProps();

  const [settingsHovered, setSettingsHovered] = useState(false);
  const [message, setMessage] = useState('');
  const divRef = useRef(null);
  
  const rect = divRef?.current?.getBoundingClientRect();
  const rectHeight = rect?.height

  useEffect(() => {
    selectNode(parent)
  }, [])

  const memoizedProps = useMemo(() => ({
    ...style,
    height: height || '100%',
    width: width || '100%',
    display: display || 'flex',
    gridTemplateColumns: display === 'grid' && gridTemplateColumns,
    gridTemplateRows: display === 'grid' && gridTemplateRows,
    gridGap: '10px',
    gap: '10px',
    flexDirection: flexDirection !== null ? flexDirection : 'column',
  }), [defaultProps, style, display, gridTemplateColumns, gridTemplateRows]);

  const handleMouseOver = useCallback(() => setSettingsHovered(true), []);
  const handleMouseLeave = useCallback(() => setSettingsHovered(false), []);
  const handleMouseDown = useCallback(() => setConnect(true), [setConnect]);

  const handleMessageClick = useCallback((e) => {
    e.stopPropagation();
    setMessage('Not Added The Widgets Yet, Drag Here!');
    setConnect(false);
    setActiveComponents(true);
  }, [setConnect, setActiveComponents]);


  if (!enabled && !children) {
    return (
      <div style={{width:'100%', overflow:'scroll', height:'100%'}}>
        <div className={`text-[#989191] w-full text-[1.5rem] py-[2vmin] text-center`}>
      This component is empty.
      </div>
      </div>
    );
  }
  
  return (
    <MainItem
      {...props}
      isHovered={settingsHovered}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseLeave}
      className={`${className} ${selected ? 'selectedNodeIsActive' : 'notSelectedNode'} relative`}
      memoizedProps={memoizedProps}
      actionState={defaultProps}
      defaultProps={defaultProps['default']}
      hoverProperties={defaultProps['hovered']}
      activeProperties={defaultProps['active']}
      connect={connect}
      enabled={enabled}
      selected={selected}
      mergedRef={divRef}
      isChild={true}
      setProp={setProp}
    >
      {!children && child && (
        <div
          className="flex justify-center items-center h-[10vmin] text-[2.5vmin] mx-auto my-auto"
          style={{ opacity: '.35', userSelect: 'none', display: rectHeight < 35 && 'none' }}
        >
          <div
            className="w-[100%] text-center height-[4vmin] flex justify-center items-center"
            onClick={handleMessageClick}
          >
            {!message ? <AddPageIcon style={{ cursor: 'pointer' }} width="15%" height="15%" stroke="#0867FC" /> : message}
          </div>
        </div>
      )}
      {enabled && selected && (
        <div
          className="absolute top-0 right-[3vmin] flex h-[fit-content] w-[fit-content]"
          style={{ opacity: '1', zIndex: 99999, display: rectHeight < 35 && 'none' }}
        >
          {id !== ROOT_NODE && <div title='Detect Parent' style={{ borderRadius: '0 0 0 1vmin' }} className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#98919150]'} hover:bg-[#0867fc60] p-[3%] cursor-pointer w-auto h-auto`} onClick={() => selectNode(parent)}><img
            alt="icons"
            src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/7.2.0/png/iconmonstr-arrow-up-alt-filled.png&r=255&g=255&b=255"
            className="min-w-[2vmin] max-w-[2vmin] max-h-[2vmin] min-h-[2vmin]"
          /></div>}
          {(message || children) && <div className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#98919150]'} hover:bg-[#0867fc60] p-[3%]`} 
          title='Add Widget' onMouseDown={handleMessageClick}>
            <AddPageIcon className='cursor-pointer' fill='#FFF' width="2vmin" height="2vmin" />
          </div>}

          <div style={{ borderRadius: '0 0 1vmin 0' }}
            className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#98919150]'} hover:bg-[#0867fc60] p-[3%] cursor-pointer`}
            onMouseDown={handleMouseDown}>
            <SettingsIcon width="2vmin" height="2vmin" stroke="#FFF" fill={'#FFF'} />
          </div>
        </div>
      )}
      {children}
      {!displayText && !notMain && !children && (
         <div
         style={{
           width: '100%',
           height: '100%',
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           position: 'relative',
         }}
       >
       {!layoutSelection && 
       <div className='w-full absolute flex justify-center items-center top-[-.6vmin] p-[.5vmin] left-0'>
      <div style={{borderRadius:'0 0 2vmin 2vmin'}} className={`h-[4vmin] cursor-pointer flex items-center text-[4vmin] justify-center text-[#FFF] w-[10vmin] bg-[#0867fc40] hover:bg-[#0867fc60]`} 
          title='Choose Layout' onMouseDown={(e) => {
            e.stopPropagation()
            setLayoutSelection(true)
          }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          fill="white"
        >
          <path d="M19 13H5V11H19V13Z" />
          <path d="M13 5H11V19H13V5Z" />
        </svg>
        </div>
       </div>
       }
         {!layoutSelection ? <img
           style={{ height: '100%', objectFit: 'cover', borderRadius:'1vmin' }}
           width="100%"
           src={require('../../ayccBuild.png')}
           alt="background"
         /> 
        : <LayoutOption onClose={() => setLayoutSelection(false)}/>}

        </div>
      )}
    </MainItem>
  );
};

Container.craft = {
  displayName: 'Container',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};

