import { useEditor } from '@craftjs/core';
import React, { useState } from 'react';
import { PageLayout } from '../PageLayout/Index';
import { ADD_OPTION, useOptionsContext } from '../../OptionsContext';
import '../Stepper.css'
import TitleStep from './TitleStep';
import BackgroundStep from './BackgroundStep';
import ThemeStep from './MainthemeStep';
import LogoStep from './LogoStep';
import NextButton from './NextButton';
import { PenEditIcon } from '../../components/editor/Viewport/Editor-icons/svgArray';
import { AddPageIcon, EditNameIcon } from '../../components/editor/Viewport/Editor-icons/EditorSvg';
import { category } from '../../components/selectors/basic/Image/ImageSettings';
import SelectionBox from '../../components/editor/Viewport/Pop-up/Images-Icons-Modal';
import { useSidesContext } from '../../SideContext';
import { useAppProps } from '../../DarkModeContext';

const Settings = () => {
    const { 
        walksFor, setWalksFor,
        setStart,
        appSettings, setAppSettings,
        pageSettings, setPageSettings,
        selectedTask, setSelectedTask,
        widgets,
        setWidgets,
        stepper,
        setStepper,
        selectedFile,
        setSelectedFile,
        TopProps,
        BottomProps,
        expandedFonts,
        setExpandedFonts,
        stepper2, setStepper2,
        fontTypes,
        select,
        finish, setFinish, topArea, leftArea, bottomArea,
        } = useSidesContext();

        const {setTuturial} = useAppProps();


    const {
        actions: { setProp },
    } = useEditor();

    const [selectedPageCount, setSelectedPageCount] = useState(1);
    const [error, setError] = useState('');

    const changeOptions = (options) => {
        idDispatch({ type: ADD_OPTION, payload: options });
    };

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;

    const selectedPageIcon = {
        name: 'default icon',
        path: "M16.5 4.5C16.5 5.17031 16.3547 5.80781 16.0875 6.38437L18.5625 10.6594C17.4516 11.8453 16.0219 12.7266 14.4141 13.1719L12 9L8.8125 14.5078C9.82031 14.8266 10.8891 15 12.0047 15C15.3188 15 18.2766 13.4672 20.2031 11.0625C20.7234 10.4156 21.6656 10.3125 22.3125 10.8281C22.9594 11.3438 23.0625 12.2906 22.5469 12.9375C20.0672 16.0219 16.2656 18 12 18C10.3406 18 8.74688 17.7 7.27969 17.1516L4.62656 21.7359C4.40625 22.1156 4.07812 22.425 3.68438 22.6219L1.0875 23.9203C0.853125 24.0375 0.576563 24.0234 0.35625 23.8875C0.135938 23.7516 0 23.5078 0 23.25V20.6531C0 20.2594 0.103125 19.8703 0.304688 19.5234L3.11719 14.6625C2.51719 14.1375 1.95938 13.5609 1.4625 12.9375C0.942188 12.2906 1.05 11.3484 1.69688 10.8281C2.34375 10.3078 3.28594 10.4156 3.80625 11.0625C4.07344 11.3953 4.35938 11.7094 4.65938 12.0047L7.9125 6.38437C7.65 5.8125 7.5 5.175 7.5 4.5C7.5 2.01562 9.51563 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM17.4844 18.4641C19.0125 17.8641 20.4141 17.0203 21.6516 15.9844L23.7 19.5234C23.8969 19.8656 24.0047 20.2547 24.0047 20.6531V23.25C24.0047 23.5078 23.8688 23.7516 23.6484 23.8875C23.4281 24.0234 23.1516 24.0375 22.9172 23.9203L20.3203 22.6219C19.9266 22.425 19.5984 22.1156 19.3781 21.7359L17.4844 18.4641ZM12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6Z",
        width: '24',
        heght: '24',
        viewBox: '0 0 24 24'
    };

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const addPages = (targetNumPages) => {
        const currentNumPages = treeData.length;
        let updatedTreeData = [...treeData];

        if (targetNumPages > currentNumPages) {
            // add pages
            for (let i = currentNumPages; i < targetNumPages; i++) {
                const name = 'New Page';
                const duplicates = updatedTreeData.filter(
                    page => page.name.toLowerCase().replace(/\d+/g, '').trim() === name.toLowerCase().replace(/\d+/g, '').trim()
                );
                const count = duplicates.length;

                const newAction = {
                    _id: generateRandomString(5),
                    name: name
                        ? (count === 0 ? `${name.toLowerCase().replace(/\d+/g, '').trim()}` : `${name.toLowerCase().replace(/\d+/g, '').trim()} ${count + 1}`)
                        : `${'New Page'.toLowerCase().replace(/\d+/g, '').trim()} ${count + 1}`,
                    icon: selectedPageIcon,
                    children: [],
                };

                updatedTreeData.push(newAction);
            }
        } else if (targetNumPages < currentNumPages) {
            updatedTreeData = updatedTreeData.slice(0, targetNumPages);
        }

        changeOptions(updatedTreeData);
    };

    const PagesCounter = ({ error, selectedPageCount, setSelectedPageCount, setError }) => {
        const MAX_PAGES = 30;

        const handleChangeCount = (value) => {
            if (value > MAX_PAGES) {
                setError(`Maximum allowed pages is ${MAX_PAGES}`);
            } else {
                setError('');
                setSelectedPageCount(value);
                addPages(value);
            }
        };

        return (
            <div className='w-auto h-auto flex flex-row justify-center flex-wrap items-center'>
                <div for='pageCountInput' className='text-[2vmin] mr-2 h-full'>
                    Pages:
                </div>
                <input
                    type='number'
                    className='text-[1.5vmin] max-w-[10vmin] mx-[1vmin] rounded-[1vmin] border-[.25vmin] border border-gray-300 text-[#000] px-[.5vmin] py-[.5vmin]'
                    value={selectedPageCount}
                    onChange={(e) => handleChangeCount(e.target.value)}
                    min='1'
                    step='1'
                    name='Page count'
                />
                {error && (
                    <div className='text-red-500 text-[1.5vmin] ml-2'>
                        {error}
                    </div>
                )}
            </div>
        );
    };

    const TreeView = ({ treeData, idDispatch }) => {
        const [editNodeId, setEditNodeId] = useState(null);
        const [newNodeName, setNewNodeName] = useState('');
        const [childId, setChildId] = useState(null);
        const newChildId = generateRandomString(5);
        const [iconsSquare, setIconsSquare] = useState(false);

        const handleEditChildName = (parentId, childId) => {
            const updatedTreeData = treeData.map(obj => {
                if (obj._id === parentId && obj.children) {
                    const updatedChildren = obj.children.map(child => {
                        if (child._id === childId) {
                            return {
                                ...child,
                                name: newNodeName,

                            };
                        }
                        return child;
                    });
                    return {
                        ...obj,
                        children: updatedChildren,
                    };
                }
                return obj;
            });
            changeOptions(updatedTreeData);

        };

        const handleEditChildIcon = (selectedIcon) => {
            if (!selectedIcon) {
                return null;
            }
            const updatedTreeData = treeData.map(obj => {
                if (selectedIcon) {
                    const updatedChildren = obj.children.map(child => {
                        if (child._id === editNodeId) {
                            return {
                                ...child,
                                icon: selectedIcon,

                            };
                        }
                        return child;
                    });
                    return {
                        ...obj,
                        children: updatedChildren,
                    };
                }
                return obj;
            });
            changeOptions(updatedTreeData);

        };

        const handleIconImageSelect = (selectedUrl) => {

            if (Array.isArray(selectedUrl) && selectedUrl.length) {
                handleEditChildIcon(selectedUrl)
            } else {
                handleEditChildIcon(selectedUrl)
            }
            setIconsSquare(false);


        };

        const addItemToChildren = (parentId, newItem) => {
            const parentObject = treeData.find(obj => obj._id === parentId);

            if (parentObject && parentObject.children) {

                const updatedTreeData = treeData.map(obj => {

                    if (obj._id === parentId) {
                        return {
                            ...obj,
                            children: [...obj.children, newItem],
                        };
                    }

                    return obj;
                });
                changeOptions(updatedTreeData);
                setChildId(null)
            };
        }



        return (
            <div className='p-[1vmin] w-full h-full overflow-scroll max-h-[30vmin]'>
                {treeData.map((node) => {

                    return (
                        <div className='flex w-full px-[1vmin] justify-between text-[1.9vmin]' key={node._id}>

                            <div className='w-full h-full flex flex-column'>
                                <div className='w-full flex items-center my-[3%] pl-[3%]' style={{ background: '#0867FC30', borderRadius: '1vmin' }}>
                                    <svg
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        aria-hidden="true"
                                        focusable="false"
                                        role="img"
                                        viewBox={node.icon.viewBox}
                                        width={'1.5vmin'}
                                        height={'1.5vmin'}
                                        fill={node.icon.fill || '#0867FC'}
                                    >
                                        <path d={node.icon.path} />
                                    </svg>
                                    <div className='ml-[3%]'>{node.name}</div>

                                </div>
                                {node.children.length !== 0 && <div>
                                    {node.children.map(child => {
                                        return (
                                            <div key={child._id} className='flex w-full ml-[3%] items-start'>
                                                {editNodeId === child._id ? (
                                                    <div className='w-full flex items-center rounded-[1.5vmin] p-[.5vmin] px-[.5vmin] mr-[.5vmin]' style={{ border: '.25vmin solid #0867FC' }}>
                                                        <div className='w-[fit-content] cursor-pointer' onClick={(e) => {
                                                            e.stopPropagation();
                                                            setIconsSquare(true)
                                                        }}>
                                                            <svg
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                role="img"
                                                                viewBox={child.icon.viewBox}
                                                                width={'1.5vmin'}
                                                                height={'1.5vmin'}
                                                                fill={child.icon.fill || '#0867FC'}
                                                            >
                                                                <path d={child.icon.path} />
                                                            </svg>
                                                            {iconsSquare && (
                                                                <SelectionBox
                                                                    category={category}
                                                                    setIconsSquare={setIconsSquare}
                                                                    iconsSquare={iconsSquare}
                                                                    onSelect={handleIconImageSelect}
                                                                />
                                                            )}
                                                        </div>
                                                        <input
                                                            className='flex-1 text-[#000] ml-[3%]'
                                                            onChange={(e) => {
                                                                e.stopPropagation()
                                                                setNewNodeName(e.target.value)
                                                            }}
                                                            placeholder='New name'
                                                            value={newNodeName}
                                                            autoFocus
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className='w-full my-[3%] ml-[3%] flex flex-row' key={child._id}>
                                                        <div className='w-full flex items-start'>
                                                            <div className='w-[fit-content] mt-[.5vmin]'>
                                                            <svg
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                role="img"
                                                                viewBox={child.icon.viewBox}
                                                                width={'1.5vmin'}
                                                                height={'1.5vmin'}
                                                                fill={child.icon.fill || '#0867FC'}
                                                            >
                                                                <path d={child.icon.path} />
                                                            </svg>
                                                            </div>
                                                            <div className='ml-[3%]'>
                                                                {child.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div
                                                    onClick={() => {
                                                        if (editNodeId === child._id) {
                                                            if (newNodeName.trim() === '') {
                                                                setEditNodeId(null)
                                                            } else {
                                                                handleEditChildName(node._id, child._id);
                                                            }
                                                        } else {
                                                            setEditNodeId(child._id);
                                                            setNewNodeName(child.name);
                                                        }
                                                    }}
                                                    style={{ color: newNodeName.trim() === '' ? 'gray' : '#0867FC' }}
                                                    className='cursor-pointer text-[1.3vmin] mt-[1vmin]'
                                                >
                                                    {editNodeId === child._id ? newNodeName.trim() === '' ? 'Cancel' : 'OK!' : (
                                                        <EditNameIcon fill='none' stroke='#161717' />
                                                    )}
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>

                            <div
                                className="iconspage m-[.5vmin]"
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addItemToChildren(node._id, { name: `Child Page`, _id: newChildId, icon: node.icon })
                                    }}
                                    className='cursor-pointer p-[.5vmin] transition-transform duration-300 transform-gpu hover:scale-125'
                                >
                                    <AddPageIcon width='2.5vmin' height='2.5vmin' fill='none' stroke='#161717' />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    if (topArea || bottomArea || leftArea) {
        return (
            <PageLayout/>
        )
    } else {
        return;
    }

    return (
        <div className="w-full h-full relative" >
            <div className='absolute top-0 left-0 bottom-0 right-0 h4 pt-[3vmin]'>Settings</div>
            {select !== null ?
                walksFor.main.value < 1 ?
                    walksFor.mainTheme.value === 3 ? (
                        <BackgroundStep
                            setStart={setStart}
                            select={select}
                            setAppSettings={setAppSettings}
                            setProp={setProp}
                            setStepper={setStepper}
                            setWalksFor={setWalksFor}
                            stepper={stepper}
                        />
                    ) : walksFor.mainTheme.value === 2 ? (
                        <ThemeStep
                            expandedFonts={expandedFonts}
                            setExpandedFonts={setExpandedFonts}
                            fontTypes={fontTypes}
                            setStart={setStart}
                            appSettings={appSettings}
                            setStepper={setStepper}
                            setWalksFor={setWalksFor}
                            setAppSettings={setAppSettings}
                            select={select}
                            stepper={stepper}
                        />
                    ) : walksFor.mainTheme.value === 0 ? (
                        <TitleStep
                            stepper={stepper}
                            setStepper={setStepper}
                            appSettings={appSettings}
                            setWalksFor={setWalksFor}
                            select={select}
                            walksFor={walksFor}
                            setStart={setStart}
                            setAppSettings={setAppSettings}
                        />
                    ) : walksFor.mainTheme.value === 1 && (
                        <LogoStep
                            setStart={setStart}
                            stepper={stepper}
                            setWalksFor={setWalksFor}
                            select={select}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            setStepper={setStepper}
                            setAppSettings={setAppSettings}
                        />
                    ) : walksFor.main.value < 2 ?
                        walksFor.mainApp.value >= 0 && walksFor.mainApp.value < 4 ? (
                            <div className='w-full h-full'>
                                <PageLayout
                                    setStart={setStart}
                                    BottomProps={BottomProps}
                                    TopProps={TopProps}
                                    widgets={widgets} setWidgets={setWidgets}
                                    selectedTask={selectedTask} setSelectedTask={setSelectedTask}
                                    pageSettings={pageSettings} setPageSettings={setPageSettings}
                                    stepper2={stepper2} setStepper2={setStepper2}
                                    walksFor={walksFor} setWalksFor={setWalksFor}
                                />
                            </div>
                        ) : (
                            <div className='w-full h-full relative'>
                                <ol style={{ listStyle: 'inside', padding: 0, textAlign: 'center', width: '100%', color:'#FFF'}}
                                    // onClick={() => addPages(1)}
                                    className='text-[1.7vmin] text-[#FFF]'>
                                    <li>How many pages you want for the app ?</li>
                                </ol>

                                <div className=' my-[2vmin] h-[fit-content] flex flex-column items-center'>
                                    <PagesCounter error={error} selectedPageCount={selectedPageCount}
                                        setError={setError} setSelectedPageCount={setSelectedPageCount} />
                                    <div className='shadow-md rounded-[1.5vmin] w-[80%] justify-center my-[2vmin]'>
                                        <TreeView treeData={treeData} idDispatch={idDispatch} />
                                    </div>
                                </div>
                                <NextButton setStart={setStart}
                                    onClick={() => {
                                        setStepper(3)
                                        setWalksFor((prev) => ({
                                            ...prev,
                                            main: { value: 2 },
                                        }))
                                    }}
                                />
                            </div>
                        ) : walksFor.main.value === 2 && (
                            <div className='w-full h-full'>
                                <div className='text-[1.8vmin] text-center'>Move to The page builder to finish the setup. And complete the content widgets with drag and drop option</div>
                                <div onClick={() => {
                                    setWalksFor((prev) => ({
                                        ...prev,
                                        main: { value: 2 },
                                        mainApp: { value: 4 }
                                      }))
                                    setTuturial(false)
                                    setFinish(true)
                                }}
                                    style={{ background: '#FFF', width: '100%', padding: '.5vmin', margin: '1vmin 0', cursor: 'pointer', color: '#0867FC', fontSize: '1.5vmin', border: '.25vmin solid #0857FC', borderRadius: '1.5vmin', textAlign: 'center', }}>Move To The Page Builder</div>
                            </div>
                        ) : (
                    <div className='w-full h-full flex justify-center items-center py-[4vmin] text-[2vmin]'>
                        Waiting the Tuturial..
                    </div>
                )}
        </div>
    );
};

export default Settings;
