/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { useState } from "react";
import GradientSettings from "../../GradientColor";

const BackgroundComponent = ({
value,
propValue,
value2,
key,
options,
setProp,
propKey,
node,
property,
el,
isDarkMode,
has

}) => {

const [gradSetting, setGradSetting] = useState(false)


const prevColors = [
    '#e83030',
    '#5f2b2b',
    '#c31313',
    '#0867fc',
    '#f84173',
    '#ebebeb',
    '#111f3e',
    '#5558b1', 
  ];

  
  const checkSelected = (property) => {
    const {value} = property
     
    if (!value) {
      return ''; 
    }
    
    if (typeof value !== 'string') {
      return value;
    }

    if (value.startsWith('#')) {
      return value; 
    }

    if (value.startsWith('tr')) {
      return 'Transparent'; 
    }

    return 'Gradient'; 
   };
 

    return (
        <div className='h-full min-h-[3vmin] my-[1vmin] flex flex-column justify-center w-full'>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>

           <div className='relative my-[1vmin] min-w-[100%] mx-[.5vmin] h-[7vmin] flex justify-center align-items-center rounded-[1vmin] overflow-hidden'>
             <input style={{ cursor: 'pointer', minWidth: '150%', height: '130%' }} type='color' value={property.value}

              onChange={(e) => setProp(node.id, (props) => {
                let newValue = e.target.value
                props[propKey][el][has].value = newValue
              }
              )}
             />
             <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[1.8vmin] w-full h-full flex align-items-center justify-center text-center'>
               <div>{checkSelected(property) || 'Click Here.'}</div>
             </div>
           </div>

           <ul style={{ width: 'fit-content', padding: '0', margin: '0 .5vmin', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto auto' }}>
             <li className='cursor-pointer' style={{ background: "url(https://i.pinimg.com/originals/40/8e/28/408e28da9e2773e4b65c9bba40307167.png) center", margin: '.35vmin', display:'flex', justifyContent:'center', alignItems:'center',  width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #0867FC30',}} 
             onClick={(e) => setProp(node.id, (props) => {
               props[propKey][el][has].value = 'transparent'
             }
             )}
             >
             </li>
             {prevColors.map((color, index) => (
              <li onClick={(e) => {
                setProp(node.id, (props) => {
                  props[propKey][el][has].value = color
                }
                )}}
               style={{ cursor: 'pointer', margin: '.35vmin', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #e9e9e9', background: color }} key={index}></li>
             ))}
             <li className='cursor-pointer' style={{ backgroundImage: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)', margin: '.35vmin', display:'flex', justifyContent:'center', alignItems:'center',  width: '3vmin', height: '3vmin', borderRadius: '50%', padding:'1px'}} onClick={() => {
              setGradSetting(true)
             }}>
             </li>
           </ul>
           {gradSetting && (
            <div key={propKey} className='relative pt-[1.5vmin]'>
              <GradientSettings property={property} setProp={setProp} propKey={propKey} node={node} el={el} has={has}/>
            </div>
           )}

         </div>

       </div>
    )
}

export default BackgroundComponent;