import React, { useCallback, useState } from 'react'
import { AddPageIcon, DeleteNameIcon, EditNameIcon, UndoIcon } from '../../Viewport/Editor-icons/EditorSvg';
import { CloseIcon } from '../../../land/Svg';

export const ClassesHandle = React.memo (
    ({options, setProp, node, label}) => {
      

const [typing, setTyping] = useState('');
const [removedClasses, setRemovedClasses] = useState([]);
const [addClass, setAddClass] = useState(false);
const [editClass, setEditClass] = useState(null);

const handleRemoveClass = useCallback((classToRemove) => {
    const updatedClasses = options['default']?.className?.split(' ').filter(cls => cls !== classToRemove).join(' ');
    setProp(node.id, (props) => (props['default'].className = updatedClasses));
    setRemovedClasses((prev) => [...prev, classToRemove]); // Track removed classes
  }, [options['default'].className, setProp, node.id]);

  const handleRestoreClass = useCallback((classToRestore) => {
    const updatedClasses = options['default'].className ? `${options['default'].className} ${classToRestore}` : classToRestore;
    setProp(node.id, (props) => (props['default'].className = updatedClasses));
    setRemovedClasses((prev) => prev.filter(cls => cls !== classToRestore)); // Remove from removedClasses
  }, [options['default'].className, setProp, node.id]);

  const handleEditClass = useCallback((oldClass, newClass) => {
    const updatedClasses = options['default']?.className?.split(' ').map(cls => (cls === oldClass ? newClass : cls)).join(' ');
    setProp(node.id, (props) => (props['default'].className = updatedClasses));
    setRemovedClasses((prev) => prev.map(cls => (cls === oldClass ? newClass : cls))); // Update removedClasses
  }, [options['default'].className, setProp, node.id]);

  const handleAddClasses = useCallback((newClass) => {
    const updatedClasses = [...new Set([...options['default']?.className?.split(' '), newClass])].join(' ');
    setProp(node.id, (props) => (props['default'].className = updatedClasses));
  }, [options['default'].className, setProp, node.id]);

  const classNamesArray = options['default']?.className ? options['default']?.className?.split(' ') : [];
  const displayedClasses = new Set();

    return (
        <div>
            <div className="myApp-container">
              <h2 className="myApp-title text-[#FFF]">{label}</h2>
              <div className="myApp-class-list">
                {classNamesArray.filter(cls => cls).map((cls) => {
                  if (displayedClasses.has(cls)) return null; // Skip if already displayed
                  displayedClasses.add(cls); // Add to Set for tracking
        
                  return (
                    <div key={cls} className="myApp-class-item" style={{ flexDirection: 'column'}}>
                      <div className='flex flex-row w-full'>
                        {editClass === cls ? (
                          <input
                            type="text"
                            defaultValue={cls}
                            style={{ color: '#000', display: 'block', width: '100%' }}
                            // onBlur={(e) => handleEditClass(cls, e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleEditClass(cls, e.target.value)
                              }
                              }}
                            className="myApp-input"
                          />
                        ) : (
                          <span className="myApp-class-name text-[#e9e9e9]">{cls}</span>
                        )}
        
                        {editClass !== cls ? (
                          <div
                            title='Edit ClassName'
                            style={{ display: 'flex', margin: '0 1vmin', marginLeft: 0, alignItems: 'center', justifyContent: 'center' }}
                            onClick={() => {
                              setEditClass(cls);
                              setTyping(cls); // Removed redundant check
                            }}
                          >
                            <EditNameIcon viewBox={'0 0 19 19'} fill='#0867FC' stroke='#0867FC' />
                          </div>
                        ) : typing === cls ? (
                          <div
                            className='cursor-pointer'
                            style={{ display: 'flex', margin: '0 1vmin', marginLeft: 0, alignItems: 'center', justifyContent: 'center' }}
                            onClick={() => setEditClass(null)}
                          >
                            <CloseIcon width='1.3vmin' height='1.3vmin' fill='#0867FC' />
                          </div>
                        ) : (
                          <div
                            className='cursor-pointer'
                            style={{ display: 'flex', margin: '0 1vmin', marginLeft: 0, alignItems: 'center', justifyContent: 'center' }}
                            onClick={() => setEditClass(null)}
                          >
                            Done
                          </div>
                        )}
        
                        <div title='Delete' className="myApp-button" onClick={() => handleRemoveClass(cls)}>
                          <DeleteNameIcon fill='#FFF' />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
        
              <div className='d-block w-full'>
                <div
                  title='Add ClassName'
                  className="myApp-button relative"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onClick={() => setAddClass(true)}
                >
                  <div className='p-[.25vmin]'><AddPageIcon viewBox={'0 0 19 19'} fill='#FFF' /></div>
        
                  {addClass && (
                    <input
                      type="text"
                      style={{color:'#000'}}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddClasses(e.target.value)
                        }
                        }}
                      // onBlur={(e) => handleAddClasses(e.target.value)}
                      className="myApp-input"
                    />
                  )}
        
                  <p className='my-auto mx-[.5vmin]'>Add</p>
        
                  {addClass && (
                    <div
                      className='absolute bottom-[-1.7vmin] right-[.7vmin] text-[#0867FC]'
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddClass(false);
                      }}
                    >
                      Cancel
                    </div>
                  )}
                </div>
              </div>
        
              {removedClasses.length > 0 && (
                <div style={{display:'flex', flexDirection:'column', color:'#000000A1'}} className="myApp-class-list mt-[2vmin]">
                  <h2 className='myApp-title' style={{fontSize:'1.7vmin'}}>Recently Deleted</h2>
                  <div className='w-full h-auto flex flex-row flex-wrap'>
                  {removedClasses.map((removedClass) => (
                    <div key={removedClass} className="myApp-class-item">
                      <span className="myApp-class-name">{removedClass}</span>
                      <div className="myApp-button" onClick={() => handleRestoreClass(removedClass)}>
                        <UndoIcon fill='#FFFFFF' width='1.5vmin' height='1.5vmin' />
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              )}
            </div>
          </div>
    )
})