import '@progress/kendo-theme-default/dist/all.css';
import '../Stepper.css';
import {  Circle2, Circle4 } from '../iconsSvg.js';
import BottomArea from './BottomArea.js'
import TopArea from './TopArea.js';
import { EditNameIcon } from '../../components/editor/Viewport/Editor-icons/EditorSvg.js';
import { useState } from 'react';
import { PenEditIcon } from '../../components/editor/Viewport/Editor-icons/svgArray.js';
import { SelectBox } from '../../components/editor/Toolbar/Toolbar-Types/SelectBox.js';
import SelectionBox from '../../components/editor/Viewport/Pop-up/Images-Icons-Modal.js';
import { category } from '../../components/selectors/basic/Image/ImageSettings.js';
import NextButton from '../SettingsArea/NextButton.js';
import { useSidesContext } from '../../SideContext.js';


export const PageLayout = () => {

    const {
      open, setStart, BottomProps, TopProps, setWidgets, widgets, selectedTask,
      setSelectedTask, setPageSettings, pageSettings, setStepper2, stepper2, setWalksFor, walksFor
    } = useSidesContext()

    const currentSide = typeof open === 'string'
    ? open.startsWith('row') 
        ? 'Top Area' 
        : open.startsWith('sideLR') 
            ? 'SidesLR Area' 
            : 'Bottom Area'
    : 'Widget Area'; // Fallback if open is a number (or other non-string type)

  const Widgets = () => {

    const [editLayers, setEditLayers] = useState(false)
    const [openLayer, setOpenLayer] = useState(null)
    const [iconsSquare, setIconsSquare] = useState(false)
    const [selectedIcon, setSelectedIcon] = useState({});
    const [tempTask, setTempTask] = useState({ name: '', icon: {}, event: '' });

    const handleIconImageSelect = (selectedUrl) => {

      if (Array.isArray(selectedUrl) && selectedUrl.length) {
          setSelectedIcon(selectedUrl);
          handleInputChange(null, selectedUrl, 'icon')
      } else {
          setSelectedIcon(selectedUrl)
          handleInputChange(null, selectedUrl, 'icon')
      }
      setIconsSquare(false);

  };

    const toggleSelection = (name) => {
      setSelectedTask(prevItems =>
        prevItems.map(item =>
          item.name === name
            ? { ...item, selected: !item.selected }
            : item
        )
      );
    };
  
    const handleTaskClick = (index) => {
      setEditLayers(true)
      setOpenLayer(index);
      setTempTask(selectedTask[index]); 
    };
  
    const handleInputChange = (e, value, field) => {

     let checkValue = e !== null ? e.target.value : value

      setTempTask(prev => ({
        ...prev,
        [field]: checkValue
      }));
    };
  
    const handleDone = () => {
      setSelectedTask(prevTasks =>
        prevTasks.map((task, index) =>
          index === openLayer
            ? { ...task, ...tempTask }
            : task
        )
      );
      setOpenLayer(null); 
    };

    const handleClosePopup = () => {
      setEditLayers(false)
      setOpenLayer(null);
    };

    let currentWidget = selectedTask[openLayer] !== null ? selectedTask[openLayer] : null
    

  return (
    <div className='flex flex-column justify-start items-center mt-[2vmin]'>
    <div className='flex justify-between w-full'>
      <li className='cursor-pointer'>
        Widgets
      </li>
      <div
        onClick={() => setWidgets(!widgets)}
        style={{ color: '#0867FC', cursor: 'pointer' }}
      >
        {widgets ? 'ON' : 'OFF'}
      </div>
    </div>
    {widgets && (
        
       <div className='relative w-full flex flex-column justify-start items-center mt-[2vmin]'>
      <ol style={{listStyle:'outside'}} className='w-full h-full max-h-[33vmin] overflow-y-scroll flex flex-col pl-[1.5vmin] list-decimal'>
        {selectedTask.map((item, index) => (
         <div
          key={index}
          className='flex flex-row justify-between w-full'>
           <li className='w-full p-[.25vmin]'>
           {item.name}
          </li>
          {/* copy the next code into the edit area */}
          <div
          className={`flex flex-row justify-center w-full text-[#0867FC] px-[1vmin] py-[.5vmin] rounded`}>
          <div
          style={{opacity: !item.selected && '0', pointerEvents: !item.selected &&'none'}}
          className='mx-[1vmin] cursor-pointer'
          onClick={() => handleTaskClick(index)}
          ><PenEditIcon fill='#0867FC60'/></div>

          <div
          className='cursor-pointer'
          onClick={() => toggleSelection(item.name)}
          >{item.selected ? 'ON' : 'OFF'}</div>
          
        </div>
         </div>
        ))}
      </ol>
      {editLayers && currentWidget !== null && <div
      className='absolute top-0 left-[4%] bg-[#FFFFFF] py-[2vmin] border-2 w-[fit-content] h-[fit-content] shadow-md rounded-[1.5vmin] px-[3vmin]'
      >
      <div className='w-full h-full flex flex-column'>
        <div className='w-full text-[#fff] text-center bg-[#0867FC] rounded-[.7vmin] py-[.5vmin]'>
        Edit '{currentWidget.name}' widget
        </div>
        <div className='flex flex-column w-full h-full'>
        <div className='my-[4%] w-full flex flex-row justify-between items-center p-[2%]'>
              <div className='w-[30%]'>Name</div>
              <div className='w-[60%] flex justify-center'>
                <input
                  type='text'
                  value={openLayer.name}
                  onChange={(e) => handleInputChange(e, null, 'name')}
                  style={{padding:'4%'}}
                  className='rounded-[1vmin] text-[1.3vmin] border bg-[transparent] text-[#000]'
                />
              </div>
            </div>
            <div className='my-[4%] w-full flex flex-column items-center p-[2%]'>
             <div className='w-full flex flex-row'>
             <div className='w-[30%]'>Icon</div>
             <div
                  style={{ color: '#0867FC', cursor:'pointer', width:'100%',  textAlign:'center'}}
                  onClick={() => setIconsSquare(true)}
              >
                  Select an Icon
              </div>
              {iconsSquare && (
                  <SelectionBox
                      category={category}
                      setIconsSquare={setIconsSquare}
                      iconsSquare={iconsSquare}
                      onSelect={handleIconImageSelect}
                  />
              )}             
            </div>
            <div className="flex justify-end pl-[20%]">
            <svg
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  viewBox={selectedIcon.viewBox}
                  width={'4vmin'}
                  height={'4vmin'}
                  fill={selectedIcon.fill || '#0867FC'}
                >
                  <path d={selectedIcon.path} />
                </svg>
              </div>
            </div>
            {/* <div className='my-[4%] w-full flex flex-row justify-between items-center p-[2%]'>
              <div className='w-[30%]'>Target Event</div>
              <div className='w-[60%] flex justify-center'>
                <input
                  type='text'
                  value={openLayer.event}
                  onChange={(e) => handleInputChange(e, null, 'event')}
                  className='rounded-[.7vmin] border p-[1%] rounded bg-[transparent] text-[#000]'
                />
              </div>
            </div> */}
        </div>
        <div className='w-full flex flex-row justify-around mt-[4vmin]'>
        <div
        onClick={handleDone}
        className='text-center cursor-pointer px-[10%] py-[1%] bg-[#0867FC] text-white rounded'
      >
        Done
      </div>
      <div
        onClick={handleClosePopup}
        className='text-center cursor-pointer px-[10%] py-[1%] bg-[gray] text-[#fff] rounded'
      >
        Cancel
      </div>
        </div>
      </div>

      </div>}
    </div>
    )}
  </div>
  )
  }

  const LeftArea = () => {
  return (
  <div className='relative w-full h-full flex-col flex py-[4vmin] text-[2vmin] text-[#FFF]'>
      <div className='w-full h-full flex flex-column'>
      <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', height:'100%', width:'100%', padding:0 }}>
        <div className='flex flex-column justify-between w-full'>
          <div className='flex justify-between w-full'>
            <li className={` cursor-pointer`} >
              Sidebar
            </li>
            <div onClick={(e) => {
              setPageSettings((prevSettings) => ({
                ...prevSettings,
                sidebar: {
                  ...prevSettings.sidebar,
                  visibility: !prevSettings.sidebar.visibility,
                  position: 'left' 

                },
              }))
              
            }} style={{ color: '#0867FC', cursor: 'pointer' }}>{!pageSettings.sidebar.visibility ? 'OFF' : 'ON'}</div>
          </div>
          <ol style={{ listStyle: 'inside', padding:0, width:'100%' }}>
            <div className='flex justify-between w-full'>
              <li className={` cursor-pointer`} >Open Way</li>
              <div className={` cursor-pointer`} onClick={(e) => {
                setPageSettings((prevSettings) => ({
                  ...prevSettings,
                  sidebar: {
                    ...prevSettings.sidebar,
                    openWay: !prevSettings.sidebar.openWay,
                  },
                }))
              }} style={{ color: '#0867FC', cursor: 'pointer',  }}>{!pageSettings.sidebar.openWay ? 'Move to the side' : 'Menu Icon'}</div>
            </div>
          </ol>
          <ol style={{ listStyle: 'inside', padding:0, width:'100%' }}>
              <div className='flex justify-between w-full'>
                <li className={` cursor-pointer`} >
                  Sidebar position
                </li>
                <div onClick={(e) => {
                  setPageSettings((prevSettings) => ({
                    ...prevSettings,
                    sidebar: {
                      ...prevSettings.sidebar,
                      position: prevSettings.sidebar.position === '' ? 'right' : prevSettings.sidebar.position === 'right' ? 'left' : 'right',
                    },
                  }));
                }} style={{ color: '#0867FC', cursor: 'pointer',  }}>{pageSettings.sidebar.position === '' ? 'Set The side' : pageSettings.sidebar.position === 'left' ? 'Left side' : 'right side'}</div>

              </div>
            </ol>
        </div>
        <Widgets position={'left'} />
      </ol>
      </div>
      {/* {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
        <Circle2 fill={stepper2 >= 2 ? 'green' : ''} />
      </div>} */}
    </div>
  )
  }

  const RightArea = () => {
  return (
  <div className='relative w-full h-full flex-col flex   py-[4vmin] text-[2vmin]'>
       <div className='w-full h-full flex-column flex'>
       <div className='text-[1.6vmin] mb-[3vmin]'>
          {`You want to use "The sidebar" in the right ? this will remove "The Sidebar" from the left side`}
        </div>

        <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', padding:0, width:'100%' }}>
          <div className='flex flex-column justify-between w-full' >
            <div className='flex justify-between w-full'>
              <li className={` cursor-pointer`} >
                Sidebar
              </li>
              <div onClick={(e) => {
                setPageSettings((prevSettings) => ({
                  ...prevSettings,
                  sidebar: {
                    ...prevSettings.sidebar,
                    visibility: !prevSettings.sidebar.visibility,
                    position: 'right' 
                  },
                }))

              }} style={{ color: '#0867FC', cursor: 'pointer' }}>{!pageSettings.sidebar.visibility ? 'OFF' : 'ON'}</div>
            </div>
            <ol style={{ listStyle: 'inside', padding:0, width:'100%' }}>
              <div className='flex justify-between w-full'>
                <li className={` cursor-pointer`} >Open Way</li>
                <div className={``} onClick={(e) => {
                  setPageSettings((prevSettings) => ({
                    ...prevSettings,
                    sidebar: {
                      ...prevSettings.sidebar,
                      openWay: !prevSettings.sidebar.openWay,
                    },
                  }))
                }} style={{ color: '#0867FC', cursor: 'pointer',  }}>{!pageSettings.sidebar.openWay ? 'Move to the side' : 'Menu Icon'}</div>
              </div>
            </ol>
            <ol style={{ listStyle: 'inside', padding:0, width:'100%' }}>
              <div className='flex justify-between w-full'>
                <li className={` cursor-pointer`} >
                  Sidebar position
                </li>
                <div onClick={(e) => {
                  setPageSettings((prevSettings) => ({
                    ...prevSettings,
                    sidebar: {
                      ...prevSettings.sidebar,
                      position: prevSettings.sidebar.position === '' ? 'right' : prevSettings.sidebar.position === 'right' ? 'left' : 'right',
                    },
                  }));
                }} style={{ color: '#0867FC', cursor: 'pointer',  }}>{pageSettings.sidebar.position === '' ? 'Set The side' : pageSettings.sidebar.position === 'left' ? 'Left side' : 'right side'}</div>

              </div>
            </ol>
          </div>
          <Widgets position={'right'} />
        </ol>

       </div>
        <NextButton setStart={setStart}
        onClick={() => {
          setStepper2(4)
          setWalksFor((prev) => ({
            ...prev,
            main: { value: 1.800 },
            mainApp: { value: 4 }
          }))
        }}
        />
        {/* {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
          <Circle4 fill={stepper2 >= 4 ? 'green' : ''} />
        </div>} */}
      </div>
  )
  }

  return (
    <>
      {currentSide === 'Top Area' ?
          (
            <TopArea/>
          ) : currentSide === 'SidesLR Area' ? (
            <LeftArea/>
          ) : currentSide === 'Bottom Area' ? (
            <BottomArea/>
          ) : walksFor.mainApp.value === 3 && (
            <RightArea/>
          )}
    </>
  );
}
