/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useCallback, useEffect, useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import SelectionBox from '../../../editor/Viewport/Pop-up/Images-Icons-Modal';
import { useNode } from '@craftjs/core';
import Toggle from '../../../editor/Toolbar/Toggle';
import { imagesArr } from '../../../editor/Viewport/Pop-up/ImageArray'
import { ArrowToRight } from '../../../editor/Viewport/Editor-icons/svgArray';
import { StatesItem } from '../MainLayouts/HookStates';

export const category = [
  // { tap: 'All Available'},
  { icon: 'https://i.ibb.co/59HkdbY/iconmonstr-accessibility-lined-48.png', tap: "Gallery" },
  { icon: 'https://i.ibb.co/6m7cfkZ/iconmonstr-cat-7-48.png', tap: "Our team" },
  { icon: 'https://i.ibb.co/hLMZ2HB/iconmonstr-language-4-48.png', tap: "Statistics" },
  { icon: 'https://i.ibb.co/59HkdbY/iconmonstr-accessibility-lined-48.png', tap: "Price" },
  { icon: 'https://i.ibb.co/k89sJ1t/iconmonstr-car-21-48.png', tap: "Contact Us/Help/Support " },
  { icon: 'https://i.ibb.co/RpwQ6MK/iconmonstr-marketing-14-48-1.png', tap: "Services" },
  { icon: 'https://i.ibb.co/nQk7FhC/iconmonstr-error-lined-48.png', tap: "Email/Pin/Note" },
  { icon: 'https://i.ibb.co/XbY1pX2/iconmonstr-user-circle-thin-48.png', tap: "Stars" },
  { icon: 'https://i.ibb.co/27rbZGy/iconmonstr-warning-lined-48.png', tap: "Clock/Time" },
  { icon: 'https://i.ibb.co/yyDnGFk/iconmonstr-generation-5-48.png', tap: "Animals" },
  { icon: 'https://i.ibb.co/jRcs2K0/iconmonstr-school-22-48.png', tap: "Education" },
  { icon: 'https://i.ibb.co/rvrR5Fm/iconmonstr-clothing-5-48.png', tap: "Warning" },
  { icon: 'https://i.ibb.co/jkgdn0S/iconmonstr-arrow-right-alt-filled-48.png', tap: "Transport" },
  { icon: 'https://i.ibb.co/Yy4v3HJ/iconmonstr-bar-chart-thin-48.png', tap: "Art" },
  { icon: 'https://i.ibb.co/YTWbxqN/iconmonstr-code-thin-48.png', tap: "Nature" },
  { icon: 'https://i.ibb.co/7QXx90t/iconmonstr-connection-8-48.png', tap: "Shopping" },
  { icon: 'https://i.ibb.co/f9D935b/iconmonstr-flash-thin-48.png', tap: "Home" },
  { icon: 'https://i.ibb.co/bWyTW3z/iconmonstr-customer-9-48.png', tap: "Entertainment" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Chat" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Message" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Arrow" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Health" },
  { icon: 'https://i.ibb.co/WWNXhjL/iconmonstr-tablet-6-48.png', tap: "Rating and Feedback" },
  { icon: 'https://i.ibb.co/Y7hnxhT/iconmonstr-cricket-1-48.png', tap: "Calendar" },
  { icon: 'https://i.ibb.co/Y7hnxhT/iconmonstr-cricket-1-48.png', tap: "Gift" },
  { icon: 'https://i.ibb.co/Y7hnxhT/iconmonstr-cricket-1-48.png', tap: "Presentation" },
  { icon: 'https://i.ibb.co/vPyt1Xv/iconmonstr-building-45-48-2.png', tap: "Location" },

];

export const imgsCate = [
  { name: "IT/Marketing teams" },
  { name: "construction" },
  { name: "Restaurant" },
  { name: "Real estate agency" },
  { name: "Beauty saloon" },
  { name: "coffee house" },
  { name: "Trucking" },
  { name: "Grocery" },
  { name: "Auto repair shop" },
  { name: "Shipping" },
  { name: "Workshop" },
  { name: "Healthcare" },
  { name: "Education" },
  { name: "Portraits" },
  { name: "Travel" },
  { name: "Sport" },
  { name: "Management" },
  { name: "Consulting" },
  { name: "Entertainment" },
  { name: "Interior Design" },
  { name: "Bakery" },
  { name: "Event planning/Wedding" },
  { name: "Home services" },
]


export const ImageCSettings = () => {
  const [iconsSquare, setIconsSquare] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [images, setImages] = useState(imagesArr)
  const [imgsCategory, setImgsCategory] = useState(imgsCate)

  const [expanded, setExpanded] = useState(null)

  const {
    node,
    options,
    actions: { setProp }
  } = useNode((node) => ({
    props: node.data.props,
    node: node,
    options:node.data.props
  }));

  const [text, setText] = useState(false)

  const toggleIsText = useCallback(() => {
    setText((prevText) => !prevText);

    setProp((prop) => {
      prop.default.convertToTxt = !prop.default.convertToTxt;
    });
  }, [setProp]);



  const [activeTab, setActiveTab] = useState("Style Manager");

  const handleIconImageSelect = (selectedUrl) => {
    if (Array.isArray(selectedUrl) && selectedUrl.length && selectedUrl[0] !== selectedIcon) {
      setSelectedIcon(selectedUrl[0]);
    } else if (selectedUrl !== selectedIcon) {
      setSelectedIcon(selectedUrl);
    }
    setIconsSquare(false);
  };

  const [customize, setCustomize] = useState({})

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };



  const handleCustomize = (group) => {
    setCustomize((prev) => ({
      ...prev,
      [group]: !prev[group], // Toggle the current group's state
    }));
  };


  return (
    <div className='flex flex-col component-settings' style={{ height: '100%', zIndex: 9999999 }}>
      <div>
        <div style={{ borderRadius: '1.5vmin', width: '100%', backdropFilter: 'blur(10px)', background: '#e9e9e930' }} className={`shadow-sm absolute left-0 top-0 z-[999999999]`}>
          <input
            placeholder="Search by title..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              display: 'flex',
              margin: '1vmin auto',
              padding: '1vmin 1.4vmin',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              fontSize: '2vmin',
              color: '#98919190',
              backgroundColor: 'transparent',
              border: '.25vmin solid #0867FC70',
              borderRadius: '2vmin',
              outline: 'none',
              width: '80%',
              height: '4vmin'

            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#007bff';
              e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#0867FC70';
              e.target.style.boxShadow = 'none';
            }}

          />
          <div className='w-full text-center text-[2.2vmin] my-[.5vmin] text-[#0867FC80]'
            style={{ fontWeight: '700', userSelect: 'none' }}>
            CUSTOMIZE
          </div>
        </div>

<div style={{ width: "100%" }}>
 <div className='w-full mb-[1vmin] flex flex-row justify-between'>
                    <StatesItem currentState="default" actionState={options.actionState} setProp={setProp} />
                    <StatesItem currentState="hovered" actionState={options.actionState} setProp={setProp} />
                    <StatesItem currentState="active" actionState={options.actionState} setProp={setProp} />
                      </div>
            <div style={{ display: 'flex', margin: 'auto auto', justifyContent: 'center', alignItems: 'center', overflowY:'scroll', minWidth:'100%', maxHeight:'75vmin', paddingBottom: expanded && '3vh', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%' }}>
            {/* <Toggle toggleIsImg={toggleIsText} on={text} first={'Text'} second={'Image'} /> */}
            <div style={{display: options['actionState'] !== 'default' ? 'none' : 'flex', alignItems:'center', width:'100%', flexDirection:'column',}}>
              
            <ToolbarItem
                  selectedIcon={selectedIcon}
                  IconsSquare={iconsSquare}
                  setIconsSquare={setIconsSquare}
                  full={true}
                  propKey="src"
                  type="src"
                  placeholder='Enter image URL'
                />
                {iconsSquare && (
    <SelectionBox
    isImages={true}
    pozition='absolute top-0 right-0'
    images={images}
    setImgsCategory={setImgsCategory}
    imgsCategory={imgsCategory}
    category={category}
    onSelect={handleIconImageSelect}
    iconsSquare={iconsSquare}
    setIconsSquare={setIconsSquare}
    setImages={setImages}
    />
    )}

              <ToolbarSection 
                searchTerm={searchTerm}
                expanded={expanded === 'Classes'}
                setExpanded={() => setExpanded(expanded === 'Classes' ? null : 'Classes')}
                title="Classes"
              >
                <ToolbarItem full={true} propKey="className" type="classes" label="Classes" />

              </ToolbarSection>
              <ToolbarSection
                searchTerm={searchTerm}
                title="Source"
                expanded={expanded === 'Source'}
                setExpanded={() => setExpanded(expanded === 'Source' ? null : 'Source')} >
                
                <ToolbarItem
                  full={true}
                  optionsDrop={['fill', 'contain', 'cover', 'none']}
                  propKey="objectFit"
                  type="select-drop"
                  label="Object Fit"
                />
                <ToolbarItem
                  full={true}
                  propKey="href"
                  type="select"
                  label="href"
                />
              </ToolbarSection>
              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'margin'}
                setExpanded={() => setExpanded(expanded === 'margin' ? null : 'margin')}
                title="margin"
                props={['margin']}
                summary={({ }) => {
                  return;
                }}
              >
                {customize['margin'] ? (
                  <>
                    <ToolbarItem propKey="marginTop" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Top" />
                    <ToolbarItem propKey="marginRight" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Right" />
                    <ToolbarItem propKey="marginBottom" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Bottom" />
                    <ToolbarItem propKey="marginLeft" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Left" /></>
                ) : (
                  <ToolbarItem propKey="margin" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Margin" />
                )}

                <div onClick={() => handleCustomize('margin')} style={{ borderRadius: '0 1vmin 1vmin 1vmin', border: '.25vmin solid', background: '#e9e9e930' }} className='absolute top-0 min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#e9e9e980] p-[.5vmin] hover:text-[#0867FC]'>{customize['margin'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>

              </ToolbarSection>


              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Padding'}
                setExpanded={() => setExpanded(expanded === 'Padding' ? null : 'Padding')}
                title="Padding"
                props={['padding']}
                summary={({ }) => {
                  return;
                }}
              >
                {customize['padding'] ? (
                  <>
                    <ToolbarItem propKey="paddingTop" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Top" />
                    <ToolbarItem propKey="paddingRight" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Right" />
                    <ToolbarItem propKey="paddingBottom" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Bottom" />
                    <ToolbarItem propKey="paddingLeft" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Left" />
                  </>
                ) : (
                  <ToolbarItem propKey="padding" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Padding" />
                )}

                <div onClick={() => handleCustomize('padding')} style={{ borderRadius: '0 1vmin 1vmin 1vmin', border: '.25vmin solid', background: '#e9e9e930' }} className='absolute top-0 min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#e9e9e980] p-[.5vmin] hover:text-[#0867FC]'>{customize['padding'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>
              </ToolbarSection>
              </div>

              
              <ToolbarSection
                searchTerm={searchTerm}
                expanded={expanded === 'Appearance'}
                setExpanded={() => setExpanded(expanded === 'Appearance' ? null : 'Appearance')}
                title="Appearance"
              >
                <ToolbarItem
                  full={true}
                  propKey="background"
                  type="bg"
                  label="Background"
                />
              </ToolbarSection>

              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Size'}
                setExpanded={() => setExpanded(expanded === 'Size' ? null : 'Size')}
                title="Size"
                props={['width', 'height', 'minWidth', 'maxWidth']}
                summary={({ width, height, minWidth, maxWidth }) => {
                  return `Width: ${width || ''}, Height: ${height || ''}, Min Width: ${minWidth || ''}, Max Width: ${maxWidth || ''}`;
                }}
              >
                <ToolbarItem
                  full={true}
                  propKey='width'
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Width"
                />
                <ToolbarItem
                  full={true}
                  propKey="height"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Height"
                />
                <ToolbarItem
                  full={true}
                  propKey="minWidth"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Min-Width"
                />
                <ToolbarItem
                  full={true}
                  propKey="maxWidth"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Max-Width"
                />
              </ToolbarSection>


              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Border'}
                setExpanded={() => setExpanded(expanded === 'Border' ? null : 'Border')}
                title="Border"
                props={['border', 'fit']}
                summary={({ border, fit }) => {
                  return `${border}px` `${fit}`;
                }}
              >

                <ToolbarItem
                  full={true}
                  propKey="borderWidth"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Border Width"
                />

                <ToolbarItem
                  full={true}
                  propKey="borderColor"
                  type="bg"
                  label="Border Color"
                />

                <ToolbarItem
                  full={true}
                  propKey="borderRadius"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Rounded"
                />
              </ToolbarSection>

              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Shadow'}
                setExpanded={() => setExpanded(expanded === 'Shadow' ? null : 'Shadow')}
                title="Shadow"
              >
                 <ToolbarItem
                  full={true}
                  propKey="shadowColor"
                  type="color"
                  label="Shadow Color"
                />
                <ToolbarItem
                  full={true}
                  propKey="shadowY"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Y offset"
                />

                <ToolbarItem
                  full={true}
                  propKey="shadowX"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="X offset"
                />

                <ToolbarItem
                  full={true}
                  propKey="shadowBlur"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Blur"
                />

               

              </ToolbarSection>


              
            </div>
          </div>
      </div>
    </div>
  );
};