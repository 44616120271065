import React, { useState } from 'react';
import SelectionBox from '../../../editor/Viewport/Pop-up/Images-Icons-Modal';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { category } from '../Image/ImageSettings';
import { useNode } from '@craftjs/core';
import { useAppProps } from '../../../../DarkModeContext';
import Toggle from '../../../editor/Toolbar/Toggle';
import { ArrowToRight } from '../../../editor/Viewport/Editor-icons/svgArray';
import { StatesItem } from '../MainLayouts/HookStates';

export const SvgIconSettings = () => {
  const [iconsSquare, setIconsSquare] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [expanded, setExpanded] = useState(null)
  const [customize, setCustomize] = useState({})
  const [searchTerm, setSearchTerm] = useState('');
  const [img, setImg] = useState(false)


  const {
    node,
    options,
    actions: { setProp }
  } = useNode((node) => ({
    props: node.data.props,
    node: node,
    options:node.data.props
  }));

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCustomize = (group) => {
    setCustomize((prev) => ({
      ...prev,
      [group]: !prev[group], // Toggle the current group's state
    }));
  };

  const toggleIsImg = () => {
      setProp((prop) => {
        prop.isImg = !prop.isImg;
      })
      setImg((prevImg) => !prevImg);
    };

  const handleIconImageSelect = (selectedUrl) => {

      if (Array.isArray(selectedUrl) && selectedUrl.length) {
        setSelectedIcon(selectedUrl[0]);
      } else {
        setSelectedIcon(selectedUrl)
      }

      setIconsSquare(false);
    };
            

  return (
    <div className='flex flex-col component-settings' style={{ height: '100%', zIndex: 9999999 }}>
      <div>
        <div style={{ borderRadius: '1.5vmin', width: '100%', backdropFilter: 'blur(10px)', background: '#e9e9e930' }} className={`shadow-sm absolute left-0 top-0 z-[999999999]`}>
          <input
            placeholder="Search by title..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              display: 'flex',
              margin: '1vmin auto',
              padding: '1vmin 1.4vmin',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              fontSize: '2vmin',
              color: '#98919190',
              backgroundColor: 'transparent',
              border: '.25vmin solid #0867FC70',
              borderRadius: '2vmin',
              outline: 'none',
              width: '80%',
              height: '4vmin'

            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#007bff';
              e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#0867FC70';
              e.target.style.boxShadow = 'none';
            }}

          />
          <div className='w-full text-center text-[2.2vmin] my-[.5vmin] text-[#0867FC80]'
            style={{ fontWeight: '700', userSelect: 'none' }}>
            CUSTOMIZE
          </div>
        </div>

<div style={{ width: "100%" }}>
  <div className='w-full mb-[1vmin] flex flex-row justify-between'>
              <StatesItem currentState="default" actionState={options.actionState} setProp={setProp} />
              <StatesItem currentState="hovered" actionState={options.actionState} setProp={setProp} />
              <StatesItem currentState="active" actionState={options.actionState} setProp={setProp} />
            </div>
            <div style={{ display: 'flex', margin: 'auto auto', justifyContent: 'center', alignItems: 'center', overflowY:'scroll', minWidth:'100%', maxHeight:'75vmin', paddingBottom: expanded && '3vh', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%' }}>
            {/* <Toggle toggleIsImg={toggleIsImg} on={img} second={'Svg'} first={'Image'} /> */}
       
           <div style={{ display: options['actionState'] !== 'default' ? 'none' : 'flex', alignItems: 'center', width: '100%', flexDirection: 'column', }}>
           <div className='container'>
           <ToolbarItem
                  selectedIcon={selectedIcon}
                  IconsSquare={iconsSquare}
                  setIconsSquare={setIconsSquare}
                  full={true}
                  propKey="src"
                  type="src"
                  iconSearch
                  noinput
                />
                {iconsSquare && (
                  <SelectionBox
                    category={category}
                    setIconsSquare={setIconsSquare}
                    iconsSquare={iconsSquare}
                    onSelect={handleIconImageSelect}
                  />
                )}
           </div>
              <ToolbarSection 
                searchTerm={searchTerm}
                expanded={expanded === 'Classes'}
                setExpanded={() => setExpanded(expanded === 'Classes' ? null : 'Classes')}
                title="Classes"
              >
                <ToolbarItem full={true} propKey="className" type="classes" label="Classes" />

              </ToolbarSection>

              <ToolbarSection
                searchTerm={searchTerm}
                title="Navigation"
                expanded={expanded === 'Navigation'}
                setExpanded={() => setExpanded(expanded === 'Navigation' ? null : 'Navigation')} >
                 
                
                <ToolbarItem
                  full={true}
                  propKey="href"
                  type="select"
                  label="href"
                />
              </ToolbarSection>
              <ToolbarSection
                searchTerm={searchTerm}
                expanded={expanded === 'Fill Color'}
                setExpanded={() => setExpanded(expanded === 'Fill Color' ? null : 'Fill Color')}
                title="Fill Color"
              >
                 <ToolbarItem
                full={true}
                propKey="fillColor"
                type="bg"
                preview={false}
                label="Svg's fill color"
              />
              </ToolbarSection>

              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Size'}
                setExpanded={() => setExpanded(expanded === 'Size' ? null : 'Size')}
                title="Size"
              >
                 <ToolbarItem
              full={true}
              propKey="scale"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Scale SVG"
            />
              </ToolbarSection>
              

              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'margin'}
                setExpanded={() => setExpanded(expanded === 'margin' ? null : 'margin')}
                title="margin"
              >
                {customize['margin'] ? (
                  <>
                    <ToolbarItem propKey="marginTop" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Top" />
                    <ToolbarItem propKey="marginRight" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Right" />
                    <ToolbarItem propKey="marginBottom" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Bottom" />
                    <ToolbarItem propKey="marginLeft" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Left" /></>
                ) : (
                  <ToolbarItem propKey="margin" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Margin" />
                )}

                <div onClick={() => handleCustomize('margin')} style={{ borderRadius: '0 1vmin 1vmin 1vmin', border: '.25vmin solid', background: '#e9e9e930' }} className='absolute top-0 min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#e9e9e980] p-[.5vmin] hover:text-[#0867FC]'>{customize['margin'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>

              </ToolbarSection>


              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Padding'}
                setExpanded={() => setExpanded(expanded === 'Padding' ? null : 'Padding')}
                title="Padding"
              >
                {customize['padding'] ? (
                  <>
                    <ToolbarItem propKey="paddingTop" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Top" />
                    <ToolbarItem propKey="paddingRight" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Right" />
                    <ToolbarItem propKey="paddingBottom" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Bottom" />
                    <ToolbarItem propKey="paddingLeft" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Left" />
                  </>
                ) : (
                  <ToolbarItem propKey="padding" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Padding" />
                )}

                <div onClick={() => handleCustomize('padding')} style={{ borderRadius: '0 1vmin 1vmin 1vmin', border: '.25vmin solid', background: '#e9e9e930' }} className='absolute top-0 min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#e9e9e980] p-[.5vmin] hover:text-[#0867FC]'>{customize['padding'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>
              </ToolbarSection>

              </div>

             <ToolbarSection
                searchTerm={searchTerm}
                expanded={expanded === 'Appearance'}
                setExpanded={() => setExpanded(expanded === 'Appearance' ? null : 'Appearance')}
                title="Appearance"
              >
                <ToolbarItem
                  full={true}
                  propKey="background"
                  type="bg"
                  label="Background"
                />
              </ToolbarSection>
              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Border'}
                setExpanded={() => setExpanded(expanded === 'Border' ? null : 'Border')}
                title="Border"
              >

                <ToolbarItem
                  full={true}
                  propKey="borderWidth"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Border Width"
                />

                <ToolbarItem
                  full={true}
                  propKey="borderColor"
                  type="bg"
                  label="Border Color"
                />

                <ToolbarItem
                  full={true}
                  propKey="borderRadius"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Rounded"
                />
              </ToolbarSection>

              <ToolbarSection searchTerm={searchTerm}
                expanded={expanded === 'Shadow'}
                setExpanded={() => setExpanded(expanded === 'Shadow' ? null : 'Shadow')}
                title="Shadow"
              >
                 <ToolbarItem
                  full={true}
                  propKey="shadowColor"
                  type="color"
                  label="Shadow Color"
                />
                <ToolbarItem
                  full={true}
                  propKey="shadowY"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Y offset"
                />

                <ToolbarItem
                  full={true}
                  propKey="shadowX"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="X offset"
                />

                <ToolbarItem
                  full={true}
                  propKey="shadowBlur"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Blur"
                />

               

              </ToolbarSection>

            </div>
          </div>
      </div>
    </div>
  );
};