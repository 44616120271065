/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { NewServicesSettings } from './newServicesSetting';
import { Text } from '../../basic/Text';
import StopIcon from '@mui/icons-material/SquareSharp';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Icon } from '@mui/material';
import  { ImageC }  from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const Services_1 = ({ selected }) => {
    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));


    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[white] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-400' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-402' className={`w-[100%] h-full flex justify-center flex-row flex-wrap `}>
                        <Element is={Parent} canvas id='random-id-403' className={` w-[30%] h-full flex flex-col flex my-auto flex justify-center px-10 text-left min-w-[400px] `} style={{ flex: '10%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", width: '100%', fontWeight: '700', fontFamily: 'Rubik' }} className={`my-5 text-[#100103] text-[46px]`} id="random-id-1"  >
                                <Text text="Our top Service" />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`mb-5 text-[#000000] text-[22px]`} id="random-id-2"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-404' className='h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center ' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-405' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-406' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '20%' }}>
                                    <Element is={Parent} canvas id='random-id-700' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200100' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-407' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='justify-start flex text-[#100103] w-full py-2' id="random-id-3"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-4"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-409' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '20%' }}>
                                    <Element is={Parent} canvas id='random-id-701' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200101' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-410' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#100103] w-full py-2' id="random-id-5"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-6"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-411' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-412' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '20%' }}>
                                    <Element is={Parent} canvas id='random-id-702' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200102' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-413' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#100103] w-full py-2' id="random-id-7"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-8"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-414' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '20%' }}>
                                    <Element is={Parent} canvas id='random-id-703' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200103' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-415' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#100103] w-full py-2' id="random-id-9"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-10"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#121826] py-10" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-416' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-418' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                        <Element is={Parent} canvas id='random-id-419' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10 text-left`} style={{ maxWidth: '600px', flex: '10%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className={`my-5 IncreaseConversion2 text-[46px]`} id="random-id-11"  >
                                <Text text="Our top Services." />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`mb-5 text-[#FFFFFF] text-[22px]`} id="random-id-12"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-420' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-421' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-422' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-704' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200104' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#4BDBA2]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-423' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#4BDBA2] w-full py-2' id="random-id-13"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-14"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-424' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-705' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200105' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#4BDBA2]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-425' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#4BDBA2] w-full py-2' id="random-id-15"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-16"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                            </Element>
                            <Element is={Parent} canvas id='random-id-426' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-427' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-706' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200106' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#4BDBA2]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-428' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#4BDBA2] w-full py-2' id="random-id-17"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-18"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-429' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-707' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200107' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#4BDBA2]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-430' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#4BDBA2] w-full py-2' id="random-id-19"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-20"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#213156] py-10" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-431' className='max-w-[1300px] h-full' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-433' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                        <Element is={Parent} canvas id='random-id-434' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10  text-left`} style={{ maxWidth: '600px', flex: '10%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Times New Roman' }} className={`my-5 text-[#EA6EF4] text-[46px]`} id="random-id-21"  >
                                <Text text="Our top Services." />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Times New Roman', lineHeight: '35.2px' }} className={`mb-5 text-[#FFFFFF] text-[22px]`} id="random-id-22"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-435' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-436' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-437' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-708' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200108' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#EA6EF4]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-438' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#EA6EF4] w-full py-2' id="random-id-23"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#FFF]' id="random-id-24"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-439' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-709' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200109' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#EA6EF4]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-440' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#EA6EF4] w-full py-2' id="random-id-25"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#FFF]' id="random-id-26"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-441' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-442' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-710' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200110' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#EA6EF4]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-443' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#EA6EF4] w-full py-2' id="random-id-27"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#FFF]' id="random-id-28"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-444' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-711' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200111' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#EA6EF4]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-445' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#EA6EF4] w-full py-2' id="random-id-29"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#FFF]' id="random-id-30"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#DFFFF9] py-10" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-446' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-448' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                        <Element is={Parent} canvas id='random-id-449' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10 text-left`} style={{ maxWidth: '600px', flex: '10%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontStyle: 'italic', fontFamily: 'Zing Script Rust SemiBold Demo' }} className={`my-5 text-[#31A993] text-[46px] `} id="random-id-31"  >
                                <Text text="Our top Services." />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Zing Script Rust SemiBold Demo', lineHeight: '35.2px' }} className={`mb-5 text-[#031815] text-[22px]`} id="random-id-32"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-450' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-451' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-452' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-712' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200112' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} classsName='bg-[#31A993]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-453' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#31A993] w-full py-2' id="random-id-33"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#031815]' id="random-id-34"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-454' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-713' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200113' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} classsName='bg-[#31A993]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-455' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#31A993] w-full py-2' id="random-id-35"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#031815]' id="random-id-36"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-456' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-457' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-714' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200114' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} classsName='bg-[#31A993]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-458' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#31A993] w-full py-2' id="random-id-37"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#031815]' id="random-id-38"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-459' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-715' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200115' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} classsName='bg-[#31A993]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-460' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans' }} className='text-[#31A993] w-full py-2' id="random-id-39"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '28.8px' }} className='text-[#031815]' id="random-id-40"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-461' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-463' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                        <Element is={Parent} canvas id='random-id-464' className={`w-[30%] min-w-[400px] text-left h-full flex flex-col flex items-center flex justify-center px-10 `} style={{ maxWidth: '600px', flex: '10%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Times New Roman' }} className={`my-5 text-[#0078D3] text-[46px]`} id="random-id-41"  >
                                <Text text="Our top Services." />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Times New Roman', lineHeight: '35.2px' }} className={`mb-5 text-[#020E17] text-[22px]`} id="random-id-42"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-465' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-466' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-467' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-716' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200116' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#0078D3]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-468' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Inter' }} className='text-[#0078D3] w-full py-2' id="random-id-43"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '28.8px' }} className='text-[#020E17]' id="random-id-44"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-469' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-717' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200117' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#0078D3]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-470' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Inter' }} className='text-[#0078D3] w-full py-2' id="random-id-45"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '28.8px' }} className='text-[#020E17]' id="random-id-46"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-471' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-472' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-718' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200118' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#0078D3]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-473' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Inter' }} className='text-[#0078D3] w-full py-2' id="random-id-47"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '28.8px' }} className='text-[#020E17]' id="random-id-48"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-474' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-719' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200119' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#0078D3]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-475' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Inter' }} className='text-[#0078D3] w-full py-2' id="random-id-49"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '28.8px' }} className='text-[#020E17]' id="random-id-50"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto bg-[white] py-10" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-476' className='w-[100%] h-[100%]' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-478' className={`w-[100%] h-full flex justify-center flex-row flex-wrap`}>
                        <Element is={Parent} canvas id='random-id-479' className={`w-[30%] min-w-[400px] flex flex-col justify-center px-10 flex justify-center bg-[#f5f5f6] text-left`} style={{ flex: '38%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: '#020212' }} className={`my-5 text-[#020212] uppercase text-[46px]`} id="random-id-51"  >
                                <Text text="Our top Services." />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: '#020212', lineHeight: '35.2px' }} className={`mb-5 text-[#020212] text-[22px]`} id="random-id-52"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-480' className='w-full h-full justify-center flex justify-center pl-4 flex flex-row flex-wrap align-items-center' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-481' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-482' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-720' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200120' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#020212]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-483' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#020212] w-full py-2' id="random-id-53"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-54"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-484' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-721' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200121' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#020212]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-485' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#020212] w-full py-2' id="random-id-55"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-56"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-486' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-487' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-722' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200122' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#020212]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-488' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#020212] w-full py-2' id="random-id-57"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-58"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-489' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-723' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200123' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#020212]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-490' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#020212] w-full py-2' id="random-id-59"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-60"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto bg-[white] py-10" >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-491' className='max-w-[1300px] h-full' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-493' className={`w-full h-full flex justify-center bg-[#6750A4] flex flex-row flex-wrap`} style={{ borderRadius: '30px' }}>
                        <Element is={Parent} canvas id='random-id-494' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10 text-left`} style={{ maxWidth: '600px', flex: '10%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Roboto' }} className={`my-5 text-[#D0BCFF] text-[46px]`} id="random-id-61"  >
                                <Text text="Our top Services." />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Roboto', lineHeight: '35.2px' }} className={`mb-5 text-[#FFFFFF] text-[22px]`} id="random-id-62"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-495' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-496' className='w-full flex flex-row flex-wrap justify-center flex justify-center pl-4' >

                                <Element is={Parent} canvas id='random-id-497' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-724' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200124' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#D0BCFF]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-498' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto' }} className='text-[#D0BCFF] w-full py-2' id="random-id-63"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-64"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-499' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-725' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200125' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#D0BCFF]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-500' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto' }} className='text-[#D0BCFF] w-full py-2' id="random-id-65"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-66"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-501' className='w-full flex flex-row flex-wrap justify-center flex justify-center pl-4' >
                                <Element is={Parent} canvas id='random-id-502' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-726' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200126' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#D0BCFF]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-503' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto' }} className='text-[#D0BCFF] w-full py-2' id="random-id-57"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-68"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-504' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-727' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200127' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#D0BCFF]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-505' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto' }} className='text-[#D0BCFF] w-full py-2' id="random-id-69"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-70"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto  bg-[#2A2A2A] py-10" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-506' className='max-w-[1300px] h-full' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-508' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                        <Element is={Parent} canvas id='random-id-509' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10  text-left`} style={{ maxWidth: '600px', flex: '10%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Rum Raisin' }} className={`my-5 text-[#FFD600] text-[46px]`} id="random-id-71"  >
                                <Text text="Our top Services." />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`mb-5 text-[#FFFFFF] text-[22px]`} id="random-id-72"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-510' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-511' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-512' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-728' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200128' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#FFD600]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-513' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-73"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-74"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-514' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-729' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200129' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#FFD600]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-515' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-75"  >
                                            <Text text="Service 2" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-76"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-516' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-517' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-730' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200130' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#FFD600]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-518' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-77"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-78"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-519' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-731' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200131' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#FFD600]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-520' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-79"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#FFFFFF]' id="random-id-80"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto bg-[#F7FBFE] py-10" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-10000' className='max-w-[1300px] h-full' >
                    <Element is={Parent} canvas id='random-id-100003' className={`w-[100%] h-full flex justify-center flex-row flex-wrap `}>
                        <Element is={Parent} canvas id='random-id-100004' className={`w-[100%] h-full flex flex-col flex items-center flex justify-center px-10  text-left `} style={{ flex: '38%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className={`my-5 text-[#6064D2] text-[46px]`} id="random-id-100006"  >
                                <Text text="Our top Service" />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`mb-5 text-[#000000] text-[22px]`} id="random-id-1000007"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-1000008' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center ' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-100009' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-202020' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-303030' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200132' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-404040' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='justify-start flex text-[#B20312] w-full py-2' id="random-id-2020212"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-20293"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-505050' className='flex flex-col flex justify-center relative' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-606060' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                        <Element is={Parent} canvas id='random-id-707070' style={{ marginTop: '5px', display:'none' }}  >
                                            <Element is={Parent} canvas id='random-id-200133' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                        </Element>
                                        <Element is={Parent} canvas id='random-id-808080' className='h-full flex flex-col flex items-center pl-2'>
                                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#B20312] w-full py-2' id="random-id-3048"  >
                                                <Text text="Service 2" />
                                            </Element>
                                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-29383"  >
                                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                            </Element>
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-897837' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-397483' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-139819' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200134' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-329348' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#B20312] w-full py-2' id="random-id-3298"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-1209"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-121029' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-12127' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} canvas id='random-id-200135' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#B20312]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-13514' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#B20312] w-full py-2' id="random-id-323232"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-238278"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto bg-[#FEFBF7] py-10" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-930' className='max-w-[1300px] h-full'>
                    <Element is={Parent} canvas id='random-id-932' className={`w-[100%] h-full flex justify-center flex-row flex-wrap `}>
                        <Element is={Parent} canvas id='random-id-933' className={`w-[100%] h-full flex flex-col flex items-center flex justify-center px-10  text-left `} style={{ flex: '38%' }}>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className={`my-5 text-[#A25738] text-[46px]`} id="random-id-934"  >
                                <Text text="Our top Service" />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`mb-5 text-[#000000] text-[22px]`} id="random-id-935"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-936' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center ' style={{ flex: '60%' }}>
                            <Element is={Parent} canvas id='random-id-937' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >

                                <Element is={Parent} canvas id='random-id-938' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-939' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} id='random-id-200010060' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#A25738]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-940' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='justify-start flex text-[#A25738] w-full py-2' id="random-id-941"  >
                                            <Text text="Service 1" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-942"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-943' className='flex flex-col flex justify-center relative' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-944' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                        <Element is={Parent} canvas id='random-id-945' style={{ marginTop: '5px', display:'none' }}  >
                                            <Element is={Parent} id='random-id-200010061' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#A25738]' />
                                        </Element>
                                        <Element is={Parent} canvas id='random-id-946' className='h-full flex flex-col flex items-center pl-2'>
                                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#A25738] w-full py-2' id="random-id-946"  >
                                                <Text text="Service 2" />
                                            </Element>
                                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-947"  >
                                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                            </Element>
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-948' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                                <Element is={Parent} canvas id='random-id-949' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-950' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} id='random-id-200010062' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#A25738]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-951' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#A25738] w-full py-2' id="random-id-952"  >
                                            <Text text="Service 3" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-953"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-954' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{ flex: '28%' }}>
                                    <Element is={Parent} canvas id='random-id-955' style={{ marginTop: '5px', display:'none' }}  >
                                        <Element is={Parent} id='random-id-200010063' style={{ minWidth: '24px', maxWidth: '24px', minHeight: '24px', maxHeight: '24px' }} className='bg-[#A25738]' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-956' className='h-full flex flex-col flex items-center pl-2'>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#A25738] w-full py-2' id="random-id-957"  >
                                            <Text text="Service 4" />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '28.8px' }} className='text-[#100103]' id="random-id-958"  >
                                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>

    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])


    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    )
}

Services_1.craft = {
    displayName: "Services 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewServicesSettings,
    },
};
