/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import {  useState } from 'react';
import { ToolbarSection } from '../../ToolbarSection';
import { SliderType } from './Slider';
import { DropDown } from './DropDown';
import BackgroundComponent from './Background-color';
import { useAppProps } from '../../../../../DarkModeContext';

export const AuthSettings = ({ value, value2, propValue, setProp, propKey, node, options, activeId }) => {

  const { isDarkMode } = useAppProps()
  const [searchTerm, setSearchTerm] = useState('')
  const [search, setSearch] = useState(false)
    const [expanded, setExpanded] = useState(null)
    const [expandedContent, setExpandedContent] = useState(null);  // Child section state




  const handleInputChange = (el, has, newValue) => {
    setProp(node.id, (props) => {
      props[propKey][el][has] = newValue
    });
  };


  return (
    <div className='w-full h-full text-[#000]'>
      {Object.keys(value).map((el, index) => {
        const element = value[el];
        const title = element.content.length > 30 ? `${element.content?.slice(0, 30)}...` : element.content

        const background = []
        const color = []
        const marginProperties = [];
        const paddingProperties = [] 
        const border = [];
        const size = []
        const otherProperties = [];

        const elementHas = Object.keys(element).map((has, index) => {
          const property = element[has];
          if (property && typeof property === 'object') {
            if (property.type === 'Number' && !has.startsWith('shadow')) {
              
              if (has.startsWith('margin')) {
                marginProperties.push(
                  <SliderType value={value} propValue={propValue} value2={value2} key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has} />
                );
              } else if (has.startsWith('padding')) {
                paddingProperties.push(
                  <SliderType value={value} propValue={propValue} value2={value2} key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has} />
                );
              } else if (has.startsWith('width') || has.startsWith('height')) {
                size.push(
                  <SliderType value={value} propValue={propValue} value2={value2} key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has} />
                )
              }
               else if (has.startsWith('borderRadius')) {
                border.push (
                  <SliderType value={value} propValue={propValue} value2={value2} key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has} />
                )
              }
               else {
                otherProperties.push(
                  <SliderType value={value} propValue={propValue} value2={value2} key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has} />
                );
              }

            } else if (property.type === 'String') {
              otherProperties.push(
                <DropDown key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has} />
              );
            } else if (property.type === 'bg' ) {
              background.push(
                <BackgroundComponent value={value} propValue={propValue} value2={value2} key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has}/>
              )
            } else if (property.type === 'color' || property.type === 'colorSpan') {
              color.push(
                <BackgroundComponent value={value} propValue={propValue} value2={value2} key={has} options={options} setProp={setProp} propKey={propKey} node={node} property={property} el={el} isDarkMode={isDarkMode} has={has}/>
              )
            }

          } else if (element.hasOwnProperty('content') && element.hasOwnProperty('placeholder') && property && typeof property !== 'object' && has !== 'textAlign') {
            if (element.hasOwnProperty('placeholder') && has === 'placeholder') {
              otherProperties.unshift(
                <div className='my-[.5vmin] p-[.5vmin] flex flex-row shadow-md w-full align-items-center justify-between' key={has}>
                  {has === 'placeholder' &&
                    <input style={{ color: isDarkMode ? '#fff' : '#000', textAlign: 'center', padding: '1vmin', width: '100%', borderRadius: '1vmin' }} value={property} onChange={(e) => {
                      let newValue = e.target.value;
                      console.log(newValue)
                      handleInputChange(el, has, newValue);
                    }} />
                  }
                </div>
              );
            }

          } else if (element.hasOwnProperty('content') && !element.hasOwnProperty('placeholder') && property && typeof property !== 'object' && has !== 'textAlign') {
            otherProperties.unshift(
              <div title={has} className='my-[.5vmin]  p-[.5vmin] flex flex-row shadow-md w-full align-items-center justify-between' key={has}>
                {has === 'content' &&
                  <input style={{ color: isDarkMode ? '#fff' : '#000', textAlign: 'center', padding: '1vmin', width: '100%', borderRadius: '1vmin' }} value={property} onChange={(e) => {
                    let newValue = e.target.value;
                    console.log(newValue)
                    handleInputChange(el, has, newValue);
                  }} />
                }
              </div>
            );
          }
        });

        return (
          <ToolbarSection auth authParent
          expanded={expanded === title}
          setExpanded={() => setExpanded(expanded === title ? null : title)}
          key={el} title={title}>
            <div style={{display:'flex', width:'100%', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
              <div style={{ color: '#0867FC', flex:1, fontWeight: '600', fontSize: '11px', textAlign:'center', marginTop:'1vmin'}}>{`This settings for current expanded element`}</div>
            </div>

            <div key={el} style={{ width: '100%', padding: '2%', borderRadius: '1vmin', marginTop: '1vmin' }}>
              {paddingProperties.length > 0 && (
                <ToolbarSection auth authChild
                expanded={expandedContent === 'Content Properties'}
                setExpanded={() => setExpandedContent(expandedContent === 'Content Properties' ? null : 'Content Properties')}
                title="Content Properties">
                  {otherProperties}
                </ToolbarSection>
              )}

              {border.length > 0 && (
                <ToolbarSection auth authChild
                expanded={expandedContent === 'Rounded'}
                setExpanded={() => setExpandedContent(expandedContent === 'Rounded' ? null : 'Rounded')}
                title="Rounded">
                  {border}
                </ToolbarSection>
              )}

              {size.length > 0 && (
                <ToolbarSection auth authChild
                expanded={expandedContent === 'Size'}
                setExpanded={() => setExpandedContent(expandedContent === 'Size' ? null : 'Size')}
                title="Size">
                  {size}
                </ToolbarSection>
              )}

              {background.length > 0 && (
                <ToolbarSection auth authChild
                expanded={expandedContent === 'Background'}
                setExpanded={() => setExpandedContent(expandedContent === 'Background' ? null : 'Background')}
                title="Background">
                  {background}
                </ToolbarSection>
              )}
              {color.length > 0 && (
                <ToolbarSection auth authChild
                expanded={expandedContent === 'Color'}
                setExpanded={() => setExpandedContent(expandedContent === 'Color' ? null : 'Color')}
                title="Color">
                  {color}
                </ToolbarSection>
              )}
              {marginProperties.length > 0 && (
                <ToolbarSection auth authChild
                expanded={expandedContent === 'Margin'}
                setExpanded={() => setExpandedContent(expandedContent === 'Margin' ? null : 'Margin')}
                title="Margin">
                  {marginProperties}
                </ToolbarSection>
              )}
              {paddingProperties.length > 0 && (
                <ToolbarSection auth authChild
                expanded={expandedContent === 'Padding'}
                setExpanded={() => setExpandedContent(expandedContent === 'Padding' ? null : 'Padding')}
                title="Padding">
                  {paddingProperties}
                </ToolbarSection>
              )}

            </div>
          </ToolbarSection>
        )
      })}
    </div>
  )
}