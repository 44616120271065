import React, { useMemo } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { Menu } from "@progress/kendo-react-layout";
import { MenuSettings } from './MenuSettings';
import { Parent } from '../../Parent';
import { Text } from '../Text';

export const BasicMenu = ({
    style = {},
    text = 'Click here',
    href,
    color = '#000',
    width,
    height,
    background,
    fontSize = '16px',
    borderColor,
    borderWidth,
    borderRadius,
    textAlign,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
  padding,
  margin,
   paddingTop,
   paddingRight,
   paddingBottom,
   paddingLeft,
    fontWeight,
    fontStyle,
    textDecoration,
    textTransform,
    fontFamily,
    lineHeight,
}) => {
    const {
        connectors: { connect },
        actions: { setProp },
    } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),

    ...handleProperty(paddingTop, 'paddingTop'),
    ...handleProperty(paddingRight, 'paddingRight'),
    ...handleProperty(paddingBottom, 'paddingBottom'),
    ...handleProperty(paddingLeft, 'paddingLeft'),

    ...handleProperty(padding, 'padding'),
    ...handleProperty(margin, 'margin'),


    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, fontFamily, background, padding, margin, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign, marginTop, marginRight, marginBottom, marginLeft, paddingTop, paddingRight, paddingBottom, paddingLeft]);

    const items = [
        {
        "text": "Item1",
        "items": [
            { "text": "Item1.1" }, 
            { "text": "Item1.2", 
                "items": [
                    { "text": "Item1.2.1" }, { "text": "Item1.2.2" }
                ] 
            }
        ]
        }, 
        {
            "text": "Item2",
            "items": [
                { "text": "Item2.1" }, { "text": "Item2.2" }, { "text": "Item2.3" }
            ]
        }, 
        {
            "text": "Item3"
        }
    ]

    return (
        <div ref={connect} style={{ display: 'inline-block' }}>
             <Menu style={{...styleProps}} items={items} />
        </div>
    );
};

BasicMenu.craft = {
    displayName: 'Menu',
    props: {
        text: 'Click here',
        href: null,
        color: '#000',
        width:null,
        height:null,
        background:null,
        fontSize: '16px',
        shadowX: null,
        shadowY: '1px',
        shadowColor:null,
        shadowBlur: '1px',
        marginTop: null,
        marginRight: null,
        marginBottom: null,
        marginLeft: null,

       paddingTop: null,
       paddingRight: null,
       paddingBottom: null,
       paddingLeft: null,

       margin:null,
       padding:null,

  borderRadius: null,
        background:null,
        borderColor:null,
        borderWidth:null,
        textAlign:null,
        fontWeight:null,
        textTransform:null,
        fontStyle:null,
        textDecoration:null,
        fontFamily:null,
        textTransform:null,
        lineHeight:null,
        isMenu: true,
    },
    related: {
        toolbar: MenuSettings, // Create a LinkSettings component for custom options
    },
};
