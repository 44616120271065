/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CardSettings } from './CardSettings';
import { Button } from '../Button/index'
import  { ImageC }  from '../Image';
import { Text } from '../../basic/Text/index';
import { Wrapper } from '../../wrapper/index.js';
import { Parent } from '../../Parent/index.js';
import { useActiveIdContext } from '../../../../ActiveIdContext.js';
import { useOptionsContext } from '../../../../OptionsContext.js';
import { useInView } from 'react-hook-inview';


const defaultProps = {
  className: '',
  initialComputed: {}, 
  style: {},
  properties: {
    background: null,
    maxWidth: null,
    minWidth: null,
    display: null,
    color: null,
    margin: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    padding: null,
    paddingTop: null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight: null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: 'solid',
    borderRadius: null,
    overflow: null,
    fontSize: null,
    textTransform: null,
    fontWeight: null,
    lineHeight: null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily: null,
    textAlign: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor:null,
    shadowBlur: '1px',
  transition: null,
    // Add more styles as needed
  },
  isBasic: true,
};



export const Card = (props) => {
  const {
    connectors: { connect },
    actions: { setProp },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));


  const {
    nodes,
    enabled,
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    nodes: state.nodes
  }));

  
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);

  const {
    className = '',
    initialComputed = {}, 
    style= {},
    properties = {
      background: props.style.background || '',
      display: props.style.display || '',
      color: props.style.color || '',
      margin: props.style.margin || '',
      marginTop: props.style.marginTop || '',
      marginBottom: props.style.marginBottom || '',
      marginLeft: props.style.marginLeft || '',
      marginRight: props.style.marginRight || '',
      padding: props.style.padding || '',
      paddingTop: props.style.paddingTop || '',
      paddingBottom: props.style.paddingBottom || '',
      paddingLeft: props.style.paddingLeft || '',
      paddingRight: props.style.paddingRight || '',
      border: props.style.border || '',
      borderWidth: props.style.borderWidth || '',
      borderColor: props.style.borderColor || '',
      borderStyle: 'solid',
      borderRadius: props.style.borderRadius || '',
      overflow: props.style.overflow || '',
      textAlign: props.style.textAlign || '',
      fontSize: props.style.fontSize || "",
      textTransform: props.style.textTransform || '',
      fontWeight: props.style.fontWeight || '',
      lineHeight: props.style.lineHeight || '',
      fontStyle: props.style.fontStyle || "normal",
      textDecoration: props.style.textDecoration || '',
      fontFamily: props.style.fontFamily || '',
      textAlign: props.style.textAlign || '',
      shadowX: '',
      shadowY: '',
      shadowColor:'',
      shadowBlur: '',
      // Add more styles as needed
    },
    isBasic= true,
    ...otherProps
  } = { ...defaultProps, ...props }; // Merge defaultProps with props

  
function rgbToHex(rgb) {
  // Extract the numbers from the RGB string
  const rgbValues = rgb.match(/\d+/g);
  
  // Convert each RGB value to hex and ensure two digits
  const hexValues = rgbValues.map(value => {
    const hex = parseInt(value).toString(16);
    return hex.length === 1 ? '0' + hex : hex; // Ensure two digits
  });
  
  // Combine the hex values and return as a string starting with '#'
  return `#${hexValues.join('')}`;
}

useEffect(() => {
  const element = mergedRef.current;

  const basicStyle = {
    width:'350px',
    height:'400px',
    color: '#000000',
    padding: '8px',
    borderWidth: '2px',
    borderColor: '#e9e9e9',
    borderStyle: 'solid',
    fontStyle: "normal",
  }

  if (element) {
    // Extract inline styles as a string
    const inlineStyles = element.getAttribute('style');
    const className = element.className;

    // Convert inline styles to an object
    const extractedStyles = inlineStyles
      ? Object.fromEntries(
          inlineStyles.split(';')
            .filter(style => style.trim())
            .map(style => {
              const [key, value] = style.split(':').map(s => s.trim());
              return [key, value];
            })
        )
      : {};

      const checkOf = isBasic ? basicStyle : extractedStyles

    // Update your object style here
    setProp(props => {
      props.initialComputed = {...checkOf}
      props.properties = {...checkOf}
    });
    const classNames = element.className.split(' '); // Extract class names
    // console.log(classNames)


    // Apply the extracted styles back to the element if needed
    Object.entries(extractedStyles).forEach(([key, value]) => {
      element.style[key] = value;
    });
  } else {
    console.error('Element not found!');
  }
}, []);

const combinedStyle = {
  ...props.style,
  ...properties,
  boxShadow: properties.shadowY !== null 
  ? `${properties.shadowX} ${properties.shadowY} ${properties.shadowBlur} ${properties.shadowColor}` 
  : ''};

  
  return ( 
    <div
    ref={setRef}
    style={{...combinedStyle}}
    >

      <Element canvas is={Parent} id='parentId12' className="w-[100%]" style={{height:'100%'}} >
        <Element canvas is={Parent} id='parentId12' className="w-[100%]" style={{height:'100%'}} row={true} >
        <Element canvas id='random-id-1' is={Parent} className='w-[100%] flex flex-column' style={{width:'100%', height:'100%', padding:'4px 8px'}}>
        
        <ImageC className='object-cover' style={{margin:'0 0 4px 0', width:'30px', height:'200px'}} src='https://i.ibb.co/gW0hkRZ/sample3banner.jpg'/>
            <Element canvas id='random-id-30000' is={Parent} className='flex flex-column' style={{ width:'100%', height:'100%', padding:'0 4px', margin:'5px 0'}}>
            <Element canvas id='random-id-2' is={Parent} style={{fontSize:'18px', display:'inline-block', fontWeight:'700', margin:'2px 0'}}>
                <Text text='Card title'/>
            </Element>
            <Element canvas id='random-id-3' is={Parent} style={{fontSize:'16px', display:'inline-block', opacity:'70%', fontWeight:'600', margin:'2px 0'}}>
                <Text text='Sub subtitle'/>
            </Element>
            <Element canvas id='random-id-4' is={Parent} style={{fontSize:'16px', display:'inline-block', fontWeight:'400'}}>
                <Text text='Some quick example text to build on the card title and make up the bulk of the card‘s content.'/>
            </Element>
            <Element canvas id='random-id-4' is={Parent} style={{width:'100%', margin:'10px 0', justifyContent:'center', display:'flex'}}>
            <Button href={'/'} classn='block' style={{height:'60px'}} class={' justify-center border w-[240px] flex rounded-[10px] py-[11px]'}>
                <Element canvas id='random-id-6' is={Parent} style={{width:'100%', display:'inline-block', color:'#000', textAlign:'center', fontSize:'18px'}}>
                    <Text text='Button'/>
                </Element>
            </Button>   
            </Element>
            </Element>
        </Element>
        </Element>
        </Element>
    </div>
  );
};

Card.craft = {
  displayName: 'Card',
  props:defaultProps,
  related: {
    toolbar: CardSettings,
  },
};
