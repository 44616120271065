const NextButton = ({setStart, onClick}) => {
    return (
        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', width: '100%', height: '15%', justifyContent:'center' }}>
                    <div onClick={() => {
                        onClick()
                        setStart(true)
                    }}
                        className="bg-[#e9e9e930] hover:bg-[#0867FC30] text-[#FFFFFF]"
                        style={{ width: '20vmin', padding: '.5vmin',
                        margin: '1vmin 0', cursor: 'pointer',
                        fontSize: '1.8vmin', border: '.25vmin solid #0857FC', fontWeight:600,
                        borderRadius: '2vmin', textAlign: 'center'
                        }}>
                            Done
                        </div>
                </div>
    )
}

export default NextButton;