import React, { act, useCallback, useMemo, useState } from 'react';
import { useAppProps } from '../../../../DarkModeContext';

const MainItem = React.forwardRef(({
    children,
    onMouseOver,
    onMouseOut,
    memoizedProps,
    className,
    hoverProperties,
    activeProperties,
    isHovered,
    connect,
    mergedRef,
    defaultProps,
    enabled, 
    selected, 
    activeBackground,
    isDragged,
    actionState,
    ShapesDisplay,
}, props) => {

  const { connected } = useAppProps();

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
      }
      mergedRef.current = node;
    }
  }, [connect]);
  

  const [active, setActive] = useState(false);


  const applyTransformations = useCallback((stateKey, positionsTranslate, positionsScale, positionsSkew, positionsRotateXYZ) => {
    const state = actionState[stateKey];

    return `${positionsTranslate && state.isTranslate ? 
        `translate(${positionsTranslate?.x && positionsTranslate?.x !== 0 ? positionsTranslate?.x?.split(" ").join("") : '0'}${positionsTranslate?.y && positionsTranslate?.y !== 0 ? `, ${positionsTranslate?.y.split(" ").join("")}` : "0"})` : ''} 
     ${positionsScale && state.isScale ? `scale(${positionsScale.x && positionsScale.x !== 0 ? positionsScale.x?.split(" ").join("") : '0'})` : ''} 
     ${positionsRotateXYZ && state.isRotateXYZ ? `rotateX(${positionsRotateXYZ.x && positionsRotateXYZ.x !== 0 ? `${positionsRotateXYZ.x?.split(" ").join("")}` : '0'}) rotateY(${positionsRotateXYZ.y && positionsRotateXYZ.y !== 0 ? `${positionsRotateXYZ.y?.split(" ").join("")}` : '0'}) rotateZ(${positionsRotateXYZ.z && positionsRotateXYZ.z !== 0 ? `${positionsRotateXYZ.z?.split(" ").join("")}` : '0'})` : ''} 
     ${positionsSkew && state.isSkew ? `skew(${positionsSkew?.x && positionsSkew?.x !== 0 ? positionsSkew?.x.split(" ").join("") : '0'}${positionsSkew?.y !== 0 ? `, ${positionsSkew?.y && positionsSkew?.y !== 0 ? positionsSkew?.y?.split(" ").join("") : '0'}` : ""})` : ''}`;
  }, [actionState]);

  const transformDefault = useMemo(() => applyTransformations(
    'default',
    actionState['default']?.positionsTranslate,
    actionState['default']?.positionsScale,
    actionState['default']?.positionsSkew,
    actionState['default']?.positionsRotateXYZ
  ), [actionState]);

  const transformHover = useMemo(() => applyTransformations(
    'hovered',
    actionState['hovered']?.positionsTranslate,
    actionState['hovered']?.positionsScale,
    actionState['hovered']?.positionsSkew,
    actionState['hovered']?.positionsRotateXYZ
  ), [actionState]);

  const transformActive = useMemo(() => applyTransformations(
    'active',
    actionState['active']?.positionsTranslate,
    actionState['active']?.positionsScale,
    actionState['active']?.positionsSkew,
    actionState['active']?.positionsRotateXYZ
  ), [actionState]);

  const mergeStyles = useCallback((defaultStyle, hoveredStyle, activeStyle, isHovered, active) => {
    return Object.keys(defaultStyle).reduce((merged, key) => {
      // If the component is active, prioritize activeStyle
      if (active) {
        merged[key] = (activeStyle[key] !== null && activeStyle[key] !== undefined) 
          ? activeStyle[key]  // Use activeStyle if it's defined
          : (isHovered && hoveredStyle[key] !== null && hoveredStyle[key] !== undefined) 
            ? hoveredStyle[key]  // Use hoveredStyle if it's defined and isHovered is true
            : defaultStyle[key];  // Otherwise use defaultStyle
      } else if (isHovered) {
        merged[key] = (hoveredStyle[key] !== null && hoveredStyle[key] !== undefined) 
          ? hoveredStyle[key]  // Use hoveredStyle if it's defined
          : defaultStyle[key];  // Otherwise, fallback to defaultStyle
      } else {
        merged[key] = defaultStyle[key];  // If neither active nor hovered, use defaultStyle
      }
  
      return merged;
    }, {}); // Start with an empty object to accumulate merged styles
  }, []);
  

  const finalStyle = useMemo(() => mergeStyles(defaultProps, hoverProperties, activeProperties, isHovered, active), [defaultProps, hoverProperties, activeProperties, isHovered, active]);

  const checkedNullForTransform = useCallback((enabled, active, connected, isHovered, transformHover, transformDefault, transformActive) => {
    if (enabled && connected) {
      if (active && transformActive && transformActive.trim() !== '') {
        return transformActive;
      }
      if (isHovered && transformHover && transformHover.trim() !== '') {
        return transformHover;
      }
      if (transformDefault && transformDefault.trim() !== '') {
        return transformDefault;
      }
    } else if (!enabled) {
      if (active && transformActive && transformActive.trim() !== '') {
        return transformActive;
      }
      if (isHovered && transformHover && transformHover.trim() !== '') {
        return transformHover;
      }
      
      if (transformDefault && transformDefault.trim() !== '') {
        return transformDefault;
      }
    }
    return null;
  }, []);

  const transformResult = useMemo(() => checkedNullForTransform(enabled, active, connected, isHovered, transformHover, transformDefault, transformActive), [enabled, active, connected, isHovered, transformHover, transformDefault, transformActive]);

  let background;
  if (activeBackground && enabled) {
    background = "#0867FC20";
  } else if (isDragged && enabled) {
    background = "#0867FC20";
  } else if (activeProperties?.background !== undefined && active) {
    if (active) {
        background = activeProperties.background ;
    }
  } else if (hoverProperties?.background !== undefined && isHovered) {
    if (isHovered) {
        background = hoverProperties.background ;
    }
  } else {
    background = defaultProps?.background;
  }

 
  
    // Check based on `active`, `isHovered`, or fallback to `defaultProps`
    const getBoxShadow = () => {
      // Helper function to generate the box-shadow string
      const generateBoxShadow = (x, y, blur, color) => {
        if (x !== undefined) {
          return `${x} ${y} ${blur} ${color}`;
        }
        return undefined;  // Return null if shadowY is undefined or null
      };

      if (isHovered) {
        if (!active && isHovered) {
          return generateBoxShadow(hoverProperties?.shadowX, hoverProperties?.shadowY, hoverProperties?.shadowBlur, hoverProperties?.shadowColor);
        } else {
          return generateBoxShadow(activeProperties?.shadowX, activeProperties?.shadowY, activeProperties?.shadowBlur, activeProperties?.shadowColor);
        }
      } else {
        return generateBoxShadow(defaultProps?.shadowX, defaultProps?.shadowY, defaultProps?.shadowBlur, defaultProps?.shadowColor);
      }
    
    };
    
    const realStyle = {
      ...finalStyle,
      ...memoizedProps,
      transform: transformResult,
      position: 'relative',
      boxShadow: getBoxShadow(),
      background: background
    };
    

  return (
    <div
    className={className}
    ref={setRef}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onMouseLeave={() => {
      setActive(false);
    }}
    onMouseDownCapture={() => {
      setActive(true); // Set active state when mouse is pressed
    }}
    onMouseUpCapture={() => {
      setActive(false); // Set active to false when mouse is released
    }}
    style={realStyle}


    {...props}

    >
      {children}
    </div>
  );
});

export default MainItem;
