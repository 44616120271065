import React, { useMemo } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

export const BasicModal = ({
    style = {},
    text = 'Click here',
    href,
    color = '#000',
    width,
    height,
    background,
    fontSize = '16px',
    borderColor,
    borderWidth,
    borderRadius,
    textAlign,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
fontWeight,
fontStyle,
textDecoration,
textTransform,
fontFamily,
lineHeight,
    isExternal,
    isLink,
}) => {
    const {
        connectors: { connect },
        actions: { setProp },
    } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),
    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, fontFamily, background, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign, marginTop, marginRight, marginBottom, marginLeft]);

      const [visible, setVisible] = React.useState(true);
      const toggleDialog = () => {
        setVisible(!visible);
      };
    

    return (
        <div ref={connect} style={{ display: 'inline-block' }}>
            <div>
            <Button type="button" onClick={toggleDialog} id="open-dialog">
                Open Dialog
            </Button>
            {visible && (
                <div
                onClick={() => setVisible(false)}
                style={{...styleProps, position:'fixed', background:'#000000A1', height:'100%', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', top:0, left:0,  zIndex:9999,  }}
                >
                <div style={{height:'200px', width:'400px', display:'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'center', padding:'0 0 20px 0', border:'1px solid #e9e9e9', background:'#FFF'}}>
                <div style={{display:'flex', justifyContent:'space-between', background:'#e9e9e930', color:'#000', alignItems:'center', height:'fit-content', width:'100%', padding:'5px 10px'}}>
                    <h4>Please Confirm</h4>
                    <div className='cursor-pointer' onClick={() => setVisible(false)}>X</div>
                </div>
                <p
                style={{
                margin: "25px",
                textAlign: "center",
                height:'100%',
                display:'center',
                alignContent:'center'
                }}
            >
                Are you sure you want to continue?
            </p>
                <div style={{display:'flex', justifyContent:'center',  width:'100%'}}>
                <Button style={{margin:'6px', width:'120px'}} type="button" onClick={toggleDialog}>
                No
                </Button>
                <Button style={{margin:'6px', width:'120px'}} type="button" onClick={toggleDialog}>
                Yes
                </Button>
                </div>
                </div>
                </div>
            )}
            </div>
        </div>
    );
};

BasicModal.craft = {
    displayName: 'Modal',
    props: {
        text: 'Click here',
        href: null,
        color: '#000',
        width:null,
        height:null,
        background:null,
        fontSize: '16px',
        shadowX: null,
        shadowY: '1px',
        shadowColor:null,
        shadowBlur: '1px',
        marginTop: null,
        marginRight: null,
        marginBottom: null,
        marginLeft: null,
  borderRadius: null,
        background:null,
        borderColor:null,
        borderWidth:null,
        textAlign:null,
        fontWeight:null,
        textTransform:null,
        fontStyle:null,
        textDecoration:null,
        fontFamily:null,
        textTransform:null,
        lineHeight:null,
        isModal:true
    },
    related: {
        toolbar: '', // Create a LinkSettings component for custom options
    },
};
