import { useState } from "react";
import { useOptionsContext } from "../../OptionsContext";
import { AddPageIcon, EditNameIcon } from "../../components/editor/Viewport/Editor-icons/EditorSvg";
import AreaEl from "../AreaEl";
import { useSidesContext } from "../../SideContext";
import SmallWidgets from "./BottomWidgets";
import { useActiveIdContext } from "../../ActiveIdContext";

const Footer = ({
    outStepper,
}) => {

    const { 
        appSettings, 
        pageSettings, 
        BottomProps,
        review,
        setClosePanel, 
        setOpen,
        } = useSidesContext();
        const { state, dispatch: setActiveId } = useActiveIdContext();
        const { activeId } = state;
    

        const handleClick = (node) => {
            setActiveId({ _id: node._id, name: node.name });
          };

        const checkFooter = pageSettings.bottomSide.visibility

        const color = appSettings?.color?.value
        const h1Styles = appSettings?.fonts?.h1
        const h2Styles = appSettings?.fonts?.h2
        const h3Styles = appSettings?.fonts?.h3
        const h4Styles = appSettings?.fonts?.h4

    const {
        bottomContents,
        bottomRows,
        setbottomRows,
        theBottomOptionRow2,
        theBottomOptionRow3,
        theBottomOptionRow,
    } = BottomProps

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;

    if (checkFooter) {
        return (
            <div style={{ height: "fit-content", width: "100%", gap:'1vmin', display:'flex', flexDirection:'column', borderTop: `.25vmin solid ${color}`}}>
                {(bottomRows < "3" || bottomRows < 3) && !review && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            
                            if (bottomRows === '1' || bottomRows === 1) {
                                setbottomRows(2);  
                            } else if (bottomRows === '2' || bottomRows === 2) {
                                setbottomRows(3);  
                            } else {
                                console.log('error with set rows');
                            }
                        }}
                        className="w-full flex justify-center items-center cursor-pointer"
                        title="Add Row"
                    >
                        <AddPageIcon height="4vmin" width="4vmin" />
                    </div>
                )}
                {(bottomRows === 3 || bottomRows === '3') && theBottomOptionRow3 && 
                <AreaEl 
                outStepper={outStepper} 
                onClick={() => {
                    setClosePanel(true)
                    setOpen('bottom3')
                   }} 
                id='bottom3'
                >
                    {theBottomOptionRow3 === 'Small widgets' ? (
                        <div 
                            style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999, width:'100%'}} className='flex justify-between items-center'>
                            <SmallWidgets 
                            check="footer"
                             review={review} 
                             color={color} 
                             h3Styles={h3Styles} 
                            />
                        </div>
                    ) : theBottomOptionRow3 === 'Navigation Pages' ? (
                        <div className="sitemap-container max-w-[90%]" style={{display:'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))', textAlign:'center'}}>
                            {treeData.map((node, id) => (
                                <div key={node._id} className="flex flex-column h-full text-center w-full cursor-pointer">
                                <div style={{...h3Styles, color: activeId._id === node._id && color, borderBottom: activeId._id === node._id && `.25vmin solid ${color}` }} className={` sitemap-item`} onClick={() => handleClick(node)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: activeId._id === child._id && color, borderBottom: activeId._id === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{...h3Styles, alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999 }} className='flex justify-center items-center'>
                            <div className='text-center'>{bottomContents.content3.text || 'Content With a link'}</div>
                            {bottomContents.content3.href !== '' && <div style={{ zIndex: 9999 }}>
                                <a style={{textDecoration:'none', color: color}} target="_blank" href={bottomContents.content3.href}>
                                    <div className="w-[17vmin] h-[6vmin] flex items-center justify-center rounded-[5vmin] ml-[2vmin]" style={{border:`.25vmin solid ${color}`, background:'transparent'}}>
                                    Click
                                    </div>
                                </a>
                            </div>}
                        </div>
                    )}
                </AreaEl>}

                {bottomRows >= 2 && theBottomOptionRow2 && 
                <AreaEl 
                outStepper={outStepper} 
                onClick={() => {
                    setClosePanel(true)
                    setOpen('bottom2')                     
                }} 
                id='bottom2' 
                >
                    {theBottomOptionRow2 === 'Small widgets' ? (
                        <div 
                            style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999,  }} className='flex justify-between items-center'>
                            <SmallWidgets 
                            check="footer"  
                             review={review} 
                             color={color} 
                             h3Styles={h3Styles} 
                            />
                        </div>
                    ) : theBottomOptionRow2 === 'Navigation Pages' ? (
                        <div className="sitemap-container max-w-[90%]" style={{display:'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))', textAlign:'center'}}>
                            {treeData.map((node, id) => (
                                <div key={node._id} className="flex flex-column h-full text-center w-full cursor-pointer">
                                <div style={{...h3Styles, color: activeId._id === node._id && color, borderBottom: activeId._id === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => handleClick(node)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: activeId._id === child._id && color, borderBottom: activeId._id === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{...h3Styles, alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999 }} className='flex justify-center items-center'>
                            <div className='text-center'>{bottomContents.content2.text || 'Content With a link'}</div>
                            {bottomContents.content2.href !== '' && <div style={{ zIndex: 9999 }}>
                                <a style={{textDecoration:'none', color: color}} target="_blank" href={bottomContents.content2.href}>
                                    <div className="w-[17vmin] h-[6vmin] flex items-center justify-center rounded-[5vmin] ml-[2vmin]" style={{border:`.25vmin solid ${color}`, background:'transparent'}}>
                                    Click
                                    </div>
                                </a>
                            </div>}
                        </div>
                    )}
                </AreaEl>}

                {theBottomOptionRow && 
                <AreaEl 
                outStepper={outStepper} 
                onClick={() => {
                    setClosePanel(true)
                    setOpen('bottom1')
                }} 
                id='bottom1' 
                >
                    {theBottomOptionRow === 'Small widgets' ? (
                        <div
                            style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999,  }} className='flex justify-between items-center'>
                            <SmallWidgets 
                             check="footer"  
                             review={review} 
                             color={color} 
                             h3Styles={h3Styles} 
                            />
                        </div>
                    ) : theBottomOptionRow === 'Navigation Pages' ? (
                        <div className="sitemap-container max-w-[90%]" style={{display:'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))', textAlign:'center'}}>
                            {treeData.map((node, id) => (
                                <div key={node._id} className="flex flex-column h-full text-center w-full cursor-pointer">
                                <div style={{...h3Styles, color: activeId._id === node._id && color, borderBottom: activeId._id === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => handleClick(node)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: activeId._id === child._id && color, borderBottom: activeId._id === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{...h3Styles, alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999 }} className='flex justify-center items-center'>
                            <div className='text-center'>{bottomContents.content1.text || 'Content With a link'}</div>
                            {bottomContents.content1.href !== '' && <div style={{ zIndex: 9999 }}>
                                <a style={{textDecoration:'none', color: color}} target="_blank" href={bottomContents.content1.href}>
                                    <div className="w-[17vmin] h-[6vmin] flex items-center justify-center rounded-[5vmin] ml-[2vmin]" style={{border:`.25vmin solid ${color}`, background:'transparent'}}>
                                    Click
                                    </div>
                                </a>
                            </div>}
                        </div>
                    )}
                </AreaEl>}

                {pageSettings?.copyright?.visibility &&
                <AreaEl 
                    outStepper={outStepper} 
                    onClick={() => {
                        setClosePanel(true)
                        setOpen('proud')
                    }}
                    id='proud'
                    style={{...h4Styles, display: 'flex', justifyContent:'center'}}
                    >
                    {pageSettings?.copyright?.content || '© 2024 AllYouCanCloud.com'}
                </AreaEl>}
                
            </div>
        )
    }
    return null
}

export default Footer;