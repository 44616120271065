import React, { act, useCallback, useMemo, useState } from 'react';
import { useAppProps } from '../../../../DarkModeContext';

const MainElement = ({
    children,
    onMouseOver,
    onMouseOut,
    memoizedProps,
    className,
    hoverProperties,
    activeProperties,
    isHovered,
    connect,
    mergedRef,
    defaultProps,
    enabled, 
    selected, 
    activeBackground,
    isDragged,
    ShapesDisplay,
    Tag,
    input,
    ...props
}) => {

  const { connected } = useAppProps();

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
      }
      mergedRef.current = node;
    }
  }, [connect]);

  const [active, setActive] = useState(false);


    const mergeStyles = useCallback((defaultStyle, hoveredStyle, activeStyle, isHovered, active) => {
      return Object.keys(defaultStyle).reduce((merged, key) => {
        // If the component is active, prioritize activeStyle
        if (active) {
          merged[key] = (activeStyle[key] !== null && activeStyle[key] !== undefined) 
            ? activeStyle[key]  // Use activeStyle if it's defined
            : (isHovered && hoveredStyle[key] !== null && hoveredStyle[key] !== undefined) 
              ? hoveredStyle[key]  // Use hoveredStyle if it's defined and isHovered is true
              : defaultStyle[key];  // Otherwise use defaultStyle
        } else if (isHovered) {
          merged[key] = (hoveredStyle[key] !== null && hoveredStyle[key] !== undefined) 
            ? hoveredStyle[key]  // Use hoveredStyle if it's defined
            : defaultStyle[key];  // Otherwise, fallback to defaultStyle
        } else {
          merged[key] = defaultStyle[key];  // If neither active nor hovered, use defaultStyle
        }
    
        return merged;
      }, {}); // Start with an empty object to accumulate merged styles
    }, []);
    

  const finalStyle = useMemo(() => mergeStyles(defaultProps, hoverProperties, activeProperties, isHovered, active), [defaultProps, hoverProperties, activeProperties, isHovered, active]);

  let background;
  if (activeBackground && enabled) {
    background = "#0867FC20";
  } else if (isDragged && enabled) {
    background = "#0867FC20";
  } else if (activeProperties?.background !== undefined && active) {
    if (active) {
        background = activeProperties.background ;
    }
  } else if (hoverProperties?.background !== undefined && isHovered) {
    if (isHovered) {
        background = hoverProperties.background ;
    }
  } else {
    background = defaultProps?.background;
  }
  
  const onMouseLeave = () => {
    if (active) {
        setActive(false)
    }
  }

  const ElType = Tag !== undefined ? Tag : 'div'

  const mergedStyle = {
    ...finalStyle, // Merge default, hover, and active styles
    ...memoizedProps, // Include any memoized styles
    position: 'relative',
    zIndex: 999999,
    textShadow: (isHovered && hoverProperties?.shadowTextX !== null) ? `${hoverProperties.shadowTextX} ${hoverProperties.shadowTextY} ${hoverProperties.shadowTextBlur} ${hoverProperties.shadowColor}` :
    (defaultProps?.shadowTextX !== null) ? `${defaultProps?.shadowTextX} ${defaultProps?.shadowTextY} ${defaultProps?.shadowTextBlur} ${defaultProps?.shadowColor}` : null,

    boxShadow: (isHovered && hoverProperties?.shadowX !== null) ? `${hoverProperties.shadowX} ${hoverProperties.shadowY} ${hoverProperties.shadowBlur} ${hoverProperties.shadowColor}` :
    (defaultProps?.shadowX !== null) ? `${defaultProps?.shadowX} ${defaultProps?.shadowY} ${defaultProps?.shadowBlur} ${defaultProps?.shadowColor}` : null,
    background: background,

  };

  if (input) {
    return (
      <ElType
  {...props}
  className={className}
  ref={setRef}
  onMouseOver={onMouseOver}
  onMouseOut={onMouseOut}
  onMouseDownCapture={() => {
    setActive(true);
  }}
  onMouseUpCapture={() => {
    setActive(false);
  }}
  onMouseLeave={onMouseLeave}
  style={{width:defaultProps?.width, position:'relative'}}
>
  {React.Children.map(children, (child) =>
    React.cloneElement(child, {
      ...props,  // Pass props to children
      style: { ...mergedStyle, ...child.props.style, width:'100%' }, // Combine parent styles with children's styles
    })
  )}
</ElType>
    )
  }


  return (
    <ElType
    {...props}
      className={className}
      ref={setRef}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseDownCapture={() => {
        setActive(true)
      }}
      onMouseUpCapture={() => {
        setActive(false)
      }}
      onMouseLeave={onMouseLeave}
      style={mergedStyle}
    >
      {children}
    </ElType>
  );
};

export default MainElement;
