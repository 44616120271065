/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewStepsSettings } from './newStepsSetting';
import { Text } from '../../basic/Text';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const Steps_1 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[white] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-1' className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] mt-4'  >
                        <Text text='Simple Steps for easy start.' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] my-4 px-6' id='random-id-2'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-402' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-403' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-404' className='mx-auto w-[64px] h-[64px] rounded-[4px]  items-center' style={{ background: '#B20312' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', color: '#fff', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-3'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#100103', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-4'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-5'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-405' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-406' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px]' style={{ background: '#B20312' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', color: '#fff', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-6'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#100103', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-7'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-8'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-407' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-408' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px]' style={{ background: '#B20312' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', color: '#fff', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-9'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#100103', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-10'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-11'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto py-10 bg-[#121826]" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >

                <Element is={Parent} canvas id='random-id-410' className='max-w-[1300px] h-full flex flex-col'>

                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center IncreaseConversion2 mt-4' id='random-id-12'  >
                        <Text text='Simple Steps for easy start' />
                    </Element>

                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFF] my-4 px-6' id='random-id-13'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>

                    <Element is={Parent} canvas id='random-id-411' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-412' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Element is={Parent} canvas id='random-id-413' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#4BDBA2' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-14'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-15'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-16'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-414' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-415' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#4BDBA2' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-17'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-18'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-19'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-416' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-417' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#4BDBA2' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-20'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-21'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-22'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element>
            </Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto py-10 bg-[#213156] " >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-419' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Times New Roman' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#EA6EF4] mt-4 ' id='random-id-23'  >
                        <Text text='Simple Steps for easy start' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Times New Roman' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFF] my-4 px-6' id='random-id-24'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-420' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-421' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Element is={Parent} canvas id='random-id-422' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[10px 2px 20px 2px] justify-center flex' style={{ background: '#EA6EF4', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Open Sans' }} className='text-center w-full h-full' id='random-id-25'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-26'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Open Sans', color: '#FFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-27'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-423' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-424' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[10px 2px 20px 2px] justify-center flex' style={{ background: '#EA6EF4', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-28'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-29'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Open Sans', color: '#FFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-30'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-425' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-426' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[10px 2px 20px 2px] justify-center flex' style={{ background: '#EA6EF4', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-31'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-32'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Open Sans', color: '#FFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-33'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto py-10  bg-[#DFFFF9] " >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-428' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#31A993] mt-4 ' id='random-id-34'  >
                        <Text text='Simple Steps for easy start' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Zing Script Rust SemiBold Demo' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#031815] my-4 px-6' id='random-id-35'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-429' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-430' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Element is={Parent} canvas id='random-id-431' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex ' style={{ background: '#31A993', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Montserrat' }} className='text-center w-full h-full' id='random-id-36'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#31A993', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-37'  >
                                ,                             <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Montserrat', color: '#031815', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-38'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-432' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-433' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex ' style={{ background: '#31A993', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-39'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#31A993', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-40'  >
                                <Text text='Step 2' />
                            </Element>

                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Montserrat', color: '#031815', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-41'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-434' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-435' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex ' style={{ background: '#31A993', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-42'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#31A993', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-43'  >
                                <Text text='Step 3' />
                            </Element>

                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Montserrat', color: '#031815', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-44'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto py-10 bg-[#FFFFFF]" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-437' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#0078D3] mt-4 ' id='random-id-45'  >
                        <Text text='Simple Steps for easy start' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Inter' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#031815] my-4 px-6' id='random-id-46'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-438' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-439' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Element is={Parent} canvas id='random-id-440' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#0078D3', color: '#FFF' }}>
                                <Element is={Parent} canvas className='text-center w-full h-full' style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter' }} id='random-id-47'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-48'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Inter', color: '#031815', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-49'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-441' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-442' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#0078D3', color: '#FFF' }}>
                                <Element is={Parent} canvas className='text-center w-full h-full' style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter', textAlign: 'center' }} id='random-id-50'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-51'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Inter', color: '#031815', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-52'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-443' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-444' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#0078D3', color: '#FFF' }}>
                                <Element is={Parent} canvas className='text-center w-full h-full' style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter', textAlign: 'center' }} id='random-id-53'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-54'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Inter', color: '#031815', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-55'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto py-10 bg-[#FFFFFF]" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-446' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas id='random-id-447' className='w-[100%] h-full bg-[#F8F8F8]'>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#020212] mt-4 ' id='random-id-56'  >
                            <Text text='Simple Steps for easy start' />
                        </Element>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#020212] my-4 px-6' id='random-id-57'  >
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-448' className='w-full h-full flex flex-row flex-wrap py-20 '>
                        <Element is={Parent} canvas id='random-id-449' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Element is={Parent} canvas id='random-id-450' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#020212', color: 'white' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces' }} className='text-center w-full h-full' id='random-id-58'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-59'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Voces', color: '#020212', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-60'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-451' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-452' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#020212', color: 'white' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-61'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-62'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Voces', color: '#020212', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-63'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-453' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-454' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#020212', color: 'white' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-64'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-65'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Voces', color: '#020212', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-66'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,



        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto py-10 bg-[#FBFAFF] " >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-456' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#6750A4] mt-4 ' id='random-id-67'  >
                        <Text text='Simple Steps for easy start' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Roboto' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#020212] my-4 px-6' id='random-id-68'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-457' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-458' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Element is={Parent} canvas id='random-id-459' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex' style={{ background: '#6750A4', color: 'white' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto' }} className='text-center w-full h-full' id='random-id-69'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-70'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto', color: '#020212', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-71'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-460' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-461' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex' style={{ background: '#6750A4', color: 'white' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-72'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-73'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto', color: '#020212', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-74'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-462' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-463' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex' style={{ background: '#6750A4', color: 'white' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-75'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-76'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto', color: '#020212', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-77'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto py-10 bg-[#2A2A2A]" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-465' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Rum Raisin' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFD600] mt-4 ' id='random-id-78'  >
                        <Text text='Simple Steps for easy start' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFFFFF] my-4 px-6' id='random-id-79'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-466' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-467' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Element is={Parent} canvas id='random-id-468' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#FFD600', color: '#2A2A2A' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-80'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-81'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-82'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-469' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-470' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#FFD600', color: '#2A2A2A' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-83'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-84'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-85'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-472' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Element is={Parent} canvas id='random-id-473' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#FFD600', color: '#2A2A2A' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-86'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-87'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto flex justify-center' id='random-id-88'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto py-10 bg-[#F7FBFE]" >
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-901' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-902' className='w-auto h-full mx-auto flex justify-center text-center text-[#6064D2] mt-4'  >
                        <Text text='Simple Steps for easy start.' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] my-4 px-6' id='random-id-903'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-904' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-905' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-906' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#B20312', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-907'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-908'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-909'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-910' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-911' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#B20312', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-912'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-913'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-914'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-915' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-916' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#B20312', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-917'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-918'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-919'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto py-10 bg-[#FEFBF7]" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-921' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Ruluko' }} id='random-id-921' className='w-auto h-full mx-auto flex justify-center text-center text-[#A25738] mt-4'  >
                        <Text text='Simple Steps for easy start.' />
                    </Element>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] my-4 px-6' id='random-id-922'  >
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-923' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Element is={Parent} canvas id='random-id-924' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-925' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#A25738', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-926'  >
                                    <Text text='1' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-927'  >
                                <Text text='Step 1' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-928'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-929' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-930' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#A25738', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-931'  >
                                    <Text text='2' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-932'  >
                                <Text text='Step 2' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-933'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-934' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-935' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#A25738', color: '#FFF' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-936'  >
                                    <Text text='3' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-937'  >
                                <Text text='Step 3' />
                            </Element>
                            <Element is={Parent} canvas style={{
                                display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', 
                                fontWeight: '400px', lineHeight: '28.8px'
                            }} className='w-[300px] mx-auto' id='random-id-938'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Element>
                        </Element>
                    </Element>
                </Element>
            </Element>
        </Element>
        ,
    ]

    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])

    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}

Steps_1.craft = {
    displayName: "Steps 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 10,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewStepsSettings,
    },
};