/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { Container } from '../../../Container';
import { ResizableContainer } from '../ResizableContainer';
import { ColumnSettings } from './ColumnSettings';
import { ArrowDivider, BookDivider, CurveDivider, SplitDivider, TiltDivider, TriangleAsymmetricalDivider, TriangleDivider, WaveDivider, WavesDivider } from '../../../editor/Viewport/Editor-icons/svgArray';
import MainItem from './MainItem';

const defaultProps = {
  actionState: 'default',

  default: {
    flexWrap: null,
    minWidth: null,
    margin: null,
    borderStyle: null,
    borderWidth: null,
    borderColor: null,
    borderRadius: null,
    cursor: null,
    width: '100%',
    height:'100%',
    shadowX: null,
    shadowY: '1px',
    shadowColor: null,
    shadowBlur: '1px',
    padding: '.5%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxHeight: '100%',
    className: '',
    background: '#98919130',
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    maxWidth: '100%',
    isRotateXYZ: false,
    transformStyle: 'translate',
    display: 'flex',
    flexDirection: 'row',
    shapePos: null,
    shapeStyle: null,
    shapeHeight: null,
    shapeWidth: null,
    shapeArrangement: null,
    shapeColor: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  },
  hovered: {
    shadowX: null,
    shadowY: null,
    shadowColor: null,
    shadowBlur: null,
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,
    transformStyle: 'translate',
    shapePos: null,
    shapeStyle: null,
    shapeHeight: null,
    shapeWidth: null,
    shapeArrangement: null,
    shapeColor: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  },
  active: {
    shadowX: null,
    shadowY: null,
    shadowColor: null,
    shadowBlur: null,
    width: null,
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,
    shapePos: null,
    shapeStyle: null,
    shapeHeight: null,
    shapeWidth: null,
    shapeArrangement: null,
    shapeColor: null,
    transformStyle: 'translate',

    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  }

};

export const Column1 = ({
  actionState = 'default',
  style = {},
  transitionDuration,
  transitionTimingFunction,
  transitionDelay,
  transitionProperty,
  cursor,
  filterOptions,
  filterValue,

  shapePositions = {
    Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
    Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
    Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
    Top: { top: 0, left: 0, transform: '' },
  },
  shapes = {
    Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
    Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
    Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
    Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
  },

  children, columns, ...defaultProps }) => {

  const {
    id,
    selected,
    node,
    connectors: { connect, drag },
    actions: {setProp},
    isDragged,
  } = useNode(node => ({
    node:node,
    selected: node.events.selected,
    isDragged: node.events.dragged,
  }));

  const {
    nodes,
    enabled,
  } = useEditor((state, query) => {
    return {
      nodes: state.nodes,
      enabled: state.options.enabled,
    };
  });

  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const child = nodes[id]
  const parent = child.data.parent;
  const parentNode = nodes[parent].data.parent
  const parentofparent = nodes[parentNode]?.data.parent

  const getParentProperty = (nodes, id, level) => {
    let currentNode = nodes[id];
    for (let i = 0; i < level; i++) {
      if (currentNode?.data?.parent) {
        currentNode = nodes[currentNode.data.parent];
      } else {
        return null; // Return null if we can't find the parent at the desired level
      }
    }
    return currentNode?.data?.props; // Assuming you want to get properties from the parent node
  };

  // Example of how to use this function
  // const childProps = getParentProperty(nodes, id, 2); // 2 means parent of the parent
  // const childWidth = childProps.default?.childrenWidth;
  // const childHeight = childProps.default?.childrenHeight;

  const mergedRef = useRef(null);

  const handleMouseEnter = (e) => {
    // e.stopPropagation()
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    // e.stopPropagation()
    setIsHovered(false);
  };
  // const gridCase = nodes[parent].data.props.display === 'grid'


  function getColor(shapeColor) {
    return shapeColor !== null ? shapeColor : '#000';
  }

  function ShapesDisplay() {
    const shapeElements = Object.keys(shapes).map((key, index) => {
      const shape = shapes[key];
      const style = {
        width: '100%',
        position: 'absolute',
        zIndex: shape.shapeArrangement === 'Underneath Section' ? 0 : 1,
        display: key === 'Left' || key === 'Right' && 'none',
        ...shapePositions[key],
      };

      let shapeComponent;
      switch (shape.shapeStyle) {
        case 'Triangle':
          shapeComponent = <TriangleDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Curve':
          shapeComponent = <CurveDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Wave':
          shapeComponent = <WaveDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Waves Opacity':
          shapeComponent = <WavesDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Triangle Asymmetrical':
          shapeComponent = <TriangleAsymmetricalDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Tilt':
          shapeComponent = <TiltDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Arrow':
          shapeComponent = <ArrowDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Split':
          shapeComponent = <SplitDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Book':
          shapeComponent = <BookDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        default:
          shapeComponent = null;
      }

      return (
        <div key={key} style={style}>
          {shapeComponent}
        </div>
      );
    });

    return shapeElements;
  }

  const memoizedProps = useMemo(() => ({
    ...style,
    filter: filterValue !== null ? `${filterOptions}(${filterValue})` : '',
    transitionProperty: transitionProperty || null,
    transitionDuration: `${transitionDuration}` || null,
    transitionTimingFunction: transitionTimingFunction || null,
    transitionDelay: `${transitionDelay}` || null,

  }), [defaultProps, enabled]);

  return (
    <MainItem
      activeBackground={activeBackground}
      isDragged={isDragged}
      isHovered={isHovered}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      memoizedProps={memoizedProps}
      actionState={defaultProps}
      defaultProps={defaultProps['default']}
      hoverProperties={defaultProps['hovered']}
      activeProperties={defaultProps['active']}
      connect={connect}
      enabled={enabled}
      selected={selected}
      mergedRef={mergedRef}
      // style={{ width: (parentofparent 
      //   ? (gridCase 
      //       ? '100%' 
      //       : (realWidth || childWidth || defaultProps?.default?.width !== null 
      //         ? defaultProps?.default?.width 
      //         : '100%')) : '100%'),}}
      className={`${defaultProps?.default?.className} ${enabled && 'helloworldsParentChildren '} relative`}
    >
      {ShapesDisplay()}

      <Element canvas id='random-id-40' flexDirection='column' child justifyContent='center' background={'transparent'} name='Columns Area' displayText is={Container}>

      </Element>
      {enabled && <>
        <ResizableContainer
          originalProperties={defaultProps}
          setProp={setProp}
          activeBackground={activeBackground}
          width={defaultProps['default']?.width}
          height={defaultProps['default']?.height}
          selected={selected}
          childRef={mergedRef}
          setActiveBackground={setActiveBackground}
          setIsHovered={setIsHovered} isHovered={isHovered} />
      </>}

    </MainItem>

  );
};
Column1.craft = {
  displayName: "Column",
  props: defaultProps,
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: ColumnSettings,
  },
};