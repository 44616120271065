/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR falseS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { DividerSettings } from './DividerSettings';
import { useActiveIdContext } from '../../../ActiveIdContext';
import { ResizableWrapper } from '../ResizableWrapper';


const defaultProps = {
  isSvg: false,
  isBasic: true,
  text: 'Type Here',
  style: {},
  tagName: 'div',
  href:null,
    className : '',
    style: {},
    width:null,
    maxWidth:null,
    minWidth:null,
    height:null,
    background:  '#0867FC80',
    color: null,
    margin: null,
    marginTop: null,
    marginBottom:null,
    marginLeft:  null,
    marginRight: null,
    padding:null ,
    paddingTop:  null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight:null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: 'solid',
    borderRadius:null,
    overflow: null,
    textAlign: null,
    fontSize: null,
    textTransform: null,
    fontWeight:  null,
    lineHeight:  null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily:  null,
    textAlign: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor:null,
    shadowBlur: '1px',
};

export const BasicDivider = ({className, ...defaultProps}) => {
  
  const {
    node,
    connectors: { connect },
    selected,
    actions: {setProp},
  } = useNode((node) => ({
    node:node,
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  
  const mergedRef = useRef(null);

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
      }
      mergedRef.current = node;
    }
  }, [connect]);


  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [width, setWidth] = useState(defaultProps?.width || '100%');
  const [height, setHeight] = useState(defaultProps?.height || '3px');


  const memoizedProps = useMemo(() => ({
    ...defaultProps,
    ...defaultProps.style,
    position:'relative',
    boxShadow: defaultProps.shadowY !== null
      ? `${defaultProps.shadowX} ${defaultProps.shadowY} ${defaultProps.shadowBlur} ${defaultProps.shadowColor}`
      : '',
    width: defaultProps.width !== null ?  defaultProps.width : width || '',
    height: defaultProps.height !== null ?  defaultProps.height : height || '',
    background: (activeBackground
                ? "green" 
                : (defaultProps?.background)),
    maxWidth:'100%',
    border: selected ? '.2vmin dashed #98919190' : undefined,
    borderRadius: '10px',
  }), [defaultProps, enabled]);

  const handleMouseEnter = (e) => {
    e.stopPropagation()
  
      setIsHovered(true);
    };

  const handleMouseLeave = (e) => {
    e.stopPropagation()
      setIsHovered(false);
  };
      

  return (
    <div
    ref={setRef}
    className={`${className} mx-auto my-[.5rem]`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    style={memoizedProps}
    >
      <div className='w-full'></div>
    {enabled && <> <ResizableWrapper selected={selected} childRef={mergedRef} setActiveBackground={setActiveBackground} setWidth={setWidth} setHeight={setHeight} setIsHovered={setIsHovered} isHovered={isHovered}/> </>}

    </div>
  );
};

BasicDivider.craft = {
  displayName: 'Divider',
  props: defaultProps,
  related: {
    toolbar: DividerSettings,
  },
};