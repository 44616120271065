/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewContentSettings } from './newContentSetting';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Text } from '../../basic/Text';
import  { ImageC }  from '../../basic/Image';
import { Button } from '../../basic/Button';

import { Parent } from '../../../selectors/Parent/index';
import { Wrapper } from '../../../selectors/wrapper/index';
import { BounceLoader } from 'react-spinners';


export const Content_2 = ({ selected }) => {

    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
    


    const styles = [
       
    <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
  <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
    <Wrapper canvas id='random-id-400' is='parent' style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', padding:'20px 0'}}>
            <Wrapper canvas id='random-id-402' is='div'>
                     <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontSize:'46px', fontStyle:'normal', fontWeight:'700px', color:'#B20312', margin:'15px'}} id="random-id-1" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
                     <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'400px', color:'#100103', marginBottom:'15px'}} id="random-id-2" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                  </Wrapper>
            </Wrapper>


            <Wrapper canvas id='random-id-403' is='wrapper' className="mx-auto" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em'}}>

                <Wrapper canvas id='random-id-404' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                    <Wrapper canvas id='random-id-405' is='div' style={{margin:'10px', height:'320px', width:'auto'}}>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-100' is='div'>
                            <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas style={{ display: "inline-block", margin:'10px', fontFamily:'Rubik', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#100103', width:'auto', height:'auto' }} id="random-id-3" is="div">
                        <Text text="Enim ad minim veniam" />
                    </Wrapper>
                         <Wrapper canvas style={{ display: "inline-block", padding:'0 10px 20px 10px', fontFamily:'Rubik', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#100103', width:'full', height:'auto' }} className="mx-auto"  id="random-id-4" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                    <Wrapper canvas id='random-id-1000' is='div' >
                        <Button className="flex bg-[#B20312] hover:bg-[#87000C] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white'}}>
                            <Wrapper className="mx-auto my-auto uppercase" style={{fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>
                                <Text text='Learn more' />
                            </Wrapper>
                        </Button>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-406' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                    <Wrapper canvas id='random-id-407' is='div' style={{margin:'10px', height:'320px', width:'auto'}}>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-101' is='div'>
                            <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                        </Wrapper>
                    </Wrapper>
                         <Wrapper canvas style={{ display: "inline-block", margin:'10px', fontFamily:'Rubik', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#100103', width:'auto', height:'auto' }} id="random-id-5" is="div">
                    <Text text="Excepteur sint occaecat" />
                  </Wrapper>
                         <Wrapper canvas style={{ display: "inline-block", padding:'0 10px 20px 10px', fontFamily:'Rubik', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#100103',  height:'auto' }} className="mx-auto" id="random-id-6" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                  <Wrapper canvas id='random-id-1001' is='div' >
                        <Button className="flex bg-[#B20312] hover:bg-[#87000C] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white'}}>
                            <Wrapper className="mx-auto my-auto uppercase" style={{fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>
                                <Text text='Learn more' />
                            </Wrapper>
                        </Button>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
    </Wrapper></Element>
  </Element>,



    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#121826] py-10" >
  <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
    <div style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', padding:'20px 0'}}>
        <div>
            <div className="IncreaseConversion2"  style={{fontFamily:'Rubik', fontSize:'46px', fontStyle:'normal', fontWeight:'700px', color:'#B20312', margin:'15px'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-7" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
            </div>
            <div  style={{fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'400px', color:'#FFF', marginBottom:'15px'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-8" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                  </Wrapper>
            </div>
        </div>

        
        <div  className="mx-auto  flex-wrap" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em'}}>

            <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                <div style={{margin:'10px', height:'320px', width:'auto'}}>
                    <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-102' is='div'>
                        <ImageC className="object-cover w-full" style={{height:'100%'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                    </Wrapper>
                </div>
                <div className="IncreaseConversion2"  style={{margin:'10px', fontFamily:'Rubik', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#FFF', width:'auto', height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-9" is="div">
                    <Text text="Enim ad minim veniam" />
                  </Wrapper>
                </div>
                <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Rubik', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF', width:'full', height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-10" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                </div>
                <div className="flex bg-[#3265E3] hover:bg-[#0049FF] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white'}}>
                    <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                </div>
            </div>
            <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                <div style={{margin:'10px', height:'320px', width:'auto'}}>
                    <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-103' is='div'>
                        <ImageC className="object-cover w-full" style={{height:'100%'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                    </Wrapper>
                </div>
                <div className="IncreaseConversion2"  style={{margin:'10px', fontFamily:'Rubik', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#FFF', width:'auto', height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-11" is="div">
                    <Text text="Excepteur sint occaecat" />
                  </Wrapper>
                </div>
                <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Rubik', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF',  height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-12" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                </div>
                <div className="flex bg-[#3265E3] hover:bg-[#0049FF] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white'}}>
                    <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                </div>
            </div>
            
    </div>
</div></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
  <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} ><div style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', background:'#213156', padding:'20px 0'}}>
        <div style={{width:'80%', margin:'auto', height:'100%'}}>
            <div>
                <div   style={{fontFamily:'Yeseva One', fontSize:'46px', fontStyle:'normal', fontWeight:'700px', color:'#D166E0', margin:'15px'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-13" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
                </div>
                <div  style={{fontFamily:'Open Sans', fontSize:'22px', fontStyle:'normal', fontWeight:'400px', color:'#FFF', marginBottom:'15px'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-14" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                  </Wrapper>
                </div>
            </div>

            
            <div  className="mx-auto" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em'}}>

                <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                    <div style={{margin:'10px', height:'320px', width:'auto'}}>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-104' is='div'>
                            <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%', borderRadius:'40px 0'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                        </Wrapper>
                    </div>
                    <div  style={{margin:'10px', fontFamily:'Yeseva One', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#D166E0', width:'auto', height:'auto'}}>
                         <Wrapper canvas style={{ display: "inline-block" }} id="random-id-15" is="div">
                    <Text text="Enim ad minim veniam" />
                  </Wrapper>
                    </div>
                    <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Open Sans', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF', width:'full', height:'auto'}}>
                         <Wrapper canvas style={{ display: "inline-block" }} id="random-id-16" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                    </div>
                    <div className="flex bg-[#D166E0] hover:bg-[#E111F2] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white', borderRadius:'30px'}}>
                        <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Open Sans', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                    </div>
                </div>
                <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                    <div style={{margin:'10px', height:'320px', width:'auto'}}>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-105' is='div'>
                            <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%', borderRadius:'40px 0'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                        </Wrapper>
                    </div>
                    <div  style={{margin:'10px', fontFamily:'Yeseva One', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#D166E0', width:'auto', height:'auto'}}>
                         <Wrapper canvas style={{ display: "inline-block" }} id="random-id-17" is="div">
                    <Text text="Excepteur sint occaecat" />
                  </Wrapper>
                    </div>
                    <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Open Sans', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF',  height:'auto'}}>
                         <Wrapper canvas style={{ display: "inline-block" }} id="random-id-18" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                    </div>
                    <div className="flex bg-[#D166E0] hover:bg-[#E111F2] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white', borderRadius:'30px'}}>
                        <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Open Sans', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                    </div>
                </div>
        </div>
        </div>
    </div></Element>
  </Element>,



     <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
  <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} ><div style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', background:'#DFFFF9', padding:'20px 0'}}>
     <div style={{width:'80%', margin:'auto', height:'100%'}}>
         <div>
             <div   style={{fontFamily:'Zing Script Rust SemiBold Demo', fontSize:'46px', fontStyle:'normal', fontWeight:'700px', color:'#31A993', margin:'15px'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-19" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
             </div>
             <div  style={{fontFamily:'Montserrat', fontSize:'22px', fontStyle:'normal', fontWeight:'400px', color:'#031815', marginBottom:'15px'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-20" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                  </Wrapper>
             </div>
         </div>

         
         <div  className="mx-auto" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em'}}>

             <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                 <div style={{margin:'10px', height:'320px', width:'auto'}}>
                    <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-106' is='div'>
                        <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%', borderRadius:'60px'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                    </Wrapper>
                 </div>
                 <div  style={{margin:'10px', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#31A993', width:'auto', height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-21" is="div">
                    <Text text="Enim ad minim veniam" />
                  </Wrapper>
                 </div>
                 <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Montserrat', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#031815', width:'full', height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-22" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                 </div>
                 <div className="flex bg-[#31A993] hover:bg-[#11816C] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', borderRadius:'40px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white'}}>
                     <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Open Sans', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                 </div>
             </div>
             <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                 <div style={{margin:'10px', height:'320px', width:'auto'}}>
                    <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-1087' is='div'>
                         <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%', borderRadius:'60px'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                    </Wrapper>
                 </div>
                 <div  style={{margin:'10px', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#31A993', width:'auto', height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-23" is="div">
                    <Text text="Excepteur sint occaecat" />
                  </Wrapper>
                 </div>
                 <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Montserrat', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#031815',  height:'auto'}}>
                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-24" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                 </div>
                 <div className="flex bg-[#31A993] hover:bg-[#11816C] cursor-pointer" style={{margin:'auto', width:'260px', height:'70px', borderRadius:'40px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white'}}>
                     <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Open Sans', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                 </div>
             </div>
     </div>
     </div>
 </div></Element>
  </Element>,




        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
  <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} ><div style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', background:'#FFFDFB', padding:'20px 0'}}>
            <div style={{width:'80%', margin:'auto', height:'100%'}}>
                <div>
                    <div  style={{fontFamily:'Inter', fontSize:'46px', fontStyle:'normal', fontWeight:'700px', color:'#0078D3', margin:'15px'}}>
                         <Wrapper canvas style={{ display: "inline-block" }} id="random-id-25" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
                    </div>
                    <div  style={{fontFamily:'Inter', fontSize:'22px', fontStyle:'normal', fontWeight:'400px', color:'#020E17', marginBottom:'15px'}}>
                         <Wrapper canvas style={{ display: "inline-block" }} id="random-id-26" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                  </Wrapper>
                    </div>
                </div>

                
                <div className="mx-auto" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em'}}>

                    <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                        <div style={{margin:'10px', height:'320px', width:'auto'}}>
                            <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-108' is='div'>
                                <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                            </Wrapper>
                        </div>
                        <div  style={{margin:'10px', fontFamily:'Inter', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#020E17', width:'auto', height:'auto'}}>
                             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-27" is="div">
                    <Text text="Enim ad minim veniam" />
                  </Wrapper>
                        </div>
                        <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Inter', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#020E17', width:'full', height:'auto'}}>
                             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-28" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                        </div>
                        <div className="flex bg-[#0078D3] hover:bg-[#11578B] cursor-pointer" style={{margin:'auto', width:'260px', height:'60px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white', borderRadius:'6px'}}>
                            <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Inter', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                        </div>
                    </div>
                    <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                        <div style={{margin:'10px', height:'320px', width:'auto'}}>
                            <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-109' is='div'>
                                <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                            </Wrapper>
                        </div>
                        <div  style={{margin:'10px', fontFamily:'Inter', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#020E17', width:'auto', height:'auto'}}>
                             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-29" is="div">
                    <Text text="Excepteur sint occaecat" />
                  </Wrapper>
                        </div>
                        <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Inter', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#020E17',  height:'auto'}}>
                             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-30" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                        </div>
                        <div className="flex bg-[#0078D3] hover:bg-[#11578B] cursor-pointer" style={{margin:'auto', width:'260px', height:'60px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'white', borderRadius:'6px'}}>
                            <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Inter', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                        </div>
                    </div>
                    
            </div>
            </div>
        </div></Element>
  </Element>,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
  <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} ><div style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', background:'#FFFDFB', padding:'20px 0'}}>
                        <div style={{border:'1px solid #020212', background:'#020212'}}>
                            <div  style={{fontFamily:'Voces', fontSize:'42px', fontStyle:'normal', fontWeight:'700px', color:'white', margin:'15px'}}>
                                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-31" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
                            </div>
                        </div>
                    <div style={{width:'80%', margin:'auto', height:'100%'}}>

                        <div  style={{fontFamily:'Voces', fontSize:'28px', fontStyle:'normal', fontWeight:'400px', color:'#020E17', margin:'15px 0', padding:'0 4em'}}>
                             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-32" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                  </Wrapper>
                        </div>
                        <div  className="mx-auto" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em' }}>

                            <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center', background:'#f5f3f2'}}>
                                <div style={{margin:'10px', height:'320px', width:'auto'}}>
                                    <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-110' is='div'>
                                        <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                                    </Wrapper>
                                </div>
                                <div  style={{margin:'10px', fontFamily:'Voces', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#020E17', width:'auto', height:'auto'}}>
                                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-33" is="div">
                    <Text text="Enim ad minim veniam" />
                  </Wrapper>
                                </div>
                                <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Voces', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#020E17', width:'full', height:'auto'}}>
                                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-34" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                                </div>
                                <div className="flex bg-[#f5f3f2] cursor-pointer hover:bg-[#020212] text-[#020212] hover:text-[#FFFFFF] " style={{margin:'auto', width:'260px', height:'60px', border:'2px solid #020212', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', borderRadius:'10px', marginBottom:'20px'}}>
                                    <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Voces', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                                </div>
                            </div>
                                <div  className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', minWidth:'400px', margin:'6px', justifyContent:'center', background:'#f5f3f2'}}>
                                <div style={{margin:'10px', height:'320px', width:'auto'}}>
                                    <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-111' is='div'>
                                        <ImageC className="object-cover w-[100%] h-[100%]" style={{height:'100%'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                                    </Wrapper>
                                </div>
                                <div  style={{margin:'10px', fontFamily:'Voces', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#020E17', width:'auto', height:'auto'}}>
                                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-35" is="div">
                    <Text text="Excepteur sint occaecat" />
                  </Wrapper>
                                </div>
                                <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Voces', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#020E17',  height:'auto'}}>
                                     <Wrapper canvas style={{ display: "inline-block" }} id="random-id-36" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                                </div>
                                <div className="flex bg-[#f5f3f2] cursor-pointer hover:bg-[#020212] text-[#020212] hover:text-[#FFFFFF] " style={{margin:'auto', width:'260px', height:'60px', border:'2px solid #020212', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', borderRadius:'10px', marginBottom:'20px'}}>
                                    <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Voces', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                                </div>
                            </div>
                            
                    </div>
                    </div>
                </div></Element>
  </Element>,

                <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
  <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} ><div style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', background:'#FFFDFB', padding:'20px 0'}}>
                <div style={{width:'80%', margin:'auto', height:'100%'}}>
                    <div>
                        <div style={{fontFamily:'Roboto', fontSize:'46px', fontStyle:'normal', fontWeight:'700px', color:'#6750A4', margin:'15px'}}>
                             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-37" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
                        </div>
                        <div style={{fontFamily:'Roboto', fontSize:'22px', fontStyle:'normal', fontWeight:'400px', color:'#031815', margin:'10px 26px 26px 26px'}}>
                             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-38" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                  </Wrapper>
                        </div>
                    </div>

                    <div className="mx-auto" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em'}}>

                        <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', background:'#6750A4', borderRadius:'40px', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                            <div style={{margin:'10px 10px 0 10px', height:'320px', width:'auto'}}>
                                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-112' is='div'>
                                    <ImageC className="object-cover w-full" style={{height:'90%', borderRadius:'30px'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                                </Wrapper>
                            </div>
                            <div style={{marginBottom:'5px', fontFamily:'Roboto', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#FFF', width:'auto', height:'auto'}}>
                                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-39" is="div">
                    <Text text="Enim ad minim veniam" />
                  </Wrapper>
                            </div>
                            <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Roboto', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF', width:'full', height:'auto'}}>
                                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-40" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                            </div>
                            <div className="flex border-1 hover:border-[#381E72] cursor-pointer mx-auto mb-4" style={{ width:'260px', height:'50px', background:'#d0bcff', borderRadius:'40px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'#381E72'}}>
                                <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Roboto', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                            </div>
                        </div>
                            <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', background:'#6750A4', borderRadius:'40px', minWidth:'400px', margin:'6px', justifyContent:'center'}}>
                            <div style={{margin:'10px 10px 0 10px', height:'320px', width:'auto'}}>
                                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-113' is='div'>
                                    <ImageC className="object-cover w-full" style={{height:'90%', borderRadius:'30px'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                                </Wrapper>
                            </div>
                            <div  style={{marginBottom:'5px', fontFamily:'Roboto', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#FFF', width:'auto', height:'auto'}}>
                                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-41" is="div">
                    <Text text="Excepteur sint occaecat" />
                  </Wrapper>
                            </div>
                            <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Roboto', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF',  height:'auto'}}>
                                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-42" is="div">
                    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
                  </Wrapper>
                            </div>
                            <div className="flex border-1 hover:border-[#381E72] cursor-pointer mx-auto mb-4" style={{ width:'260px', height:'50px', background:'#d0bcff', borderRadius:'40px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'#381E72'}}>
                                <h4 className="mx-auto my-auto uppercase" style={{fontFamily:'Roboto', fontSize:'22px', fontStyle:'normal', fontWeight:'600px'}}>learn more</h4>
                            </div>
                        </div>
                </div>
                </div>
                </div></Element>
  </Element>,

<Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
  <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} ><div style={{width:'100%', textAlign:'center', height:'100%', maxWidth:'1300px', background:'#2A2A2A', padding:'20px 0'}}>
<div style={{width:'80%', margin:'auto', height:'100%'}}>
    <div>
        <div style={{fontFamily:'Rum Raisin', fontSize:'36px', fontStyle:'normal', fontWeight:'400px', color:'#FFD600', margin:'15px'}}>
             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-43" is="div">
    <Text text="Increase Conversion" />
  </Wrapper>
        </div>
        <div style={{fontFamily:'Rubik', fontSize:'24px', fontStyle:'normal', fontWeight:'400px', color:'#FFFFFF', margin:'10px 26px 26px 26px'}}>
             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-44" is="div">
    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
  </Wrapper>
        </div>
    </div>

    <div className="mx-auto" style={{display:'flex', flexDirection:'row', width:'100%', flexWrap:'wrap', marginBottom:'2em'}}>

        <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'50%', flexWrap:'nowrap', background:'transparent', borderRadius:'10px', minWidth:'400px', margin:'6px', border: '1px solid #FFB8001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', justifyContent:'center'}}>
            <div style={{ height:'320px', width:'auto'}}>
                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-114' is='div'>
                    <ImageC className="object-cover w-full" style={{height:'90%', borderRadius:'10px 10px 0px 0px'}} src="https://i.ibb.co/VYC3dVg/content-1-1.jpg"/>
                </Wrapper>
            </div>
            <div style={{marginBottom:'5px', fontFamily:'Rubik', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#FFD600', width:'auto', height:'auto'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-45" is="div">
    <Text text="Enim ad minim veniam" />
  </Wrapper>
            </div>
            <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Rubik', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF', width:'full', height:'auto'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-46" is="div">
    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
  </Wrapper>
            </div>
            <div className="flex bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer" style={{margin:'auto', width:'260px', height:'40px', borderRadius:'10px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'#2A2A2A', marginBottom:'2em'}}>
                <h4 className="mx-auto my-auto" style={{fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'bold'}}>learn more</h4>
            </div>
        </div>
            <div className='mx-auto' style={{display:'flex', flexDirection:'column', width:'49%', flexWrap:'nowrap', background:'transparent', borderRadius:'10px', minWidth:'400px', margin:'6px', border: '1px solid #FFB8001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', justifyContent:'center'}}>
            <div style={{ height:'320px', width:'auto'}}>
                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-115' is='div'>
                    <ImageC className="object-cover w-full" style={{height:'90%', borderRadius:'10px 10px 0px 0px'}} src="https://i.ibb.co/NTmDp2z/content1-2.jpg"/>
                </Wrapper>
            </div>
            <div  style={{marginBottom:'5px', fontFamily:'Rubik', fontStyle:'normal', fontWeight:'700px', fontSize:'36px', color:'#FFD600', width:'auto', height:'auto'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-47" is="div">
    <Text text="Excepteur sint occaecat" />
  </Wrapper>
            </div>
            <div className="mx-auto" style={{padding:'0 10px 20px 10px', fontFamily:'Rubik', fontWeight:'400px', fontStyle:'normal', fontSize:'18px', textAlign:'center', color:'#FFF',  height:'auto'}}>
                 <Wrapper canvas style={{ display: "inline-block" }} id="random-id-47" is="div">
    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse sed." />
  </Wrapper>
            </div>
            <div className="flex bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer" style={{margin:'auto', width:'260px', height:'40px', borderRadius:'10px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', color:'#2A2A2A', marginBottom:'2em'}}>
                <h4 className="mx-auto my-auto" style={{fontFamily:'Rubik', fontSize:'22px', fontStyle:'normal', fontWeight:'bold'}}>learn more</h4>
            </div>
        </div>
</div>
</div>
</div></Element>
  </Element>,
    
    ]

     
    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
      setProp((prop) => {
        setSel(100);
      }, 1000);

      setTimeout(() => {
        setProp((prop) => {
          setSel(number)
        }, 1000);
      }, 100);


      
  }

  useEffect(() => {
    if(selected || selected >= 0) {
      makeOdd(selected);
    }
  },[selected])

  return ( <div style={{ padding: enabled? '10px' : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
   {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Content_2.craft = {
    displayName: "Content 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length:8,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewContentSettings,
      },
  };