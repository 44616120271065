/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { withStyles } from '@mui/styles';
import { useState } from 'react';
import { UndoIcon } from '../../Viewport/Editor-icons/EditorSvg';
import { Slider as Small } from '@mui/material';


const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const NumberSlider = ({
    value,
    node,
    setProp,
    isDarkMode,
    propKey,
    props,
    label,
    onChange,
    unit,
    min,
    max
}) => {

    const numericValue = parseFloat(value) || 0;

    

    return (
        <div className='w-full flex flex-row justify-between align-items-center flex-1 flex-wrap'>
            {props.label || label ? (
              <div className={` flex items-center ${isDarkMode ? 'text-[#b0b0b0]' : 'text-[#FFFFFF]'}`} style={{ fontSize: '2vmin', fontWeight: '500', fontFamily: 'Inter', lineHeight:'100%', textWrap:'nowrap', flex:1, minWidth:onChange? 'fit-content' : '15vmin'}}>{props.label || label}</div>
            ) : null}
              <div className={`${isDarkMode ? 'text-[#b0b0b0]' : 'text-[#FFFFFF]'} text-[2vmin] text-center flex-1 mx-[1vmin]`}>{value || 0}{unit? unit === '%' ? '%' : 'px' : null}</div>
              <div className=' mx-auto flex justify-center'>
              <SliderStyled
              style={{minWidth:'15vmin'}}
              className='w-full'
                  min={min || 0}
                  max={max? max : unit ? unit === '%' ? 100 : 500 : 500}
                  value={numericValue}
                  onChange={(e) => {
                    let newValue = e.target.value
                    onChange? onChange(newValue) :
                    setProp(node.id, (props) => {
                      props[propKey] = newValue
                    })
                  }}
                />
              </div>
          </div>
    )
}