import React from 'react'
import { Circle1 } from "../iconsSvg"
import NextButton from './NextButton';

const TitleStep = ({setStepper, setAppSettings, setWalksFor, appSettings, stepper, setStart, select, walksFor}) => {

    const handleAppTitle = (e) => {
        let newTitle = e.target.value
        setStepper(0);
        setAppSettings((prev) => ({
            ...prev,
            appTitle: { value: newTitle }
        }));
    };

    return (
        <div className='relative w-full h-full flex flex-col justify-center items-center py-[4vmin] text-[2vmin]' >
                            <div>Set your app name</div>
                            <div style={{ border: '.25vmin solid #e9e9e930' }}
                                className={`w-[100%] py-[.7vmin] shadow-md mb-[1.5Vmin] `}
                            >
                                <input
                                    className={`text-[#000] py-[.7vmin] text-[2.1vmin] text-center font-bold font-["Inter"] text-[#161717]`}
                                    style={{ height: 'fit-content', background: 'transparent' }}
                                    value={appSettings.appTitle.value}
                                    onChange={(e) => handleAppTitle(e)}
                                />
                            </div>
                            {/* {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
                                <Circle1 fill={stepper >= 1 ? 'green' : ''} />
                            </div>} */}
                            <NextButton setStart={setStart}
                            onClick={() => {
                                setStepper(1)
                                setWalksFor((prev) => ({
                                    ...prev,
                                    main: { value: 0.350 },
                                    mainTheme: { value: 1 }
                                }))
                            }}
                            />
                        </div>
    )
}

export default TitleStep;