import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppProps } from '../../../../DarkModeContext';
import { useEditor, useNode } from '@craftjs/core';
import { withStyles } from '@mui/styles';
import { Slider as MSlider } from '@mui/material';
import { Toolbar } from '../../Toolbar';
import SideBarProperties from '../../../selectors/blocks/Header/Side bar/SideBarProperties';

// Styled Slider component
const SliderStyled = withStyles({
  root: {
    color: '#ABE2FE',
    padding: '5% 0',
  },
  thumb: {
    height: '60%',
    width: '8%',
    backgroundColor: '#ffffff',
    border: '2px solid #ABE2FE',
    marginTop: '-0.5%',
  },
  valueLabel: {
    left: 'calc(-50% + 1.1%)',
    top: '-22%',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    padding: '1.5%',
    height: '2%',
  },
  rail: {
    padding: '1.5%',
    height: '2%',
    opacity: 0.5,
    backgroundColor: '#ABE2FE',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: '8%',
    width: '10%',
    marginTop: '-3%',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(MSlider);

// Memoize SideBarProperties to avoid unnecessary re-renders
const MemoizedSideBarProperties = React.memo(SideBarProperties);

export const Sidebar = ({
  selectNode,
  socialIcons,
  setSocialIcons,  
}) => {

  const { 
    currentStyleIndex, setCurrentStyleIndex,
    logoText, setLogoText,
    setStylesOn, logoIcon,
    setLogoIcon,
  } = useAppProps();

  // Memoize the styles to avoid unnecessary recalculations
  const sliderStyles = useMemo(() => ({
    width: '90%',
    padding: '3% 15%',
    borderRadius: '2.5vmin',
    margin: '3vmin auto',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border:'.25vmin solid #e9e9e990',
  }), []); // No dependencies, so it will only be computed once

  const handleSliderChange = useCallback((event) => {
    setCurrentStyleIndex(parseInt(event.target.value, 10));
  }, [setCurrentStyleIndex]);

  const handleSliderBlur = useCallback(() => {
    setStylesOn(false);
  }, [setStylesOn]);

  const handleSliderFocus = useCallback(() => {
    setStylesOn(true);
  }, [setStylesOn]);


  return (
      <>
          <MemoizedSideBarProperties
            selectNode={selectNode}
            socialIcons={socialIcons}
            setSocialIcons={setSocialIcons}
            logoText={logoText}
            setLogoText={setLogoText}
            setLogoIcon={setLogoIcon}
            logoIcon={logoIcon}
          />
          <div style={sliderStyles}>
            <SliderStyled
              title="Navigation System Styles"
              min={0}
              max={23}
              value={currentStyleIndex}
              onChange={handleSliderChange}
              onBlur={handleSliderBlur}
              onFocus={handleSliderFocus}
            />
          </div>
      </> 
  )
};
