/****************************
 * Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy).
 * Original Project Name: LoStudio.
 * use this file except in compliance with the License. You may obtain a copy
 * of the License contacting Carlo Di Michele at:
 *
 * carlo.dimichele@gmail.com
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations under
 * the License.
 ****************************/
import { useEditor } from "@craftjs/core";
import cx from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import "./ToolBox.css";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { Toolbox } from "./Toolbox";
import { useAppProps } from "../../../DarkModeContext";
import { useActiveIdContext } from "../../../ActiveIdContext";
import NewHeader from "../../selectors/blocks/Header/NewHeader";
import NewFooter from "../../selectors/blocks/Footer/newFooter";
import SideBar from "../../selectors/blocks/Header/Side bar/sideBar";
import { Slider as Small } from "@mui/material";
import { withStyles } from "@mui/styles";
import { BounceLoader } from "react-spinners";
import axios from "axios";
import addThemeMutation from "../../../graphql/mutations/theme/addThemeMutation";
import { useMutation } from "@apollo/client";
import WidthContext from "../../../WidthContext/WidthContext";
import SidebarContext from "../../../SidebarShown/SidebarContext";
import AskHeader from "../../widgets/newHeader/PopupAsk";
import { New_Header } from "../../widgets/newHeader";
import { NewHeaderNav } from "../../widgets/newHeader/block";
import { Editor_Widget } from "../../widgets/editor";
import { AppSetup } from "../../../Tutourial/ViewArea/OriginalView";
import { useSidesContext } from "../../../SideContext";
import styled from "styled-components";
import { useOptionsContext } from "../../../OptionsContext";
import { MenuIcon3 } from "./Editor-icons/svgArray";

const SliderStyled = withStyles({
  root: {
    color: "#ABE2FE",
    padding: "5% 0",
  },
  thumb: {
    height: "60%",
    width: "8%",
    backgroundColor: "#ffffff",
    border: "2px solid #ABE2FE",
    marginTop: "-0.5%",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 1.1%)",
    top: "-22%",
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    padding: "1.5%",
    height: "2%",
  },
  rail: {
    padding: "1.5%",
    height: "2%",
    opacity: 0.5,
    backgroundColor: "#ABE2FE",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: "8%",
    width: "10%",
    marginTop: "-3%",
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Small);

const TabContainer = styled.div`
  width:100%;
  display: flex;
  margin-top: 1vmin;
  margin-left:2vmin;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const Tab = styled.div`
  padding: 1vmin 2vmin;
  font-size: 1.9vmin;
  text-transform: capitalize;
  margin: ${(props) => (props.space ? "" : "0 5px")};
  background-color: ${(props) => (!props.active ? props.menu ? "#0867FC40" : "#e9e9e940" : "#e9e9e980")};
  border: .25vmin solid #e9e9e930;
  border-radius: ${(props) => (props.menu ? "1vmin" : "10px 10px 0 0")};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "500" : "normal")};
  color: ${(props) => (!props.active ? props.menu ? "#FFF" : "rgba(233, 233, 233, 0.89)" : props.menu ? "#" :  "#FFF")};
  box-shadow: ${(props) =>
    props.active ? "0 4px 10px rgba(0, 0, 0, 0.1)" : "none"};
  transition: all 0.3s ease;

  // &:hover {
  //   background-color: #ddd;
  // }
`;


export const Viewport = ({
  auth,
  preview,
  view,
  children,
  setWorking,
  settings,
  setSettings,
  renderMarkup,
  downloadContent,
  divRef,
  orgId,
}) => {
  const [
    addTheme,
    { error: addThemeError, data: addThemeData, loading: addThemeLoading },
  ] = useMutation(addThemeMutation);


  function flattenObject(obj, parentKey = "", result = {}, delimiter = ".") {
    for (const [key, value] of Object.entries(obj)) {
      const newKey = parentKey ? `${parentKey}${delimiter}${key}` : key;
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        flattenObject(value, newKey, result, delimiter);
      } else {
        result[newKey] = value;
      }
    }
    return result;
  }

  const buildTheme = async () => {
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    setIsLoadingTheme(true)
    let name = prompt("", "Please write the theme name ")
    let templateProps = flattenObject(singinNode)
    if (name) {
      try {
        const response = await axios.post(
          "https://authapi.allyoucancloud.com/build",
          // "http://localhost:4001/build",
          {
            name: `${name}-${orgId}`,
            allProps: templateProps
          },
        )
        console.log(response, "response is this one")
        const responseRun = axios.post(
          "https://authapi.allyoucancloud.com/run",
        )
        await delay(50000);
        console.log("start theme", 1099879)
        addTheme({
          variables: {
            name: name,
            org: orgId
          }
        }).then(res => {
          if (res.data) {
            console.log(res.data, "res data is ready")
            setIsLoadingTheme(false)
            alert(`your login link is : https://sso.allyoucancloud.com/admin/${res.data.addTheme.name}-${orgId}/console/`)
          }
        })
      }
      catch (error) {
        console.log(error)
      }
    }
  };


  // async function loadPageContent(page) {
  //   // Simulating loading the content with a delay of 1 second
  //   setActiveId({ _id: page._id, name: page.name })
  //   await new Promise(resolve => setTimeout(resolve, 1000));

  //   // Perform the necessary logic to load the content of the page
  //   // ...
  //   return page; // Return the updated page object with loaded content
  // }

  // async function savePage(page) {
  //   // Perform the necessary logic to save the page
  //   // ...

  //   // Simulating saving the page with a delay of 1 second

  //   renderMarkup(codeContent, page.name, json)

  //   await new Promise(resolve => setTimeout(resolve, 1000));

  //   return page
  // }

  // async function setActivePageAndSave(page) {
  //   // Set the active page
  //   // ...
  //   // Load the content of the active page
  //   const pageWithContent = await loadPageContent(page);
  //   // Save the entire page
  //   await savePage(pageWithContent);
  // }

  // async function processPages(pages) {
  //   for (const page of pages) {
  //     await setActivePageAndSave(page);
  //   }
  // }


  const {
    active, // keep this variable from the first useEditor
    enabled,
    connectors,
    singinNode,
    json,
    actions: { setOptions, selectNode, setProp },
  } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent("selected").first();
    return {
      active: currentlySelectedNodeId, // include active from the second useEditor
      enabled: state.options.enabled,
      selected: state.events.selected,
      singinNode: state?.nodes["ROOT"]?.data?.props,
      node: state,
      json: query.serialize(),
      related:
        currentlySelectedNodeId && state.nodes[currentlySelectedNodeId]?.related,
    };
  });

  const selectedRef = useRef(null); // Initialize ref

  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [start, setStart] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState(null);
  const [showMenu, setShowMenu] = useState(false);


  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId } = state;

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  
  const { 
    isDarkMode, 
  } = useAppProps();

  const {showSideBar, showHeader, showFooter} = useContext(SidebarContext)
 const {finish, review, setReview} = useSidesContext();
  
  const { 
    socialIcons,
    setSocialIcons,
    tuturial,
    width,
    popUp, setPopUp, 
    isLoadingTheme, setIsLoadingTheme,
    isLoading, setIsLoading,
    navigationSide, setNavigationSide,
    setConnect, 
  } = useAppProps();

    useEffect(() => {
      if (treeData && preview)
        setActiveId({ _id: treeData[0]?._id, name: treeData[0]?.name })
    }, [preview]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 4000);

      return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
      if (!window) {
        return;
      }

    window.requestAnimationFrame(() => {
      // Notify doc site
      window.parent.postMessage(
        {
          LANDING_PAGE_LOADED: true,
        },
        "*"
      );

      setTimeout(() => {
        setOptions((options) => {
          if (!preview) {
            options.enabled = true;
          } else {
            options.enabled = true;
          }
        });
      }, 200);
      setOptions((options) => {
        options.enabled = false;
      }, 200);
    });
  }, [setOptions, preview]);


  const isColor = (value) => {
    const colorPattern =
      /^(#([0-9a-fA-F]{3}){1,2}|rgb(a)?\(\d{1,3}%?,\s*\d{1,3}%?,\s*\d{1,3}%?(,\s*\d?\.?\d+)?\)|hsl(a)?\(\d{1,3}°,\s*\d+%,\s*\d+%(,\s*\d?\.?\d+)?\)|[a-zA-Z]+)$/;
    return typeof value === "string" || colorPattern.test(value);
  };

  const identifyValue = (value) => {
    if (typeof value === "string" && value.startsWith("blob:")) {
      return "Blob";
    } else if (
      typeof value === "string" &&
      (value.startsWith("http://") || value.startsWith("https://"))
    ) {
      return "URL";
    } else if (isColor(value)) {
      return "Color";
    } else {
      return "Unknown";
    }
  };



  let pages = [
    "login.ftl",
    "register.ftl",
    "register-user-profile.ftl",
    "info.ftl",
    "error.ftl",
    "login-reset-password.ftl",
    "login-verify-email.ftl",
    "terms.ftl",
    "login-oauth2-device-verify-user-code.ftl",
    "login-oauth-grant.ftl",
    "login-otp.ftl",
    "login-username.ftl",
    "login-password.ftl",
    "webauthn-authenticate.ftl",
    "login-update-password.ftl",
    "login-update-profile.ftl",
    "login-idp-link-confirm.ftl",
    "login-idp-link-email.ftl",
    "login-page-expired.ftl",
    "login-config-totp.ftl",
    "logout-confirm.ftl",
    "update-user-profile.ftl",
    "idp-review-user-profile.ftl",
    "update-email.ftl",
    "select-authenticator.ftl",
    "saml-post-form.ftl",
    "my-extra-page-1.ftl",
    "my-extra-page-2.ftl",
    "info.ftl"
  ];

  const authData = useMemo(() => {
    return pages.map(page => {
      let name = page.replace('.ftl', '').replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
      return {
        _id: page,
        name: name,
        children: []
      };
    });
  }, [pages]);

  const currentData = auth ? authData : treeData;
  
  useEffect(() => {
    // const backgroundValue = singinNode?.background;
    // const backgroundSetup = identifyValue(backgroundValue);
    // console.log(backgroundSetup)
    // if (backgroundSetup === 'Blob') {
    //   setBackgroundStyle({
    //     backgroundImage: `url(${backgroundValue})`,
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center center',
    //   });
    // } else if (backgroundSetup === 'URL') {
    //   setBackgroundStyle({
    //     backgroundImage: `url(${backgroundValue})`,
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: 'cover',
    //   });
    // } else if (backgroundSetup === 'Color') {
    //   setBackgroundStyle({ background: backgroundValue });
    // } else {
    //   setBackgroundStyle({});
    // }
  }, [singinNode?.background]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getResponsivePagesDisplay = () => {
    if (windowWidth <= 850 || width === '500px') {
      return 5; 
    } else if (windowWidth <= 1200 || width === '800px') {
      return 8;  
    } else {
      return 11; 
    }
  };

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const pagesToDisplay = getResponsivePagesDisplay();

  const dynamicStyles = useMemo(() => ({
    overflowY:!enabled && 'scroll',
    width: !enabled ? '100%' : width === "100%" ? "100%" : width === "800px" ? "800px" : "500px",
  }), [width, enabled]);

  const combinedStyles = { ...dynamicStyles, ...backgroundStyle };

  const handleClose = useCallback(() => {
    setNavigationSide(false);
  }, [navigationSide]);

  const responsivePagesDisplay = pagesToDisplay

  return (
    <div
    onClick={() => {
      selectNode(null)
      setConnect(false)
    }}
      onDrop={() => {
        setStart(true);
        setProp(
          "ROOT",
          (props) => {
            props["startImage"] = false;
          },
          500
        )
      }}
      className="viewport "
      style={{ height: "100%", width: '100%' }}
    >
      {/* {enabled && <DraggableComponent/>} */}
      
      {isLoading && (
        <div className="loading-load">
          <BounceLoader size={100} color={"#FFFFFF"} loading={true} />
        </div>
      )}

      {enabled && popUp &&
        <div
        onClick={() => setPopUp(false)}
        className="absolute bg-[#e9e9e930] flex justify-center align-items-center border-4 top-0 left-0 w-[100%] h-[100vh]"
        style={{ zIndex: 999 }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="mx-auto my-auto"
            style={{ zIndex: 99999 }}
          >
          <AskHeader />
        </div>
          
        </div>
      }

      <Header
        preview={preview}
        view={view}
        buildTheme={buildTheme}
        auth={auth}
        renderMarkup={renderMarkup}
        outStepper
      />

      <div
        style={{ paddingTop: enabled && '4vmin', position:'relative', zIndex:999 }}
        className={cx(["flex h-full visible max-w-[100%] flex-row w-full"])}
      >
        <Toolbox
        auth={auth}
        />
        <div
          ref={divRef}
          style={{
            width: '100%',
            justifyContent: isLoading || isLoadingTheme ? "center" : "flex-start",
            opacity: isLoading || isLoadingTheme ? "10%" : "",
            margin: enabled && "0 1vw 0 0",
            paddingBottom: enabled && "4vh",
            flexDirection: "column",
            overflowY: enabled &&'scroll',
            borderRadius: enabled &&'2vmin',
            scrollbarWidth:'none'
          }}
          className="page-container flex flex-col h-full w-full"
        >

<div style={{position:'relative', zIndex:auth && 999999999999, margin: '0 auto', display: enabled ? 'flex' : 'none', width: !enabled ? '100%' : width === "100%" ? "100%" : width === "800px" ? "800px" : "500px" }}>
  <TabContainer>    

    {/* Render first 13 pages normally */}
    {currentData.slice(0, [responsivePagesDisplay]).map((page, index) => {
      const haveChildren = page.children.length > 0;

      if (haveChildren) {
        return (
          <div style={{ display: 'flex' }} key={index}>
            <Tab
              active={activeId?.name === page.name}
              space={activeSubTab === page._id}
              onClick={() => {
                setShowMenu(false)
                setActiveId({ _id: page._id, name: page.name });
                setActiveSubTab((prev) =>
                  page.children.length > 0 && prev === page?._id ? null : page?._id
                );
              }}
            >
              <div className="flex-row flex">
                {page?.icon && (
                  <div className="logo-side">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="img"
                      viewBox={page.icon.viewBox}
                      width="2vmin"
                      height="2vmin"
                      fill={isDarkMode ? page.icon.fill || '#B0B0B0' : page.icon.fill || '#FFF'}
                    >
                      <path d={page.icon.path}></path>
                    </svg>
                  </div>
                )}
              </div>
            </Tab>
            {activeSubTab === page._id && (
              <div style={{ width: '1vmin', height: '.25vmin', margin: 'auto auto', background: '#e9e9e980' }}></div>
            )}
            {activeSubTab === page._id && (
              <div className="flex">
                {page.children.map((child, index) => (
                  <Tab
                    space={activeSubTab === page._id}
                    onClick={() => {
                      setShowMenu(false)
                      setActiveId({ _id: child._id, name: child.name })}}
                    active={activeId?.name === child.name}
                    key={index}
                  >
                    {child?.name}
                  </Tab>
                ))}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <Tab
            key={index}
            active={activeId?.name === page.name}
            onClick={() => {
              setShowMenu(false)
              setActiveId({ _id: page._id, name: page.name })}}
          >
            {page?.name?.length > 7 ? `${page?.name?.slice(0, 6)}...` : page.name}
          </Tab>
        );
      }
    })}
    {currentData.length > [responsivePagesDisplay] ? (
      <div>
        <Tab
          active={currentData.slice([responsivePagesDisplay]).some((page) => activeId?.name === page.name)}
          onClick={() => setShowMenu((prev) => !prev)}  // Toggle menu visibility
          style={{ cursor: 'pointer' }}
        >
          <MenuIcon3 fill='#FFF' width='3vmin' height='3vmin'/>
        </Tab>
        {showMenu && (
          <div
            style={{
              position: 'absolute',
              top: '7vmin',
              width:'20vmin',
              right: '15%',
              backgroundColor: '#FFF',
              border:'.25vmin solid #0867FC40',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              zIndex: 999999999999,
              borderRadius: '2vmin',
              padding:'2vh .5vmin'
            }}
          >
            <div style={{
              overflowY:'scroll',
              height:'50vmin',
              display:'flex',
              flexDirection:'column',
              paddingBottom:'1vh',
              gap:'1vmin'
            }}>
            {currentData.slice([responsivePagesDisplay]).map((page, index) => {
              return (
                <Tab
                title={page.name}
                  menu={true}
                  key={index}
                  active={activeId?.name === page.name}
                  onClick={() => setActiveId({ _id: page._id, name: page.name })}
                >
                  {page?.name?.length > 11 ? `${page?.name?.slice(0, 10)}...` : page.name}
                </Tab>
              );
            })}
            </div>
          </div>
        )}
      </div>
    ) : null}
  </TabContainer>
</div>

             


          <div
          onClick={(e) => e.stopPropagation()}
            ref={(ref) => {
              selectedRef.current = ref;
              connectors.select(connectors.hover(ref, null), null);
            }}
            id="craftjs"
            style={combinedStyles}
            className={cx(["craftjs-renderer rounded-[1vmin] w-full flex-grow ", { "": enabled }])}
          >

            {/* Sidebar and Header */}
          {/* {!auth &&  (width === '100%' || width === '800px' && width !== '500px') && showSideBar ? (
              <div className={`side-header`} style={{borderRight:'.25vmin solid #e9e9e960', zIndex:99999999999}}>
                <SideBar
                  setWorking={setWorking}
                  full={width == "100%" ? true : false} 
                  active={active}
                  enabled={enabled}
                />
              </div>
            ) : null} 
            */}

            <div
            style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}
              className={`relative rounded-[1vmin] flex-column w-full flex h-[max-content] ${enabled ? "IHiDDENTHIS-viewEnabledPort" : ""
                }`}
            >
              {/* {waitingConfirm ? (
                <div className="loading-load" style={{position:'absolute', width:'100%', height:'100%', background:'rgba(44, 81, 140, 0.32)', borderRadius:'1vmin'}}>
                <BounceLoader size={100} color={"#FFFFFF"} loading={true} />
                <div className="justify-around flex items-center" style={{width:'100%', background:'#FFF', position:'absolute', padding:'1vmin 0', top:0, left:0}}>Hello</div>
              </div>
              ) : (
                <div style={{width:'100%', height:'100%'}}>confirm</div>
              )} */}
              

              <div className="min-h-[100%] w-full rounded-[1vmin]">
                {!auth && !enabled ? showHeader && (
                <div style={{ height: "fit-content", width:'100%' }}>
                  <NewHeader enabled={enabled} active={active}/>
                </div>
              ) : showSideBar && (
                <div className="mopile-side-header mb-[3.5vmin]">
                  <SideBar enabled={enabled} active={active}/>
                </div>
              )} 
              {finish ? <AppSetup enable={enabled} review={enabled} outStepper>{children}</AppSetup> : 
              <div style={{paddingBottom: enabled && '5vh', width:'100%'}} className="min-h-[100%] w-full rounded-[1vmin]">
                {children}
                </div>
              }
              {!auth && !enabled && showFooter && (
                <div style={{ height: "fit-content", width:'100%' }}>
                  <NewFooter enabled={enabled} active={active} />
                </div>
              )}
              </div> 
             
            </div>
          </div>
        </div>


          <div className="sidebarApp bluredContent" 
          style={{ position: 'fixed', top: '15vh', right: '1vw', transition: 'opacity 100ms ease, transform 100ms ease', background: isDarkMode ? "#111F3E" : '#FBFBFB', height: 'fit-content', pointerEvents:!navigationSide && 'none', transform: navigationSide ? 'translateX(0)' : 'translateX(500px)', opacity: !navigationSide && '0' }}>
            <Sidebar
              selectNode={selectNode}
              socialIcons={socialIcons}
              setSocialIcons={setSocialIcons}
            />
            <div
              style={{
                zIndex: 9999,
                fontSize: '2vmin',
                borderRadius: "1.3vmin",
                border: ".25vmin solid #e9e9e9",
              }}
              onClick={handleClose}
              title="Close"
              className="cursor-pointer py-[.5vmin] my-auto bg-[#0867FC80] mx-auto hover:bg-[#0867FC50] text-[#FFF] border h-full w-[50%] flex items-end justify-center"
            >
              ClOSE
            </div>
          </div>
      </div>
    </div>
  );
};
