/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { useNode, useEditor, Element } from '@craftjs/core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { TextSettings } from '../Text/TextSettings.js';
import { useInView } from 'react-hook-inview';
import { ResizableWrapper } from '../../ResizableWrapper/index.js';
import { useAppProps } from '../../../../DarkModeContext.js';
import { LinkSettings } from './LinkSettings.js';
import MainElement from '../MainElements/MainElement.js';

const defaultProps = {
  actionState:'default',
  isSvg: false,
  isBasic: true,
  style:{},
  default : {
      textBasic: 'This is a Link',
      href:null,
      className : '',
      overflowWrap:'anywhere',
      width:null,
      maxWidth:null,
      minWidth:null,
      height:null,
      background:  null,
      display: null,
      color: null,
      margin:  null,
      marginTop: null,
      marginBottom:null,
      marginLeft:  null,
      marginRight: null,
      padding:null ,
      paddingTop:  null,
      paddingBottom: null,
      paddingLeft: null,
      paddingRight:null,
      border: null,
      borderWidth: null,
      borderColor: null,
      borderStyle: 'solid',
      borderRadius: '1.5vmin',
      overflow: null,
      fontSize: '16px',
      textTransform: null,
      fontWeight:  null,
      lineHeight:  null,
      fontStyle: "normal",
      textDecoration: 'underline',
      fontFamily:  null,
      textAlign: null,
      shadowX: null,
      shadowY: '1px',
      shadowColor:null,
      shadowBlur: '1px',
      shadowTextX: null,
    shadowTextY: '1px',
    shadowTextColor:null,
    shadowTextBlur: '1px',
      headingNum: null,
      isExternal:null,
      cursor:null,
  },
  hovered: {
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    shadowTextX: null,
  shadowTextY: '1px',
  shadowTextColor:null,
  shadowTextBlur: '1px',
  cursor:'pointer',
  },
  active: {
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    shadowTextX: null,
  shadowTextY: '1px',
  shadowTextColor:null,
  shadowTextBlur: '1px',
  }
}
  

export const BasicLink = ({style= {}, children, isSvg, isBasic = false, text, ...defaultProps}) => {
  const {
    node,
    connectors: { connect },
    selected,
    isDragged,
  } = useNode((node) => ({
    node:node,
    isDragged: node.events.dragged,
    selected: node.events.selected,
  }));

  const {
    nodes,
    enabled,
    actions : {setProp},
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    nodes: state.nodes
  }));

  const {
    isExternal,
    className,
    href,
    textBasic
  } = defaultProps.default

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [inViewRef, inView] = useInView()
  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const mergedRef = useRef(null)

  const headingTags = {
    'Heading one': 'h1',
    'Two': 'h2',
    'Three': 'h3',
    'Four': 'h4',
    'Five': 'h5',
    'Six: Smallest' : 'h6'
  };

  const tagName = headingTags[defaultProps.headingNum] || 'div';
  const Tag = tagName;

  const handleClick = useCallback((e) => {
    e.preventDefault();
      if (!enabled) {
        if (href && href._id && href._id.includes("www")) {
        // If href exists and href._id includes "www", open a new tab with the URL
        window.open("https://" + href.name, "_blank");
      } else if (href && href._id && href.name === 'dom') {
        const element = Object.values(nodes).find((node) => node.id === href._id);

        if (element) {

          if (element.dom) {
            element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
            console.log("DOM Element not found for ID:", element.id);
          }
        } else {
          console.log("Element with ID not found:", href.id);
        }}
        else if (href && href.name !== 'dom') {
          // Find the item in treeData based on href._id
          const parentItem = treeData.find((x) => x?._id === href?._id);
          if (parentItem) {
            // Set activeId to the parent item if found
            setActiveId(parentItem);
          } else {
            // If parent item not found, find the child item in treeData
            let childItem = null;
            for (const parent of treeData) {
              const child = parent.children.find((child) => child._id === href._id);
              if (child) {
                childItem = child;
                break;
              }
            }
            // Set activeId to the child item if found
            setActiveId(childItem);
          }
        } 
      }
  }, [enabled, href, treeData]);

const memoizedProps = useMemo(() => ({
    ...style,
    // cursor: href && !enabled ? 'pointer' : '',
  }), [style,  href, enabled]);

  const [isEditing, setIsEditing] = useState(false);

  const handleDoubleClick = (e) => {
    e.stopPropagation()
    if (enabled) {
      setIsEditing(true);
    }
  };

    const handleBlur = (e) => {
      setIsEditing(false);
    };

  const handleMouseEnter = (e) => {
      setIsHovered(true);
    };

  const handleMouseLeave = (e) => {
      setIsHovered(false);
  };

  return (
    <>
        <MainElement
        Tag={Tag}
activeBackground={activeBackground}
connect={connect}
enabled={enabled}
selected={selected}
mergedRef={mergedRef}
defaultProps={defaultProps['default']}
hoverProperties={defaultProps['hovered']}
activeProperties={defaultProps['active']}
isDragged={isDragged}
isHovered={isHovered}
onMouseOver={handleMouseEnter}
onMouseOut={handleMouseLeave}
memoizedProps={memoizedProps}

        onDoubleClick={handleDoubleClick}
        onBlur={handleBlur}
        contentEditable={isEditing}
        suppressContentEditableWarning={true}
        disabled={!enabled}
        onClick={handleClick}
        className={`${className} p-[.5%] ${href && activeId?.name && text && 
          (href.name.toLowerCase() === activeId.name.toLowerCase() || text.toLowerCase() === activeId.name.toLowerCase()) ? selected : (enabled && 'helloworldsParentChildren')}`}
            >
            {children || text || textBasic}
            
            {enabled && <>  <ResizableWrapper 
                  selected={selected} 
                  childRef={mergedRef} 
                  setActiveBackground={setActiveBackground}
                  setIsHovered={setIsHovered} 
                  isHovered={isHovered}/>  </> }
        </MainElement>
    </>
      );
};

BasicLink.craft = {
  displayName: 'Link',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: LinkSettings,
  },
};
