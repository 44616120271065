
const loginContents = {
    headerLogin:{
      content:'Sign In',
      background:{value:null, type:'bg'},
      fontSize:{value:'24px', type:'Number'},
      lineHeight:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'center', type:'String'},
      color: {value:null, type:'color'},

      width:{value:null, type:'Number'},
      height:{value:null, type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
      
    },
    firstInput : {
      id:'firstInputId',
      content:'Input Username or email',

      placeholder: 'Username or email',
      background:{value:'transparent', type:'bg'},
      borderRadius: {value:'16px', type:'Number'},

      fontSize:{value:null, type:'Number'},
      color: {value:'#161717', type:'color'},

      width:{value:null, type:'Number'},
      height:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'left', type:'String'},
      width:{value:null, type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
    },

    firstLabel : {
      content:'Username or email',
      background:{value:'transparent', type:'bg'},
      fontSize:{value:null, type:'Number'},
      lineHeight:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'left', type:'String'},
      color: {value:null, type:'color'},
      borderRadius: {value:'16px', type:'Number'},

      width:{value:null, type:'Number'},
      height:{value:null, type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
    },

    secondInput : {
      content:'Input Password',

      placeholder: '********',
      background:{value:'transparent', type:'bg'},
      borderRadius: {value:'16px', type:'Number'},

      fontSize:{value:null, type:'Number'},
      color: {value:'#161717', type:'color'},

      width:{value:null, type:'Number'},
      height:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'left', type:'String'},
      width:{value:null, type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
    },

    secondLabel : {
      content:'Password',
      background:{value:'transparent', type:'bg'},
      fontSize:{value:null, type:'Number'},
      lineHeight:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'left', type:'String'},
      color: {value:null, type:'color'},
      borderRadius: {value:'16px', type:'Number'},

      width:{value:null, type:'Number'},
      height:{value:null, type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
    },
    Button : {
      content:'Sign In',
      background:{value:'#0867FC', type:'bg'},
      height:{value:'4vmin', type:'Number'},
      fontSize:{value:null, type:'Number'},
      lineHeight:{value:null, type:'Number'},
      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},
      textAlign :{value:'center', type:'String'},
      borderRadius: {value:'16px', type:'Number'},
      color: {value:'#fff', type:'color'},
      width:{value:'30%', type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},
      shadow:{value:null, type:'Number'},
    },
  };

  export default loginContents;