import { useState } from "react";
import AreaEl from "../AreaEl";
import { SidesIcon } from "../iconsSvg";
import { ArrowDDown, ArrowDown, ArrowUpp } from "../../components/editor/Viewport/Editor-icons/svgArray";
import { useSidesContext } from "../../SideContext";
import { useOptionsContext } from "../../OptionsContext";
import { useActiveIdContext } from "../../ActiveIdContext";


const SidesLR = ({handleClick, outStepper}) => {
    const [menuOpens, setMenuOpens] = useState(true)
    const [expanded, setExpanded] = useState({})

    const { 
      
      appSettings, 
      navbarProps,
      selectedTask, 
      widgets,
      styles,
      setStyles,
      review,
      pageSettings,
      selectedItem,
      menuHover,
      setMenuHover,
      closePanel,
      setClosePanel,
      open,
      setOpen,
      } = useSidesContext();

      const checkLeftSide = pageSettings.sidebar.visibility

      const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;


      const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;

    

    let logoIcon =
    "M16.5 4.5C16.5 5.17031 16.3547 5.80781 16.0875 6.38437L18.5625 10.6594C17.4516 11.8453 16.0219 12.7266 14.4141 13.1719L12 9L8.8125 14.5078C9.82031 14.8266 10.8891 15 12.0047 15C15.3188 15 18.2766 13.4672 20.2031 11.0625C20.7234 10.4156 21.6656 10.3125 22.3125 10.8281C22.9594 11.3438 23.0625 12.2906 22.5469 12.9375C20.0672 16.0219 16.2656 18 12 18C10.3406 18 8.74688 17.7 7.27969 17.1516L4.62656 21.7359C4.40625 22.1156 4.07812 22.425 3.68438 22.6219L1.0875 23.9203C0.853125 24.0375 0.576563 24.0234 0.35625 23.8875C0.135938 23.7516 0 23.5078 0 23.25V20.6531C0 20.2594 0.103125 19.8703 0.304688 19.5234L3.11719 14.6625C2.51719 14.1375 1.95938 13.5609 1.4625 12.9375C0.942188 12.2906 1.05 11.3484 1.69688 10.8281C2.34375 10.3078 3.28594 10.4156 3.80625 11.0625C4.07344 11.3953 4.35938 11.7094 4.65938 12.0047L7.9125 6.38437C7.65 5.8125 7.5 5.175 7.5 4.5C7.5 2.01562 9.51563 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM17.4844 18.4641C19.0125 17.8641 20.4141 17.0203 21.6516 15.9844L23.7 19.5234C23.8969 19.8656 24.0047 20.2547 24.0047 20.6531V23.25C24.0047 23.5078 23.8688 23.7516 23.6484 23.8875C23.4281 24.0234 23.1516 24.0375 22.9172 23.9203L20.3203 22.6219C19.9266 22.425 19.5984 22.1156 19.3781 21.7359L17.4844 18.4641ZM12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6Z'"

    
      const handleStyleChange = (id, updatedStyle) => {
        setStyles(prevStyles => ({
          ...prevStyles,
          [id]: updatedStyle,
        }));
      };

    const logoPath = navbarProps.find(widget => widget.id === 2)?.icon?.path;
    const logoViewBox = navbarProps.find(widget => widget.id === 2)?.icon?.viewBox || '0 0 24 24';

    const menuIcon = navbarProps.find(widget => widget.id === 1)?.icon?.path || 'M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z';
    const menuViewBox = navbarProps.find(widget => widget.id === 1)?.icon?.viewBox || '0 0 24 24';
    let color = appSettings?.color?.value
    const h1Styles = appSettings?.fonts?.h1
    const h2Styles = appSettings?.fonts?.h2
    const h3Styles = appSettings?.fonts?.h3
    const h4Styles = appSettings?.fonts?.h4
    const selectedPage = (item) => selectedItem !== item 
    ? `${color}10` 
    : `${color}70`;


    const SVgIcon = ({ key, icon, asIcon, title }) => {
        return (
          <div
            key={key}
            style={{ borderRadius: '50%' }}
            className='title my-[3vmin] w-full h-[4vmin] flex justify-center items-center border shadow-md hover:bg-[#0867FC10] relative'
          >
            {icon !== null ? (
              <svg
                onClick={(e) => {
                  e.stopPropagation();
                }}
                aria-hidden="true"
                focusable="false"
                role="img"
                viewBox={icon?.viewBox}
                width={'3.5vmin'}
                height={'3.5vmin'}
                fill={color || icon.fill}
              >
                <path d={icon?.path} />
              </svg>
            ) : <div className="first-letter">
              {asIcon}
            </div>}
            <span className={`tooltip ${pageSettings.sidebar.position !== 'right' ? 'right-[-4vmin]' : 'left-[-4vmin]'}`} style={{ transform: pageSettings.sidebar.position !== 'right' ? 'translateX(50%)' : 'translateX(-50%)' }}>{title}</span>
          </div>
        )
      }


    if (checkLeftSide) {
      return (
        <>
          {pageSettings.sidebar.visibility && (pageSettings.sidebar.openWay && menuOpens || !pageSettings.sidebar.openWay && menuHover) ? (
            <AreaEl
            outStepper={outStepper} 
            id='sideLR'
            onClick={() => {
              setClosePanel(true)
              setOpen('sideLR')
            }} 
              className={`viewSide ${widgets ? 'w-[40vmin]' : 'w-[fit-contnet]'} shadow-md rounded-[.5vmin] p-[1vmin] flex flex-column items-start justify-start h-full bg-[#fff]`}
              style={{...styles.sideLR, 
                minWidth: '30vmin', zIndex: pageSettings.sidebar.openWay ? 1 : menuHover ? 1 : -9,}}
            >

              <div className={`${pageSettings.sidebar.position === 'left' ? 'flex flex-row' : 'flex flex-row-reverse'} ${!pageSettings.sidebar.openWay? 'justify-center' : 'justify-between'} w-full pt-[2%] px-[3%]`}>
               <div className="cursor-pointer w-[fit-content]" style={{margin:!pageSettings.sidebar.openWay && '0 auto'}}>
               <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  viewBox={logoViewBox}
                  width="4vmin"
                  height="4vmin"
                  fill={color}
                >
                  <path d={logoPath} />
                </svg>
               </div>
                {pageSettings.sidebar.openWay && 
                 <div className="cursor-pointer w-[fit-content]">
                  <svg
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuOpens(!menuOpens);
                  }}
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  viewBox={menuViewBox}
                  width="4vmin"
                  height="4vmin"
                  fill={color}
                >
                  <path d={menuIcon} />
                </svg></div>}
              </div>
              <div className={`${!pageSettings?.sidebar?.position ? 'flex flex-row-reverse' : pageSettings?.sidebar?.position === 'left' ? 'flex flex-row-reverse' : 'flex flex-row'} w-full h-full`}>
                {widgets && <div className='w-[7vmin] h-full flex-column flex px-[1vmin]'>
                  <div className='my-auto w-full h-full'>
                    {selectedTask.filter(widget => widget.selected).map((widget, i) => (
                      <SVgIcon
                        key={widget.name}
                        title={widget.name}
                        asIcon={widget.name.charAt(0)}
                        icon={widget.icon}
                      />
                    ))}
                  </div>
                </div>}
                <div className="w-full h-full flex flex-column items-start justify-between">
                  <div style={{scrollbarWidth:'none', padding:'2vmin 0', height:'100%'}} className='w-full flex flex-column items-start justify-start my-[4vmin] text-start'>
                    {treeData.map((node, id) => {
                      return (
                        <div className="flex flex-column w-full my-[1vmin]">
                        <div onClick={() => {
                          handleClick(node._id)
                          if (outStepper) {
                            setActiveId(node._id)
                          }
                        }} className={`cursor-pointer bg-[${selectedPage(node._id)}] mx-auto w-[90%] shadow-sm items-center my-[.5vmin] ${pageSettings.sidebar.position === 'right' ? 'flex flex-row-reverse' : 'flex flex-row'} rounded-[.7vmin] px-[1.5vmin] py-[1vmin]`} 
                        key={id}>
                          <div className="w-full flex justify-start items-center">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            viewBox={node.icon.viewBox}
                            width={'3.5vmin'}
                            height={'3.5vmin'}
                            fill={color}
                          >
                            <path d={node.icon.path} />
                          </svg>
                          <div style={h3Styles} className='mx-[1vmin]'>{node.name}</div>
                          {node.children.length !== 0 && <div className={'flex-1 w-full flex justify-end'} onClick={() => setExpanded(prev => prev === node._id ? null : node._id)}>
                              {expanded === node._id ? <ArrowUpp className='rotate-90' stroke={color} fill={color} /> : <ArrowDDown stroke={color} fill={color} />}
                            </div>}
                          </div>
                        </div>
                        {node.children.length !== 0 && expanded === node._id && <div className="flex flex-column space-y-[1vmin] items-end w-full">
                          {(
                            node.children.map(child => {
                                return (
                                    <div key={child._id} onClick={(e) => {
                                        e.stopPropagation()
                                        handleClick(child._id)
                                        if (outStepper) {
                                          setActiveId(child._id)
                                        }
                                        }} 
                                        className={`cursor-pointer bg-[${selectedPage(child._id)}] w-[80%] flex mx-auto flex-row items-center shadow-md justify-between rounded-[1vmin] my-[1vmin]`} style={{ height: 'auto', padding: '0 .5vmin' }}>
  
                                        <div className="w-[4vmin] ml-[1vmin] flex justify-start items-center">
                                            <svg aria-hidden="true" focusable="false" fill={(appSettings?.color?.value || child?.icon?.fill) || '#FFF'} viewBox={child.icon.viewBox} role="img" width="2.9vmin" height="2.9vmin" >
                                                <path d={child.icon.path} />
                                            </svg>
                                        </div>
                                        <div className={`navbar-item`} style={{...h3Styles, display: 'flex', justifyContent: 'start', alignItems: 'center', textAlign: 'left', width: '100%' }}>
                                            {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name}
                                        </div>
                                    </div>
                                )
                            })
                          )}
                          </div>}
                          </div>
                      )
                    })}
                  </div>
                  {/* {pageSettings.copyright.visibility && <div style={h4Styles} className='my-auto border mx-auto text-center text-[gray] h-[fit-content] flex items-center'>
                    {pageSettings.copyright.content || '© 2024 AllYouCanCloud.com'}
                  </div>} */}
                </div>
              </div>
              {!pageSettings.sidebar.openWay && menuHover && (
                <div
                style={{zIndex:9999}}
                  className={`w-[5vmin] absolute ${pageSettings.sidebar.position === 'right' ? 'left-[-5vmin]' : 'right-[-5vmin]'}  top-0 flex h-full items-center`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuHover(false);
                  }}
                >
                  {pageSettings.sidebar.position === 'right' ? (
                    <SidesIcon style={{ margin:0, padding:0}} width='100%' height='6vmin' fill={color || '#0867FC'}/>
                  ) : (
                    <SidesIcon style={{ margin:0, padding:0}} width='100%' height='6vmin' fill={color || '#0867FC'}/>
                  )}
                </div>
              )}
            </AreaEl>
          ) : pageSettings.sidebar.openWay && (
            <div className="m-[1vmin] cursor-pointer" style={{ zIndex: pageSettings.sidebar.openWay ? 1 : -99999, }}>
              <svg
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuOpens(!menuOpens);
                }}
                aria-hidden="true"
                focusable="false"
                role="img"
                viewBox="0 0 24 24"
                width="4vmin"
                height="4vmin"
                fill={color}
              >
                <path d={'M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z'} />
              </svg>
            </div>
          )}

        </>
      )
    }

    return null;
  }


  export default SidesLR;