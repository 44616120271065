import React, { useMemo } from 'react';
import { useNode, useEditor, Element } from '@craftjs/core';
import {
    ExpansionPanel,
    ExpansionPanelContent,
  } from "@progress/kendo-react-layout";
  import { Reveal } from "@progress/kendo-react-animation";
import { Parent } from '../../Parent';
import  { ImageC }  from '../Image';
import { Text } from '../Text';
  
export const BasicAccordion = ({
    style = {},
    text = 'Click here',
    href,
    color = '#000',
    width,
    height,
    background,
    fontSize = '16px',
    borderColor,
    borderWidth,
    borderRadius,
    textAlign,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
fontWeight,
fontStyle,
textDecoration,
textTransform,
fontFamily,
lineHeight,
    isExternal,
    isLink,
}) => {
    const {
        connectors: { connect },
        actions: { setProp },
    } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),
    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, fontFamily, background, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign, marginTop, marginRight, marginBottom, marginLeft]);

      const [expanded, setExpanded] = React.useState("Brazil");

      const data = [
        {
          id: "Brazil",
          country: "Brazil",
          continent: "South America",
          imageUrl:
            "https://www.telerik.com/kendo-react-ui-develop/components/layout/assets/countries/brazil.jpg",
          text: "The word “Brazil” likely comes from the Portuguese word for brazilwood, a tree that once grew plentifully along the Brazilian coast. In Portuguese, brazilwood is called pau-brasil, with the word brasil commonly given the etymology “red like an ember”, formed from brasa (“ember”) and the suffix -il (from -iculum or -ilium). As brazilwood produces a deep red dye, it was highly valued by the European textile industry and was the earliest commercially exploited product from Brazil. Throughout the 16th century, massive amounts of brazilwood were harvested by indigenous peoples (mostly Tupi) along the Brazilian coast, who sold the timber to European traders (mostly Portuguese, but also French) in return for assorted European consumer goods.",
        },
    ]      
    return (
        <div ref={connect} style={{ display: 'inline-block' }}>
      {data.map((item) => (
        <ExpansionPanel
        style={{fontSize:'14px', width:'max-content', flex:1, margin:'0 auto' }}
          title={item.country}
          subtitle={item.continent}
          expanded={expanded === item.id}
          tabIndex={0}
          key={item.id}
          onAction={(event) => {
            setExpanded(event.expanded ? "" : item.id);
          }}
        >
          <Reveal>
            {expanded === item.id && (
              <ExpansionPanelContent>
                <Element canvas id='random-id-403' is={Parent} className="content flex">
                  <Element canvas id='random-id-400' is={Parent} className="image-container">
                    <ImageC
                     style={{maxHeight:'400px', objectFit:'cover'}}
                      src={item.imageUrl}
                      alt={`KendoReact Layout ${item.country}`}
                    />
                  </Element>
                  <Element canvas is={Parent} id='random-id-401' className="content-text border-2 my-auto max-w-[800px] mx-auto">
                    <Text text={item.text}/>
                  </Element>
                </Element>
              </ExpansionPanelContent>
            )}
          </Reveal>
        </ExpansionPanel>
      ))}
        </div>
    );
};

BasicAccordion.craft = {
    displayName: 'Accordion',
    props: {
        text: 'Click here',
        href: null,
        color: '#000',
        width:null,
        height:null,
        background:null,
        fontSize: '16px',
        shadowX: null,
        shadowY: '1px',
        shadowColor:null,
        shadowBlur: '1px',
        marginTop: null,
        marginRight: null,
        marginBottom: null,
        marginLeft: null,
  borderRadius: null,
        background:null,
        borderColor:null,
        borderWidth:null,
        textAlign:null,
        fontWeight:null,
        textTransform:null,
        fontStyle:null,
        textDecoration:null,
        fontFamily:null,
        textTransform:null,
        lineHeight:null,
        isAccordion:true
    },
    related: {
        toolbar: '', // Create a LinkSettings component for custom options
    },
};
