import { useMemo } from "react";

export const StatesItem = ({ currentState, actionState, setProp }) => {
    // Memoizing the background style based on currentState and actionState props
    const backgroundStyle = useMemo(() => {
      return actionState === currentState ? '#e0995e' : '#98919130';
    }, [actionState, currentState]);
  
    return (
      <div
        style={{
          textTransform: 'capitalize',
          background: backgroundStyle, // Using memoized backgroundStyle
        }}
        className={`cursor-pointer text-[1.5vmin] text-center flex-1 mx-[2%] py-[1%] rounded-[1vmin] text-[#fff]`}
        onClick={() => {
          setProp((props) => {
            props['actionState'] = currentState; // Set actionState based on the currentState clicked
          });
        }}
      >
        {currentState}
      </div>
    );
  };
  

