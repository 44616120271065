/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewSolutionSettings } from './newSolutionSetting';
import { Text } from '../../basic/Text';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import AlbumIcon from '@mui/icons-material/Album';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AbcIcon from '@mui/icons-material/Abc';
import CellTowerOutlinedIcon from '@mui/icons-material/CellTowerOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import  { ImageC }  from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';


export const Solution_2 = ({ selected }) => {

  const {
    actions: { setProp },
    connectors: {
      connect
    }
  } = useNode();


  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));
  const styles = [

    <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto py-10" style={{ background: '#FFFFFF' }}>
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
          <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Poppins', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#000000' }} className={`mt-4 mb-6 text-[46px]`} id="random-id-1"  >
            <Text text="Solutions" />
          </Element>

          <Element is={Parent} canvas id='random-id-402' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
            <Element is={Parent} canvas id='random-id-403' className={`mx-2 my-2 bg-[#2479FD] solution-card2`}>
              <Element is={Parent} canvas id='random-id-404' className='mx-auto w-[100px] h-[100px] flex bg-[#FFC92E] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#2479FD' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
              </Element>
              <Element is={Parent} canvas className='px-4 pt-4' style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }}>
                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-405' className={`mx-4 my-2 bg-[#FFC92E] solution-card2`} >
              <Element is={Parent} canvas id='random-id-406' className='mx-auto w-[100px] h-[100px] flex bg-[#2479FD] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#FFC92E' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#000000', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-3"  >
                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-407' className={`mx-2 my-2 bg-[#2479FD] solution-card2`} >
              <Element is={Parent} canvas id='random-id-408' className='mx-auto w-[100px] h-[100px] flex bg-[#FFC92E] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#2479FD' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-4"  >
                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
              </Element>
            </Element>
          </Element>
        </Element></Element>
    </Element>
    ,

    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto py-10 bg-[#FFF]" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-410' className='max-w-[1300px]'>
          <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Poppins', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#000000' }} className={`mt-4 mb-6 text-[46px]`} id="random-id-5"  >
            <Text text="Solutions" />
          </Element>

          <Element is={Parent} canvas id='random-id-411' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
            <Element is={Parent} canvas id='random-id-412' className={`mx-2 my-2 bg-[#FF0060] solution-card2`}>
              <Element is={Parent} canvas id='random-id-413' className='mx-auto w-[100px] h-[100px] flex bg-[#F6F8A2] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#FF0060' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-6"  >
                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-414' className={`mx-4 my-2 bg-[#F6F8A2] solution-card2`} >
              <Element is={Parent} canvas id='random-id-415' className='mx-auto w-[100px] h-[100px] flex bg-[#FF0060] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#F6F8A2' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#000000', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-7"  >
                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-416' className={`mx-2 my-2 bg-[#FF0060] solution-card2`} >
              <Element is={Parent} canvas id='random-id-417' className='mx-auto w-[100px] h-[100px] flex bg-[#F6F8A2] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#FF0060' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-8"  >
                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
              </Element>
            </Element>
          </Element>
        </Element></Element>
    </Element>
    ,

    <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto py-10 bg-[#FFF]" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-419' className='max-w-[1300px]'>
          <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Poppins', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#000000' }} className={`mt-4 mb-6 text-[46px]`} id="random-id-9"  >
            <Text text="Solutions" />
          </Element>

          <Element is={Parent} canvas id='random-id-420' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
            <Element is={Parent} canvas id='random-id-421' className={`mx-2 my-2 bg-[#F31559] solution-card2`}>
              <Element is={Parent} canvas id='random-id-422' className='mx-auto w-[100px] h-[100px] flex bg-[#FFB7B7] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#F31559' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-10"  >
                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-423' className={`mx-4 my-2 bg-[#FFB7B7] solution-card2`} >
              <Element is={Parent} canvas id='random-id-424' className='mx-auto w-[100px] h-[100px] flex bg-[#F31559] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#FFB7B7' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#000000', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-11"  >
                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-425' className={`mx-2 my-2 bg-[#F31559] solution-card2`} >
              <Element is={Parent} canvas id='random-id-426' className='mx-auto w-[100px] h-[100px] flex bg-[#FFB7B7] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#F31559' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-12"  >
                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
              </Element>
            </Element>
          </Element>
        </Element></Element>
    </Element>
    ,

    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto py-10 bg-[#FFF]" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-428' className='max-w-[1300px]'>
          <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Poppins', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#000000' }} className={`mt-4 mb-6 text-[46px]`} id="random-id-13"  >
            <Text text="Solutions" />
          </Element>

          <Element is={Parent} canvas id='random-id-429' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
            <Element is={Parent} canvas id='random-id-430' className={`mx-2 my-2 bg-[#0C134F] solution-card2`}>
              <Element is={Parent} canvas id='random-id-431' className='mx-auto w-[100px] h-[100px] flex bg-[#D4ADFC] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#0C134F' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-14"  >
                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-432' className={`mx-4 my-2 bg-[#D4ADFC] solution-card2`} >
              <Element is={Parent} canvas id='random-id-433' className='mx-auto w-[100px] h-[100px] flex bg-[#0C134F] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#D4ADFC' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#000000', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-15"  >
                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-434' className={`mx-2 my-2 bg-[#0C134F] solution-card2`} >
              <Element is={Parent} canvas id='random-id-435' className='mx-auto w-[100px] h-[100px] flex bg-[#D4ADFC] justify-center items-center' style={{ borderRadius: '14px' }}>
                <SvgIcon width='50px' height='50px' fill='#0C134F' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
              </Element>
              <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='px-4 pt-4' id="random-id-16"  >
                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
              </Element>
            </Element>
          </Element>
        </Element></Element>
    </Element>
    ,

  ]


  const [sel, setSel] = useState(selected)

  function makeOdd(number) {
    setProp((prop) => {
      setSel(100);
    }, 1000);

    setTimeout(() => {
      setProp((prop) => {
        setSel(number)
      }, 1000);
    }, 100);



  }

  useEffect(() => {
    if (selected || selected >= 0) {
      makeOdd(selected);
    }
  }, [selected])


  return (
    <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
      {sel == 100 ? (
        <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <BounceLoader
            size={100}
            color={"white"}
            loading={true}
          />
        </div>
      ) : (
        styles[sel]
      )}
    </div>
  );
};

Solution_2.craft = {
  displayName: "Solution 2",
  props: {
    selected: 1, // here is the default value of the selected style
    length: 3,
    isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewSolutionSettings,
  },
};


