 /****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { useRef, useState } from 'react';
import { ArrowIconHori, ArrowIconVert, CircleRotateX, CircleRotateY, CircleRotateZ, ResizeIcon } from '../../Viewport/Editor-icons/svgArray';


export const SelectBox = ({
    propKey,
    propKey2,
    propKey3, 
    propKey4,
    propKey5,
    propKey6,
    propKey7,
    setProp,
    props,
    RotateLeftIcon,
    OpenWithIcon,
    OpenInFullIcon,
    options,
    type,
    noinput,
    isDarkMode,
    selectBoxes,
    node,
}) => {

  const currentState = options.actionState

const parentRef = useRef(null)
const [isDragging, setIsDragging] = useState(false);
const [rotationCircleX, setRotationCircleX] = useState({x: 70, y:40}); 
const [rotationCircleY, setRotationCircleY] = useState({x: 70, y:40}); 
const [rotationCircleZ, setRotationCircleZ] = useState({x: 70, y:40, z:20}); 
  

const [scale, setScale] = useState({ x: 100, y: 100 });
const [isSelected, setIsSelected] = useState('translate')
const [position, setPosition] = useState({ x: 100, y: 100 });
const [skew, setSkew] = useState({ x: 0, y: 0 });
    

    const handleMouseDown = (e) => {
        setIsDragging(true);
      };
    
      const handleMouseUp = () => {
        setIsDragging(false);
      };
    
      const handleMouseMove = (e) => {
    
        if (!isDragging) return;
        setProp(node.id, (props) => {
          props[currentState]['isTranslate'] = true
        })
        const scaleX = 8;
        const scaleY = 5;
    
        const parentRect = parentRef.current.getBoundingClientRect();
        const offsetX = (e.clientX - parentRect.left - parentRect.width / 2) * scaleX;
        const offsetY = (e.clientY - parentRect.top - parentRect.height / 2) * scaleY;
    
        setPosition({ 
          x: offsetX,
          y: offsetY,
        });
    
    
        let numberValueX = offsetX
        let numberValueY = offsetY
        let unitValue = "px"
    
        let finalvalueX = [numberValueX, unitValue]
        let finalvalueY = [numberValueY, unitValue]
    
        let valueWithUnitX = finalvalueX.join(" ")
        let valueWithUnitY = finalvalueY.join(" ")
    
        setProp(node.id, (props) => {
    
          props[currentState][propKey3] = { x: valueWithUnitX, y: valueWithUnitY };
    
        }, 500);
      };
    
    
      const handleSkew = (e, axis) => {
        if (!isDragging) return;
      
        setProp(node.id, (props) => {
          props[currentState]['isSkew'] = true;
        });
      
        
        const skewX = 10;
        const skewY = 20; 
      
        const parentRect = parentRef.current.getBoundingClientRect();
        const offsetX = (e.clientX - parentRect.left - parentRect.width / 2) * skewX;
        const offsetY = (e.clientY - parentRect.top - parentRect.height / 2) * skewY;
      
        if(axis === 'y') { 
          setSkew({
            ...skew,
            y: offsetY,
          })
        } else if (axis === 'x') {
          setSkew({ 
            ...skew,
            x: offsetX,
          });
        }
       
    
        let numberValueX = offsetX
        let numberValueY = offsetY
        let unitValue = "deg"
    
        let finalvalueX = [numberValueX, unitValue]
        let finalvalueY = [numberValueY, unitValue]
    
        let valueWithUnitX = finalvalueX.join(" ")
        let valueWithUnitY = finalvalueY.join(" ")
    
      
      if (axis === 'y') {
        setProp(node.id, (props) => {
          props[currentState][propKey6] = {
            ...props[propKey6],
            y: valueWithUnitY,
          };
        }, 500);
      } else if (axis === 'x') {
        setProp(node.id, (props) => {
          props[currentState][propKey6] = {
            ...props[propKey6],
            x: valueWithUnitX,
          }
        }, 500);
      }
      };
    
      const handleScale = (e) => {
        if (!isDragging) return;
        setProp(node.id, (props) => {
          props[currentState]['isScale'] = true
        })
        const { offsetX, offsetY } = e.nativeEvent;
    
        
        // Invert the scale values
        const invertedX = offsetX * 2;
        const invertedY = offsetY * 2;
    
        setScale({ x: invertedX, y: invertedY })
    
        let numberValueX = invertedX
        let numberValueY = invertedY
        let unitValue = "%"
    
        let finalvalueX = [numberValueX, unitValue]
        let finalvalueY = [numberValueY, unitValue]
    
        let valueWithUnitX = finalvalueX.join(" ")
        let valueWithUnitY = finalvalueY.join(" ")
    
        const num = numberValueX + valueWithUnitY
    
    
        setProp(node.id, (props) => {
          props[currentState][propKey4] = { x: valueWithUnitX };
        }, 500);
      };
    
      const svgRefX = useRef(null)
      const svgRefY = useRef(null)
      const svgRefZ = useRef(null)
    
    
    
      const handleRotateXYZ = (e, axis) => {
        if (isDragging) {
          setProp(node.id, (props) => {
            props[currentState]['isRotateXYZ'] = true;
          });
          const svgElement = axis === 'y'? svgRefX.current : axis === 'x'? svgRefY.current : svgRefZ.current;
          const svgRect = svgElement.getBoundingClientRect();
          const centerX = svgRect.width / 2;
          const centerY = svgRect.height / 2;
          const radius = 25.4681; // Radius of the circular path
      
          // Calculate mouse position relative to SVG element
          const mouseX = e.clientX - svgRect.left;
          const mouseY = e.clientY - svgRect.top;
      
          // Calculate angle relative to center
          const deltaX = mouseX - centerX;
          const deltaY = mouseY - centerY;
          const angle = Math.atan2(deltaY, deltaX);
      
          const newRotationAngle = (angle * 180) / Math.PI;
      
          const unitValue = "deg";
          let valueWithUnit;
      
          if (axis === 'y') {
            const newRotX = [newRotationAngle, unitValue];
            valueWithUnit = newRotX.join(" ");
          } else if (axis === 'x') {
            const newRotY = [newRotationAngle, unitValue];
            valueWithUnit = newRotY.join(" ");
          } else if (axis === 'z') {
            const newRotZ = [newRotationAngle, unitValue];
            valueWithUnit = newRotZ.join(" ");
          }
      
          // Calculate new circle position along the circular path
          const newX = centerX + Math.cos(angle) * radius;
          const newY = centerY + Math.sin(angle) * radius;
    
      
          // Update circle position
          if(axis === 'y') {
            setRotationCircleX({ x: newX, y: newY });
    
          } else if (axis === 'x' ) {
            setRotationCircleY({ x: newX, y: newY });
    
          } else if (axis === 'z') {
            setRotationCircleZ({ x: newX, y: newY, z: angle });
    
          }
      
      
          setProp(node.id, (props) => {
            if (axis === 'y') {
              props[currentState][propKey5] = { ...props[propKey5], y: valueWithUnit };
            } else if (axis === 'x') {
              props[currentState][propKey5] = { ...props[propKey5], x: valueWithUnit };
            } else if (axis === 'z') {
              props[currentState][propKey5] = { ...props[propKey5], z: valueWithUnit };
            }
          });
        }
      };
      
      const handleRotateXYZReset = () => {
        // Reset rotation circles
        setRotationCircleX({ x: 70, y: 40 });
        setRotationCircleY({ x: 70, y: 40 });
        setRotationCircleZ({ x: 70, y: 40, z: 20 });
    
        // Reset rotation transformation and set flag to false
        setProp(node.id, (props) => {
            if (!props[currentState]) {
                props[currentState] = {}; // Initialize if currentState does not exist
            }
    
            // Reset rotation values for XYZ
            props[currentState][propKey5] = { x: 0, y: 0, z: 0 };
    
            // Set rotation flag to false
            props[currentState]['isRotateXYZ'] = false;
        });
    };
    const handleSkewReset = () => {
      // Reset skew values
      setSkew({ x: 0, y: 0 });
  
      // Reset skew transformation and set flag to false
      setProp(node.id, (props) => {
          if (!props[currentState]) {
              props[currentState] = {}; // Initialize if currentState does not exist
          }
  
          // Reset skew values
          props[currentState][propKey6] = { x: 0, y: 0 };
  
          // Set skew flag to false
          props[currentState]['isSkew'] = false;
      });
  };
  const handleScaleReset = () => {
    // Reset scale values
    setScale({ x: 100, y: 100 });

    // Reset scale transformation and set flag to false
    setProp(node.id, (props) => {
        if (!props[currentState]) {
            props[currentState] = {}; // Initialize if currentState does not exist
        }

        // Reset scale values
        props[currentState][propKey4] = { x: 0, y: 0 };

        // Set scale flag to false
        props[currentState]['isScale'] = false;
    });
};
      
      const handleTranslateReset = () => {
        // Step 1: Reset the position to {x: 100, y: 100}
        setPosition({ x: 100, y: 100 });
    
        // Step 2: Reset the translation values in props
        setProp(node.id, (props) => {
            // Ensure that currentState exists, if not, initialize it
            if (!props[currentState]) {
                props[currentState] = {}; // Initialize currentState if it doesn't exist
            }
    
            // Set the propKey3 (translation values) to {x: 0, y: 0}
            props[currentState][propKey3] = { x: 0, y: 0 };
    
            // Set isTranslate to false
            props[currentState]['isTranslate'] = false;
        });
    };
    

return (
<div className='w-full h-full'>
{type === 'select-box' && (

<div className={`text-[#fff] container`} style={{ padding: noinput ? '' : '1vmin 0', fontWeight:500 }}>
    <div style={{ fontSize: '2vmin', fontWeight: '500', fontFamily: 'Inter'}}>{props.label}</div>
    {/* {props.label === 'Transition' ? <div className='text-[#989191] text-[1.5vmin] px-[.25Vmin]'>You can add a transition</div> : props.label === 'Display' ? <div className='text-[#989191] text-[1.5vmin] px-[.25Vmin]'>Flexbox layout mode</div> : props.label === 'Direction' ? <div className='text-[#989191] text-[1.5vmin] px-[.25Vmin]'>Set the layout direction</div> : null} */}
    <div
    style={{
        color: isDarkMode ? '#fff' : '#000',
        fontFamily: 'Inter',
        fontWeight: '500',
        padding: '.5Vmin 1Vmin',
        fontSize: '2vmin',
        border: isDarkMode ? '.25vmin solid #e9e9e960' : '.25Vmin solid #E9E9EA',
        borderRadius: '2.5Vmin',
        backgroundColor: 'transparent',
        margin: '.5vmin 0',
        // transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        width: '100%',
        flex:1,
        border: 'none',
        display: 'flex',
        flexDirection: 'row',
    }}
    >
    {selectBoxes.map((box, index) => (
        <div
        title={box}
        className={`shadow-2xl ${isDarkMode ? isSelected !== box && 'hover:bg-[#0866fc19]' : isSelected !== box && 'hover:bg-[#0866fc19]'} cursor-pointer ${box && isSelected === box ? isDarkMode? 'bg-[#0866fc73]' : 'bg-[#0866fc73]' : 'bg-[transparent]'}`}
        style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            margin: '0 1%',
            color: 'black',
            flex: 1,
            width:'fit-content',
            height: '3vmin',
            borderRadius: '1.5vmin',
            borderRadius:'1.5vmin',
            // border: '.25vmin solid #e9e9e960',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position:'relative',
            // transition: 'All .2s ease-in-out'
        }}
        onClick={() => {
            setIsSelected(box)
        }}
        key={box}
        >
        <div className='absolute top-[-.6vmin] left-[-0.6vmin] text-[2.5vmin] text-[red]' style={{userSelect:'none'}}>

            {options[currentState]['isScale'] === true && box === 'scale' && <div onClick={handleScaleReset}>
            x
            </div>}
            {options[currentState]['isTranslate'] === true && box === 'translate' && <div onClick={handleTranslateReset}>
            x
            </div>}
            {options[currentState]['isRotateXYZ'] === true && box === 'rotateXYZ' && <div onClick={handleRotateXYZReset}>
            x
            </div>}
            {options[currentState]['isSkew'] === true && box === 'skew' && <div onClick={handleSkewReset}>
            x
            </div>}
            {options[currentState]['isOrigin'] === true && box === 'origin' && <div>
            x
            </div>}
            </div>
        {box === 'scale' ? (
            <div>
            <OpenInFullIcon sx={{ color: isSelected === box ? '#FFF' : isDarkMode? '#989191' : '#000' }} />
            </div>
        ) : box === 'translate' ? (
            <div><OpenWithIcon sx={{ color: isSelected === box ? '#FFF' : isDarkMode? '#989191' : '#000' }} /></div>
        ) : box === 'rotateXYZ' ? (
            <div>
            
            <div><RotateLeftIcon sx={{ color: isSelected === box ? '#FFF' : isDarkMode? '#989191' : '#000', transform:'rotate(90deg)' }} /></div>

            </div>
            ) : box === 'origin' ? (
            <div>
            
            <div><RotateLeftIcon sx={{ color: isSelected === box ? '#FFF' : isDarkMode? '#989191' : '#000', transform:'rotate(180deg)' }} /></div>

            </div>
            ) : box === 'skew' && (
            <div>
            
            <div style={{padding:'.5vmin', border:`.25vmin solid ${isSelected === box ? '#FFF' : isDarkMode? '#989191' : '#000'}`, transform:'skew(10deg, 12deg)'}}></div>

            </div>
            )}
        </div>
    ))}
    </div>
</div>

)}

{isSelected === 'scale' ? (
<div
    className='shadow-xl mx-auto'
    ref={parentRef}
    onMouseDown={handleMouseDown}
    onMouseMove={handleScale}
    onMouseUp={handleMouseUp}
    style={{
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '25vmin',
    cursor: "pointer",
    display: 'flex',
    justifyContent: 'center',
    borderRadius:'1.5vmin',
    border: '.25vmin solid #e9e9e960',
    alignItems: 'center',
    background: "url(https://i.ibb.co/Fs5RpxT/2x2grid.png) center"
    }}
>
<div style={{ color: '#000', pointerEvents:'none', cursor: 'nesw-resize', border:'.4vmin solid #0866fc73', background:'#e9e9e970', transform:`scale(${scale.x / 70}`, width:'5vmin', height:'5vmin'}}>
<div className='absolute top-0 right-0' style={{display:'flex', cursor: 'nesw-resize', alignContent:'center', justifyContent:'center', height:'1vmin'}}>
<ResizeIcon fill='#0866fc73'/>
</div>
</div>
</div>

) : isSelected == 'rotateXYZ' ? (
    <div className='flex flex-row flex-wrap justify-between w-full shadow-md' style={{border:'.25vmin solid #e9e9e960', alignItems:'center', alignContent:'center', minHeight:'20vmin', padding:'2vmin 1.5vmin', background:'transparent', borderRadius: '1.5vmin'}}>
    <div className='h-full flex flex-column mx-auto my-[1vmin] justify-center align-items-center text-center align-content-center relative'>
    
    <div style={{zIndex:2}}>
    <CircleRotateZ isDarkMode={isDarkMode} handleMouseDown={handleMouseDown} handleRotateXYZ={handleRotateXYZ} handleMouseUp={handleMouseUp} rotationCircleZ={rotationCircleZ} svgRefZ={svgRefZ}/>
    </div>
    {<div style={{border:'.25vmin solid #0866fc73', borderRadius:'1vmin', padding:'1vmin', minWidth: '12vmin', margin:'2vmin 0 0 0', background:'#e9e9e930', color:'#FFF'}}>{rotationCircleZ?.z === 20 ? '0' :  rotationCircleZ?.z?.toFixed(2)}deg</div>}
    <div style={{width:'1vmin', height:'1vmin', borderRadius:'100%', background:'#e9e9e9', position:'absolute', top:'6.8vmin', right:'6.8vmin', zIndex:3}}></div>
    
    </div>

    <div className='h-full flex flex-column mx-auto my-[1vmin] justify-center align-items-center text-center align-content-center relative'>
    
    <div style={{zIndex:2}}>
    <CircleRotateY isDarkMode={isDarkMode} handleMouseDown={handleMouseDown} handleRotateXYZ={handleRotateXYZ} handleMouseUp={handleMouseUp} rotationCircleY={rotationCircleY} svgRefY={svgRefY}/>
    </div>
    {<div style={{border:'.25vmin solid #0866fc73', borderRadius:'1vmin', padding:'1vmin', minWidth: '12vmin', margin:'2vmin 0 0 0', background:'#e9e9e930', color:'#FFF'}}>{rotationCircleY?.y === 40 ? '0' :  rotationCircleY?.y?.toFixed(2)}deg</div>}
    <div style={{width:'16vmin', height:'.3vmin', background:'#e9e9e9', position:'absolute', top:'7.3vmin', right:0, zIndex:1}}></div>
    
    </div>
    
    <div className='h-full flex flex-column mx-auto my-[1vmin] justify-center align-items-center text-center align-content-center relative'>
    
    <div style={{zIndex:2}}>
    <CircleRotateX isDarkMode={isDarkMode} handleMouseDown={handleMouseDown} handleRotateXYZ={handleRotateXYZ} handleMouseUp={handleMouseUp} rotationCircleX={rotationCircleX} svgRefX={svgRefX}/>
    </div>
    {<div style={{border:'.25vmin solid #0866fc73', borderRadius:'1vmin', padding:'1vmin', minWidth: '12vmin', margin:'2vmin 0 0 0', background:'#e9e9e930', color:'#FFF'}}>{rotationCircleX?.x === 70 ? '0' :  rotationCircleX?.x?.toFixed(2)}deg</div>}
    <div style={{width:'.35vmin', height:'16vmin', background:'#e9e9e9', position:'absolute', bottom:'5vmin', right:'7.3vmin', zIndex:1}}></div>
    
    </div>
    </div>
) : isSelected === 'skew' ? (
<div
    className='shadow-xl mx-auto'
    ref={parentRef}
    style={{
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '25vmin',
    cursor: "pointer",
    display: 'flex',
    justifyContent: 'center',
    borderRadius:'1.5vmin',
    border: '.25vmin solid #e9e9e960',
    alignItems: 'center',
    background: "url(https://i.ibb.co/Fs5RpxT/2x2grid.png) center"
    }}
    
>
<div>
<div 
style={{ color: '#000',   border:'.6vmin solid #0866fc73', background:'#e9e9e970', transform: `skew(${skew.x}deg, ${skew.y}deg)`, width:'7vmin', height:'7vmin'}}></div>
</div>
<div 
onMouseMove={(e) => handleSkew(e, 'x')}
onMouseDown={handleMouseDown}
onMouseUp={handleMouseUp}
className='absolute  bottom-[1vmin] w-full' style={{display:'flex', alignContent:'center', justifyContent:'center', userSelect:'none', cursor:'ew-resize'}}>
<ArrowIconVert fill='#0866fc73'/>
</div>
<div 
onMouseMove={(e) => handleSkew(e, 'y')}
onMouseDown={handleMouseDown}
onMouseUp={handleMouseUp}
className='absolute  text-center align-center left-[1vmin] h-full' style={{display:'flex', alignContent:'center', justifyContent:'center', userSelect:'none', cursor:'ns-resize'}}>
<div className='my-auto'>
    <ArrowIconHori fill='#0866fc73'/>
</div>
</div>
</div>


) : (
<div
    onMouseDown={handleMouseDown}
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}

    className='shadow-xl mx-auto'
    ref={parentRef}
    style={{
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '25vmin',
    borderRadius:'1.5vmin',
    border: '.25vmin solid #e9e9e960',
    cursor: "move",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: "url(https://i.ibb.co/Fs5RpxT/2x2grid.png) center"

    }}>
    <div
    className='shadow-2xl' style={{
        position: 'absolute',
        pointerEvents: 'none',
        top: '50%',
        left: '50%',
        transform: `translate(-62%, -81%) translate(${position.x / 8}px, ${position.y / 5}px)`,
        background: '#0866fc73',
        borderRadius:'.5vmin',
        padding: '4vmin 7vmin',
        zoom:'70%',
        display:'flex',
        justifyContent:'center',
        justifyItems:'center',
        alignContent:'center',
        alignItems:'center'
    }}
    >
    <OpenWithIcon sx={{color:'#FFF', fontSize:'4vmin'}}/>

    </div>
</div>
)}

            </div>
    )
}