import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import GradientSettings from '../../components/editor/Toolbar/GradientColor';
import { NumberSlider } from '../../components/editor/Toolbar/Toolbar-Types/NumberSlider';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';
import Settings from '../SettingsArea/Index';
import { CloseIcon } from '../../components/land/Svg';
import { useSidesContext } from '../../SideContext';
import { ToolbarItem, ToolbarSection, ToolbarTextInput } from '../../components/editor';
import { useAppProps } from '../../DarkModeContext';


const usePanelStyles = makeStyles((_) => ({
    root: {
        overflowY: 'scroll',
        overflowX: "hidden",
        boxShadow: 'none',
        '&:before': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        '&.Mui-expanded': {
            margin: '0 0',
            minHeight: '7%',
            '&:before': {
                opacity: '1',
            },
            '& + .MuiExpansionPanel-root:before ': {
                display: 'block'
            },
        },
    },
}));

const useSummaryStyles = makeStyles((_) => ({
    root: {
        'min-height': 'auto',
        padding: 0,
        display: "flex",
        alignItems: "flex-start"
    },
    content: {
        marginLeft: '0px',

    },
}));

const SettingsPanel = () => {

    const { setClosePanel, open, setOpen, setStyles, styles, topArea, leftArea, rightArea, bottomArea,} = useSidesContext()
    const {isDarkMode} = useAppProps()
    const currentRow = styles[open]
    const [expanded, setExpanded] = useState('Top Area / Settings')

    useEffect(() => {
        if (!topArea && !bottomArea && !leftArea) {
            setClosePanel(false)
            setOpen('')
        }
    }, [topArea, bottomArea, leftArea])

    const handleChangeStyles = (property, value) => {
        setStyles(prevStyles => ({
            ...prevStyles,
            [open]: {
                ...prevStyles[open],
                [property]: value
            }
        }));
    };

    const currentSide = typeof open === 'string'
    ? open.startsWith('row') 
        ? 'Top Area' 
        : open.startsWith('sideLR') 
            ? 'SidesLR Area' 
            : 'Bottom Area'
    : 'Widget Area'; // Fallback if open is a number (or other non-string type)

    return (
        <>

            <div
                id={open}
                className='w-full rounded-[1.5vmin]'
                style={{ height: '100%', width: '100%', padding: '2vh 0 10vh 0', minWidth:'100%' }}
            >
                <div style={{color: isDarkMode ? '#B0b0b0' : '#FFF'}} className='absolute top-[-2.5vmin] right-0 cursor-pointer rounded-[1.5vmin] h6 items-center px-[1vmin] mx-auto text-center flex justify-center' onClick={() => {
                    setClosePanel(false)
                }} >Close</div>
                <div className='flex flex-column overflow-scroll'
                    style={{ height: '100%', width: '100%' }}
                >
                <div className='text-center w-full text-[2.2vmin] mb-[2vmin] mt-[.5vmin] text-[#0867FC80]'
                    style={{ fontWeight: '700', userSelect: 'none' }}>
                    CUSTOMIZE
                </div>

                <ToolbarSection
                expanded={expanded === `${[currentSide]} / Settings`}
                setExpanded={() => setExpanded(expanded === `${[currentSide]} / Settings` ? null : `${[currentSide]} / Settings`)}
                title={`${[currentSide]} / Settings`}
                >                   
                    <Settings currentSide={currentSide}/>
                </ToolbarSection>
                 
                <ToolbarSection
                    expanded={expanded === 'Appearance'}
                    setExpanded={() => setExpanded(expanded === 'Appearance' ? null : 'Appearance')}
                    title="Appearance"
                >
                    <ToolbarTextInput
                    nunNode
                    label='Background'
                    type='color'
                    value={styles[open]?.background}
                    onChange={(color) => {
                        handleChangeStyles('background', color); // Update the background color in the state
                    }}
                    propKey={[open]}
                    />

                <ToolbarTextInput
                    nunNode
                    label='Color'
                    type='color'
                    value={styles[open]?.color}
                    onChange={(color) => {
                        handleChangeStyles('color', color); // Update the background color in the state
                    }}
                    propKey={[open]}
                    />
                </ToolbarSection>

                <ToolbarSection
                    expanded={expanded === 'Styles'}
                    setExpanded={() => setExpanded(expanded === 'Styles' ? null : 'Styles')}
                    title="Styles"
                >
                    <div style={{ textTransform: 'capitalize' }} className="text-start space-y-4 p-[1vmin]">
                        <NumberSlider unit='px' value={currentRow?.width?.replace('px', '')} props={{ label: 'Width' }} onChange={(newValue) => handleChangeStyles('width', newValue + 'px')} />
                        <NumberSlider unit='px' value={currentRow?.height?.replace('px', '')} props={{ label: 'Height' }} onChange={(newValue) => handleChangeStyles('height', newValue + 'px')} />
                        <NumberSlider unit='px' value={currentRow?.padding?.replace('px', '')} props={{ label: 'Padding' }} onChange={(newValue) => handleChangeStyles('padding', newValue + 'px')} />
                        <NumberSlider unit='px' value={currentRow?.margin?.replace('px', '')} props={{ label: 'Margin' }} onChange={(newValue) => handleChangeStyles('margin', newValue + 'px')} />
                        <NumberSlider unit='px' value={currentRow?.borderRadius?.replace('px', '')} props={{ label: 'Rounded' }} onChange={(newValue) => handleChangeStyles('borderRadius', newValue + 'px')} />

                    </div>
                </ToolbarSection>
            </div>
            </div>
        </>

    );
};

export default SettingsPanel;
