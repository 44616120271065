import React, { useState, useRef } from 'react';
import { IconPerson, MenuIcon3 } from '../../../../editor/Viewport/Editor-icons/svgArray';
import './sideBar.css'
import { useOptionsContext } from '../../../../../OptionsContext';
import { useActiveIdContext } from '../../../../../ActiveIdContext';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppProps } from '../../../../../DarkModeContext';



export default function SideBar({ full, active,
    enabled }) {
    const [childrenArray, setChildrenArray] = useState(false)

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeDatas } = idState;

    const { state, dispatch: setActiveId } = useActiveIdContext();
    const { activeId: activeId } = state;

    const [selectedItem, setSelectedItem] = useState('homeItem');


    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const homeRef = useRef(null)
    const projectsRef = useRef(null)
    const benefitsRef = useRef(null)
    const feedbackRef = useRef(null)
    const StatisticsRef = useRef(null)
    const componentRef = useRef(null);


    const items = [
        { ref: homeRef, id: 'homeItem' },
        { ref: projectsRef, id: 'projectsItem' },
        { ref: benefitsRef, id: 'benefitsItem' },
        { ref: feedbackRef, id: 'feedItem' },
        { ref: StatisticsRef, id: 'statsItem' },
    ];

    const [selected, setSelected] = useState({})


    const { 
            logoIcon,
            socialIcons,
            width,
            setNavigationSide,
            logoText, 
            setStylesOn, 
            currentStyleIndex,
            navigationSide,
          } = useAppProps();

    const handleItemClick = (item) => {
        setSelected(item);
        if (selected?._id === item._id) {
            setChildrenArray(!childrenArray);
        } else {
            setSelected(null);
            setSelected(item);
            setChildrenArray(!childrenArray);
        }
    };


    // const handleItemClick = (ref) => {

    //     handleItemClickItem(ref);
    //     items.forEach((item) => {
    //         document.getElementById(item.id).style.color = item.ref === ref ? '#B20312' : 'inherit';
    //     });
    // };

    const [show, setShow] = useState(true)

    const sideBar = [
        <div className='side-bar h-[100%]'>
            <div className='sidebar-wrapper' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between', }}>

                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#510B11'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto'>
                            {logoText}
                        </div>

                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#510B11', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#510B11', fontSize: '40px' }} />}
                    </div>

                </div>
                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` ${activeId?._id === x._id ? 'bg-[#510B11]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>

                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}
                                            >

                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#510B11]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer", height: '35px',
                                                marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side" style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}

                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}

            </div>
        </div>,


        <div className='side-bar h-full' style={{ background: '#121826' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between', }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#4BDBA2'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#4BDBA2' }}>
                            {logoText}
                        </div>
                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#4BDBA2', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#4BDBA2', fontSize: '40px' }} />}
                    </div>
                </div>

                {show && <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'gradbackground' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", color: activeId?._id === x._id ? '#fff' : '#989191A1', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                    key={x._id}
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={() => {
                                            handleItemClick(x)
                                        }}>
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) && x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'gradbackground' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191A1', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>))}

                            </div>

                        ))}

                    </div>
                    <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                        {socialIcons?.map((icon, index) => (
                            <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#4bdba2' : icon?.fill ? icon?.fill : '#4bdba2'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#4bdba2' : icon?.fill ? icon?.fill : '#4bdba2'}><path d={icon?.path}></path></svg>
                                </div>}
                            </div>
                        ))}
                    </div>
                </div>}

            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#213156' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between', }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#DD6AEA'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#DD6AEA' }}>
                            {logoText}
                        </div>
                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#EA6EF4', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#EA6EF4', fontSize: '40px' }} />}
                    </div>
                </div>

                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#DD6AEA' : 'transparent', color: activeId?._id === x._id ? '#fff' : '#989191A1', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '20px 4px' : '' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id === selected?._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#DD6AEA' : 'transparent', color: child?._id === activeId?._id ? '#fff' : '#989191A1', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '20px 4px' : ''
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#EA6EA4' : icon?.fill ? icon?.fill : '#EA6EA4'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#EA6EA4' : icon?.fill ? icon?.fill : '#EA6EA4'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}
            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#DFFFF9' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#31A993'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#31A993' }}>
                            {logoText}
                        </div>
                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#31A993', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#31A993', fontSize: '40px' }} />}
                    </div>
                </div>


                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#31A993' : 'transparent', color: activeId?._id === x._id ? '#fff' : '#989191A1', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '30px' : '' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#31A993' : 'transparent', color: child?._id === activeId?._id ? '#fff' : '#989191A1', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '30px' : ''
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#31A993' : icon?.fill ? icon?.fill : '#31A993'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#31A993' : icon?.fill ? icon?.fill : '#31A993'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}
            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#fff' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between', }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#0078D3'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#0078D3' }}>
                            {logoText}
                        </div>
                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#007BD3', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#007BD3', fontSize: '40px' }} />}
                    </div>
                </div>

                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#0078D3' : 'transparent', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '' : '' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#0078D3' : 'transparent', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '' : ''
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>
                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#007BD3' : icon?.fill ? icon?.fill : '#007BD3'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#007BD3' : icon?.fill ? icon?.fill : '#007BD3'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}
            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#020212' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between', }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#FFFFFF' }}>
                            {logoText}
                        </div>
                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFFFFF', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFFFFF', fontSize: '40px' }} />}
                    </div>
                </div>

                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#FFFFFF' : 'transparent', color: activeId?._id === x._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '' : '' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#020212' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#020212' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#020212' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#FFFFFF' : 'transparent', color: child?._id === activeId?._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '' : ''
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#020212' : ' #989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}
            </div>
        </div>,


        <div className='side-bar h-full' style={{ background: '#6750A4', borderRadius: ' 0px 30px 30px 0px' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FBFAFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#FBFAFF' }}>
                            {logoText}
                        </div>
                    </div>

                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFF', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFF', fontSize: '40px' }} />}
                    </div>
                </div>

                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#FBFAFF' : 'transparent', color: activeId?._id === x._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '30px' : '' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#020212' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#6750A4' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#6750A4' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#FBFAFF' : 'transparent', color: child?._id === activeId?._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '30px' : ''
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#020212' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>
                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>
                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}

            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#2A2A2A', borderRadius: '0px 10px 10px 0px' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between', }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FFD600'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#FFD600' }}>
                            {logoText}
                        </div>
                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFD600', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFD600', fontSize: '40px' }} />}
                    </div>
                </div>

                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#FFD600' : 'transparent', color: activeId?._id === x._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '10px' : '' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#020212' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#FFD600' : 'transparent', color: child?._id === activeId?._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '10px' : ''
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#020212' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>
                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFd600' : icon?.fill ? icon?.fill : '#FFd600'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFd600' : icon?.fill ? icon?.fill : '#FFd600'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}

            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#6064D2', borderRadius: '0px 8px 8px 0px' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#FFF', fontFamily: 'Rubik' }}>
                            {logoText}
                        </div>
                    </div>
                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFF', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFF', fontSize: '40px' }} />}
                    </div>
                </div>


                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#B20312' : 'transparent', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '4px' : '', fontFamily: 'Roboto' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#B20312' : 'transparent', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '4px' : '', fontFamily: 'Roboto'
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>
                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}

            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#A25738' }}>
            <div className='sidebar-wrapper' style={{ height: '100%', }}>
                <div className='logo-aria p-4' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center w-auto h-auto'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FEFBF7'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content my-auto' style={{ color: '#FFFFFF', width: 'fit-content' }}>
                            {logoText}
                        </div>
                    </div>

                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFF', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFF', fontSize: '40px' }} />}
                    </div>
                </div>


                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", background: activeId?._id === x._id ? '#FFFFFF' : 'transparent', color: activeId?._id === x._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '' : '' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#020212' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', background: child?._id === activeId?._id ? '#FFFFFF' : 'transparent', color: child?._id === activeId?._id ? '#020212' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400', borderRadius: selectedItem === homeRef ? '' : ''
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#020212 ' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                        <div style={{ height: '10%', display: 'flex', margin: '15px 0', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{ alignContent: 'center', alignItems: 'center' }}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={icon?.fill ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>}

            </div>
        </div>,

        <div className='side-bar h-full' style={{ background: '#FFF', borderRadius: '0px 0px 30px 0px', }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#2479FD'}><path d={logoIcon?.path} /></svg>

                        </div>
                        <div className='logo-content  my-auto' style={{ color: '#2479FD' }}>
                            {logoText}
                        </div>
                    </div>

                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#2479FD', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#2479FD', fontSize: '40px' }} />}
                    </div>
                </div>


                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", borderRadius: '14px', background: activeId?._id === x._id ? '#FFC92E' : 'transparent', color: activeId?._id === x._id ? '#2479FD' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#2479FD' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#2479FD' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#2479FD' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', borderRadius: '14px', background: child?._id === activeId?._id ? '#FFC92E' : 'transparent', color: child?._id === activeId?._id ? '#2479FD' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#2479FD' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                    </div>}
            </div>
        </div>,


        <div className='side-bar h-full' style={{ background: '#FFF', borderRadius: '0px 0px 20px 0px', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.04)' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FF0060'}><path d={logoIcon?.path} /></svg>

                        </div>
                        <div className='logo-content  my-auto' style={{ color: '#FF0060' }}>
                            {logoText}
                        </div>
                    </div>

                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#2479FD', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#2479FD', fontSize: '40px' }} />}
                    </div>
                </div>



                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", borderRadius: '30px', background: activeId?._id === x._id ? '#F6FA70' : 'transparent', color: activeId?._id === x._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#000' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', borderRadius: '30px', background: child?._id === activeId?._id ? '#F6FA70' : 'transparent', color: child?._id === activeId?._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#000' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                    </div>}
            </div>
        </div>,


        <div className='side-bar h-full' style={{ background: '#FFF', borderRadius: '0px 0px 20px 0px' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#F31559'}><path d={logoIcon?.path} /></svg>

                        </div>
                        <div className='logo-content  my-auto' style={{ color: '#F31559' }}>
                            {logoText}
                        </div>
                    </div>

                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#F31559', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#F31559', fontSize: '40px' }} />}
                    </div>
                </div>



                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", borderRadius: '20px 4px', background: activeId?._id === x._id ? '#FFB7B7' : 'transparent', color: activeId?._id === x._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#000' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', borderRadius: '20px 4px', background: child?._id === activeId?._id ? '#FFB7B7' : 'transparent', color: child?._id === activeId?._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#000' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                    </div>}
            </div>
        </div>,


        <div className='side-bar h-full' style={{ background: '#FFF', borderRadius: '4px' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#0C134F'}><path d={logoIcon?.path} /></svg>

                        </div>
                        <div className='logo-content  my-auto' style={{ color: '#0C134F' }}>
                            {logoText}
                        </div>
                    </div>

                    <div className='menu-hidden' onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#0C134F', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#0C134F', fontSize: '40px' }} />}
                    </div>
                </div>



                {show &&
                    <div className='p-4' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className='side-list'>
                            {treeDatas.map((x, key) => (
                                <div key={key}>
                                    <div className={` items-side my-2 flex flex-row justify-between`}
                                        style={{ cursor: "pointer", borderRadius: '8px', background: activeId?._id === x._id ? '#D4ADFC' : 'transparent', color: activeId?._id === x._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => {
                                            setActiveId(x)
                                            setChildrenArray(!childrenArray)
                                        }}
                                        val={x.name}
                                        _id="sidebarItemx"
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#000' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>
                                            <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                                {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                            </div>

                                        </div>
                                        {x?.children && (
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                handleItemClick(x)
                                            }}

                                            >
                                                {x?.children?.length ? (
                                                    <div>
                                                        {childrenArray && selected?._id === x._id && selected.children ?
                                                            <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} /> :
                                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} />

                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                        <div
                                            key={child._id} className={` items-side item-side-sm my-2 flex flex-row justify-start  `}
                                            style={{
                                                cursor: "pointer",
                                                marginLeft: 'auto', borderRadius: '8px', background: child?._id === activeId?._id ? '#D4ADFC' : 'transparent', color: child?._id === activeId?._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                            }}
                                            //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                            onClick={() => setActiveId(child)}
                                        >
                                            <div className="logo-side " style={{ margin: 'auto 0' }}>
                                                <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#000' : '#989191'}><path d={child.icon.path}></path></svg>
                                                </div>


                                            </div>
                                            <div className='item-side-name' style={{ margin: 'auto 10px' }}>
                                                {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                            </div>

                                        </div>)) : null}

                                </div>

                            ))}
                        </div>
                    </div>}
            </div>
        </div>,


        <div className='side-bar h-full' style={{ background: '#E5E5E5', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#343CE8]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)

                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)


                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#343CE8]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-full mx-auto'>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#424146', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#9007D2' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#9007D2]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div className='p-[2px]' onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#9007D2]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px', border: '1px solid #fff' }}>
                            <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#DDEDFB', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#035CFB' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#035CFB]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div className='p-2' onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#035CFB]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px' }}>
                            <div className='button-txt'>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,



        <div className='side-bar h-full' style={{ background: '#CADAD4', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#3DA169' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#3DA169]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#3DA169]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px' }}>
                            <div className='button-txt'>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#FFF', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#FF0560' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#FF0560]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#FF0560]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px' }}>
                            <div className='button-txt'>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#4C3856', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#950B67' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#950B67]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#950B67]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px', border: '1px solid #FFF' }}>
                            <div className='button-txt' style={{ color: '#FFF' }}>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#CCF2FA', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#FE490B' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#FE490B]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#FE490B]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px' }}>
                            <div className='button-txt'>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#311043', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#D423A5' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#D423A5]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#fff' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}>
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#FFF' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#D423A5]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#fff' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#fff' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>


                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px', border: '1px solid #FFF' }}>
                            <div className='button-txt' style={{ color: '#FFF' }}>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#BFF0F0', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper' style={{ height: '100%' }}>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#02C8B4' }}>
                            <div><IconPerson fill='#000' /></div>
                            <div className='button-txt' style={{ color: '#000' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#02C8B4]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#000' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#02C8B4]' : 'transparent'} items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side">
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#000' : '#989191'}><path d={x.icon.path}></path></svg>
                                            </div>

                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>

                    <div className='py-[30px] '>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px' }}>
                            <div className='button-txt'>Register</div>
                        </div>
                    </div>
                </div>}
            </div>

        </div>,


        <div className='side-bar h-full' style={{ background: '#78838B', borderRadius: '30px', margin: '10px 0' }}>
            <div className='sidebar-wrapper h-full'>
                <div className='logo-aria p-4 mx-auto w-full' style={{ justifyContent: 'space-between' }}>
                    <div style={{ height: '100%', width: 'fit-content', display: 'flex' }}>

                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill ? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='button-full' style={{ background: '#C7D9E3' }}>
                            <div><IconPerson fill='#000' /></div>
                            <div className='button-txt' style={{ color: '#000' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{ marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='h-[100%] px-4' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='side-list'>
                        {treeDatas.map((x, key) => (
                            <div key={key}>
                                <div className={` ${activeId?._id === x._id ? 'bg-[#C7D9E3]' : 'transparent'} items-side my-2 flex flex-row justify-between`}
                                    style={{ cursor: "pointer", borderRadius: '30px', color: activeId?._id === x._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400' }}
                                    //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                    onClick={() => {
                                        setActiveId(x)
                                        setChildrenArray(!childrenArray)
                                    }}
                                    val={x.name}
                                    _id="sidebarItemx"
                                >
                                    <div className="logo-side">
                                        <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                            <svg aria-hidden="true" focusable="false" role="img" viewBox={x.icon.viewBox} width={x.icon.width} height={x.icon.height} fill={activeId?._id === x._id ? x.icon.fill ? x.icon.fill : '#000' : '#989191'}><path d={x.icon.path}></path></svg>
                                        </div>
                                        <div className="item-side-name" style={{ margin: 'auto 10px', minWidth: 'fit-content' }}>
                                            {x?.name?.length > 10 ? `${x?.name?.slice(0, 8)}...` : x?.name}
                                        </div>

                                    </div>
                                    {x?.children && (
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            handleItemClick(x)
                                        }}

                                        >
                                            {x?.children?.length ? (
                                                <div>
                                                    {childrenArray && selected?._id === x._id && selected.children ?
                                                        <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} /> :
                                                        <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id ? '#000' : '#989191' }} />

                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                                {x?.children?.some(y => y._id == selected._id) || (childrenArray && selected?._id === x._id && selected.children) ? x?.children?.map(child => (
                                    <div
                                        key={child._id} className={` ${child?._id === activeId?._id ? 'bg-[#C7D9E3]' : 'transparent'}  items-side item-side-sm my-2 flex flex-row justify-start  `}
                                        style={{
                                            cursor: "pointer", height: '35px', borderRadius: '30px',
                                            marginLeft: 'auto', color: child?._id === activeId?._id ? '#000' : '#989191', fontWeight: selectedItem === homeRef ? '700' : '400'
                                        }}
                                        //  onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}
                                        onClick={() => setActiveId(child)}
                                    >
                                        <div className="logo-side " style={{ margin: 'auto 0' }}>
                                            <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                                <svg aria-hidden="true" focusable="false" role="img" viewBox={child.icon.viewBox} width={child.icon.width} height={child.icon.height} fill={child?._id === activeId?._id ? child.icon.fill ? child.icon.fill : '#000' : '#989191'}><path d={child.icon.path}></path></svg>
                                            </div>
                                        </div>
                                        <div className='item-side-name ' style={{ margin: 'auto 10px' }}>
                                            {child?.name?.length > 15 ? `${child?.name?.slice(0, 8)}...` : child?.name}

                                        </div>

                                    </div>)) : null}

                            </div>

                        ))}

                    </div>
                    <div className='py-[30px]'>
                        <div className='button-outline-sm justify-center mx-auto' style={{ width: '170px', border: '1px solid #FFF' }}>
                            <div className='button-txt' style={{ color: '#FFF' }}>Register</div>
                        </div>
                    </div>
                </div>}

            </div>

        </div>,

    ]

    return (
        <div className={`sidebarcontainer ${!full ? "sidebarmobile" : ""} h-[100%]`} 
        ref={componentRef} 
        onClick={enabled ? (e) => {
            e.stopPropagation()
            setNavigationSide(true)
            setStylesOn(true)
        } : null}>
            {sideBar.map((content, index) => (
                <div

                    key={index}
                    className={`${enabled && navigationSide && !active ? 'bordered' : ''}`}
                    style={{ display: index === currentStyleIndex ? 'block' : 'none', height: '100%' }}
                >
                    {content}
                </div>
            ))}
        </div>)
}