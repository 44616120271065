import { Box } from "@mui/material";
import SelectionBox from "../../components/editor/Viewport/Pop-up/Images-Icons-Modal";
import { category } from "../../components/selectors/basic/Image/ImageSettings";
import { Circle2 } from "../iconsSvg";
import React, { useState } from "react";
import NextButton from "./NextButton";

const LogoStep = ({selectedFile, setStart, setSelectedFile, select, stepper, setStepper, setWalksFor, setAppSettings}) => {

    const [iconsSquare, setIconsSquare] = useState(false)
    const [selectedIcon, setSelectedIcon] = useState({});

    const handleIconUploud = (event) => {
        const file = event.target.files[0]; // Access the file object
        setSelectedFile(file); // Update the state

        setAppSettings((prev) => ({
            ...prev,
            siteLogo: { value: null }
        }))
    };

    const handleIconSelected = (selectedUrl) => {

        if (Array.isArray(selectedUrl) && selectedUrl.length) {
            setSelectedIcon(selectedUrl[0]);
            setAppSettings((prev) => ({
                ...prev,
                siteLogo: { value: selectedUrl }
            }))
            setSelectedFile(null);

        } else {
            setStepper(1);
            setSelectedIcon(selectedUrl)
            setAppSettings((prev) => ({
                ...prev,
                siteLogo: { value: selectedUrl }
            }))
            setSelectedFile(null);

        }
        setIconsSquare(false);

    };

    return (
        <div className='relative w-full h-full flex-col flex justify-center items-center py-[4vmin] text-[2vmin]'>
                            <div style={{ width: "100%", textAlign: 'center', cursor: 'pointer' }}>
                                <div>
                                    <Box className='max-w-[20Vmin] mx-auto flex flex-col justify-center'>
                                        <label className="custom-file-upload">
                                            <input
                                                type="file"
                                                accept=".svg, .png, .jpg, .jpeg"
                                                id="contained-button-file2"
                                                multiple
                                                onChange={handleIconUploud}
                                            />
                                            Upload an Icon
                                        </label>
                                    </Box>
                                    {selectedFile && (
                                        <div className='mt-[1vmin]  flex justify-center items-center flex-column'>
                                            <p className='text-[1.7vmin]'>File Type: {selectedFile.type}</p>
                                            <img
                                                src={URL.createObjectURL(selectedFile)}
                                                alt="Preview"
                                                style={{ maxWidth: '10vmin', maxHeight: '10vmin', margin: '0 auto' }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='w-full text-center mt-[4vmin]'>or select from our icons library</div>
                                <div
                                    style={{ color: '#0867FC' }}
                                    onClick={() => setIconsSquare(true)}
                                >
                                    Select an Icon
                                </div>
                                {iconsSquare && (
                                    <SelectionBox
                                        category={category}
                                        setIconsSquare={setIconsSquare}
                                        iconsSquare={iconsSquare}
                                        onSelect={handleIconSelected}
                                    />
                                )}
                            </div>
                            <NextButton setStart={setStart}
                            onClick={() => {
                                setStart(true)
                                setStepper(2)
                                setWalksFor((prev) => ({
                                    ...prev,
                                    main: { value: .600 },
                                    mainTheme: { value: 2 }
                                }))
                            }}
                            />
                            {/* {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
                                <Circle2 fill={stepper >= 2 ? 'green' : ''} />
                            </div>} */}
                        </div>
    )
}

export default LogoStep;