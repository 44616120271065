import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useNode, useEditor } from '@craftjs/core';
import { TextArea } from "@progress/kendo-react-inputs";
import { TextAreaSettings } from './TextAreaSettings';
import { useInView } from 'react-hook-inview';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { ResizableWrapper } from '../../ResizableWrapper';
import MainElement from '../MainElements/MainElement';


const defaultProps = {
  actionState:'default',
  style: {},

  default: {
    className: '',
    overflowWrap:'anywhere',
    href: null,
    width:null,
    height:null,
    background: null,
    maxWidth: null,
    minWidth: null,
    display: null,
    color: null,
    margin: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    padding: null,
    paddingTop: null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight: null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: 'solid',
    borderRadius: '1.5vmin',
    overflow: null,
    fontSize: '22px',
    textTransform: null,
    fontWeight: null,
    lineHeight: null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily: null,
    textAlign: null,
    headingNum: 'div',
    transition: null,
    isBasic: false,
    readOnly: false,
    lineHeight: null,
    textValue: 'Tell us a little bit about yourself...',
    placeholder: null,
    isTextArea : true,
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    shadowTextX: null,
    shadowTextY: '1px',
    shadowTextColor:null,
    shadowTextBlur: '1px',
  },

  hovered : {
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    shadowTextX: null,
  shadowTextY: '1px',
  shadowTextColor:null,
  shadowTextBlur: '1px',
  },
  active: {
    shadowX: null,
      shadowY: null,
      shadowColor:null,
      shadowBlur: null,
      shadowTextX: null,
    shadowTextY: '1px',
    shadowTextColor:null,
    shadowTextBlur: '1px',
  }
   
};


export const BasicTextArea = ({ style={}, isBasic = false, ...defaultProps}) => {
  const {
    node,
    connectors: { connect },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
    node:node
  }));

  const {
    nodes,
    enabled,
    isDragged,
    actions : {setProp}
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    isDragged: node.events.dragged,
    nodes: state.nodes,
  }));

  const {
    readOnly,
    textValue,
    placeholder,
    href,
    className,
  } = defaultProps?.default


  const textStyle = {
    color: defaultProps.default.color,
    fontSize: defaultProps.default.fontSize,
    textTransform: defaultProps.default.textTransform,
    fontWeight: defaultProps.default.fontWeight,
    lineHeight: defaultProps.default.lineHeight,
    fontStyle: defaultProps.default.fontStyle,
    textDecoration: defaultProps.default.textDecoration,
    fontFamily: defaultProps.default.fontFamily,
    textAlign: defaultProps.default.textAlign,
  };
  
  
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  

  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);

  
 
   const handleClick = useCallback((e) => {
     e.preventDefault();
         if (!enabled) {
           if (href && href._id && href._id.includes("www")) {
           // If href exists and href._id includes "www", open a new tab with the URL
           window.open("https://" + href.name, "_blank");
         } else if (href && href._id && href.name === 'dom') {
           const element = Object.values(nodes).find((node) => node.id === href._id);
   
           if (element) {
   
             if (element.dom) {
               element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
             } else {
               console.log("DOM Element not found for ID:", element.id);
             }
           } else {
             console.log("Element with ID not found:", href.id);
           }
           }
           else if (href && href.name !== 'dom') {
             // Find the item in treeData based on href._id
             const parentItem = treeData.find((x) => x?._id === href?._id);
             if (parentItem) {
               // Set activeId to the parent item if found
               setActiveId(parentItem);
             } else {
               // If parent item not found, find the child item in treeData
               let childItem = null;
               for (const parent of treeData) {
                 const child = parent.children.find((child) => child._id === href._id);
                 if (child) {
                   childItem = child;
                   break;
                 }
               }
               // Set activeId to the child item if found
               setActiveId(childItem);
             }
           }
         }
     }, [enabled, href, treeData]);


  const memoizedProps = useMemo(() => ({
    ...style,
    cursor: href && !enabled ? 'pointer' : '',
  }), [href, enabled]);
  
const handleMouseEnter = (e) => {
    e.stopPropagation()
    setIsHovered(true);
  };

const handleMouseLeave = (e) => {
  e.stopPropagation()
    setIsHovered(false);
};

 
  return ( 
    <>
      <MainElement
        activeBackground={activeBackground}
        connect={connect}
        enabled={enabled}
        selected={selected}
        mergedRef={mergedRef}
        defaultProps={defaultProps['default']}
        hoverProperties={defaultProps['hovered']}
        activeProperties={defaultProps['active']}
        isDragged={isDragged}
        isHovered={isHovered}
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
        memoizedProps={memoizedProps}

    onClick={handleClick}
    className={`${className} p-[.5%] ${href && activeId?.name && textValue && 
      (href.name.toLowerCase() === activeId.name.toLowerCase() || textValue.toLowerCase() === activeId.name.toLowerCase()) ? selected : (enabled && 'helloworldsParentChildren')}`}
    >
    <textarea 
    className='focus-text'
    value={textValue}
    readOnly={readOnly === 'True'? true : false} 
    placeholder={placeholder}
    rows="1" cols="50" 
    onChange={(e) => {
      setProp(node.id, (prop) => (prop.textValue = e.target.value), 500);
    }}
    style={{...textStyle, background:'transparent', width:'100%', height:'100%', maxHeight:'100%', maxWidth:'100%', border:'none'}}/>
     <ResizableWrapper
          selected={selected} 
          childRef={mergedRef} 
          setActiveBackground={setActiveBackground}
          setIsHovered={setIsHovered} 
          isHovered={isHovered}/> 
    </MainElement>
    </>
    );
};

BasicTextArea.craft = {
    displayName: 'Text Area',
    props: defaultProps,
    rules: {
      canDrag: () => true,
    },
    related: {
      toolbar: TextAreaSettings,
    },
  };



  