/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../editor';
import { TabOption } from '../../editor/Viewport/Sidebar/TabOption';
import { useNode } from '@craftjs/core';
import { ArrowToRight } from '../../editor/Viewport/Editor-icons/svgArray';

export const DividerSettings = () => {
    const [activeTab, setActiveTab] = useState("Style Manager");
    const [expanded, setExpanded] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
  

    const {
        options,
        node,
    } = useNode((node) => ({
        options: node.data.props,
    }));


    const [customize, setCustomize] = useState({})

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
    
      const handleCustomize = (group) => {
        setCustomize((prev) => ({
          ...prev,
          [group]: !prev[group], // Toggle the current group's state
        }));
      };
    return (
        <div className='flex flex-col component-settings' style={{ height: '100%', zIndex: 9999999 }}>

        <div>
        <div style={{ borderRadius: '1.5vmin', width:'100%', backdropFilter: 'blur(10px)', background:'#e9e9e930'}} className={`shadow-sm absolute left-0 top-0 z-[999999999]`}>

        <input
          placeholder="Search by title..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
            margin: '1vmin auto',
            padding: '1vmin 1.4vmin',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '400',
            fontSize: '2vmin',
            color: '#98919190',
            backgroundColor: 'transparent',
            border: '.25vmin solid #0867FC70',
            borderRadius: '2vmin',
            outline: 'none',
            width: '80%',
            height:'4vmin'

          }}
          onFocus={(e) => {
            e.target.style.borderColor = '#007bff';
            e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
          }}
          onBlur={(e) => {
            e.target.style.borderColor = '#0867FC70';
            e.target.style.boxShadow = 'none';
          }}

        />
        <div className='w-full text-center text-[2.2vmin] my-[.5vmin] text-[#0867FC80]'
          style={{ fontWeight: '700', userSelect: 'none' }}>
          CUSTOMIZE
        </div>
        </div>

        {activeTab === "Style Manager" ? (
          <div style={{ width: "100%" }}>

                    <div style={{ display: 'flex', margin: 'auto auto', justifyContent: 'center', alignItems: 'center', overflowY:'scroll', minWidth:'100%', maxHeight:'75vmin', paddingBottom: expanded && '3vh', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%' }}>

                        <ToolbarSection
                            expanded={expanded === 'Size'}
                            setExpanded={() => setExpanded(expanded === 'Size' ? null : 'Size')}
                            title="Size"
                        >
                            <ToolbarItem
                                full={true}
                                propKey="width"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Width"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="height"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Height"
                            />


                        </ToolbarSection>
                        <ToolbarSection
                            expanded={expanded === 'Appearance'}
                            setExpanded={() => setExpanded(expanded === 'Appearance' ? null : 'Appearance')}
                            title="Appearance"
                        >
                            <ToolbarItem full={true} label='Background' propKey="background" type="bg" />
                        </ToolbarSection>

                        <ToolbarSection
                            expanded={expanded === 'Shadow'}
                            setExpanded={() => setExpanded(expanded === 'Shadow' ? null : 'Shadow')}
                            title="Shadow"
                        >
                             <ToolbarItem
                                full={true}
                                propKey="shadowColor"
                                type="color"
                                label="Shadow Color"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="shadowY"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Y offset"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="shadowX"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="X offset"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="shadowBlur"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Blur"
                            />

                           


                        </ToolbarSection>

                        <ToolbarSection searchTerm={searchTerm}
                            expanded={expanded === 'Border'}
                            setExpanded={() => setExpanded(expanded === 'Border' ? null : 'Border')}
                            title="Border"
                            props={['border', 'fit']}
                            summary={({ border, fit }) => {
                                return `${border}px` `${fit}`;
                            }}
                        >

                            <ToolbarItem
                                full={true}
                                propKey="borderWidth"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Border Width"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="borderColor"
                                type="bg"
                                label="Border Color"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="borderRadius"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Rounded"
                            />
                        </ToolbarSection>

                        <ToolbarSection
                            expanded={expanded === 'margin'}
                            setExpanded={() => setExpanded(expanded === 'margin' ? null : 'margin')}
                            title="margin"
                        >
                            {customize['margin'] ? (
                                <>
                                    <ToolbarItem propKey="marginTop" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Top" />
                                    <ToolbarItem propKey="marginRight" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Right" />
                                    <ToolbarItem propKey="marginBottom" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Bottom" />
                                    <ToolbarItem propKey="marginLeft" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Left" /></>
                            ) : (
                                <ToolbarItem propKey="margin" type="slider"
                                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                    label="Margin" />
                            )}

                            <div onClick={() => handleCustomize('margin')} style={{ borderRadius: '0 0 1vmin 1vmin', border: '.25vmin solid' }} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['margin'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>

                        </ToolbarSection>


                        <ToolbarSection
                            expanded={expanded === 'padding'}
                            setExpanded={() => setExpanded(expanded === 'padding' ? null : 'padding')}
                            title="Padding"
                            props={['padding']}
                        >
                            {customize['padding'] ? (
                                <>
                                    <ToolbarItem propKey="paddingTop" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Top" />
                                    <ToolbarItem propKey="paddingRight" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Right" />
                                    <ToolbarItem propKey="paddingBottom" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Bottom" />
                                    <ToolbarItem propKey="paddingLeft" type="slider"
                                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                        label="Left" />
                                </>
                            ) : (
                                <ToolbarItem propKey="padding" type="slider"
                                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                    label="Padding" />
                            )}

                            <div onClick={() => handleCustomize('padding')} style={{ borderRadius: '0 0 1vmin 1vmin', border: '.25vmin solid' }} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['padding'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>
                        </ToolbarSection>

                    </div>
</div>
                ) : null}
            </div>
        </div>
    );
};
