/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Slider as Small } from '@mui/material';
import { ToolbarTextInput } from '../..';
import { ChromePicker } from 'react-color';
import GradientSettings from '../GradientColor';



const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#e9e9e9',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const ShapesPositions = ({
    options,
    value,
    number,
    style,
    propKey,
    setProp,
    optionsDrop,
    node,
    props,
    color,
}) => {

    const [selected, setSelected] = useState(value);

    const currentstate = options?.actionState

    // useEffect(() => {
    //     if (props[currentstate]['shapePos'] === undefined) {
    //         setProp(node.id, (props) => {
    //             props[currentstate]['shapePos'] = 'Top';
    //         });
    //     }
    // }, [])
    let selectPos = options[currentstate]['shapePos']

    let shapePos = options[currentstate]['shapes'][selectPos]


    const handleChangeColor = (color) => {
        setProp(node.id, (props) => {
            props[currentstate]['shapes'][selectPos][propKey] = color;
            return { ...props };
        });
    }


    const prevColors = [
        '#e83030',
        '#5f2b2b',
        '#c31313',
        '#0867fc',
        '#f84173',
        '#ebebeb',
        '#111f3e',
        '#5558b1',
    ];

    const checkSelected = () => {

        if (selected) {
            if (typeof selected !== 'string') {
                return selected;
            }

            if (selected.startsWith('#')) {
                return selected;
            }

            if (selected.startsWith('tr')) {
                return 'Transparent';
            }

            return 'Gradient';
        }
    };

    const handleKey = options[currentstate]['shapes'][selectPos]

    console.log(options[currentstate]['shapes'][selectPos][propKey])

    return (
        <div className='text-[#FFF] container w-full flex flex-column mb-[2vmin]'>
            <div style={{ fontSize: '2vmin', fontWeight: '500', fontFamily: 'Inter', paddingBottom: '.5Vmin', marginBottom: '.5vmin' }}>{props?.label}</div>
            {color && <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className='h-full flex flex-column justify-center w-full'>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>

                        <div className='relative my-[1vmin] min-w-[100%] h-[7vmin] flex justify-center align-items-center rounded-[1vmin] overflow-hidden'>
                            <input
                                style={{ cursor: 'pointer', minWidth: '150%', height: '130%' }}
                                type='color'
                                value={selected} // Ensure selected is a hex color string
                                onChange={(e) => {
                                    handleChangeColor(e.target.value);
                                    setSelected(e.target.value);
                                }}
                            />
                            <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[1.8vmin] w-full h-full flex align-items-center justify-center text-center'>
                                <div>{typeof checkSelected() === 'string' ? checkSelected() : 'Click Here.'}</div> {/* Ensure checkSelected returns a string */}
                            </div>
                        </div>

                        <ul style={{ width: 'fit-content', padding: '0', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto auto' }}>
                            <li
                                className='cursor-pointer'
                                style={{
                                    background: "url(https://i.pinimg.com/originals/40/8e/28/408e28da9e2773e4b65c9bba40307167.png) center",
                                    margin: '.35vmin',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '3vmin',
                                    height: '3vmin',
                                    borderRadius: '50%',
                                    border: '1px solid #0867FC30',
                                }}
                                onClick={() => {
                                    handleChangeColor('transparent');
                                    setSelected('transparent');
                                }}
                            />
                            {prevColors.map((color, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        handleChangeColor(color);
                                        setSelected(color);
                                    }}
                                    className='cursor-pointer'
                                    style={{
                                        margin: '.35vmin',
                                        width: '3vmin',
                                        height: '3vmin',
                                        borderRadius: '50%',
                                        border: '1px solid #e9e9e9',
                                        background: color,
                                    }}
                                />
                            ))}
                        </ul>

                    </div>
                </div>

            </div>
            }
            {number && <div style={{border:'.25vmin solid #e9e9e980'}} className='w-auto rounded-[2.5vmin]  flex flex-row justify-between align-items-center'>
                <div style={{color:'#e9e9e9'}} className='text-[2vmin] text-center flex-1'>
                    {parseInt(handleKey?.[propKey] || 0) || value}
                </div>
                <div className='flex flex-row justify-around px-[2vmin] items-center w-full flex-1'>
                    <SliderStyled
                        className='mx-auto my-auto justify-center flex flex-1 max-w-[100%] items-center'
                        style={{ border: '.25vmin solid transparent'}}
                        min={0}
                        max={100}
                        value={handleKey?.[propKey]}
                        onChange={(e) => {
                            let newValue = e.target.value;
                            setProp(node.id, (props) => {
                                props[currentstate]['shapes'][selectPos][propKey] = newValue;
                                return { ...props };
                            });
                        }}
                    />
                </div>
            </div>}
            {style && <div>
                <select
              style={{
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                padding: '.7vmin 1.2vmin',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                fontSize: '1.5vmin',
                color: '#FFF',
                backgroundColor: 'transparent',
                border: '.25vmin solid #e9e9e9',
                borderRadius: '2vmin',
                outline: 'none',
                width: '95%',
                margin:'0 auto',
                // transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
              }}

                    value={handleKey?.[propKey]}

                    onChange={(e) => {
                        let newValue = e.target.value;
                        setProp(node.id, (props) => {
                            props[currentstate]['shapes'][selectPos][propKey] = newValue;
                            return { ...props };
                        });
                    }}

                    onFocus={(e) => {
                        e.target.style.borderColor = '#007bff';
                        e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                    }}
                    onBlur={(e) => {
                        e.target.style.borderColor = '#ccc';
                        e.target.style.boxShadow = 'none';
                    }}
                >
                    <option value="">default</option>
                    {optionsDrop.map((option) => (
                        <option
                            style={{
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '2.2Vmin',
                                color: 'black',
                            }}
                            key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>}
            <div className='flex flex-row w-full'>
                {!style && !number && !color && optionsDrop.map((position) => (
                    <div key={position} className={`flex-1 text-center p-[1vmin] text-[2vmin] ${selectPos === position ? 'bg-[#0867FC4D]' : ''} hover:bg-[#0867FC4D] cursor-pointer rounded-[.5vmin] shadow-sm mx-[1vmin]`}
                        onClick={() => setProp(node.id, (props) => {
                            props[currentstate]['shapePos'] = position
                        }
                        )}
                    >{position}</div>
                ))}
            </div>
        </div>
    )
}