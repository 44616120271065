/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { ROOT_NODE, useEditor } from '@craftjs/core';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ToolbarSection } from '../Toolbar';
import { ADD_OPTION, useOptionsContext } from '../../../OptionsContext';
import { useActiveIdContext } from '../../../ActiveIdContext'
import { components, parents } from './ToolBoxData/components.js';
import Group from './ToolBoxData/Group.js';
import { useAppProps } from '../../../DarkModeContext.js';
import { LoginSettings } from '../../../login/LoginSettings.js';
import SettingsPanel from '../../../Tutourial/ViewArea/SettingsPanel.js';
import { useSidesContext } from '../../../SideContext.js';

const ToolboxDiv = styled.div`
  // transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  height: 100%;
  ${(props) => (`width: 100%;`)}
  // ${(props) => (!props.activeComponents ? `` : 'width: 100%; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;')}
  ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

const Item = styled.a` 
  svg {
    width: 4%;
    height: 4%;
    fill: #707070;
  }
  ${(props) =>
    props.move &&
    `
    cursor: move;
  `}
`;

const getTabStyle = (tab, activeTab, isDarkMode, colorTheme) => {
  const isActive = activeTab === tab;
  const textColor = isDarkMode ? colorTheme : `${colorTheme + '90'}`;
  const textOpacity = isActive ? 1 : 0.6;

  return {
    cursor: 'pointer',
    fontWeight: 'medium',
    display: 'flex',
    fontSize: '2vmin',
    justifyContent: 'center',
    color: textColor,
    opacity: textOpacity,
  };
};



export const Toolbox = ({ auth }) => {
  const { isDarkMode, connected,  activeComponents, allNodes, setColorTheme,
    colorTheme,
    setAllNodes} = useAppProps();

    const {closePanel} = useSidesContext()

  const [filterComponents, setFilterComponents] = useState(null)
  const [activeTab, setActiveTab] = useState("Basic")
  const [allComponents, setAllComponents] = useState({})
  const [expanded, setExpanded] = useState([]);
  const [search, setSearch] = useState("")




  const {
    active, // keep this variable from the first useEditor
    enabled,
    connectors,
    connectors: { create },
    selected,
    actions,
    related,
    node,
    nodes,
    json,
    singinNode,
    actions: { setOptions, selectNode, setProp },
  } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent("selected").first();
    return {
      active: currentlySelectedNodeId, // include active from the second useEditor
      enabled: state.options.enabled,
      selected: state.events.selected,
      singinNode: state?.nodes["ROOT"]?.data?.props,
      node: state,
      nodes: state.nodes,
      json: query.serialize(),
      related:
        currentlySelectedNodeId && state.nodes[currentlySelectedNodeId]?.related,
    };
  });

  useEffect(() => {
    if (search) {
      setFilterComponents(([...components].filter((x) => {
        return (
          new RegExp(search, "gi").test(x.name)
        )
      })))
    }
  }, [search])

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  // const options = useSelector((state) => state.options.options); 

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  
  

  const changeOptions = (options) => {

    idDispatch({ type: ADD_OPTION, payload: options });
  };

  useEffect(() => {
    // Handle the case when activeId exists and auth is false
    if (activeId && !auth) {
      const json = localStorage.getItem(activeId?._id);
      const parsed = JSON.parse(json);
  
      if (parsed) {
        // Deserialize based on whether Widgets exist
        actions.deserialize(parsed,);
      } else {
        const found = allNodes.find(x => x._id === activeId?._id);
  
        if (found) {
          actions.deserialize(found.json);
        } else {
          actions.deserialize(
            {
              "ROOT": {
                "type": { "resolvedName": "Container" },
                "isCanvas": true,
                "props": {
                  "isContainer": true,
                  "flexDirection": "column",
                  "alignItems": "flex-start",
                  "justifyContent": "flex-start",
                  "fillSpace": "no",
                  "background": { "r": 255, "g": 255, "b": 255, "a": 0.708 },
                  "startImage": false,
                  "color": { "r": 0, "g": 0, "b": 0, "a": 1 },
                  "width": "100%",
                  "child": false,
                  "height": "100%",
                  "minHeight": "100vh",
                  "padding" : "1%",
                  "isApp": true,
                },
                "displayName": "Container",
                "custom": { "displayName": "App" },
                "hidden": false,
                "nodes": [],
                "linkedNodes": {}
              },
            }
          );
        }
      }
      
    }
  }, [activeId, auth]);
  

  useEffect(() => {
    if (json && !auth) {
      let jsoned = JSON.parse(json);
      if (jsoned) {
        let length = Object.keys(jsoned).length;
        if (length > 0) {
          localStorage.setItem(activeId?._id, json);
          setAllNodes([...allNodes.filter((x) => x._id !== activeId?._id), { name: activeId?.name, json: JSON.parse(json) }]);
        }
      }
    }
  }, [json, activeId, auth]);

  // useEffect(() => {
  //   if (json) {
  //     let jsoned = JSON.parse(json)
  //     if (jsoned) {
  //       let length = Object.keys(jsoned)
  //       if (length?.length) {
  //         console.log(json, 99999)
  //         // localStorage.setItem(activeId?._id, json);
  //         // setAllNodes([...allNodes.filter(x => x._id !== activeId?._id), { name: activeId?.name, json: JSON.parse(json) }])
  //       }
  //     }
  //   }
  // }, [json]) 


  useEffect(() => {
    let newCom = search && filterComponents?.length ? filterComponents : components
    let type = search && filterComponents?.length ? "all" : "some"
    console.log(newCom)
    const fetchImages = async () => {
      const urls = await Promise.all(
        newCom.filter(x => type == "all" ? x : x.block === true).map(async (imageObj) => {
          const { name } = imageObj;
          const imagePath = imageObj.block === true && imageObj.url;
          // const { default: imageUrl } = imagePath ? await import(`${imagePath}`) : { default: null }

          return {
            name: imageObj.name,
            component: imageObj.component,
            url: imagePath,

          };
        })
      );

      const groupedComponents = {};

      urls.forEach((x) => {
        const groupName = x.name.replace(/\d+$/, ''); // Remove the index at the end

        if (!groupedComponents[groupName]) {
          groupedComponents[groupName] = [];
        }

        x.parentIcon = <div style={{ padding: "20%" }}>{parents[groupName]}</div>
        groupedComponents[groupName].push(x);
      });

      setAllComponents(groupedComponents);

    };

    fetchImages();
  }, [search]);


  const checkAuthPages = nodes["ROOT"]?.data?.props['isLogin']

  const renderGroups = useMemo(() => {
    const groupComponents = Object.entries(allComponents).filter(
      ([groupName]) => groupName !== "Header" && groupName !== "Footer"
    ).map(([groupName, components]) => (
      <Group
        setExpanded={setExpanded}
        create={create}
        colorTheme={colorTheme}
        isDarkMode={isDarkMode}
        expanded={expanded}
        key={groupName}
        icon={components[0]?.parentIcon}
        name={groupName}
        components={components}
      />
    ));
    return groupComponents;
  }, [activeTab, isDarkMode, expanded, setExpanded, create, allComponents]);



  return (
    <>
      {(
        <div className="sidebarApp bulredContent overflowX-hidden" style={{ paddingTop: 0, background: isDarkMode ? connected && active ? '' : "#e9e9e910" : "#FFF", overflow: 'visiable', display: !enabled && 'none' }}>
          <ToolboxDiv
            activeComponents
            enabled={enabled}
            className="flex flex-col w-full h-full max-h-full min-h-full user-select-none transition"
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex flex-col user-select-none" id="SidebarComponentRoot"
                onClick={(event) => event.stopPropagation()}>
                  <div style={{display: !activeComponents ? 'flex' : 'none', paddingTop:'4vh'}}>
                  <h4 style={{color: '#FFF', textAlign:'center', padding:'3% 5%', background:`${colorTheme + '30'}`, width:'100%'}}>
                    {Object.keys(nodes).length <= 1 ? 'Open the component to drag an element!' : 'Select an element to start editing'}
                  </h4>
                  </div>
                  <div
                    className="bluredContent rounded-[1.5vmin]"
                    style={{
                      boxShadow:'none',
                      width:'100%',
                      zIndex: 999999,
                      transform: (closePanel) ? 'translateX(0px)' : (active && checkAuthPages) ? 'translateX(0px)' : (connected && active) ? 'translateX(0px)' : 'translateX(-200px)' ,  
                      height: '97vh',
                      transition:'transform 100ms ease',
                      background: isDarkMode ? "#1D2B4C" : '#FFF',
                      position: 'absolute',
                    }}
                  >
                    {closePanel ? <SettingsPanel/> : (active && checkAuthPages) ? React.createElement(related?.toolbar) : (connected && active) && React.createElement(related?.toolbar) }
                  </div>
                <div className="containerList" style={{display: !activeComponents && 'none', marginTop:'14vh', maxHeight:'450px', overflow:'scroll', zIndex:99999}}>
                <div style={{ borderRadius: '1.5vmin', width:'100%', backdropFilter: 'blur(10px)', background:'#e9e9e930'}} className={`shadow-sm absolute left-0 top-0 z-[999999999]`}>
                <input
                  disabled
                    placeholder="Search by title..."
                    style={{
                      alignContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      margin: '1vmin auto',
                      padding: '1vmin 1.4vmin',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: '400',
                      fontSize: '2vmin',
                      color: '#161717',
                      backgroundColor: 'transparent',
                      border: '.25vmin solid #98919190',
                      borderRadius: '2vmin',
                      outline: 'none',
                      width: '80%',
                      height:'4vmin',
                      cursor:'not-allowed',
                      userSelect:'none',
                      // pointerEvents:'none'
                    }}
                    onFocus={(e) => {
                      e.target.style.borderColor = '#007bff';
                      e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                    }}
                    onBlur={(e) => {
                      e.target.style.borderColor = '#ccc';
                      e.target.style.boxShadow = 'none';
                    }}
                  />
                    <div className='w-full text-center text-[2.2vmin]  text-[#98919190]'
                      style={{ fontWeight: '600', userSelect: 'none' }}>
                      Components
                    </div>
                    <div className="relative w-full">
                      <div className="flex w-full font-bold justify-around items-start pb-[.5Vmin] rounded-[0.15Vmin]">
                        <div onMouseDown={() => setActiveTab("Basic")}
                          style={getTabStyle("Basic", activeTab, isDarkMode, colorTheme)}>
                          Basic
                        </div>
                        {/* 
                          <div onMouseDown={() => setActiveTab("Blocks")}
                            style={getTabStyle("Blocks", activeTab, isDarkMode)}>
                            Blocks
                          </div> 
                        */}
                      </div>
                    </div>
                  </div>
                  {
      //             activeTab === "Blocks" && (
      //   <div className="flex flex-col items-end w-full h-full">
      //     <div className="flex flex-col items-end w-full h-full">
      //       {/* {renderGroups} */}
      //     </div>
      //   </div>
      // )
      }

      {activeTab === "Basic" && (
        <>
          <Group
            setExpanded={setExpanded}
            create={create}
            colorTheme={colorTheme}
            isDarkMode={isDarkMode}
            expanded={expanded}
            name={"Grid Samples"}
            icon={<img alt='controls' src='https://i.ibb.co/TwmhTKW/iconmonstr-dashboard-lined-240.png' />}
            components={components.filter(c => c.name.startsWith('Layout'))}
          />
          <Group
            setExpanded={setExpanded}
            create={create}
            colorTheme={colorTheme}
            isDarkMode={isDarkMode}
            expanded={expanded}
            noWrap
            name={"controls"}
            icon={<img alt='controls' src='https://i.ibb.co/TwmhTKW/iconmonstr-dashboard-lined-240.png' />}
            components={components.filter(x => x.type === "controls" && !x.name.startsWith('Layout'))}
          />
        </>
      )}

      {activeTab === "Widgets" && (
        <Group
          setExpanded={setExpanded}
          create={create}
          colorTheme={colorTheme}
          isDarkMode={isDarkMode}
          expanded={expanded}
          noWrap
          name={"Widgets"}
          full={true}
          icon={<img alt='controls' src='https://i.ibb.co/TwmhTKW/iconmonstr-dashboard-lined-240.png' />}
          components={components.filter(x => x.type === "widgets")}
        />
      )}

                </div>
              </div>
            </div>
          </ToolboxDiv>
        </div>
      )}


    </>
  );
};

