import React, { createContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [showSideBar, setShowSideBar] = useState(false)
    const [showFooter, setShowFooter] = useState(true)
    const [showHeader, setShowHeader] = useState(true)

    const [popUp, setPopUp] = useState(false)


  return (
    <SidebarContext.Provider value={{ showSideBar, setShowSideBar, popUp, setPopUp, showFooter, setShowFooter,
      showHeader, setShowHeader }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
