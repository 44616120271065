/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { UndoIcon } from '../../../Viewport/Editor-icons/EditorSvg';




export const DropDown = ({
    has,
    isDarkMode,
    property,
    el,
    setProp,
    node,
    propKey,
    options
}) => {

    const textTransform = ['uppercase', 'lowercase', 'capitalize']

    const textDecoration = ['underline', 'overline', 'line-through', 'blink']

    const textAlign = ['left', 'center', 'right']

    const fontWegiht = ['normal', 'bold', 'bolder', 'lighter']

    const fontFamily = ['Rubik', 'Open Sans', 'Inter', 'Roboto', 'Voces', 'Poppins', 'Sansation', 'Prompt', 'Almarai', 'Chivo',]

    const handleSliderChange = (el, has, newValue) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = `${newValue}px`;
        });
      };

      const handleInputChange = (el, has, newValue) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = `${newValue}px`;
        });
      };
      
      const handleReset = (el, has) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = null;
        });
      };

      function addSpaceBeforeCapitalLetters(name) {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2');
      }

    return (
        <div
            className='w-[100%] flex flex-column wrapper-slide justify-between my-[2vmin] rounded-[2vmin]'>
            <div className={` items-center mb-[1vmin] flex justify-start text-left w-[fit-content]`}
                style={{ fontSize: '1.8vmin', fontWeight: '500', fontFamily: 'Inter', textTransform:'capitalize',  color: isDarkMode? '#b0b0b0' : '#111f3e', }}>{addSpaceBeforeCapitalLetters(has)}</div>
            <div className='px-[1.5Vmin] mx-auto flex flex-row justify-between flex-wrap' style={{ width: '100%' }}>
            <select
              style={{
                color: isDarkMode ? '#fff' : '#000',
                backgroundColor: isDarkMode ? '#111f3e60' :  '#b0b0b040',
                fontFamily: 'Inter',
                fontWeight: '500',
                padding: '.5Vmin 1Vmin',
                fontSize: '2vmin',
                border: isDarkMode ? '.25vmin solid #e9e9e930' : '.25Vmin solid #E9E9EA',
                borderRadius: '2.5Vmin',
                margin: '.5vmin 0',
                lineHeight: '1.2em',
                // transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }}

              value={property.value || ''}
              onChange={(e) => setProp(node.id, (props) => {
                let newValue = e.target.value
                props[propKey][el][has].value = newValue
              })}
            >
              <option value="">default</option>
                {has === 'textTransform' ? textTransform.map((textTr) => (
                        <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={textTr} value={textTr}>
                            {textTr}
                      </option>
                )) : has === 'textAlign' ? textAlign.map((textAl) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={textAl} value={textAl}>
                            {textAl}
                      </option>
                )) : has === 'fontFamily' ? fontFamily.map((fontFa) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={fontFa} value={fontFa}>
                            {fontFa}
                      </option>
                )) : has === 'textDecoration' ? textDecoration.map((textDec) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={textDec} value={textDec}>
                            {textDec}
                      </option>
                )) : has === 'fontWeight' ? fontWegiht.map((fontWe) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={fontWe} value={fontWe}>
                            {fontWe}
                      </option>
                )) : null}
            </select>
            </div>
        </div>
    )
}