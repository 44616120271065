/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Button } from '../Button';


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useMemo, useState, useRef } from 'react';
import { Text } from '../Text';
import { Container } from '../../../Container/index.js';
import { Column1 } from '../MainLayouts/Column1.js';
import { Column3 } from '../MainLayouts/Column3.js';
import { ColumnLayoutSettings } from '../MainLayouts/ColumnLayoutSettings.js';
import { ArrowDivider, BookDivider, CurveDivider, SplitDivider, TiltDivider, TriangleAsymmetricalDivider, TriangleDivider, WaveDivider, WavesDivider } from '../../../editor/Viewport/Editor-icons/svgArray.js';
import MainItem from '../MainLayouts/MainItem.js';
import { useInView } from 'react-hook-inview';
import ResizableContainer from '../ResizableContainer/index.js';

const defaultProps = {
  actionState: 'default',
  childrenCount: null,
  default: {
    childrenWidth: '33%',
    className: '',
    minWidth: null,
    margin: null,
    borderStyle: null,
    borderWidth: null,
    borderColor: null,
    borderRadius: null,
    background: '#e9e9e9',
    padding: '.5vmin',
    childrenHeight: null,
    transitionDuration: null,
    transitionTimingFunction: null,
    transitionDelay: null,
    transitionProperty: null,
    cursor: null,
    filterOptions: null,
    filterValue: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor: null,
    shadowBlur: '1px',
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    width: '100%',
    height: 'auto',
    maxWidth: '100%',
    isRotateXYZ: false,
    transformStyle: 'translate',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: null,
    justifyCenter: 'center',
    alignItems: 'center',
    shapePos: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  },
  hovered: {
    childrenWidth: null,
    childrenHeight: null,
    shadowX: null,
    shadowY: null,
    shadowColor: null,
    shadowBlur: null,
    width: null,
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,
    transformStyle: 'translate',
    shapePos: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  },
  active: {
    childrenWidth: null,
    childrenHeight: null,
    shadowX: null,
    shadowY: null,
    shadowColor: null,
    shadowBlur: null,
    width: null,
    filterOptions: null,
    filterValue: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,
    transformStyle: 'translate',
    shapePos: null,
    shapePositions: {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    },

    shapes: {
      Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
      Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
      Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },

  }

};


export const Layout2 = ({
  gotChild,
  childrenCount,
  gridTemplateColumns='',
  gridTemplateRows='',
  actionState = 'default',

  style = {},
  transitionDuration,
  transitionTimingFunction,
  transitionDelay,
  transitionProperty,
  cursor,
  filterOptions,
  filterValue,
  shapePos = null,

  shapePositions = {
    Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
    Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
    Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
    Top: { top: 0, left: 0, transform: '' },
  },

  shapes = {
    Right: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#B20312', shapeArrangement: 'Underneath Section' },
    Left: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#000', shapeArrangement: 'Underneath Section' },
    Bottom: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
    Top: { shapeStyle: null, shapeWidth: '100', shapeHeight: '100', shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
  },

  background, childWidth, margin, padding, ...defaultProps }) => {

  const { id } = useNode()

  const {
    selected,
    connectors: { connect, drag },
    isDragged,
    node,
  } = useNode(node => ({
    selected: node.events.selected,
    isDragged: node.events.dragged,
    node: node
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null);

  function getColor(shapeColor) {
    return shapeColor !== null ? shapeColor : '#000';
  }

  function ShapesDisplay() {
    const shapeElements = Object.keys(shapes).map((key, index) => {
      const shape = shapes[key];
      const style = {
        width: '100%',
        position: 'absolute',
        zIndex: shape.shapeArrangement === 'Underneath Section' ? 0 : 1,
        display: key === 'Left' || key === 'Right' && 'none',
        ...shapePositions[key],
      };

      let shapeComponent;
      switch (shape.shapeStyle) {
        case 'Triangle':
          shapeComponent = <TriangleDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Curve':
          shapeComponent = <CurveDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Wave':
          shapeComponent = <WaveDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Waves Opacity':
          shapeComponent = <WavesDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Triangle Asymmetrical':
          shapeComponent = <TriangleAsymmetricalDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Tilt':
          shapeComponent = <TiltDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Arrow':
          shapeComponent = <ArrowDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Split':
          shapeComponent = <SplitDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        case 'Book':
          shapeComponent = <BookDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
          break;
        default:
          shapeComponent = null;
      }

      return (
        <div key={key} style={style}>
          {shapeComponent}
        </div>
      );
    });

    return shapeElements;
  }

  const memoizedProps = useMemo(() => ({
    ...style,
    filter: filterValue !== null ? `${filterOptions}(${filterValue})` : '',
    transitionProperty: transitionProperty || null,
    transitionDuration: `${transitionDuration}` || null,
    transitionTimingFunction: transitionTimingFunction || null,
    transitionDelay: `${transitionDelay}` || null,
  }), [defaultProps, enabled]);

  const handleMouseEnter = (e) => {
    setIsHovered(true)
  };
  const handleMouseLeave = (e) => {
    setIsHovered(false)
  };

  return (
    <MainItem
      activeBackground={activeBackground}
      isDragged={isDragged}
      isHovered={isHovered}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      className={`${defaultProps?.default?.className} ${enabled && 'helloworldsParentChildren parent-div-grid p-[.5vmin]'} relative`}
      memoizedProps={memoizedProps}
      actionState={defaultProps}
      defaultProps={defaultProps['default']}
      hoverProperties={defaultProps['hovered']}
      activeProperties={defaultProps['active']}
      ShapesDisplay={ShapesDisplay}
      connect={connect}
      enabled={enabled}
      selected={selected}
      mergedRef={mergedRef}
    >

      <Element
          gridTemplateColumns={gridTemplateColumns}
          gridTemplateRows={gridTemplateRows}
          canvas 
          display={'grid'} 
          id='random-id-400' 
          is={Container} 
          className='w-full h-full'>
        {Array.from({ length: childrenCount }, (_, index) => (

          <Element
            key={index}
            id={`random-id-${index + 1}`}
            canvas
            is={Column1}
          >
          </Element>
        ))}
      </Element>

      {enabled && <> <ResizableContainer originalProperties={defaultProps} activeBackground={activeBackground} width={defaultProps['default']?.width} height={defaultProps['default']?.height} selected={selected} childRef={mergedRef} setActiveBackground={setActiveBackground} setIsHovered={setIsHovered} isHovered={isHovered} /> </>}

    </MainItem>
  )
};
Layout2.craft = {
  displayName: "Layout grid",
  props: defaultProps,
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: ColumnLayoutSettings,
  },
};