const welcomeHeader = {
   welcomeMessage : {
    content:'Welcome To All You Can Cloud.',
    background:{value:null, type:'bg'},
    fontSize:{value:null, type:'Number'},
    lineHeight:{value:null, type:'Number'},

    fontWeight :{value:null, type:'String'},
    textDecoration :{value:null, type:'String'}, 
    fontFamily :{value:null, type:'String'},
    textTransform :{value:null, type:'String'},

    textAlign :{value:'center', type:'String'},
    color: {value:null, type:'color'},
    span: {value:'#0867FC', type:'colorSpan'},


    width:{value:'100%', type:'Number'},
    height:{value:null, type:'Number'},
    marginTop:{value:null, type:'Number'},
    marginRight:{value:null, type:'Number'},
    marginBottom:{value:null, type:'Number'},
    marginLeft:{value:null, type:'Number'},
    paddingTop:{value:null, type:'Number'},
    paddingRight:{value:null, type:'Number'},
    paddingBottom:{value:null, type:'Number'},
    paddingLeft:{value:null, type:'Number'},

    shadow:{value:null, type:'Number'},
   }
  }

export default welcomeHeader;
