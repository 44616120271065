const loginConfigTotpContents = {
    headerConfigTotp:{
        content:'Mobile Authenticator Setup',
        background:{value:null, type:'bg'},
        fontSize:{value:'24px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
  
        textAlign :{value:'center', type:'String'},
        color: {value:null, type:'color'},
  
        width:{value:null, type:'Number'},
        height:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},
  
        shadow:{value:null, type:'Number'},
        
      },

      firstContent:{
        content:'Install one of the following applications on your mobile:',
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        fontWeight: null,
        color: {value:null, type:'color'},
        textAlign :{value:'center', type:'String'},
        textDecoration: null, 
        fontFamily: null,
        textTransform: null,
  
        width:{value:null, type:'Number'},
height:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
paddingTop:{value:null, type:'Number'},
paddingRight:{value:null, type:'Number'},
paddingBottom:{value:null, type:'Number'},
paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
        
      },
      
      SecondContent:{
        content:'Open the application and scan the barcode:',
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        fontWeight: null,
        color: {value:null, type:'color'},
        textAlign :{value:'center', type:'String'},
        textDecoration: null, 
        fontFamily: null,
        textTransform: null,
  
        width:{value:null, type:'Number'},
height:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
paddingTop:{value:null, type:'Number'},
paddingRight:{value:null, type:'Number'},
paddingBottom:{value:null, type:'Number'},
paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
        
      },

      thirdContent:{
        content:'Enter the one-time code provided by the application and click Submit to finish the setup.',
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textAlign :{value:'left', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        fontWeight: null,
        color: {value:null, type:'color'},
        textAlign :{value:'center', type:'String'},
        textDecoration: null, 
        fontFamily: null,
        textTransform: null,
  
        width:{value:null, type:'Number'},
height:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
paddingTop:{value:null, type:'Number'},
paddingRight:{value:null, type:'Number'},
paddingBottom:{value:null, type:'Number'},
paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
        
      },

      fourContent:{
        content:'Provide a Device Name to help you manage your OTP devices.',
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textAlign :{value:'left', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        fontWeight: null,
        color: {value:null, type:'color'},
        textAlign :{value:'center', type:'String'},
        textDecoration: null, 
        fontFamily: null,
        textTransform: null,
  
        width:{value:null, type:'Number'},
height:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
paddingTop:{value:null, type:'Number'},
paddingRight:{value:null, type:'Number'},
paddingBottom:{value:null, type:'Number'},
paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
        
      },

      inputOtp : {
        content:'Input Otp',

        placeholder: 'Code-OTP',
        background:{value:'transparent', type:'bg'},
        borderRadius: {value:'16px', type:'Number'},

        fontSize:{value:null, type:'Number'},
        color: {value:null, type:'color'},

        width:{value:null, type:'Number'},
        height:{value:null, type:'Number'},

        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        textAlign :{value:'left', type:'String'},
        width:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
      },
  
      inputLabelOtp : {
        content:'One-time code',
        background:{value:'transparent', type:'bg'},
        fontSize:{value:null, type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
  
        textAlign :{value:'left', type:'String'},
        color: {value:null, type:'color'},
        borderRadius: {value:'16px', type:'Number'},
  
        width:{value:null, type:'Number'},
        height:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},
  
        shadow:{value:null, type:'Number'},
      },

      inputDevice : {
        content:'Device Name Input',

        placeholder: 'Device Name',
        background:{value:'transparent', type:'bg'},
        borderRadius: {value:'16px', type:'Number'},

        fontSize:{value:null, type:'Number'},
        color: {value:null, type:'color'},

        width:{value:null, type:'Number'},
        height:{value:null, type:'Number'},

        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        textAlign :{value:'left', type:'String'},
        width:{value:null, type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
      },
  
      inputLabelDevice : {
        content:'Device Name',
        background:{value:'transparent', type:'bg'},
      fontSize:{value:null, type:'Number'},
      lineHeight:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'left', type:'String'},
      color: {value:null, type:'color'},
      borderRadius: {value:'16px', type:'Number'},

      width:{value:null, type:'Number'},
      height:{value:null, type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
      },

      button: {
        content:'Submit',
        background:{value:'#0867FC', type:'bg'},
        fontSize:{value:null, type:'Number'},
        lineHeight:{value:null, type:'Number'},
        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        borderRadius: {value:'16px', type:'Number'},
        color: {value:'#fff', type:'color'},
        width:{value:'25vmin', type:'Number'},
height:{value:'4vmin', type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},
        shadow:{value:null, type:'Number'},
    },
  
}

export default loginConfigTotpContents;