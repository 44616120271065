/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewBlogSettings } from './newBlogSetting';
import 'bootstrap/dist/css/bootstrap.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Text } from '../../basic/Text';
import  { ImageC }  from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const Blog_1 = ({selected}) => {
  const {
    enabled,

  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();


const Blogs = [
  {
    header:'Lorem ipsum dolor sit amet',
    content:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img:'https://iili.io/JlHlrlt.th.jpg',
  },
  {
    header:'Lorem ipsum dolor sit amet',
    content:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img:'https://iili.io/JlHlrlt.th.jpg',
  },{
    header:'Lorem ipsum dolor sit amet',
    content:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img:'https://iili.io/JlHlrlt.th.jpg',
  },{
    header:'Lorem ipsum dolor sit amet',
    content:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img:'https://iili.io/JlHlrlt.th.jpg',
  },{
    header:'Lorem ipsum dolor sit amet',
    content:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img:'https://iili.io/JlHlrlt.th.jpg',
  },{
    header:'Lorem ipsum dolor sit amet',
    content:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img:'https://iili.io/JlHlrlt.th.jpg',
  },{
    header:'Lorem ipsum dolor sit amet',
    content:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    img:'https://iili.io/JlHlrlt.th.jpg',
  },
]

  const postsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(2);


  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = Blogs?.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(Blogs.length / postsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
    

    const styles = [
      <Element canvas is={Parent} id='parentId1' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId21' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-1' is='div' className='w-full h-full'>
      <Wrapper canvas id='random-id-2' is='container' className='container'>
        <Wrapper canvas id='random-id-3' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-4' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#000', fontSize: '46px', fontFamily: 'Rubik', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-5' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-6' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-7' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-8' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-9' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Rubik', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-10' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Rubik', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-11' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-12' is='div' >
                <Wrapper canvas id='random-id-13' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-14' is='div' 
                      key={index}
                      className={`pagination-square ${currentPage === index + 1 ? 'bg-red-500' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>
,

<Element canvas is={Parent} id='parentId12' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId13' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-15' is='div' className='w-full h-full bg-[#121826]'>
      <Wrapper canvas id='random-id-16' is='container' className='container'>
        <Wrapper canvas id='random-id-17' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-18' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#000', fontSize: '46px', fontFamily: 'Rubik', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-19' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-20' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-21' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-22' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-23' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Rubik', fontWeight: '600', color: '#fff', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-24' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Rubik', fontWeight: '400', color: '#fff' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-25' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-26' is='div' >
                <Wrapper canvas id='random-id-27' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-28' is='div' 
                      key={index}
                      className={`pagination-square2 ${currentPage === index + 1 ? 'bg-red-5002' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,

    
<Element canvas is={Parent} id='parentId14' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId15' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-29' is='div' className='w-full h-full bg-[#213156]'>
      <Wrapper canvas id='random-id-30' is='container' className='container'>
        <Wrapper canvas id='random-id-31' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-32' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#EA6EF4', fontSize: '46px', fontFamily: 'Times New Roman', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-33' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-34' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-35' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-36' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-37' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Open Sans', fontWeight: '600', color: '#fff', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-38' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Open Sans', fontWeight: '400', color: '#fff' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-39' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-40' is='div' >
                <Wrapper canvas id='random-id-41' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-42' is='div' 
                      key={index}
                      className={`pagination-square3 ${currentPage === index + 1 ? 'bg-red-5003' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,

    
<Element canvas is={Parent} id='parentId16' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId17' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-43' is='div' className='w-full h-full bg-[#DFFFF9]'>
      <Wrapper canvas id='random-id-44' is='container' className='container'>
        <Wrapper canvas id='random-id-45' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-46' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#31A993', fontSize: '46px', fontFamily: 'Zing Script Rust SemiBold Demo', fontWeight: '700', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontStyle:'italicx  ' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-47' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-48' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-49' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-50' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-51' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Montserrat', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-52' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-53' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-54' is='div' >
                <Wrapper canvas id='random-id-55' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-56' is='div' 
                      key={index}
                      className={`pagination-square4 ${currentPage === index + 1 ? 'bg-red-5004' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,
    
<Element canvas is={Parent} id='parentId18' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId19' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-57' is='div' className='w-full h-full bg-[#FFFFFF]'>
      <Wrapper canvas id='random-id-58' is='container' className='container'>
        <Wrapper canvas id='random-id-59' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-60' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#0078D3', fontSize: '46px', fontFamily: 'Rubik', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-61' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-62' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-63' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-64' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-65' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Inter', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-66' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Inter', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-67' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-68' is='div' >
                <Wrapper canvas id='random-id-69' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-70' is='div' 
                      key={index}
                      className={`pagination-square5 ${currentPage === index + 1 ? 'bg-red-5005' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,
    
<Element canvas is={Parent} id='parentId220' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId231' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-71' is='div' className='w-full h-full bg-[#FFF]'>
      <Wrapper canvas id='random-id-72' is='container' className='container'>
        <Wrapper canvas id='random-id-73' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-74' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#020212', fontSize: '46px', fontFamily: 'Voces', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-75' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-76' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-77' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-78' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-79' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Voces', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-80' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Voces', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-81' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-82' is='div' >
                <Wrapper canvas id='random-id-83' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-84' is='div' 
                      key={index}
                      className={`pagination-square6 ${currentPage === index + 1 ? 'bg-red-5006' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,

    
<Element canvas is={Parent} id='parentId242' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId253' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-85' is='div' className='w-full h-full bg-[#FBFAFF]'>
      <Wrapper canvas id='random-id-86' is='container' className='container'>
        <Wrapper canvas id='random-id-87' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-88' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#6750A4', fontSize: '46px', fontFamily: 'Roboto', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-89' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-90' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-91' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-92' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-93' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Roboto', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-94' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Roboto', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-95' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-96' is='div' >
                <Wrapper canvas id='random-id-97' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-98' is='div' 
                      key={index}
                      className={`pagination-square7 ${currentPage === index + 1 ? 'bg-red-5007' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,

    
<Element canvas is={Parent} id='parentId264' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId275' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-99' is='div' className='w-full h-full bg-[#2A2A2A]'>
      <Wrapper canvas id='random-id-100' is='container' className='container'>
        <Wrapper canvas id='random-id-101' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-102' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#FFD600', fontSize: '46px', fontFamily: 'Rum Raisin', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-103' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-104' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-105' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-106' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-107' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Rubik', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-108' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Rubik', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-109' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-110' is='div' >
                <Wrapper canvas id='random-id-111' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-112' is='div' 
                      key={index}
                      className={`pagination-square8 ${currentPage === index + 1 ? 'bg-red-5008' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,

    
<Element canvas is={Parent} id='parentId286' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId297' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-113' is='div' className='w-full h-full bg-[#F7FBFE]'>
      <Wrapper canvas id='random-id-114' is='container' className='container'>
        <Wrapper canvas id='random-id-115' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-116' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#6064D2', fontSize: '46px', fontFamily: 'Rubik', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-117' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-118' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-119' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-120' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-121' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Rubik', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-122' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Rubik', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-123' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-124' is='div' >
                <Wrapper canvas id='random-id-125' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-126' is='div' 
                      key={index}
                      className={`pagination-square9 ${currentPage === index + 1 ? 'bg-red-5009' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,

    
<Element canvas is={Parent} id='parentId308' className="w-[100%] h-auto">
      <Element canvas is={Parent} id='parentId319' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-127' is='div' className='w-full h-full bg-[#FEFBF7]'>
      <Wrapper canvas id='random-id-128' is='container' className='container'>
        <Wrapper canvas id='random-id-129' is='div' className='w-full h-full flex flex-col my-10'>
          <Wrapper canvas id='random-id-130' is='div' className='w-full mb-4' style={{display:'inline-block', color: '#A25738', fontSize: '46px', fontFamily: 'Ruluko', fontWeight: '700', textAlign: 'center' }}>
            <Text text='Blog.'/>
          </Wrapper>
          <Wrapper canvas id='random-id-131' is='div' className='w-full h-full'>
            {currentPosts.map(((blog, index) => {
              return (
                <Wrapper canvas id='random-id-132' is='div' key={index} className='min-w-[100%] w-full h-full flex flex-row flex-wrap'>
                  <Wrapper canvas id='random-id-133' is='div' style={{ flex: '30%', minWidth:'180px' }}>
                    <ImageC className='w-full h-full max-h-[450px] max-w-[420px] object-fit-cover' alt='blog-img' src={blog.img} />
                  </Wrapper>
                  <Wrapper canvas id='random-id-134' is='div' style={{ flex: '70%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Wrapper canvas id='random-id-135' is='div' style={{display:'inline-block', fontSize: '24px', fontFamily: 'Sansation', fontWeight: '600', color: '#000', margin: '0 0 20px 0' }}>
                      <Text text={blog.header} />
                    </Wrapper>
                    <Wrapper canvas id='random-id-136' is='div' style={{display:'inline-block', fontSize: '18px', fontFamily: 'Sansation', fontWeight: '400', color: '#000' }}>
                      <Text text={blog.content}/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              )
            }))}
          </Wrapper>
          <Wrapper canvas id='random-id-137' is='div' className='w-full text-center mt-4'>
            {Blogs.length > 0 && (
              <Wrapper canvas id='random-id-138' is='div' >
                <Wrapper canvas id='random-id-139' is='div' className="pagination-container">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Wrapper canvas id='random-id-140' is='div' 
                      key={index}
                      className={`pagination-square10 ${currentPage === index + 1 ? 'bg-red-50010' : ''}`}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                    </Wrapper>
                  ))}
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
    </Element>
    </Element>,
     
    ]

    
  const [sel, setSel] = useState(selected)

  function makeOdd(number) {
    setProp((prop) => {
      setSel(100);
    }, 1000);

    setTimeout(() => {
      setProp((prop) => {
        setSel(number)
      }, 1000);
    }, 100);


    
}

useEffect(() => {
  if(selected || selected >= 0) {
    makeOdd(selected);
  }
},[selected])
   
    return (
      <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
      {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
  );
}

    Blog_1.craft = {
        displayName: "Blog 1",
        props: {
        selected: 1, // here is the default value of the selected style
        length:9,
isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewBlogSettings,
        },
    };