/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { NewOurTeamSettings } from './newOurTeamSetting'
import { Text } from '../../basic/Text';
import  { ImageC }  from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const Team_1 = ({ selected }) => {

  const {
    actions: { setProp },
    connectors: {
      connect
    }
  } = useNode();


  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

  const styles = [
    <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto py-10" style={{ background: '#FFFDFB' }}>
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-400' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-401' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-[#100103] text-[46px] py-[25px] flex justify-center' id="random-id-1"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-402' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-403' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid #DBD9D9', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-100'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-404' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik' }} id="random-id-2"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik' }} id="random-id-3"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-405' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid #DBD9D9', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-101'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-406' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik' }} id="random-id-4"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik' }} id="random-id-5"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-407' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid #DBD9D9', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-102'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-408' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik' }} id="random-id-6"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik' }} id="random-id-7"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,


    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto py-10" style={{ background: '#121826' }}>
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-409' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-410' className='w-full justify-center h-full' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: 'bold', fontFamily: 'Rubik' }} className='IncreaseConversion2 text-[#100103] text-[46px] py-[25px] flex justify-center' id="random-id-8"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-411' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-412' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width: '30%', minWidth: '290px', height: '100%', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                <Element is={Parent} canvas style={{ width: '100%', height: '100%', display: 'inline-block' }} id='random-id-103'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-413' className='mx-[10px] my-4 flex flex-col align-items-start h-full'>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: 'white' }} id="random-id-9"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: 'white' }} id="random-id-10"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-414' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #FFFFFF1A', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-104'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-415' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: 'white' }} id="random-id-11"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: 'white' }} id="random-id-12"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-416' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #FFFFFF1A', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-105'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-417' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: 'white' }} id="random-id-13"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: 'white' }} id="random-id-14"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,


    <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto py-10" style={{ background: '#213156' }} >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-418' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-419' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: 'bold', fontFamily: 'Times New Roman' }} className=' text-[#EA6EF4] text-[46px] py-[25px] flex justify-center' id="random-id-15"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-420' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-421' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width: '30%', minWidth: '290px', height: '100%', boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius: '10px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-106'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-422' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: 'white' }} id="random-id-16"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: 'white' }} id="random-id-17"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-423' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #FFFFFF1A', width: '30%', minWidth: '290px', height: '100%', borderRadius: '10px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-107'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-424' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: 'white' }} id="random-id-18"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: 'white' }} id="random-id-19"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-425' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #FFFFFF1A', width: '30%', minWidth: '290px', height: '100%', borderRadius: '10px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-108'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-426' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: 'white' }} id="random-id-20"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: 'white' }} id="random-id-21"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,


    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto py-10" style={{ background: '#DFFFF9' }}>
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-427' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-428' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '100px', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className=' text-[#31A993] text-[46px] py-[25px] flex justify-center' id="random-id-22"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-429' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-430' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width: '30%', minWidth: '290px', height: '100%', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius: '10px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-109'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-431' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#031815' }} id="random-id-23"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Montserrat', color: '#031815' }} id="random-id-24"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-432' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #0825201A', width: '30%', minWidth: '290px', height: '100%', borderRadius: '10px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-110'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-433' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#031815' }} id="random-id-25"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Montserrat', color: '#031815' }} id="random-id-26"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-434' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #0825201A', width: '30%', minWidth: '290px', height: '100%', borderRadius: '10px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-111'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-435' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#031815' }} id="random-id-27"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Montserrat', color: '#031815' }} id="random-id-28"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,


    <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto py-10" style={{ background: '#FFFFFF' }}>
      <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-436' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-437' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-[#0078D3] text-[46px] py-[25px] flex justify-center' id="random-id-29"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-438' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-439' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width: '30%', minWidth: '290px', height: '100%', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-112'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-440' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#000000' }} id="random-id-30"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: '#000000' }} id="random-id-31"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-441' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #FFFFFF1A', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-113'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-442' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#000000' }} id="random-id-32"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: '#000000' }} id="random-id-33"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-443' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 4px 0px #FFFFFF1A', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-114'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-444' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#000000' }} id="random-id-34"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: '#000000' }} id="random-id-35"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,

    <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto py-10" style={{ background: '#020212' }}>
      <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-445' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-446' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: 'bold', fontFamily: 'Voces' }} className='text-[#FFFFFF] text-[46px] py-[25px] flex justify-center' id="random-id-36"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-447' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-448' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width: '30%', minWidth: '290px', height: '100%', boxShadow: '0px 0px 10px 0px #FFFFFF33' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-115'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-449' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Voces', color: '#FFFFFF' }} id="random-id-37"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Voces', color: '#FFFFFF' }} id="random-id-38"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-450' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 10px 0px #FFFFFF33', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-116'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-451' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Voces', color: '#FFFFFF' }} id="random-id-39"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Voces', color: '#FFFFFF' }} id="random-id-40"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-452' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 10px 0px #FFFFFF33', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-117'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-453' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Voces', color: '#FFFFFF' }} id="random-id-41"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Voces', color: '#FFFFFF' }} id="random-id-42"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,

    <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto py-10" style={{ background: '#FBFAFF' }}>
      <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-454' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-455' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: 'bold', fontFamily: 'Roboto' }} className=' text-[#6750A4] text-[46px] py-[25px] flex justify-center' id="random-id-43"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-456' className='w-[100%] h-full flex flex-row flex-wrap justify-center items-center' >
              <Element is={Parent} canvas id='random-id-457' className='flex justify-center my-[10px] mx-[10px] flex flex-col bg-[#6750A4]' style={{ width: '380px', height: '380px', boxShadow: '0px 0px 10px 0px #FFFFFF33', borderRadius: '20px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-118'  >
                  <ImageC className='p-4' style={{ borderRadius: '20px' }} src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-458' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#FFFFFF' }} id="random-id-44"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: 'lighter', fontFamily: 'Roboto', color: '#FFFFFF' }} id="random-id-45"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-459' className='flex justify-center my-[10px] mx-[10px] flex flex-col bg-[#6750A4]' style={{ boxShadow: '0px 0px 10px 0px #FFFFFF33', width: '380px', height: '380px', borderRadius: '20px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-119'  >
                  <ImageC className='p-4' style={{ borderRadius: '20px' }} src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-460' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#FFFFFF' }} id="random-id-46"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: 'lighter', fontFamily: 'Roboto', color: '#FFFFFF' }} id="random-id-47"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-461' className='flex justify-center my-[10px] mx-[10px] flex flex-col bg-[#6750A4]' style={{ boxShadow: '0px 0px 10px 0px #FFFFFF33', width: '380px', height: '380px', borderRadius: '20px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-120'  >
                  <ImageC className='p-4' style={{ borderRadius: '20px' }} src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-462' className='mx-[10px] my-4 flex flex-col align-items-center items-center'   >
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#FFFFFF' }} id="random-id-48"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: 'lighter', fontFamily: 'Roboto', color: '#FFFFFF' }} id="random-id-49"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,

    <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto py-10" style={{ background: '#2A2A2A' }}>
      <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-463' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-464' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400px', fontFamily: 'Rum Raisin' }} className=' text-[#FFD600] text-[46px] py-[25px] flex justify-center' id="random-id-50"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-465' className='w-[100%] h-full flex flex-row flex-wrap justify-center items-center' >
              <Element is={Parent} canvas id='random-id-466' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width: '30%', minWidth: '290px', height: '100%', boxShadow: '0px 0px 10px 0px #FFFFFF0F', border: '1px solid #FFD6001A', borderRadius: '10px 10px 0px 0px' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-121'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-467' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFFFFF' }} id="random-id-51"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: '#FFFFFF' }} id="random-id-52"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-468' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 10px 0px #FFFFFF0F', border: '1px solid #FFD6001A', borderRadius: '10px 10px 0px 0px', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-122'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-469' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFFFFF' }} id="random-id-53"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: '#FFFFFF' }} id="random-id-54"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-460' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ boxShadow: '0px 0px 10px 0px #FFFFFF0F', border: '1px solid #FFD6001A', borderRadius: '10px 10px 0px 0px', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-123'  >
                  <ImageC style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-461' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFFFFF' }} id="random-id-55"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik', color: '#FFFFFF' }} id="random-id-56"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,

    <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto py-10" style={{ background: '#F7FBFE' }}>
      <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-900' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-901' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-[#6064D2] text-[46px] py-[25px] flex justify-center' id="random-id-902"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-903' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-904' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#fff', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%', borderRadius: '10px 10px 0px 0px' }} id='random-id-905'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-906' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik' }} id="random-id-907"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik' }} id="random-id-908"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-909' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#fff', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%', borderRadius: '10px 10px 0px 0px' }} id='random-id-910'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-911' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik' }} id="random-id-912"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik' }} id="random-id-5"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-913' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#fff', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%', borderRadius: '10px 10px 0px 0px' }} id='random-id-914'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' style={{ borderRadius: '10px 10px 0px 0px' }} src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-915' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Rubik' }} id="random-id-916"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Rubik' }} id="random-id-917"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,


    <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto py-10" style={{ background: '#FFFFFF' }}>
      <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-918' className='max-w-[1300px]' >
          <Element is={Parent} canvas id='random-id-919' className='w-full h-full flex justify-center ' style={{ display: 'flex', flexDirection: 'column' }}>
            <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '400', fontFamily: 'Ruluko' }} className='text-[#A25738] text-[46px] py-[25px] flex justify-center' id="random-id-920"  >
              <Text text="Our Team" />
            </Element>
            <Element is={Parent} canvas id='random-id-921' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Element is={Parent} canvas id='random-id-922' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '30px 0', background: '#FEFBF7', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%', borderRadius: '0px 30px 0px 0px' }} id='random-id-923'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' style={{ borderRadius: '0px 30px 0px 0px' }} src='https://i.ibb.co/py3SMt4/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-924' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Sansation' }} id="random-id-925"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Sansation' }} id="random-id-926"  >
                    <Text text="CEO of Company" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-927' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '30px 0', background: '#FEFBF7', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%', borderRadius: '0px 30px 0px 0px' }} id='random-id-928'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' style={{ borderRadius: '0px 30px 0px 0px' }} src='https://i.ibb.co/QQQ2ZSz/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-929' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Sansation' }} id="random-id-930"  >
                    <Text text="Jane Austen" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Sansation' }} id="random-id-931"  >
                    <Text text="Developement Team Lead" />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-932' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '30px 0', background: '#FEFBF7', width: '30%', minWidth: '290px', height: '100%' }}>
                <Element is={Parent} canvas style={{ display: 'inline-block', height: '100%', width: '100%', borderRadius: '0px 30px 0px 0px' }} id='random-id-933'  >
                  <ImageC className='max-h-[280px] h-[214px] w-full object-cover' style={{ borderRadius: '0px 30px 0px 0px' }} src='https://i.ibb.co/rpqJtLk/image.png' />
                </Element>
                <Element is={Parent} canvas id='random-id-934' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '24px', fontWeight: 'bold', fontFamily: 'Sansation' }} id="random-id-935"  >
                    <Text text="Mark Twin" />
                  </Element>
                  <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '300px', fontFamily: 'Sansation' }} id="random-id-936"  >
                    <Text text="Teach Lead" />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>
        </Element> </Element>
    </Element>,
  ]


  const [sel, setSel] = useState(selected)

  function makeOdd(number) {
    setProp((prop) => {
      setSel(100);
    }, 1000);

    setTimeout(() => {
      setProp((prop) => {
        setSel(number)
      }, 1000);
    }, 100);



  }

  useEffect(() => {
    if (selected || selected >= 0) {
      makeOdd(selected);
    }
  }, [selected])


  return (
    <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
      {sel == 100 ? (
        <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <BounceLoader
            size={100}
            color={"white"}
            loading={true}
          />
        </div>
      ) : (
        styles[sel]
      )}
    </div>
  );
}

Team_1.craft = {
  displayName: "Team 1",
  props: {
    selected: 1, // here is the default value of the selected style
    length: 9,
    isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewOurTeamSettings,
  },
};

