/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor } from '@craftjs/core';
import { Box, Modal } from '@mui/material';
import { browserName, osName } from 'react-device-detect';
import React, { useCallback, useEffect, useState } from 'react';
import { useOptionsContext } from '../../../OptionsContext';
import { DescDevice, EditorSvg, EditorSvgActive, MopileDevice, TabDevice, UndoIcon, ToggleSun, ToggleMoon, EyeIcon, DownloadIcon, ThreeDots, ResetIcon, ErrorIcon, StepperRunner, EditNameIcon } from './Editor-icons/EditorSvg.js';
import './ToolBox.css'
import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from '@apollo/client';
import addFeedbackMutation from '../../../graphql/mutations/feedback/addFeedbackMutation.js';
import { DownloadModal } from './modals/downloadModal.js';
import { useActiveIdContext } from '../../../ActiveIdContext.js';
import { TreeView } from './TreeView.js';
import MouseOverPopover from '../../../Tutourial/PopOver.js';
import Tuturial from '../../../Tutourial/Tuturial.js';
import { Stepper } from '../../../Tutourial/Stepper.js';
import { useSidesContext } from '../../../SideContext.js';
import { ConfirmModal } from './modals/confirmModal.js';
import { useAppProps } from '../../../DarkModeContext.js';
import { ArrowToRight } from './Editor-icons/svgArray.js';

export const Header = ({
  preview,
  view,
  renderMarkup,
  auth, outStepper }) => {

  const {
    selected,
    json,
    actions: { selectNode, setActive },
  } = useEditor((state, query) => {
    return {
      selected: state.events.selected,
      json: query.serialize(),
    };
  });

  const [codeContent, setCodeContent] = useState("")
  const [downloadModal, setDownloadModal] = useState(false);
  const [hiddenAlert, setHiddenAlert] = useState(false);
  const [DotsClicked, setDotsClicked] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [keysInfo, setKeysInfo] = useState(false);

  const [signUp, setSignUp] = useState(false);

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId } = state;

  async function loadPageContent(page) {
    setActiveId({ _id: page._id, name: page.name });
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Simulate loading content
    return page;
  }

  async function savePage(page) {
    // Simulate saving page logic
    renderMarkup(codeContent, page.name, json);

    await new Promise(resolve => setTimeout(resolve, 1000));

    return page;
  }

  async function setActivePageAndSave(page) {
    const pageWithContent = await loadPageContent(page);
    await savePage(pageWithContent);
  }

  async function processPages(pages) {
    for (const page of pages) {
      await setActivePageAndSave(page);
    }
  }

  const {
    connected,
    isDarkMode,
    toggleDarkMode,
    setCustomizeApp,
    customizeApp,
    setColorTheme,
    colorTheme,
    width,
    setWidth,
    setWorking,
    isLoading,
    activeComponents, setActiveComponents, setTuturial
  } = useAppProps();


  const [addFeedback, { error: addFeedbackError, data: addFeedbackData, loading: addFeedbackLoading }] = useMutation(addFeedbackMutation);

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const { enabled, canUndo, canRedo, actions } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));

  function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  function findNodeById(nodeId, nodes) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node._id === nodeId) {
        return node;
      }
      if (node.children) {
        const childNode = findNodeById(nodeId, node.children);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  }

  const flattenTree = (tree) => {
    const result = [];

    function traverse(node) {
      result.push(node);
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => traverse(child));
      }
    }

    tree.forEach(node => traverse(node));

    return result;
  };

  const openDownloadModal = () => setDownloadModal(true);
  const closeDownloadModal = () => setDownloadModal(false);

  const [downloadingProcess, setDownloadingProcess] = useState(false);
  const [IPAdress, setIPAddress] = useState(null);

  const DotsRef = useRef(null);
  const DotsContain = useRef(null);

  // Keys Shortcut
  useHotkeys('shift+d', () => setDownloadModal(true));
  useHotkeys('shift+p', () => actions.setOptions((options) => (options.enabled = !enabled)));
  useHotkeys('ctrl+z', () => actions.history.undo());
  useHotkeys('ctrl+y', () => actions.history.redo());
  useHotkeys('shift+m', () => toggleDarkMode());
  useHotkeys('shift+r', () => {
    localStorage.clear();
    window.location.reload(false);
    setDotsClicked(false);
  });
  useHotkeys('shift+c', () => setActiveComponents(!activeComponents));
  useHotkeys('shift+l', () => setWidth('100%'));
  useHotkeys('shift+t', () => setWidth('800px'));
  useHotkeys('shift+s', () => setWidth('500px'));

  const handleWidth = useCallback((newWidth) => {
    setWidth(newWidth);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (DotsRef.current && !DotsRef.current.contains(event.target) && DotsContain.current && !DotsContain.current.contains(event.target)) {
        setDotsClicked(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [DotsRef]);

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip));
  }, []);

  const downloading = () => {
    addFeedback({
      variables: {
        rate: `${rateValue}`,
        position: positionValue,
        feature: wishMessage,
        bug: bugsMessage,
        ip: IPAdress,
        device: osName,
        browser: browserName,
      }
    });

    setTimeout(() => {
      setDownloadingProcess(true);
      actions.setOptions((options) => (options.enabled = true));
    }, 200);

    setTimeout(() => {
      setDownloadingProcess(false);
      actions.setOptions((options) => (options.enabled = true));
      setDownloadModal(false);
    }, 4000);

    actions.setOptions((options) => (options.enabled = false));
    processPages(flattenTree([treeData[0]]));
  };

  const [rateValue, setRateValue] = useState(2);
  const [positionValue, setPositionValue] = useState('');
  const [wishMessage, setWishMessage] = useState('');
  const [bugsMessage, setBugsMessage] = useState('');
  const [open, setOpen] = useState(false);
  const colorPickerForTheme = ['#0867FC', '#6c757d', '#1c7430', '#6f42c1', '#008080'];

  const handleSliderChange = (e) => {
    const sliderValue = e.target.value;
    const index = sliderValue;
    const color = colorPickerForTheme[index];
    setColorTheme(color);
  };


    const [hovered, setHovered] = useState({})
  
    const onMouseEnter = (id) => {
      setHovered((prev) => ({...prev, [id]:true}))
    }
    const onMouseLeave= (id) => {
      setHovered((prev) => ({...prev, [id]:false}))
    }
 


  const { review, setReview, setFinish,
    topArea, settopArea,
    bottomArea, setBottomArea,
    rightArea, setRightArea,
    leftArea, setLeftArea,
   } = useSidesContext();

   useEffect(() => {
    if (topArea) {
      const targetDiv = document.getElementById('row1');
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [topArea]); // Runs whenever `topArea` changes


  useEffect(() => {
    if (bottomArea) {
      const targetDiv = document.getElementById('bottom1');
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [bottomArea]); // Runs whenever `bottomArea` changes
  


  return (
    <>
      {confirmModal && (
        <ConfirmModal
          open={confirmModal}
          setOpen={setConfirmModal}
          onClose={() => {
            localStorage.clear();
            window.location.reload(false);
            setConfirmModal(false);
            setDotsClicked(false);
          }}
          text="Are you sure you want to reset the page?"
        />
      )}

      <div
        id="HeaderRoot"
        className={`p-[2vmin] pb-0 header-root ${isLoading ? 'header-root-loading' : ''} ${enabled ? 'header-root-enabled' : 'header-root-disabled'}`}
      >
        {/* {tuturial && (
          <div
          onClick={(e) => {
            e.stopPropagation()
          }}
            className={`tutorial-container ${!review ? 'px-[2%]' : ''} ${isDarkMode ? 'tutorial-container-dark-mode' : 'bg-[#e9e9e980]'}`}
            style={{ borderRadius: tuturial ? '2.5vmin' : '' }}
          >
            <div className="tutorial-stepper">
              <TopArea outStepper={outStepper}/>
            </div>
          </div>
        )} */}

          <div
            style={{ zIndex: 9999 }}
            className={`header-content ${isDarkMode ? 'header-content-dark-mode' : 'header-content-light-mode'}`}
          >
            <div className="header-content-blurred"></div>


            <div className='navbar-conteiner'>
              {enabled ? <>
                <div className="auth-header">
                  {/* When not authenticated (auth = false) */}
                  {!auth ? (
                    <div className="auth-false-content">
                      <div style={{ userSelect: 'none', position:'relative' }} className="logo-container">
                        <img 
                          style={{ width: 'auto', height: 'auto', position:'absolute', zIndex:9999999999999, top:'-2vmin', right:'-.7vmin' }}
                          className='absolute w-full h-full' src='https://static.vecteezy.com/system/resources/previews/010/175/827/original/3d-birthday-hat-object-free-png.png'/>
                        <img
                          src="https://file.rendit.io/n/hgQeGwikg5Hh6qeQRkUN.svg"
                          id="Logo"
                          alt="Logo"
                          style={{ width: '4vmin', height: '4vmin' }}
                        />
                      </div>
                      <div style={{ userSelect: 'none' }} className="dev-version">Dev1.1.0</div>
                    </div>
                  ) : (
                    <div
                      className="auth-true-content"
                      onClick={() => {
                        window.location.href = 'https://admin.allyoucancloud.com/admin/dashboard';
                      }}
                    >
                     <ArrowToRight fill={isDarkMode? '#b0b0b0' : '#111f3e'}/>
                    </div>
                  )}
                </div>
                <div className="flex flex-row align-items-center h-full">
                  <div className="flex flex-column align-items-center h-full">
                    <div
                      className="editor-button"
                      onClick={() => {
                        setOpen(false);
                        selectNode(null);
                        setActiveComponents(true)
                        if(!connected && activeComponents) {
                          setActiveComponents(false)
                        }
                      }}
                    >
                      <div className="editor-button-content">
                        <div
                          title="Component"
                          className="editor-title"
                          id="EditorRoot"
                        >
                          <div className="editor-title-inner">
                            <div
                            style={{color: activeComponents ? colorTheme : '#98919190'}}
                              className={`component-text`}
                            >
                              <EditorSvgActive
                                fill={!activeComponents ? '#B0B0B0' : colorTheme}
                                width="3.5Vmin"
                                height="3.5Vmin"
                              />
                              <div className="ml-[1vmin]">{'Component'}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='relative'>
                    <TreeView
                      open={open}
                      setOpen={setOpen}
                      auth={auth}
                      data={treeData}
                      activeComponents={activeComponents}
                      setActiveComponents={setActiveComponents}
                      setWorking={setWorking}
                    />
                  </div>
                 
                </div>

              </> : null}


              {enabled ? (
                <div className="hideMobileBarAll flex flex-row items-center container ">
                  <div
                    className={`toggleDarkMode ${isDarkMode ? 'dark-mode' : 'light-mode'} relative toggle-style`}
                    onClick={toggleDarkMode}
                  >
                    <div 
                    style={{background: !isDarkMode ? '' : colorTheme}}
                    className={`moon-icon`}>
                      <ToggleMoon fill={isDarkMode ? '#fff' : '#B0B0B0'} />
                    </div>
                    <div 
                    style={{background: isDarkMode ? '' : colorTheme}}
                    className={`sun-icon`}>
                      <ToggleSun width="2.9vmin" height="3vmin" fill={!isDarkMode ? '#fff' : '#B0B0B0'} />
                    </div>
                    <div className='absolute top-0 left-[-3.5vmin] h-full flex items-center'>
                    <div onClick={(e) => {
                      e.stopPropagation()
                      setCustomizeApp(!customizeApp)
                    }}>
                      <EditNameIcon fill={'#0867FC'} stroke='#0867FC' width='2.5vmin' height='2.5vmin'/>
                    </div>
                    </div>
                      <div onClick={(e) => e.stopPropagation()} style={{display: customizeApp ? 'flex' : 'none', background:colorTheme + '30', boxShadow:'2px 2px 5px #16171730'}} className='bluredContent absolute w-[20vmin] top-[100%] left-[-50%] mt-[1vmin] p-[1vmin] rounded-[1.5vmin]'>
                      <input
                        type="range"
                        min="0"
                        max="4"
                        defaultValue={colorTheme}
                        onChange={handleSliderChange}
                      />
                      </div>
                  </div>

                  <div className="width-controls flex flex-row justify-around gap-[3%] items-center">
                    <div className='flex-1 max-w-[fit-content] gap-[2%] flex flex-row justify-around'>
                    <div 
                    onMouseEnter={() => onMouseEnter('top')}
                    onMouseLeave={() => onMouseLeave('top')}
                    className='text-center' 
                    style={{background: topArea ? colorTheme + '40'  : hovered['top'] ? colorTheme + '40' : colorTheme + '20', color: topArea ? '#FFF' : hovered['top'] ? '#FFF' : colorTheme , userSelect:'none', border:'.25vmin solid #e9e9e990', width:'12vmin', borderRadius:'1vmin', cursor:'pointer'}} onClick={() => {
                      setFinish(true)
                      settopArea(!topArea)
                    }}>Top Area</div>
                     <div 
                    onMouseEnter={() => onMouseEnter('left')}
                    onMouseLeave={() => onMouseLeave('left')}
                    className='text-center' 
                    style={{background: leftArea ? colorTheme + '40'  : hovered['left'] ? colorTheme + '40' : colorTheme + '20', color: leftArea ? '#FFF' : hovered['left'] ? '#FFF' : colorTheme , userSelect:'none', border:'.25vmin solid #e9e9e990', width:'12vmin', borderRadius:'1vmin', cursor:'pointer'}} onClick={() => {
                      setFinish(true)
                      setLeftArea(!leftArea)
                    }}>Left Area</div>
                    <div 
                    onMouseEnter={() => onMouseEnter('bottom')}
                    onMouseLeave={() => onMouseLeave('bottom')}
                    className='text-center' 
                    style={{background: bottomArea ? colorTheme + '40'  : hovered['bottom'] ? colorTheme + '40' : colorTheme + '20', color: bottomArea ? '#FFF' : hovered['bottom'] ? '#FFF' : colorTheme , userSelect:'none', border:'.25vmin solid #e9e9e990', width:'12vmin', borderRadius:'1vmin', cursor:'pointer'}}
                    onClick={() => {
                      setFinish(true)
                      setBottomArea(!bottomArea)
                    }}
                    >Bottom Area</div>
                    {/* <div 
                    onMouseEnter={() => onMouseEnter('right')}
                    onMouseLeave={() => onMouseLeave('right')}
                    className='text-center' 
                    style={{background: hovered['right'] ? colorTheme + '40' : colorTheme + '20', color: hovered['right'] ? '#FFF' : colorTheme , userSelect:'none', border:'.25vmin solid #e9e9e990', width:'12vmin', borderRadius:'1vmin', cursor:'pointer'}} onClick={() => {
                      setFinish(true)
                      setRightArea(!rightArea)
                    }}>Right Area</div> */}
                    </div>
                      {/* <MouseOverPopover 
                      onClick={() => setTuturial(!tuturial)}
                      title="The Layout Manager 'Stay Tuned!!'" /> */}
                    <div className='flex flex-row justify-center flex-1 gap-[3%] items-center'>
                    <div onClick={() => handleWidth('100%')} className="width-option.full width-100">
                      <div className="flex justify-center items-center">
                        <DescDevice
                          fill={isDarkMode ? (width == '100%' ? colorTheme : '#B0B0B0') : width == '100%' ? `${colorTheme + '80'}` : '#98919190'}
                        />
                      </div>
                    </div>

                    <div onClick={() => handleWidth('800px')} className="width-option.pad width-800">
                      <div className="mx-auto my-auto">
                        <TabDevice
                          fill={isDarkMode ? (width == '800px' ? colorTheme : '#B0B0B0') : width == '800px' ? `${colorTheme + '80'}` : '#98919190'}
                        />
                      </div>
                    </div>

                    <div onClick={() => handleWidth('500px')} className="width-option.mop width-500">
                      <div className="mx-auto my-auto">
                        <MopileDevice
                          fill="none"
                          stroke={isDarkMode ? (width == '500px' ? colorTheme : '#B0B0B0') : width == '500px' ? `${colorTheme + '80'}` : '#98919190'}
                        />
                      </div>
                    </div>
                    </div>
                  </div>  

                  {/* Undo and redo buttons */}
                  <div className="flex flex-row justify-end items-center undo-redo">
                    <div className="flex flex-row w-[50] h-auto justify-center undo-redo-actions">
                      <div
                        onClick={() => (canUndo ? actions.history.undo() : null)}
                        style={{background: canUndo ? colorTheme + '30' : '#e9e9ea99'}}
                        className={`undo-btn mx-[2%]`}
                      >
                        <UndoIcon fill={isDarkMode ? (canUndo ? colorTheme : '#B0B0B0') : '#FFFFFF'} />
                      </div>
                      <div
                        onClick={() => (canRedo ? actions.history.redo() : null)}
                        style={{ transform: 'scaleX(-1)', background: canRedo ? colorTheme + '30' : '#e9e9ea99' }}
                        className={`redo-btn mx-[2%]`}
                      >
                        <UndoIcon fill={isDarkMode ? (canRedo ? colorTheme : '#B0B0B0') : '#FFFFFF'} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row justify-between w-[100%] items-center"></div>
              )}



              <div className="wrapper-btns">
                <div className='flex justify-center items-center pr-[1vmin]' >
                <div
                  onClick={() => {
                    setReview(prevReview => !prevReview);
                    actions.setOptions((options) => (options.enabled = !enabled))

                  }}
                  className={`disabled-button hideMobileBarAll btn-tool ${isDarkMode ? "button-outline-dark" : 'button-outline-light'} flex flex-col justify-center shrink-0 items-center `}
                  id="Button2"
                >
                  <div
                    className="text-center in-btn font-bold flex justify-center"
                    id="Label1"
                    style={{ fontFamily: 'Inter' }}
                  >
                    {enabled ? <div className='mx-auto' style={{color: colorTheme}}>Review</div> : null}
                  </div>
                </div>
                <div className='disabled-button'>/</div>
                <div
                  // onClick={() => {
                  //   actions.setOptions((options) => (options.enabled = false))
                  //   processPages(flattenTree(treeData))

                  //   // renderMarkup(codeContent, activeId, treeData)
                  // }}
                  // onClick={() => {
                  //   auth ? buildTheme() : setDownloadModal(true)
                  //  downloading()
                  // }}
                  className={`disabled-button notAllowed btn-tool ${isDarkMode ? "button-outline-dark" : 'button-outline-light'}  `}
                  id="Button1"
                >
                  <div
                    title={auth ? "Build" : "Save soon"}
                    className="text-center in-btn font-bold text-[#989191] flex justify-center"
                    style={{
                      fontFamily: 'Inter',
                      // transition: 'all .3s ease-in-out' 
                    }}
                    id="Label"
                  >
                    {enabled ? <div className='mx-auto' style={{ transform: auth ? "rotate(180deg)" : "" }}>
                      Download
                      </div> : null}
                    {/* <div className='text-in-btn px-[.5Vmin]' >{auth ? "Build" : "Save soon"}</div> */}
                  </div>
                </div>
                </div>
                {enabled ? <>
                  <div
                    ref={DotsContain}
                    className={`dots-btn my-auto bluredContent`}
                    style={{ fontWeight: '700', fontFamily: 'Inter', minWidth: '5vmin', boxShadow: 'none', background: isDarkMode ? colorTheme + '40' : colorTheme + '30' }}
                    id="Label"

                    onClick={() => {
                      setDotsClicked(!DotsClicked)
                    }}

                  >
                    <ThreeDots />
                  </div>
                  <div 
                    ref={DotsRef}
                    style={{overflow:'hidden'}}
                    className={`bluredContent flex flex-col shadow-sm rounded-[2vmin] w-[17Vmin] top-[7vmin] right-[5vmin] dot-container ${DotsClicked ? 'dots-expanded' : 'dots-collapsed'} ${DotsClicked ? 'opacity-100' : 'opacity-0'}`}>

                    {/* <div className="download-in-menu shadow-sm" id="Button1">
                      {enabled ? <div className="mx-[.5vmin]"><DownloadIcon stroke={isDarkMode ? '#fff' : '#000'} /></div> : null}
                    </div> */}

                    <div style={{overflow:'hidden', background: isDarkMode ? colorTheme + '60' : colorTheme + '30'}} className={`w-full p-[5%] rounded-[2vmin] items-center flex flex-column h-full`}>
                      <div
                      className={`shortcut-text`}
                        onClick={() => setKeysInfo(true)}
                        >
                        <div >Shortcut Keys</div>
                        <div><ErrorIcon fill={`#FFF`} /></div>
                      </div>

                      <div
                      className={`reset-text`}
                        onClick={() => {
                          setConfirmModal(true);
                          // localStorage.clear()
                          // window.location.reload(false);
                          // setDotsClicked(false)
                        }}
                        >
                        <div>Reset</div>
                        <div><ResetIcon fill={`#FFF`} /></div>
                      </div>
                    </div>
                  </div>


                </> : <div
                  className=" flex flex-col justify-center w-[120px] shrink-0 h-8% items-center rounded-[`30%`]"
                  id="Button1"
                >

                </div>}
              </div>
            </div>


            {keysInfo && <Modal open={keysInfo}
              onClose={() => setKeysInfo(false)}>
              <Box className='modal-container'>
                <div className='shortCutIem'>ctrl+z undo</div>
                <div className='shortCutIem'>ctrl+y redu</div>
                <div className='shortCutIem'>shift+r reset</div>
                <div className='shortCutIem'>shift+c open components</div>
                <div className='shortCutIem'>shift+d download the page</div>
                <div className='shortCutIem'>shift+t tablet screen size</div>
                <div className='shortCutIem'>shift+s mobile screen size</div>
                <div className='shortCutIem'>shift+m change to dark mode</div>
                <div className='shortCutIem'>shift+l desktop screen size</div>
                <div className='shortCutIem'>shift+p enable and disabled edit mode</div>
              </Box>
            </Modal>}
            {downloadModal && (
              <>
                <DownloadModal
                  open={downloadModal}
                  onClose={closeDownloadModal}
                  downloadingProcess={downloadingProcess}
                  rateValue={rateValue}
                  setRateValue={setRateValue}
                  positionValue={positionValue}
                  setPositionValue={setPositionValue}
                  bugsMessage={bugsMessage}
                  setBugsMessage={setBugsMessage}
                  wishMessage={wishMessage}
                  setWishMessage={setWishMessage}
                  downloading={downloading}
                  setDownloadModal={setDownloadModal}
                />
              </>
            )}
          </div>
      </div>
      {!enabled &&
        <div onClick={() => {
          setReview(prevReview => !prevReview);
          actions.setOptions((options) => (options.enabled = !enabled))
        }} className='fixed top-0 right-[1%] cursor-pointer px-[1%] py-[.5vmin] hover:bg-[#0867FC40] bg-[#98919190] hover:text-[#0867FC] text-[#FFF]' style={{ zIndex: 999999999, borderRadius:'0 0 .5vmin .5vmin' }}>Back to edit mode</div>
      }
      {/* {!enabled && <div onMouseOver={() => setHiddenAlert(true)} onMouseLeave={() => setHiddenAlert(false)} className='h-[2vmin] bg-[transparent] w-full absolute top-0 left-0' style={{ zIndex: 21, height:preview ? "100%":"2vmin" }}>
        {hiddenAlert && <div
          // style={{ transition: 'all 0.3s ease-in-out' }}
          onClick={() => {
            setReview(prevReview => !prevReview);
            {
            if(preview) {
              window.parent.location.href = `https://app.allyoucancloud.com/build/${view}`
            } else {
              actions.setOptions((options) => (options.enabled = !enabled))
            }
          }}}
          className='flex justify-center pt-[2vmin]'>
          <div style={{position:"relative"}} className={`cursor-pointer hover:bg-[#0078D3A1] bg-[#0078d352] border-[.25vmin] border-[#fff] rounded-full p-[2vmin]`}>
            {preview ? <Creation fill='#fff' height='3vmin' width='3vmin' /> : <CloseIcon fill='#fff' height='3vmin' width='3vmin' />}
            <div style={{position:"absolute", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"8px", padding:"3px 3px", bottom:"-30px", background:"white"}}>Click to edit</div>
          </div>
        </div>
        }
      </div>
      } */}
    </>
  );
};
