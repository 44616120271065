import React, { useState } from 'react';
import { StarIcon } from '../Editor-icons/svgArray';
import { useAppProps } from '../../../../DarkModeContext';

export const Item = ({ auth, authParent, authChild, index, name, small, expanded, setExpanded, title, key, onOpen, onClose, dragRef, img }) => {

  const [over, setOver] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const { isDarkMode, colorTheme } = useAppProps()

  if (index?.name === 'Layout Grid') {
    return;
  }

// BG color style logic
const bgColorStyle = {
  background: expanded
    ? isDarkMode
      ? auth
        ? '#e9e9e960'  // If expanded, isDarkMode and auth are true
        : `${colorTheme + '50'}`  // If expanded, isDarkMode but no auth
      : auth
      ? `${colorTheme + '80'}`  // If expanded, no isDarkMode but auth is true
      : `${colorTheme + '70'}`  // If expanded, no isDarkMode, no auth
    : `${colorTheme + '90'}`,  // If not expanded, same background color with lower opacity
};

// Hover effect style logic
const hoverStyle = {
  background: isHovered
    ? (auth ? (isDarkMode ? '#e9e9e960' : `${colorTheme + '80'}`) : `${colorTheme + '70'}`)
    : bgColorStyle.background,  // Default to bgColorStyle when not hovered
};

// Handle mouse enter and leave to trigger hover effect
const onMouseEnter = () => setIsHovered(true);
const onMouseLeave = () => setIsHovered(false);

  return (
    <div
      ref={dragRef}
      key={key}
      onClick={onOpen}
      onMouseDown={onClose}
      style={{
        position: 'relative',
        width: small ? 'fit-content' :  '100%',
        cursor: img ? 'move' : 'pointer',
        minHeight: 'fit-content',
        padding: small ? '.25em .5em' : '1vmin 1.5vmin',
        display: 'flex',
        alignItems: 'center',
        margin: img && '2%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        borderRadius: '.8vmin',
        overflow: 'hidden', 
      }}
    >
      <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
        className={`bluredContent`}
        style={{
          ...hoverStyle,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      />
      <div
        style={{
          fontSize: small ? '1.2vmin' : '2vmin',
          alignItems: 'center',
          color: '#FFFFFF', 
          fontWeight: 600, 
          textAlign: 'center',
          textTransform: 'capitalize',
          width: '100%',
          userSelect: 'none',
          pointerEvents: 'none',
          zIndex: 1,
          borderRadius: '.8vmin', 
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {img &&
          <div style={{
            width: '55%'
          }} className='flex justify-center items-center items-center h-full'>
            <img
              className='w-[3.5vmin] h-[3.5vmin]'
              alt='basic'
              src={img}
            />
          </div>
        }
        <div className='w-full h-full' style={{ textAlign: index ? 'left' : 'center' }}>
          {title === 'Quick Style' && <span className='inline-flex'><StarIcon width='2vmin' height='2vmin' fill='yellow' /></span>} {title}
          {index?.title === 'Div' && <span className='ml-[1vmin] text-[yellow]'>Main</span>}
        </div>
      </div>
    </div>
  );
};
