import { useState } from "react";
import { ADD_OPTION, useOptionsContext } from "../../OptionsContext";
import { Circle1 } from "../iconsSvg";
import { PenEditIcon } from "../../components/editor/Viewport/Editor-icons/svgArray";
import SelectionBox from "../../components/editor/Viewport/Pop-up/Images-Icons-Modal";
import { category } from "../../components/selectors/basic/Image/ImageSettings";
import { EyeIcon } from "../../components/editor/Viewport/Editor-icons/EditorSvg";
import SmallWidgets from "./HandleWidgets";
import NextButton from "../SettingsArea/NextButton";
import { useSidesContext } from "../../SideContext";
import { useActiveIdContext } from "../../ActiveIdContext";

const TopArea = () => {

    const { 
        TopProps,
        pageSettings,
        setPageSettings,
        } = useSidesContext();

    const {
        rows,
        setRows,
    } = TopProps

    const [expandedRow, setExpandedRow] = useState('1');
    const { state, dispatch: setActiveId } = useActiveIdContext();
        const { activeId } = state;
    

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;
    const [activeTab, setActiveTab] = useState('widgets');
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const TreeView = ({ treeData, idDispatch, setActiveId }) => {
        const [editNodeId, setEditNodeId] = useState(null);
        const [newNodeName, setNewNodeName] = useState('');
        const [iconsSquare, setIconsSquare] = useState(false);

    
        const handleChangeName = (id, node, icon) => {
            const updatedName = newNodeName.trim() !== '' ? newNodeName : node;
            idDispatch({
                type: 'CHANGE_NAME',
                payload: { _id: id, name: updatedName, icon: icon },
            });
    
            setEditNodeId(null);
            setNewNodeName('');
            setActiveId({ _id: id, name: updatedName });
        };

        const handleEditChildIcon = (selectedIcon) => {
            if (!selectedIcon) {
                return null;
            }
                if (selectedIcon) {
                    idDispatch({
                        type: 'CHANGE_NAME',
                        payload: { _id: editNodeId._id, name: editNodeId.name, icon: selectedIcon },
                      });
                }
        };


        const handleIconImageSelect = (selectedUrl) => {

            if (Array.isArray(selectedUrl) && selectedUrl.length) {
                handleEditChildIcon(selectedUrl)
            } else {
                handleEditChildIcon(selectedUrl)
            }
            setIconsSquare(false);
        };
    
        return (
            <div>
                {treeData.map((node) => {
                    const isEditing = editNodeId?._id === node?._id;
    
                    return (
                        <div className='flex w-full px-[1vmin] justify-between text-[1.6vmin]' key={node?._id}>
                            {isEditing ? (
                                <div className="flex w-full items-center">
                                <div className='w-[fit-content] cursor-pointer' onClick={(e) => {
                                    e.stopPropagation();
                                    setIconsSquare(true)
                                    }}>
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="img"
                                        viewBox={node?.icon?.viewBox}
                                        width={'1.5vmin'}
                                        height={'1.5vmin'}
                                        fill={node?.icon?.fill || '#0867FC'}
                                    >
                                        <path d={node?.icon?.path} />
                                    </svg>
                                    {iconsSquare && (
                                        <SelectionBox
                                            category={category}
                                            setIconsSquare={setIconsSquare}
                                            iconsSquare={iconsSquare}
                                            onSelect={handleIconImageSelect}
                                        />
                                    )}
                                </div>
                                <input
                                    className='ml-[3%] text-[#000]'
                                    onChange={(e) => setNewNodeName(e.target.value)}
                                    placeholder='New name'
                                    value={newNodeName}
                                    autoFocus
                                />
                                </div>
                            ) : (
                                <div className="w-full flex items-center">
                                    <div className='w-[fit-content] cursor-pointer' onClick={(e) => {
                                            e.stopPropagation();
                                            }}>
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                role="img"
                                                viewBox={node?.icon?.viewBox}
                                                width={'1.5vmin'}
                                                height={'1.5vmin'}
                                                fill={node?.icon?.fill || '#0867FC'}
                                            >
                                                <path d={node?.icon?.path} />
                                            </svg>
                                        </div>
                                        <div className="ml-[3%] text-[#FFF]">{node?.name}</div>

                                </div>
                            )}
                            <div
                                className="iconspage"
                                style={{ margin: '.25Vmin' }}
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (isEditing) {
                                            handleChangeName(node?._id, node?.name, node?.icon);
                                        } else {
                                            setEditNodeId(node);
                                            setNewNodeName(node?.name); // Set the existing name as default
                                        }
                                    }}
                                    className='cursor-pointer transition-transform duration-300 transform-gpu hover:scale-125'
                                >
                                    {isEditing ? (
                                        <div
                                            style={{ color: newNodeName.trim() === '' ? 'gray' : '#0867FC' }}
                                            className='cursor-pointer text-[1.3vmin]'
                                        >
                                            {newNodeName.trim() === '' ? 'Cancel' : 'OK!'}
                                        </div>
                                    ) : (
                                        <PenEditIcon fill='none' stroke='#161717' />
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    

    const handleRowVisible = (rowId) => {
        setExpandedRow(prevRow => (prevRow === rowId ? null : rowId));
    };
    
    const handleTopAria = (e) => {
        const num = e.target.value
        setRows(num)
        console.log(rows)
    }

    const FirstRow = ({TopProps}) => {
        const {
        navbarProps,
        setNavbarProps,
        setTheOptionRow,
        theOptionRow,
        contents,
        setContents,
    } = TopProps

    const handleChange = (key, field, value) => {
        setContents(prevContents => ({
          ...prevContents,
          [key]: {
            ...prevContents[key],
            [field]: value,
          },
        }));
      };



        return (
            theOptionRow === '' ?
                <>
                    <ol style={{  width:'100%', height:'100%', padding:0, width: '100%'}}>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow('Navigation Pages')}>Navigation Pages!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow('Small widgets')}>Small widgets!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow('Content with a Link')}>Content with a Link!</li>
                        <li style={{ cursor: 'pointer', color:'#0867FC', fontWeight:'500' }} onClick={() => setTheOptionRow('Basic Header')}>Basic Header!</li>

                    </ol>
                </> : theOptionRow === 'Small widgets' ? (
                    <SmallWidgets data={navbarProps} setData={setNavbarProps}/>
                ) : theOptionRow === 'Navigation Pages' ? (
                    <div className='flex flex-column w-full h-full p-[1vmin]'>
                        <TreeView setActiveId={setActiveId} treeData={treeData} idDispatch={idDispatch}/>
                    </div>
                ) : theOptionRow === 'Basic Header' ? (
                    <div className="w-full h-full flex flex-column">
                    <div className='flex flex-row w-full h-full p-[1vmin]'>
                        <div
                            className={`w-[50%] h-[fit-content] rounded-[1vmin] text-center mx-[1vmin] p-2 cursor-pointer ${activeTab === 'widgets' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabChange('widgets')}
                        >
                            Widgets
                        </div>
                        <div
                            className={`w-[50%] h-[fit-content] rounded-[1vmin] text-center mx-[1vmin] p-2 cursor-pointer ${activeTab === 'items' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabChange('items')}
                        >
                            Items
                        </div>
                    </div>
                     <div onClick={(e) => e.stopPropagation()} className='mt-2 w-full h-full'>
                     {activeTab === 'widgets' && (
                         <div>
                           <SmallWidgets data={navbarProps} setData={setNavbarProps}/>
                         </div>
                     )}
                     {activeTab === 'items' && (
                         <div className="text-[#FFF]">
                           <div className="container pb-[1.5vmin]">
                            <div className="radio-group">
                                {/* Radio button for banner children */}
                                <label className="radio-option">
                                <input
                                    type="radio"
                                    name="displayChildren"
                                    value="banner"
                                    checked={pageSettings.navbarProps.displayChildren === 'banner'}
                                    onChange={() => {
                                    setPageSettings((prev) => ({
                                        ...prev,
                                        navbarProps: {
                                        ...prev.navbarProps, // Correctly spread navbarProps
                                        displayChildren: 'banner', // Update displayChildren
                                        },
                                    }));
                                    }}
                                />
                                Banner Children
                                </label>

                                {/* Radio button for basic children */}
                                <label className="radio-option">
                                <input
                                    type="radio"
                                    name="displayChildren"
                                    value="basic"
                                    checked={pageSettings.navbarProps.displayChildren === 'basic'}
                                    onChange={() => {
                                    setPageSettings((prev) => ({
                                        ...prev,
                                        navbarProps: {
                                        ...prev.navbarProps, // Correctly spread navbarProps
                                        displayChildren: 'basic', // Update displayChildren
                                        },
                                    }));
                                    }}
                                />
                                Basic Children
                                </label>
                            </div>
                            </div>
                            <TreeView setActiveId={setActiveId} treeData={treeData} idDispatch={idDispatch}/>
                         </div>
                     )}
                 </div>
                 </div>
                ) : (
                    <div className='flex flex-column w-full h-[100%] text-[#FFF] px-[1vmin] text-[1.5vmin] my-[1vmin]'>
                        <div className='w-full flex-row flex '>
                            <div>Content:</div>
                            <input style={{color:'#161717'}} placeholder='Type someting here!' className='ml-[1.5vmin] resize-none overflow-hidden' value={contents.content1.text} onChange={(e) => handleChange('content1', 'text', e.target.value)}
 />
                        </div> 
                        <div className='items-center flex flex-row h-full w-full justify-between'>
                            <div>Href:</div>
                            <input style={{color:'#161717'}} placeholder='Leads to..' className='ml-[1.5vmin] resize-none overflow-hidden' value={contents.content1.href} onChange={(e) => handleChange('content1', 'href', e.target.value)}
 />
                        </div>

                    </div>
                )
        )
    }

    const SecondRow = ({TopProps}) => {
        const {
        navbarProps,
        setNavbarProps,
        theOptionRow2,
        setTheOptionRow2,
        contents,
        setContents,
    } = TopProps

    const handleContents = (contentNum, e) => {
        let newContent = e.target.value

        setContents((prev) => ({
            ...prev,
            [contentNum]: { ...prev[contentNum], text: newContent }
        }))
    }

    const handleHrefs = (contentNum, e) => {
        let newHref = e.target.value

        setContents((prev) => ({
            ...prev,
            [contentNum]: { ...prev[contentNum], href: newHref }
        }))
    }


        return (
            theOptionRow2 === '' ?
                <>
                    <ol style={{  width:'100%', height:'100%', padding:0, width: '100%', }}>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow2('Navigation Pages')}>Navigation Pages!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow2('Small widgets')}>Small widgets!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow2('Content with a Link')}>Content with a Link!</li>
                        <li style={{ cursor: 'pointer', color:'#0867FC', fontWeight:'500' }} onClick={() => setTheOptionRow2('Basic Header')}>Basic Header!</li>

                    </ol>
                </> : theOptionRow2 === 'Small widgets' ? (
                    <SmallWidgets data={navbarProps} setData={setNavbarProps}/>
                ) : theOptionRow2 === 'Navigation Pages' ? (
                    <div className='flex flex-column w-full h-full p-[1vmin]' onClick={() => console.log(treeData)}>
                        <TreeView setActiveId={setActiveId} treeData={treeData} idDispatch={idDispatch}/>
                    </div>
                ) : theOptionRow2 === 'Basic Header' ? (
                    <div className="w-full h-full flex flex-column">
                    <div className='flex flex-row w-full h-full p-[1vmin]'>
                        <div
                            className={`w-[50%] h-[fit-content] rounded-[1vmin] text-center mx-[1vmin] p-2 cursor-pointer ${activeTab === 'widgets' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabChange('widgets')}
                        >
                            Widgets
                        </div>
                        <div
                            className={`w-[50%] h-[fit-content] rounded-[1vmin] text-center mx-[1vmin] p-2 cursor-pointer ${activeTab === 'items' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                            onClick={() => handleTabChange('items')}
                        >
                            Items
                        </div>
                    </div>
                     <div onClick={(e) => e.stopPropagation()} className='mt-2 w-full h-full'>
                     {activeTab === 'widgets' && (
                         <div>
                           <SmallWidgets data={navbarProps} setData={setNavbarProps}/>
                         </div>
                     )}
                     {activeTab === 'items' && (
                         <div>
                            <TreeView setActiveId={setActiveId} treeData={treeData} idDispatch={idDispatch}/>
                         </div>
                     )}
                 </div>
                 </div>
                ) : (
                    <div className='flex flex-column w-full h-[100%] px-[vmin] text-[1.5vmin] text-[#FFF] my-[1vmin]'>
                        <div className='w-full flex-row flex'>
                            <div>Content:</div>
                            <input style={{color:'#161717'}} placeholder='Type someting here!' className='ml-[1.5vmin] resize-none overflow-hidden' value={contents.content2.text} onChange={(e) => handleContents('content2', e)} />
                        </div>
                        <div className='items-center flex flex-row h-full w-full justify-between items-center'>
                            <div>Href:</div>
                            <input style={{color:'#161717'}} placeholder='Leads to..' className='ml-[1.5vmin] resize-none overflow-hidden' value={contents.content2.href} onChange={(e) => handleHrefs('content2', e)} />
                        </div>

                    </div>
                )
        )
    }

    const RowThree = ({TopProps}) => {
            const {
        navbarProps,
        setNavbarProps,
        theOptionRow3,
        setTheOptionRow3,
        contents,
        setContents,
    } = TopProps


    const handleContents = (contentNum, e) => {
        let newContent = e.target.value

        setContents((prev) => ({
            ...prev,
            [contentNum]: { ...prev[contentNum], text: newContent }
        }))
    }

    const handleHrefs = (contentNum, e) => {
        let newHref = e.target.value

        setContents((prev) => ({
            ...prev,
            [contentNum]: { ...prev[contentNum], href: newHref }
        }))
    }

        return (
            theOptionRow3 === '' ?
                <>
                    <ol style={{  width:'100%', height:'100%', padding:0, width: '100%', }}>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow3('Navigation Pages')}>Navigation Pages!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow3('Small widgets')}>Small widgets!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheOptionRow3('Content with a Link')}>Content with a Link!</li>
                    </ol>
                </> : theOptionRow3 === 'Small widgets' ? (
                    <SmallWidgets data={navbarProps} setData={setNavbarProps}/>
                ) : theOptionRow3 === 'Navigation Pages' ? (
                    <div className='flex flex-column w-full h-full p-[1vmin]' onClick={() => console.log(treeData)}>
                        <TreeView setActiveId={setActiveId} treeData={treeData} idDispatch={idDispatch}/>
                    </div>
                ) : (
                    <div className='flex flex-column w-full h-[100%] px-[1vmin] text-[#FFF] text-[1.5vmin] my-[1vmin]'>
                        <div className='w-full flex-row flex'>
                            <div>Content:</div>
                            <input style={{color:'#161717'}} placeholder='Type someting here!' className='ml-[1.5vmin] resize-none overflow-hidden' value={contents.content3.text} onChange={(e) => handleContents('content3', e)} />
                        </div>
                        <div className='items-center flex flex-row h-full w-full justify-between'>
                            <div>Href:</div>
                            <input style={{color:'#161717'}} placeholder='Leads to..' className='ml-[1.5vmin] resize-none overflow-hidden' value={contents.content3.href} onChange={(e) => handleHrefs('content3', e)} />
                        </div>

                    </div>
                )
        )
    }

    const TopRows = ({TopProps, expandedRow}) => {
        const {
            setStart,
            setTheOptionRow,
            theOptionRow,
            theOptionRow2,
            setTheOptionRow2,
            theOptionRow3,
            setTheOptionRow3,
            rows,
            stepper2,
            setStepper2,
            select,
            setWalksFor,
            pageSettings,
            setPageSettings,
        } = TopProps
    

        
        return (
            <div className='relative w-full h-full flex-col flex text-[1.7vmin]'>
                <div className="w-full h-full flex flex-column">
                {/* <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', width:'100%', paddingBottom:0, padding:0 }}>
                    <div className='flex flex-column justify-between w-full'>
                        <div className='flex justify-between w-full'>
                            <li>
                                Header Visibility
                            </li>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setPageSettings((prev) => ({
                                    ...prev,
                                    navbarProps: {
                                        ...prev.navbarProps,
                                        visibility: !prev.navbarProps.visibility
                                    },
                                }))
                            }} style={{ color: '#0867FC', cursor: 'pointer' }}>{!pageSettings.navbarProps.visibility ? 'OFF' : 'ON'}</div>
                        </div>
                    </div>
                </ol> */}

               {pageSettings.navbarProps.visibility && <div className="w-full h-full flex flex-column justify-start">
                <div className="w-full h-[fit-content] flex flex-column">
                <div className='w-full h-full flex justify-center items-center' style={{ color:'#FFF', fontSize: '1.7vmin', marginBottom: '2vmin', textTransform:'capitalize'}}>How many rows you need in the top aria?</div>
                <div className="flex justify-center items-center w-[70%] text-[1vmin] rounded-[1.5vmin] mx-auto">
                    <select
                    style={{fontSize:'1.5vmin', padding:'.5vmin'}}
                    className="w-full bg-[#e9e9e930] rounded-[1.5vmin]"
                    value={rows}
                    onChange={handleTopAria}
                    >
                    <option value={1}>Only One</option>
                    <option value={2}>Two Rows</option>
                    <option value={3}>Three Rows</option>
                    </select>
                </div>
                </div>

                {rows !== '0' && (
                    <div className='w-full h-full flex-column flex'>
                        <div className='my-[1vmin] border-[.25vmin] border-solid border-[#e9e9e930] shadow-md rounded-[1vmin] justify-center h-[fit-content] flex flex-column px-[.5vmin] py-[1vmin]'>
                            <div className='h-auto rounded-[.7vmin] flex items-center justify-between text-[#FFF]'>
                                <div className="flex-1 w-full pr-[0vmin]">First Row</div>
                                {theOptionRow !== '' && <div style={{ color: '#0867FC', fontSize: '1.5vmin', textAlign:'center', cursor: 'pointer', flex:1, width:'100%' }} 
                                onClick={() => {
                                    setTheOptionRow('')
                                    handleRowVisible('1')
                                    }}>{theOptionRow}</div>}
                                <div className='cursor-pointer' onClick={() => handleRowVisible('1')}>
                                    <div style={{ width: "2Vmin" }}>
                                        <svg style={{ float: 'right', marginLeft: '1vmin', 
                                       // transition: 'all .1s ease-in',
                                         transform: expandedRow === '1' ? 'rotate(90deg)' : '' }}
                                        aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin"
                                        fill={expandedRow === '1' ? '#FFFFFF' : '#e9e9e990'}>
                                            <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                    </div>
                                </div>
                            </div>

                            
                            {expandedRow === '1' && <div className="mt-[1vmin]"> <FirstRow TopProps={TopProps}/> </div>}
                        </div>
                        {rows > 1 && (
                            <div className='my-[1vmin] border-[.25vmin] border-solid border-[#e9e9e930] shadow-md rounded-[1vmin] justify-center h-[fit-content] flex flex-column px-[.5vmin] py-[1vmin]'>
                                <div className='rounded-[.7vmin] flex items-center justify-between text-[#FFF]'>
                                    <div className="flex-1 w-full pr-[0vmin]">Second Row</div>
                                    {theOptionRow2 !== '' && <div style={{ color: '#0867FC', fontSize: '1.5vmin', textAlign:'center', cursor: 'pointer', flex:1, width:'100%' }} onClick={() => {
                                    setTheOptionRow2('')
                                    handleRowVisible('2')
                                    }}>{theOptionRow2}</div>}
                                    <div className='cursor-pointer' onClick={() => handleRowVisible('2')}>
                                    <div style={{ width: "2Vmin" }}>
                                        <svg style={{ float: 'right', marginLeft: '1vmin', 
                                       // transition: 'all .1s ease-in',
                                         transform: expandedRow === '2' ? 'rotate(90deg)' : '' }}
                                        aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin"
                                        fill={expandedRow === '2' ? '#FFFFFF' : '#e9e9e990'}>
                                            <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                    </div>
                                    </div>
                                </div>
                                {expandedRow === '2' && <div className="mt-[1vmin]"> <SecondRow TopProps={TopProps}/> </div>}
                            </div>
                        )}
                        {(rows === 3 || rows === '3') && (
                            <div className='my-[1vmin] border-[.25vmin] border-solid border-[#e9e9e930] shadow-md rounded-[1vmin] justify-center h-[fit-content] flex flex-column px-[.5vmin] py-[1vmin]'>
                                <div className='rounded-[.7vmin] flex items-center justify-between text-[#FFF]'>
                                    <div className="flex-1 w-full pr-[0vmin]">Third Row</div>
                                    {theOptionRow3 !== '' && <div style={{ color: '#0867FC', fontSize: '1.5vmin', textAlign:'center', cursor: 'pointer', flex:1, width:'100%' }} onClick={() => {
                                    setTheOptionRow3('')
                                    handleRowVisible('3')
                                    }}>{theOptionRow3}</div>}
                                    <div className='cursor-pointer' onClick={() => handleRowVisible('3')}>
                                    <div style={{ width: "2Vmin" }}>
                                        <svg style={{ float: 'right', marginLeft: '1vmin', 
                                       // transition: 'all .1s ease-in',
                                         transform: expandedRow === '3' ? 'rotate(90deg)' : '' }}
                                        aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin"
                                        fill={expandedRow === '3' ? '#FFFFFF' : '#e9e9e990'}>
                                            <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                    </div>
                                    </div>
                                </div>
                                {expandedRow === '3' && <div className="mt-[1vmin]"> <RowThree TopProps={TopProps}/> </div>}
                            </div>
                        )}
                    </div>
                )}
                </div>}
                </div>
                {/* <NextButton setStart={setStart}
                onClick={() => {
                    setStepper2(1)
                    setWalksFor((prev) => ({
                        ...prev,
                        main: { value: 1.200 },
                        mainApp: { value: 1 }
                    }))
                }}
                /> */}
                {/* {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
                    <Circle1 fill={stepper2 >= 1 ? 'green' : ''} />
                </div>} */}
            </div>
        )
    }

    return (
        <TopRows expandedRow={expandedRow} setExpandedRow={setExpandedRow} TopProps={TopProps}/>
    )
}

export default TopArea;