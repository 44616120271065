/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { withStyles } from '@mui/styles';
import { useEffect, useReducer, useState } from 'react';
import { UndoIcon } from '../../Viewport/Editor-icons/EditorSvg';
import { Slider as Small } from '@mui/material';
import { useAppProps } from '../../../../DarkModeContext';


const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#e9e9e9',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const SliderType = ({
    

    value,
    node,
    setProp,
    isDarkMode,
    propKey,
    unitOptions,
    value2,
    onChange,
    propValue,
    index,
    activeState,
    options,
    hoverV,
    scrollV,
    props,
    max,
    maxValue,
}) => {

    const {savedProperties, setSavedProperties} = useAppProps()
    const [currentUnit, setCurrentUnit] = useState(unitOptions[0])
    const currentState = options.actionState


    // useEffect(() => {
    //     setProp(node.id, (props) => {
    //         if (!props.initState)
    //         props.initState = {[currentState] : {[propKey] : props[currentState][propKey]}}
    //     })
    // }, [props])


    // useEffect(() => {
    //     if (props.initState) {
    //         console.log(props.initState)
    //     } else {
    //         console.log('shit')
    //     }
    // }, [props])


    useEffect(() => {
        if (value) {
            // Use a regular expression to extract the numeric value and unit
            const regex = /(\d+(\.\d+)?)([a-zA-Z%]+)/; // Matches number and unit (fixed regex syntax)
            const match = value.match(regex);

            if (match) {
                const unit = match[3]; // Extract the unit part (e.g., 'px', '%', etc.)
                setCurrentUnit(unit); // Update the unit based on the extracted value
            }
        }
    }, [value]);



    const handleReset = () => {
        setProp(node.id, (props) => {
            // Ensure that props[currentState] exists
            if (props[currentState][propKey]) {
                props[currentState][propKey] = undefined
            }
        });
    };


    const handleChange = (e) => {
        const numberValue = e.target.value; // Get the number from the input
        const updatedValueWithUnit = `${numberValue}${currentUnit}`; // Combine the number with the unit

            console.log('node')
            setProp(node.id, (props) => {
                // Update the state property based on the current state ('default' or 'hovered')
                if (currentState === 'hovered') {
                    // Only update the 'hovered' state property if it's explicitly set
                    if (props[currentState]) {
                        props[currentState][propKey] = updatedValueWithUnit;
                    } else {
                        // If 'hovered' does not exist, initialize it with the new value
                        props[currentState] = { [propKey]: updatedValueWithUnit };
                    }
                } else {
                    // For the 'default' state, just set the property directly
                    if (!props[currentState]) {
                        // Initialize the 'default' state if it doesn't exist
                        props[currentState] = {};
                    }
                    props[currentState][propKey] = updatedValueWithUnit;
    
                }
            });

    };


    const handleUnitChange = (e) => {

        const newUnit = e.target.value; // Get the selected unit
        setCurrentUnit(newUnit); // Update the current unit in the state

        // Ensure propValue exists and contains a numeric value
        if (value) {
            const currentValue = value.match(/(\d+(\.\d+)?)([a-zA-Z%]+)/); // Extract the numeric value and unit
            if (currentValue) {
                const numericValue = currentValue[1]; // Get the numeric part
                const updatedValueWithUnit = `${numericValue}${newUnit}`; // Combine the numeric value with the selected unit
                setProp(node.id, (props) => {
                    props[currentState][propKey] = updatedValueWithUnit; // Update the property with the new value
                });
            }
        }

    }
    return (
        <div
            className='w-[100%] py-[1vmin] border-b border-[#e9e9e930] m-[.5vmin] flex flex-column wrapper-slide container justify-between  rounded-[2vmin]'>
            <div
                className={`relative items-center flex justify-start text-left w-[fit-content]`}
                style={{ fontSize: '1.8vmin', fontWeight: '500', fontFamily: 'Inter', color: '#FFF' }}
            >
                {props.label}
            </div>

            <div className=' mx-auto flex flex-row justify-between flex-wrap' style={{ width: '100%' }}>

                <div className='flex flex-row justify-around items-center w-full flex-1'>
                    <SliderStyled
                        onMouseDown={(e) => {
                            e.stopPropagation();  // Prevents dragging when interacting with the slider                  
                        }}
                        className='mx-auto my-auto justify-center flex flex-1 max-w-[40%] items-center'
                        style={{ border: '.25vmin solid transparent', paddingLeft: '.5vmin' }}
                        min={props.label === 'Transform value' ? -100 : 1}
                        max={maxValue ? maxValue : max || props.label === 'Transform value' ? 100 : currentUnit !== 'px' ? 100 : 300}
                        value={parseInt(value) || 0}
                        onChange={(e) => handleChange(e)}

                    />

                    <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={handleReset}>
                        <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${(value && value !== 0 && value !== null) ? '#0867FC' : '#FFFFFF'}`} />
                        <div className={`${(value && value !== 0 && value !== null) ? 'text-[#0867FC]' : 'text-[#FFFFFF]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>

                </div>


                <div className='value-props' >
                    <input style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        padding: '.7vmin 1.4vmin',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '400',
                        fontSize: '1.5vmin',
                        color: '#FFFFFF',
                        backgroundColor: 'transparent',
                        border: '.25vmin solid #FFFFFF',
                        borderRadius: '2vmin',
                        outline: 'none',
                        //  transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
                    }}
                        value={parseInt(value) || 0}

                        placeholder='0'
                        onFocus={(e) => {
                            e.target.style.borderColor = '#007bff';
                            e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                        }}
                        onBlur={(e) => {
                            e.target.style.borderColor = '#ccc';
                            e.target.style.boxShadow = 'none';
                        }}
                        onChange={(e) => handleChange(e)}
                    />


                    <div className='w-full h-full flex-1 items-center flex'>
                        <select
                            style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                padding: '.7vmin 1.4vmin',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: '400',
                                fontSize: '1.5vmin',
                                color: '#FFFFFF',
                                backgroundColor: 'transparent',
                                border: '.25vmin solid #FFFFFF',
                                borderRadius: '2vmin',
                                outline: 'none',
                                cursor: 'pointer',
                            }}
                            value={currentUnit} // Set selected unit based on current property value
                            onChange={(e) => handleUnitChange(e)}
                            onFocus={(e) => {
                                e.target.style.borderColor = '#007bff';
                                e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                            }}
                            onBlur={(e) => {
                                e.target.style.borderColor = '#ccc';
                                e.target.style.boxShadow = 'none';
                            }}
                        >
                            {unitOptions?.map((option) => (
                                <option
                                    style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: '400',
                                        fontSize: '1.4rem',
                                        color: '#333',
                                    }}
                                    key={option}
                                    value={option}
                                >
                                    {option}
                                </option>
                            ))}
                        </select>


                    </div>
                </div>
            </div>
        </div>
    )
}