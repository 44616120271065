/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useAppProps } from '../../DarkModeContext';

const IndicatorDiv = styled.div`
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;

  svg {
    width: 15px;
    height: 15px;
  } 
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

export const RenderNode = ({ render }, settings, setSettings, setWorking) => {
  const { isDarkMode, smartMenu, setSmartMenu } = useAppProps();
  const { id } = useNode();
  const { actions, query, isActive, selected, nodeObj, connectors, actions: { setOptions, selectNode }, } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),
    nodeObj: state,
    selected: state.events.selected,
  }));

  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
    node,
    children,
    props,
    actions: { setProp }
  } = useNode((node) => ({
    isHover: node.events?.hovered,
    dom: node.dom,
    name: node.data.props.nameOf || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
    node: node,
    children: node.data.linkedNodes,

  }));

  useEffect(() => {

    if (children) {
      let newChildren = Object.entries(children)
      newChildren.map(([key, x]) => {
      })
    }
  }, [children])



// useEffect(() => {
//   if(isActive) {
//     setSmartMenu(id)
//   }
// },[isActive])





  const insertNodeOnParent = useCallback(
    (
      nodeId,
      parentId,
      indexToInsert,
      selectNodeAfterCreated = false,
    ) => {
      const node = query.node(nodeId).get();

      const freshNode = {
        data: {
          ...node.data,
          nodes: [],
        },
      };

      const nodeToAdd = query.parseFreshNode(freshNode).toNode();

      actions.add(nodeToAdd, parentId, indexToInsert);

      if (node.data.nodes.length === 0) {
        return;
      }

      node.data.nodes.forEach((childNode, index) => {
        insertNodeOnParent(childNode, nodeToAdd.id, index);
      });

      if (selectNodeAfterCreated) actions.selectNode(nodeToAdd.id);
    },
    [actions, query],
  );


  return (
    <>
      {render}
    </>
  );
};
