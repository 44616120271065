/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element } from '@craftjs/core';
import { useNode } from '@craftjs/core';
import React, { Children, useState } from 'react';
import { Container } from '../../../Container/index.js';
import { ResizableWrapper } from '../../ResizableWrapper/index.js';
import { ColumnLayoutSettings } from './ColumnLayoutSettings.js';
import { ResizableContainer } from '../ResizableContainer/index.js';

export const Column2 = ({ layouts, options, children, direction = ['column'] }) => {
  const {
    connectors: {
      connect
    },
    actions: { setProp }
  } = useNode(node => ({
    selected: node.events.selected
  }));

  return (
    <ResizableContainer style={{height:'100%', width:'100%'}}>
      <div style={{ justifyContent:'center', width: "100%", height:'100%', padding: "1%"}} ref={connect}>
      <Element canvas is={Container} width={'100%'} notMain id='random-id-1'/>
      </div>
      </ResizableContainer>
  );
};

Column2.craft = {
  displayName: "div",
  props: {
    layouts: 1,
    options: false,
    direction: ['column'],
    isLayout: true
  },
  // rules: {
  //   canDrag: () => true,
  //   canDrop: () => true,
  // },
  related: {
    toolbar: ColumnLayoutSettings,
  },
};
