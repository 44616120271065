/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { useNode, Element } from '@craftjs/core';
import React from 'react';
import { Column3 } from '../MainLayouts/Column3.js';
import { ColumnSettings } from '../MainLayouts/ColumnSettings.js';
import { Container } from '../../../Container/index.js';
import { Text } from '../Text/index.js';


export const Row1 = (props) => {
  const { 
    connectors: {
      connect
    },
    active,
    parent,
  } = useNode(node => ({
    selected: node.events.selected,
    active: node.events.selected,
    parent:node.data.parent
  }));
  return (
    <Element id='randomiddd' canvas is={Column3}>
    
    </Element>
  )
}

Row1.craft = {
  displayName: 'Rowwwww',
};