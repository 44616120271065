/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { useNode } from '@craftjs/core';
import { ArrowToRight } from '../../../editor/Viewport/Editor-icons/svgArray';
import { StatesItem } from '../MainLayouts/HookStates';

export const InputSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");
  const [expanded, setExpanded] = useState(null)
  const [searchTerm, setSearchTerm] = useState('');
  const [customize, setCustomize] = useState({})


  const {
    node,
    options,
    actions: { setProp }
  } = useNode((node) => ({
    props: node.data.props,
    node: node,
    options: node.data.props
  }));
  
  const handleCustomize = (group) => {
    setCustomize((prev) => ({
        ...prev,
        [group]: !prev[group], // Toggle the current group's state
    }));
};

const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};


  return (
    <div className='flex flex-col component-settings' style={{ height: '100%', zIndex: 9999999 }}>
      <div>
        <div style={{ borderRadius: '1.5vmin', width: '100%', backdropFilter: 'blur(10px)', background: '#e9e9e930' }} className={`shadow-sm absolute left-0 top-0 z-[999999999]`}>
          <input
            placeholder="Search by title..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              display: 'flex',
              margin: '1vmin auto',
              padding: '1vmin 1.4vmin',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              fontSize: '2vmin',
              color: '#98919190',
              backgroundColor: 'transparent',
              border: '.25vmin solid #0867FC70',
              borderRadius: '2vmin',
              outline: 'none',
              width: '80%',
              height: '4vmin'

            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#007bff';
              e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#0867FC70';
              e.target.style.boxShadow = 'none';
            }}

          />
          <div className='w-full text-center text-[2.2vmin] my-[.5vmin] text-[#0867FC80]'
            style={{ fontWeight: '700', userSelect: 'none' }}>
            CUSTOMIZE
          </div>
        </div>


      {activeTab === "Style Manager" ? (
<div style={{ width: "100%" }}>
  <div className='w-full mb-[1vmin] flex flex-row justify-between'>
                    <StatesItem currentState="default" actionState={options.actionState} setProp={setProp} />
                    <StatesItem currentState="hovered" actionState={options.actionState} setProp={setProp} />
                    <StatesItem currentState="active" actionState={options.actionState} setProp={setProp} />
                      </div>

            {/* <Toggle toggleIsImg={toggleIsSvg} on={svg} first={'Svg'} second={'Text'} /> */}
            <div style={{ display: 'flex', margin: 'auto auto', justifyContent: 'center', alignItems: 'center', overflowY:'scroll', minWidth:'100%', maxHeight:'75vmin', paddingBottom: expanded && '3vh', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%' }}>
            <div style={{ display: options['actionState'] !== 'default' ? 'none' : 'flex', alignItems: 'center', width: '100%', flexDirection: 'column', }}>

              <ToolbarSection
                searchTerm={searchTerm}
                expanded={expanded === 'Quick Style'}
                setExpanded={() => setExpanded(expanded === 'Quick Style' ? null : 'Quick Style')}
                title="Quick Style"
              >
                <ToolbarItem
                  full={true}
                  propKey="href"
                  type="select"
                  label="href"
                />
                <ToolbarItem
                  full={true}
                  propKey="fontSize"
                  type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

                  label="Font Size"
                />
                <ToolbarItem
                  full={true}
                  propKey="textAlign"
                  optionsDrop={['left', 'center', 'right']}
                  type="select-drop"
                  label="Text Align"
                />
                 <ToolbarItem
                  full={true}
                  propKey="fontWeight"
                  optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
                  type="select-drop"
                  label="Weight"
                />
                <ToolbarItem
                  full={true}
                  propKey="textTransform"
                  optionsDrop={['uppercase', 'lowercase', 'capitalize']}
                  type="select-drop"
                  label="Transform"
                />
                <ToolbarItem full={true} label='Color' propKey="color" type="color" />

                <ToolbarItem full={true} label='Background' propKey="background" type="bg" />
                
              </ToolbarSection>


              <ToolbarSection
           searchTerm={searchTerm}
           expanded={expanded === 'Actions'}
           setExpanded={() => setExpanded(expanded === 'Actions' ? null : 'Actions')}
           title="Actions"
        > 
           <ToolbarItem
            full={true}
            propKey="placeholder"
            type="text"
            label="Place Holder"
          />

          <ToolbarItem
            full={true}
            propKey="type"
            optionsDrop={[ 'string' , 'number', 'email', 'password', 'checkbox', 'file', 'hidden']}
            type="select-drop"
            label="Type"
          />

          <ToolbarItem
            full={true}
            propKey="name"
            type="text"
            label="Name"
          />

           <ToolbarItem
            full={true}
            propKey="inputValue"
            type="text"
            label="Value"
          />

        <ToolbarItem
            full={true}
            propKey="readOnly"
            optionsDrop={['true', 'false']}

            type="select-drop"
            label="Read Only"
          />

        </ToolbarSection>
        <ToolbarSection
            searchTerm={searchTerm}
            expanded={expanded === 'Size'}
            setExpanded={() => setExpanded(expanded === 'Size' ? null : 'Size')}
            title="Size"
           
          >
            <ToolbarItem
              full={true}
              propKey="width"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Width"
            />

          <ToolbarItem
              full={true}
              propKey="height"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Height"
            />

            <ToolbarItem
              full={true}
              propKey="minWidth"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Min-Width"
            />

<ToolbarItem
              full={true}
              propKey="maxWidth"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Max-Width"
            />

           
          </ToolbarSection>

        <ToolbarSection
          searchTerm={searchTerm}
          expanded={expanded === 'Content'}
          setExpanded={() => setExpanded(expanded === 'Content' ? null : 'Content')}
          title="Content">

          <ToolbarItem
              full={true}
              propKey="fontSize"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Font Size"
            />
            <ToolbarItem
              full={true}
              propKey="lineHeight"
              type="slider"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Line Height"
            />
            <ToolbarItem
              full={true}
              propKey="fontFamily"
              optionsDrop={['Rubik', 'Open Sans', 'Inter', 'Roboto', 'Voces', 'Poppins', 'Sansation', 'Prompt', 'Almarai', 'Chivo',]}
              mainProps
              type="select-drop"
              label="Font Family"
            />
            <ToolbarItem
              full={true}
              propKey="fontWeight"
              optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
              mainProps
              type="select-drop"
              label="Weight"
            />
            <ToolbarItem
              full={true}
              propKey="textTransform"
              optionsDrop={['uppercase', 'lowercase', 'capitalize']}
              mainProps
              type="select-drop"
              label="Transform"
            />
            <ToolbarItem
              full={true}
              propKey="textDecoration"
              optionsDrop={['underline', 'overline', 'line-through', 'blink']}
              mainProps
              type="select-drop"
              label="Text Decoration"
            />
            <ToolbarItem
              full={true}
              propKey="textAlign"
              optionsDrop={['left', 'center', 'right']}
              mainProps
              type="select-drop"
              label="Text Align"
            />
          
        </ToolbarSection>

        <ToolbarSection

searchTerm={searchTerm}
expanded={expanded === 'margin'}
setExpanded={() => setExpanded(expanded === 'margin' ? null : 'margin')}
title="margin"
>
{customize['margin'] ? (
<>
<ToolbarItem propKey="marginTop" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Top" />
<ToolbarItem propKey="marginRight" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Right" />
<ToolbarItem propKey="marginBottom" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Bottom" />
<ToolbarItem propKey="marginLeft" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Left" /></>
) : (
<ToolbarItem propKey="margin" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Margin" />
) } 

<div onClick={() => handleCustomize('margin')} style={{borderRadius:'0 0 1vmin 1vmin', border:'.25vmin solid'}} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['margin'] ? <ArrowToRight fill='#0867FC' style={{transform:'rotate(180deg)'}} width='3vmin' height='2vmin'/> : 'customize' }</div>

</ToolbarSection>


<ToolbarSection
searchTerm={searchTerm}
expanded={expanded === 'Padding'}
setExpanded={() => setExpanded(expanded === 'Padding' ? null : 'Padding')}
title="Padding"
>
{customize['padding'] ? (
  <>
  <ToolbarItem propKey="paddingTop" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Top" />
  <ToolbarItem propKey="paddingRight" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Right" />
  <ToolbarItem propKey="paddingBottom" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Bottom" />
  <ToolbarItem propKey="paddingLeft" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Left" /> 
  </>
) : (
  <ToolbarItem propKey="padding" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Padding" />
)}
  
  <div onClick={() => handleCustomize('padding')} style={{borderRadius:'0 0 1vmin 1vmin', border:'.25vmin solid'}} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['padding'] ? <ArrowToRight fill='#0867FC' style={{transform:'rotate(180deg)'}} width='3vmin' height='2vmin'/> : 'customize' }</div>

</ToolbarSection>

        </div>
         
         

          <ToolbarSection
            searchTerm={searchTerm}
            expanded={expanded === 'Appearance'}
            setExpanded={() => setExpanded(expanded === 'Appearance' ? null : 'Appearance')}
            title="Appearance"
          >
            
            <ToolbarItem full={true} label='Color' propKey="color" type="color" />
            <ToolbarItem full={true} label='Background' propKey="background" type="bg" />


          </ToolbarSection>

          <ToolbarSection
            searchTerm={searchTerm}
            expanded={expanded === 'Shadow'}
            setExpanded={() => setExpanded(expanded === 'Shadow' ? null : 'Shadow')}
            title="Shadow"
           
          >
             <ToolbarItem
              full={true}
              propKey="shadowColor"
              type="color"
              label="Shadow Color"
            />
            <ToolbarItem
              full={true}
              maxValue={20}
              propKey="shadowY"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Y offset"
            />

          <ToolbarItem
              full={true}
              maxValue={20}
              propKey="shadowX"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="X offset"
            />

            <ToolbarItem
              full={true}
              maxValue={20}
              propKey="shadowBlur"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Blur"
            />

           


           
          </ToolbarSection>
          <ToolbarSection searchTerm={searchTerm}
                          expanded={expanded === 'Border'}
                          setExpanded={() => setExpanded(expanded === 'Border' ? null : 'Border')}
                          title="Border"
                          props={['border', 'fit']}
                          summary={({ border, fit }) => {
                            return `${border}px` `${fit}`;
                          }}
                        >
                          <ToolbarItem
                            full={true}
                            propKey="borderStyle"
                            label="Border Style"
                            optionsDrop={['inset', 'solid', 'dotted', 'dashed',]}
                            type="select-drop"
                          />
                          
                          <ToolbarItem
                            full={true}
                            propKey="borderWidth"
                            type="slider"
                            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                            label="Border Width"
                          />
          
                          <ToolbarItem
                            full={true}
                            propKey="borderColor"
                            type="bg"
                            label="Border Color"
                          />
          
                          <ToolbarItem
                            full={true}
                            propKey="borderRadius"
                            type="slider"
                            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                            label="Rounded"
                          />
                        </ToolbarSection>

        </div>
        </div>
      ) : null}

      </div>
    </div>
  );
};