/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import { ADD_OPTION, useOptionsContext } from "../../../OptionsContext";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DeleteNameIcon, Doublicate } from "./Editor-icons/EditorSvg.js";
import { BorderUnder, PenEditIcon } from "./Editor-icons/svgArray.js";
import { useActiveIdContext } from "../../../ActiveIdContext";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { CloseIcon } from "../../land/Svg";
import { category, imgsCate } from '../../selectors/basic/Image/ImageSettings.js';
import { ArrowDDown, ArrowUpp } from "./Editor-icons/svgArray.js";
import SelectionBox from "./Pop-up/Images-Icons-Modal.js";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHotkeys } from "react-hotkeys-hook";
import { useAppProps } from "../../../DarkModeContext.js";
import { imagesArr } from "./Pop-up/ImageArray.js";

export const TreeView = ({ auth, data, setActiveComponents, open, setOpen }) => {

  const { isDarkMode, colorTheme} = useAppProps();


  let pages = [
    "login.ftl",
    "register.ftl",
    "register-user-profile.ftl",
    "info.ftl",
    "error.ftl",
    "login-reset-password.ftl",
    "login-verify-email.ftl",
    "terms.ftl",
    "login-oauth2-device-verify-user-code.ftl",
    "login-oauth-grant.ftl",
    "login-otp.ftl",
    "login-username.ftl",
    "login-password.ftl",
    "webauthn-authenticate.ftl",
    "login-update-password.ftl",
    "login-update-profile.ftl",
    "login-idp-link-confirm.ftl",
    "login-idp-link-email.ftl",
    "login-page-expired.ftl",
    "login-config-totp.ftl",
    "logout-confirm.ftl",
    "update-user-profile.ftl",
    "idp-review-user-profile.ftl",
    "update-email.ftl",
    "select-authenticator.ftl",
    "saml-post-form.ftl",
    "my-extra-page-1.ftl",
    "my-extra-page-2.ftl",
    "info.ftl"
  ];

  const authData = useMemo(() => {
    return pages.map(page => {
      let name = page.replace('.ftl', '').replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
      return {
        _id: page,
        name: name,
        children: []
      };
    });
  }, [pages]);

  const newData = auth ? authData : data;

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId } = state;
    const [hovered, setHovered] = useState({})
   
  const onMouseEnter = (id) => {
    setHovered((prev) => ({ ...prev, [id]: true }));
  };
const onMouseLeave = (id) => {
  setHovered((prev) => ({ ...prev, [id]: false }));

}



  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [dialog, setDialog] = useState(false);
  const [childEdit, setChildEdit] = useState(null);
  const [childEditName, setChildEditName] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [newActionName, setNewActionName] = useState('');
  const [focused, setFocused] = useState(false);
  const [name, setName] = useState('');
  const [activeEdit, setActiveEdit] = useState(null);
  const [childId, setChildId] = useState(null);
  const [iconsSquare, setIconsSquare] = useState(false);
  const [imagesSquare, setImagesSquare] = useState(false);
  const [images, setImages] = useState(imagesArr)
  const [imgsCategory, setImgsCategory] = useState(imgsCate)
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState({
    name: 'default icon',
    path: "M16.5 4.5C16.5 5.17031 16.3547 5.80781 16.0875 6.38437L18.5625 10.6594C17.4516 11.8453 16.0219 12.7266 14.4141 13.1719L12 9L8.8125 14.5078C9.82031 14.8266 10.8891 15 12.0047 15C15.3188 15 18.2766 13.4672 20.2031 11.0625C20.7234 10.4156 21.6656 10.3125 22.3125 10.8281C22.9594 11.3438 23.0625 12.2906 22.5469 12.9375C20.0672 16.0219 16.2656 18 12 18C10.3406 18 8.74688 17.7 7.27969 17.1516L4.62656 21.7359C4.40625 22.1156 4.07812 22.425 3.68438 22.6219L1.0875 23.9203C0.853125 24.0375 0.576563 24.0234 0.35625 23.8875C0.135938 23.7516 0 23.5078 0 23.25V20.6531C0 20.2594 0.103125 19.8703 0.304688 19.5234L3.11719 14.6625C2.51719 14.1375 1.95938 13.5609 1.4625 12.9375C0.942188 12.2906 1.05 11.3484 1.69688 10.8281C2.34375 10.3078 3.28594 10.4156 3.80625 11.0625C4.07344 11.3953 4.35938 11.7094 4.65938 12.0047L7.9125 6.38437C7.65 5.8125 7.5 5.175 7.5 4.5C7.5 2.01562 9.51563 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM17.4844 18.4641C19.0125 17.8641 20.4141 17.0203 21.6516 15.9844L23.7 19.5234C23.8969 19.8656 24.0047 20.2547 24.0047 20.6531V23.25C24.0047 23.5078 23.8688 23.7516 23.6484 23.8875C23.4281 24.0234 23.1516 24.0375 22.9172 23.9203L20.3203 22.6219C19.9266 22.425 19.5984 22.1156 19.3781 21.7359L17.4844 18.4641ZM12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6Z",
    width: '24',
    height: '24',
    viewBox: '0 0 24 24'
  });

  const [expandedNodes, setExpandedNodes] = useState([]);
  const downRef = useRef(null);
  const currentActiveId = useRef(null);

  // Optimized random string generator using useCallback
  const generateRandomString = useCallback((length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  }, []);

  const newChildId = generateRandomString(5);



  // Memoized effect to store tree data in localStorage
  useEffect(() => {
    if (treeData.length) {
      localStorage.setItem("treeData", JSON.stringify({ treeData }));
    }
  }, [treeData]);

  // Effect to set the activeId when newData is available
  useEffect(() => {
    if (!activeId && newData) {
      setActiveId({ _id: newData[0]?._id, name: newData[0]?.name });
    }
  }, [activeId, newData, setActiveId]);

  // Optimized scrolling effect
  useEffect(() => {
    const scrollToActive = () => {
      if (currentActiveId.current) {
        currentActiveId.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center"
        });

        const offset = 50;
        window.scrollBy(0, -offset);
      }
    };

    if (open && currentActiveId.current) {
      scrollToActive();
    }
  }, [open]);

  const changeOptions = useCallback((options) => {
    idDispatch({ type: ADD_OPTION, payload: options });
  }, [idDispatch]);

  const addItemToChildren = useCallback((parentId, icon, image) => {
    const updatedActionName = name || "New Page"; // If name is empty or undefined, set to an empty string.
    setNewActionName(updatedActionName);

    const duplicates = treeData.find(x => x._id === parentId)?.children?.filter(
      page => page.name.toLowerCase().replace(/\d+/g, '').trim() === updatedActionName.toLowerCase().replace(/\d+/g, '').trim()
    );

    const count = duplicates.length;
    const newChildId = generateRandomString(5);

    const parentObject = treeData.find(obj => obj._id === parentId);

    if (parentObject && parentObject.children) {
      const updatedTreeData = treeData.map(obj => {
        if (obj._id === parentId) {
          return {
            ...obj,
            children: [...obj.children, {
              _id: newChildId,
              name: newActionName ? `${newActionName.toLowerCase().replace(/\d+/g, '').trim()} ${count + 1}` : `New Page ${count + 1}`,
              icon: icon, // Ensure there's a fallback for `icon`
              image : image
            }],
          };
        }
        return obj;
      });

      changeOptions(updatedTreeData);
      setDialog(false);
      setChildId(null);
      setSelectedImage(null)
    }
  }, [treeData, changeOptions]);

  const handleDeleteChild = useCallback((parentId, childId) => {
    const updatedTreeData = treeData.map(obj => {
      if (obj._id === parentId && obj.children) {
        const updatedChildren = obj.children.filter(child => child._id !== childId);
        return { ...obj, children: updatedChildren };
      }
      return obj;
    });
    changeOptions(updatedTreeData);
  }, [treeData, changeOptions]);

  const handleEditChildName = useCallback((parentId, childId, newName) => {
    const updatedTreeData = treeData.map(obj => {
      if (obj._id === parentId && obj.children) {
        const updatedChildren = obj.children.map(child => {
          if (child._id === childId) {
            return { ...child, name: newName };
          }
          return child;
        });
        return { ...obj, children: updatedChildren };
      }
      return obj;
    });
    changeOptions(updatedTreeData);
  }, [treeData, changeOptions]);

  const handleAddAction = useCallback((name, icon, parentId) => {
    const duplicates = treeData.filter(page => page.name.toLowerCase().replace(/\d+/g, '').trim() === name.toLowerCase().replace(/\d+/g, '').trim());
    const count = duplicates.length;

    const newActionId = generateRandomString(5);
    const newAction = {
      _id: newActionId,
      name: name ? `${name.toLowerCase().replace(/\d+/g, '').trim()} ${count + 1}` : `New Page ${count + 1}`,
      icon,
      children: [],
    };


    const updatedTreeData = [...treeData, newAction];
    changeOptions(updatedTreeData);
    setDialog(false);
    setOpen(false);
  }, [treeData, changeOptions, generateRandomString]);

  const handleDeleteNode = useCallback((id, nodeName, e) => {
    setName(nodeName);
    setDeleteId(id);
    setDialog(true);
  }, []);

  const handleDeleteNodeFunc = useCallback((id) => {
    const filteredTreeData = treeData.filter(x => x._id !== id);
    changeOptions(filteredTreeData);
    setActiveId(filteredTreeData[0]);
    setDialog(false);
    setOpen(false);
    setDeleteId(null);
  }, [treeData, changeOptions, setActiveId]);

  const handleChangeName = useCallback((id, node, icon) => {
    idDispatch({ type: 'CHANGE_NAME', payload: { _id: id, name: node, icon } });
    setActiveId({ _id: id, name: node });
    setDialog(false);
    setOpen(false);
  }, [idDispatch, setActiveId]);

  const duplicatePage = useCallback((id) => {
    let json = localStorage.getItem(id);
    const item = treeData.find(x => x._id === id);
    const newItem = { ...item, children: [] };

    const duplicates = treeData.filter(page => page.name.replace(/\d+/g, '').trim() === item.name.replace(/\d+/g, '').trim());
    const count = duplicates.length;

    newItem._id = generateRandomString(5);
    newItem.name = `${newItem.name.replace(/\d+/g, '').trim()} ${count + 1}`;
    localStorage.setItem(newItem._id, json);

    changeOptions([...treeData, newItem]);
    setActiveId({ _id: newItem._id, name: newItem.name });
  }, [treeData, generateRandomString, changeOptions, setActiveId]);

  const handleIconSelect = useCallback((selectedUrl) => {
    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcon(selectedUrl[0]);
    } else {
      setSelectedIcon(selectedUrl);
    }

    setIconsSquare(false);

  }, []);

  const handleIconImageSelect = useCallback((selectedUrl) => {
    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedImage(selectedUrl[0]);
    } else {
      setSelectedImage(selectedUrl);
    }
     
     setImagesSquare(false);
 
   }, []);

  const changeIndexUp = useCallback((node) => {
    let json = localStorage.getItem(node._id);
    const nodeIndex = treeData.findIndex((x) => x._id === node._id);

    if (nodeIndex > 0 && nodeIndex !== -1) {
      const temp = treeData[nodeIndex];
      treeData[nodeIndex] = treeData[nodeIndex - 1];
      treeData[nodeIndex - 1] = temp;
      localStorage.setItem(temp._id, json);
      changeOptions([...treeData]);
      setOpen(false);
    } else {
      alert("You can't move up the node.");
    }
  }, [treeData, changeOptions]);

  const changeIndex = useCallback((node) => {
    let json = localStorage.getItem(node._id);

    if (json !== null) {
      const nodeIndex = treeData.findIndex((x) => x._id === node._id);
      if (nodeIndex >= 0 && nodeIndex < treeData.length - 1) {
        const temp = treeData[nodeIndex];
        treeData[nodeIndex] = treeData[nodeIndex + 1];
        treeData[nodeIndex + 1] = temp;
        localStorage.setItem(temp._id, json);
        changeOptions([...treeData]);
        setOpen(false);
      } else {
        alert("You can't move down the node.");
      }
    } else {
      alert("Item does not exist in localStorage.");
    }
  }, [treeData, changeOptions]);

  const handleDialog = (id, childNode, name, icon) => {
    const updatedActionName = name || "New Page"; // If name is empty or undefined, set to an empty string.
    setNewActionName(updatedActionName);

    setDialog(true);

    if (childEdit && id && name && icon) {
      handleEditChildName(id, childNode, newActionName);
    } else if (id && name) {
      setActiveEdit(id);
    } else if (id && !name) {
      setChildId(id);
    } else {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!dialog) {
      setActiveEdit(null);
      setNewActionName("");
      setChildId(false)
    }
  }, [dialog]);

  const toggleNode = useCallback((nodeId) => {
    if (expandedNodes.includes(nodeId)) {
      setExpandedNodes(expandedNodes.filter(id => id !== nodeId));
    } else {
      setExpandedNodes([...expandedNodes, nodeId]);
    }
  }, [expandedNodes]);

  const isNodeExpanded = useCallback((nodeId) => {
    return expandedNodes.includes(nodeId);
  }, [expandedNodes]);

  const dupSubPage = useCallback((parentId, childId) => {
    let json = localStorage.getItem(childId);
    const parentItem = treeData.find(item => item._id === parentId);
    const childIndex = parentItem.children.findIndex(child => child._id === childId);
    const childItem = parentItem.children[childIndex];
    const newChildItem = { ...childItem };

    const duplicates = parentItem.children.filter(child => child.name.replace(/\d+/g, '').trim() === childItem.name.replace(/\d+/g, '').trim());
    const count = duplicates.length;

    newChildItem._id = generateRandomString(5);
    newChildItem.name = count === 0 ? `${newChildItem.name.replace(/\d+/g, '').trim()} 1` : `${newChildItem.name.replace(/\d+/g, '').trim()} ${count + 1}`;

    const newTreeData = treeData.map(item => {
      if (item._id === parentId) {
        return { ...item, children: [...item.children.slice(0, childIndex + 1), newChildItem, ...item.children.slice(childIndex + 1)] };
      }
      return item;
    });

    localStorage.setItem(newChildItem._id, json);
    changeOptions(newTreeData);
    setActiveId({ _id: newChildItem._id, name: newChildItem.name });
  }, [treeData, generateRandomString, changeOptions]);

  useHotkeys('shift+a', (e) => {

    handleDialog()
    setSelectedIcon(selectedIcon)
    setOpen(false);
    e.stopPropagation()

  })

  const renderTree = (nodes, child, level = 0) => {

    const getBackgroundColor = (activeId, node, hovered, index, colorTheme) => {
      if (activeId?._id === node?._id) {
        return colorTheme + '80';  // Active item
      }
      if (hovered[index]) {
        return colorTheme + '60';  // Hovered item
      }
      return colorTheme + '40';  // Default color
    };

    const getBackgroundColorForChild = (activeId, childNode, hovered, colorTheme) => {
      if (activeId?._id === childNode?._id) {
        return hovered[childNode] ? colorTheme + '60' : colorTheme + '80';  // Active item with hover or without hover
      }
      return '';  // Default when no match
    };

    const isLastNode = (nodeIndex) => nodes.length !== 1 && nodeIndex === nodes.length - 1;


    return (
      <>

        <div
          className="Treeview-parent"
          onClick={(e) => {
            e.stopPropagation()
            setOpen(!open)
            setActiveComponents(false)
          }}
        >
          <div style={{ paddingBottom: '0' }}
            className={`my-auto h-full transition-all duration-500 ease-in-out flex flex-column w-[100%] items-center`}>
            <div
              title='Pages'
              className="flex w-full flex-col justify-between h-[100%] my-auto w-[100%] items-center"
              id="EditorRoot"
            >
              <div className={`current-selected-node`} style={{color:colorTheme}}>
                <div className="w-auto h-full items-center flex">
                  {nodes
                    ?.filter(node => node?.name === activeId?.name || node?.children?.some(child => child._id === activeId?._id)) // Filter nodes by activeId or check if any children match
                    .map((node, i) => (
                      node?.icon && (
                        <div
                          key={i}
                          className="w-[3vmin] h-[3vmin] mr-[1vmin] logo-sidee items-center justify-center flex"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            viewBox={node.icon.viewBox}
                            width="3.5vmin"
                            height="3.5vmin"
                            fill={node.icon.fill || colorTheme}
                          >
                            <path d={node.icon.path}></path>
                          </svg>
                        </div>
                      )
                    ))}

                  {!auth ? activeId?.name?.length > 8 ? `${activeId?.name?.slice(0, 5)}...` : activeId?.name : activeId?.name}

                  <div style={{rotate: open ? '' : '180deg', height:'100%', display:'flex', alignItems:'center'}}>
                  <KeyboardArrowUpIcon sx={{ margin: 'auto .5vmin', fontSize: '2.5vmin', color: colorTheme }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div>{<BorderUnder width='100%' fill={colorTheme} />}</div>
        <div
          ref={downRef}
          className={`bluredContent nodes-menu ${isDarkMode ? 'bg-[#111f3e60]' : 'bg-[#e9e9e960]'} shadow-sm`}
          style={{
            padding: '2%',
            border: '.25vmin solid #e9e9e920',
            transform: open
              ? 'translate(0, 0) scale(1)' // Full size and positioned at the center
              : 'translate(17%, -40%) scale(0)', // Initially small and centered
            opacity: open ? 1 : 0, // Fade in and out when open
          }}
          id="dropdown-menu"
        >
          <div className="flex flex-col w-full shadow-sm" style={{ borderRadius: '2vmin', overflow: 'hidden' }}>
            <div style={{ borderRadius: '2vmin', height: '15vmin', width: '100%', borderRadius: '2vmin', overflow: 'scroll', scrollbarWidth: 'none' }} >
              {nodes?.map((node, i, a) => {
                const isLast = isLastNode(i);
                return (
                  <div
                    key={i}
                    onMouseEnter={() => onMouseEnter(i)}
                    onMouseLeave={() => onMouseLeave(i)}
                    style={{ background: getBackgroundColor(activeId, node, hovered, i, colorTheme) }}
                    ref={activeId?._id === node?._id ? currentActiveId : null}
                    className={`h-[fit-content] items-center w-full py-[1vmin] pl-0 m-0 pr-[.5vmin]`}
                  >
                    <div
                      onKeyDown={(event) => event.stopPropagation()}
                      key={node._id}
                      disableRipple={true}
                      style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center' }}
                      className="h-[100%]"
                    >
                      <div
                        onClick={() => {
                          setActiveId(node);
                          setOpen(false);
                        }}
                        className="menu-item-content px-[1vmin]"
                      >
                        <div className="menu-item-header">
                          <div className="menu-item-icons">
                              <div style={{display: treeData.length > 1 && !auth ? 'flex' : 'none'}} className="icon-container flex flex-column ">
                                  <div
                                  style={{display: treeData.findIndex((x) => x._id === node._id) !== 0 ? 'flex' : 'none'}}
                                    className="icon-action"
                                    onClick={(e) => {
                                      changeIndexUp(node);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <ArrowUpp stroke={isDarkMode ? `#111f3e` : colorTheme} width="2vmin" height="2vmin" />
                                  </div>
                                  <div style={{display: treeData.findIndex((x) => x._id === node._id) !== treeData.length - 1 ? 'flex' : 'none'}}
                                    className="icon-action"
                                    onClick={(e) => {
                                      changeIndex(node);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <ArrowDDown stroke={isDarkMode ? `#111f3e` : colorTheme} width="2vmin" height="2vmin" />
                                  </div>
                              </div>
                              <div  style={{display: node?.icon ? 'flex' : 'none'}} className="logo-side">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  role="img"
                                  viewBox={node.icon.viewBox}
                                  width="2vmin"
                                  height="2vmin"
                                  fill={isDarkMode ? node.icon.fill || '#B0B0B0' : node.icon.fill || '#FFF'}
                                >
                                  <path d={node.icon.path}></path>
                                </svg>
                              </div>
                          </div>
                          <div
                          style={{display: !auth ? 'flex' : 'none'}}
                            title={node.name}
                            className={`node-name w-full ${isDarkMode ? 'dark-text' : 'light-text'}`}
                          >
                            {(node?.name?.length > 7 ? `${node?.name?.slice(0, 5)}...` : node?.name)}
                          </div>
                        </div>

                          <div style={{display:!auth ? 'flex' : 'none'}} className="action-buttons">
                            <div className="button-container">
                              <div
                                className="iconspage"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  duplicatePage(node._id);
                                }}
                              >
                                <div className="icon-action">
                                  <Doublicate />
                                </div>
                              </div>
                              <div
                                className="iconspage"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDialog(node._id);
                                  setOpen(false);
                                }}
                              >
                                <div className="icon-action">
                                  <AddBoxIcon sx={{ fontSize: '2.3vmin', color: '#FFF' }} />
                                </div>
                              </div>

                              <div className="iconspage">
                                <div
                                  className="iconspage"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedIcon(node.icon);
                                    handleDialog(node._id, undefined, node.name);
                                  }}
                                >
                                  <div className="icon-action">
                                    {isDarkMode ? <PenEditIcon fill="#FFF" stroke="#FFF" /> : <PenEditIcon fill="#FFF" stroke="#FFF" />}
                                  </div>
                                </div>
                                {a.length > 1 && (
                                  <div
                                    className="iconspage"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpen(false);
                                      handleDeleteNode(node._id, node.name);
                                    }}
                                  >
                                    <div className="icon-action">
                                      {isDarkMode ? <DeleteNameIcon fill="#FFF" /> : <DeleteNameIcon fill="#FFF" />}
                                    </div>
                                  </div>
                                )}
                              </div>

                              {node.children && node.children.length > 0 && (
                                <div
                                  className="expand-collapse"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleNode(node._id);
                                  }}
                                >
                                  <div className="expand-icon broder-4">
                                    {isNodeExpanded(node._id) ? (
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="img"
                                        className="octicon octicon-chevron-down"
                                        viewBox="0 0 12 12"
                                        width="2vmin"
                                        height="2vmin"
                                        fill={'#FFF'}
                                      >
                                        <path d="M6 8.825c-.2 0-.4-.1-.5-.2l-3.3-3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l2.7 2.7 2.7-2.7c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1l-3.2 3.2c-.2.2-.4.3-.6.3Z"></path>
                                      </svg>
                                    ) : (
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="img"
                                        className="octicon octicon-chevron-right"
                                        viewBox="0 0 12 12"
                                        width="2vmin"
                                        height="2vmin"
                                        fill={'#FFF'}
                                      >
                                        <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path>
                                      </svg>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>

                          </div>
                      </div>
                    </div>

                      <div style={{display:isNodeExpanded(node._id) && node.children && node.children.length > 0 ? 'flex' : 'none', flexDirection:'column'}} className="children-list">
                        {node.children.map((childNode) => (
                          <div
                            key={childNode._id}
                            className="child-node w-full"
                            onClick={() => {
                              setActiveId(childNode);
                              setOpen(false);
                            }}
                          >
                            <div
                            onMouseEnter={() => onMouseEnter(childNode)}
                            onMouseLeave={() => onMouseLeave(childNode)}
                              style={{ background: getBackgroundColorForChild(activeId, childNode, hovered, colorTheme)}}
                              className="child-node-header px-[1vmin] py-[.7vmin] items-center flex rounded-[1vmin] cursor-pointer">
                                <div style={{display: childNode?.icon ? 'flex' : 'none'}} className="logo-side">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    role="img"
                                    viewBox={childNode.icon.viewBox}
                                    width="2vmin"
                                    height="2vmin"
                                    fill={isDarkMode ? childNode.icon.fill || '#B0B0B0' : childNode.icon.fill || '#FFFFFF'}
                                  >
                                    <path d={childNode?.icon.path}></path>
                                  </svg>
                                </div>
                              <div
                                className={`node-name items-center flex h-full ${isDarkMode ? 'dark-text' : 'light-text'}`}
                              >
                                <div
                                  title={childNode.name}
                                  className="w-full">{childNode?.name?.length > 10 ? `${childNode?.name?.slice(0, 10)}...` : childNode?.name}</div>
                                  <div className="iconspage" style={{ width: 'auto', display: !auth ? 'none' : 'flex' }}>
                                    <div className='w-[100%] h-[100%]' style={{
                                      display: "flex", justifyContent: "end", alignItems: "center", textAlign: "right", flexDirection: 'row'
                                    }}
                                    >
                                      <div
                                        className="iconspage"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          dupSubPage(node._id, childNode._id)
                                        }}
                                      >
                                        <div className='icon-action'>
                                          {isDarkMode ? (
                                            <Doublicate />
                                          ) : <Doublicate />}
                                        </div>
                                      </div>
                                      <div
                                        className="iconspage"
                                        style={{display: a.length > 1 ? 'flex' : 'none'}}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setOpen(false)
                                          handleDeleteChild(node._id, childNode._id); // Update the function to handle child deletion
                                        }}
                                      >
                                        <div className='icon-action'>
                                        <DeleteNameIcon fill='#FFF' />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                  </div>
                )
              })}
            </div>
          </div>


            <div style={{display: auth ? 'none' : 'flex'}} className="add-new-page cursor-pointer w-full">
              <div
              onMouseEnter={() => onMouseEnter('add')}
              onMouseLeave={() => onMouseLeave('add')}
              style={{background: hovered['add'] ? colorTheme + '40' : colorTheme + '70'}}
                className={`add-new-button flex justify-center w-full ${isDarkMode ? 'dark-button' : ''}`}
                onClick={() => {
                  handleDialog();
                  setSelectedIcon(selectedIcon);
                  setOpen(false);
                }}
              >
                <div className="add-text ">
                  <div className="text">
                    {'Create New Page'}
                  </div>
                </div>
              </div>
            </div>
        </div>


        <Dialog
          PaperProps={{ sx: { background: isDarkMode ? '#111f3e99' : '#fff', borderRadius: "3vmin", overflow: 'hidden', height: '27.125vmin', zIndex: 0, width: '58.8vmin', maxWidth: '59.8vmin' } }}
          disablePortal // Disables rendering in a portal
          container={() => document.body} // Renders within the body
          sx={{ zIndex: 999 }}
          open={dialog ? true : false}
          onClose={() => setDialog(false)}
        >
          <div className="bluredContent rounded-[3vmin] h-full" style={{ padding: '0 3.5vmin 2vmin 3.5vmin', border: `.5vmin solid ${isDarkMode ? '#e9e9e920' : '#B0B0B0'}`, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <DialogTitle className='mx-auto text-center' sx={{
              fontSize: '3.3vmin', fontWeight: '700', color: isDarkMode ? '#B0B0B0' : '#161717', fontFamily: 'Inter', width: '100%', padding: '2vmin 0 0 0'
            }}>
              {deleteId !== null ? "Delete the page" : (activeEdit ? "Change the name of the page" : (childId ? "Add nested child" : childEdit ? 'change name of child' : "Create new page"))}
            </DialogTitle>
            <DialogContent sx={{ padding: '0', height: '5vmin', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {deleteId !== null ? (
                <div className='my-auto mx-auto text-center' style={{ fontSize: '2vmin', fontWeight: '400', fontFamily: 'Rubik', }}>
                  Are you really sure you want to delete the <span style={{ fontWeight: 'bold' }}>"{name}"</span> page?
                </div>
              ) : (
                <>
                  {(
                    <div className='w-full flex flex-row justify-center items-center'>
                      {childId && <div
                      onClick={() => setImagesSquare(!imagesSquare)}
                      className="pr-[3%] text-[#0867FC] cursor-pointer">Banner Image</div>}
                      <input
                        onFocus={(e) => {
                          e.target.style.borderColor = '#007bff';
                          e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = '#B0B0B0';
                          e.target.style.boxShadow = 'none';
                        }}
                        style={{
                          alignContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          padding: '.1vmin 1.4vmin',
                          height: '5vmin',
                          fontFamily: 'Inter, sans-serif',
                          fontWeight: '400',
                          fontSize: '1.5vmin',
                          color: '#161617',
                          backgroundColor: 'transparent',
                          border: `.25vmin solid ${isDarkMode ? '#B0B0B080' : '#98919190'}`,
                          borderRadius: '4vmin',
                          outline: 'none', width: '50%'
                        }}

                        id="InputText"
                        value={newActionName}
                        placeholder="Enter the name"
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setNewActionName(e.target.value);
                        }}

                      />
                      <div className='w-[10vmin] flex-row flex h-[5vmin] justify-around items-center' style={{ borderRadius: '3.5vmin', border: `.25vmin solid ${isDarkMode ? '#B0B0B080' : '#98919190'}` }}>
                        <div className="w-[50%] justify-center flex items-center">
                          {iconsSquare ?
                            <div className='cursor-pointer'
                              onClick={() => setIconsSquare(!iconsSquare)}>
                              <CloseIcon fill={isDarkMode ? '#B0B0B0' : '#989191'} width='3vmin' height='3vmin' /></div> :
                            selectedIcon ? (
                              <svg aria-hidden="true" focusable="false" role="img" viewBox={selectedIcon.viewBox} width="3vmin" height="3vmin" fill={!isDarkMode ? selectedIcon.fill ? selectedIcon.fill : '#000' : selectedIcon.fill ? selectedIcon.fill : '#000'}><path d={selectedIcon.path}></path></svg>
                            ) : (
                              <svg aria-hidden="true" focusable="false" role="img" viewBox={selectedIcon.viewBox} width="3vmin" height="3vmin" fill={!isDarkMode ? selectedIcon.fill ? selectedIcon.fill : '#000' : selectedIcon.fill ? selectedIcon.fill : '#000'}><path d={selectedIcon.path}></path></svg>

                            )
                          }
                        </div>
                        <div className='cursor-pointer w-[50%] justify-center flex items-center' onClick={() => setIconsSquare(!iconsSquare)}>
                          <PenEditIcon fill={'#000'} stroke={isDarkMode ? '#B0B0B0' : '#989191'} width='3vmin' height='3vmin' />
                        </div>

                      </div>

                    </div>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions sx={{ margin: 'auto auto', padding: '0', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div onClick={() => {
                setDialog(false)
              }}
                className={`flex ${isDarkMode ? 'button-outline-dark' : 'button-outline-light'} cursor-pointer w-[12.8vmin] h-[4vmin] rounded-[2.5vmin]`}
                style={{ margin: '0 1vmin', displa: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className=' text-[2vmin] ' style={{ fontWeight: '400' }}>Cancel</div>
              </div>
              {deleteId !== null ? (
                <div onClick={(e) => handleDeleteNodeFunc(deleteId)}
                  className={`flex ${isDarkMode ? 'button-basic-dark' : 'button-basic-light'} cursor-pointer w-[14.8vmin] h-[5vmin] rounded-[2.5vmin]`}
                  style={{ margin: '0 1vmin', displa: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className=' text-[2vmin]' style={{ fontWeight: '400' }}>Confirm</div>
                </div>
              ) : (
                <div onClick={(e) => {
                  if (activeEdit) {
                    handleChangeName(activeEdit, newActionName, selectedIcon);
                  } else if (childId) {
                    addItemToChildren(childId, selectedIcon, selectedImage);
                  } else {
                    handleAddAction(newActionName, selectedIcon);
                  }
                }} className={`flex ${isDarkMode ? 'button-basic-dark' : 'button-basic-light'} cursor-pointer w-[12.8vmin] h-[4vmin] rounded-[2.5vmin]`} style={{ margin: '0 1vmin', displa: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className=' text-[2vmin]' style={{ fontWeight: '400', }}>
                    {activeEdit || childEdit ? "Save" : "Create"}
                  </div>
                </div>
              )}
            </DialogActions>
            {imagesSquare && (
    <SelectionBox
    isImages={true}
    pozition='absolute top-0 right-0'
    images={images}
    setImgsCategory={setImgsCategory}
    imgsCategory={imgsCategory}
    category={category}
    onSelect={handleIconImageSelect}
    iconsSquare={imagesSquare}
    setIconsSquare={setImagesSquare}
    setImages={setImages}
    />
    )}
            {iconsSquare && (
              <SelectionBox
                pozition='absolute top-0 right-0'
                category={category}
                setIconsSquare={setIconsSquare}
                iconsSquare={iconsSquare}
                onSelect={handleIconSelect}
              />
            )}
          </div>

        </Dialog>
      </>
    );
  };

  return <div className='w-auto my-auto ml-[2vmin] h-full'> {renderTree(newData)}</div>;
}
