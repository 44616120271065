import { useEditor, useNode } from '@craftjs/core';
import { Grid, Divider, Tooltip, Typography } from '@mui/material';
import { Transition } from 'react-transition-group';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { makeStyles } from '@mui/styles';
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { Item } from '../Viewport/ToolBoxData/Item';
import { useAppProps } from '../../../DarkModeContext';

const usePanelStyles = makeStyles((_) => ({
  root: {
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-expanded': {
      margin: '0 0',
      minHeight: '7%',
      '&:before': {
        opacity: '1',
      },
    },
  },
}));

const useSummaryStyles = makeStyles((_) => ({
  root: {
    minHeight: 'auto',
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  content: {
    marginLeft: '0px',
  },
}));

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '1.5rem',
    backgroundColor: '#0867FC50',
    color: '#fff',
    padding: '0.5rem 1rem',
    borderRadius: '8px',
  },
  arrow: {
    color: '#fff',
  },
}));

export const ToolbarSection = ({
  searchTerm = "",
  title = "",
  big,
  children,
  onClick,
  props,
  toolbox,
  expanded,
  setExpanded,
  summary,
  small,
  icon,
  transformScale,

  auth,
  authParent,
  authChild,
}) => {
  const panelClasses = usePanelStyles({});
  const summaryClasses = useSummaryStyles({});
  const classes = useStyles();
  const { isDarkMode, colorTheme } = useAppProps();
    const [hovered, setHovered] = useState(false)
  
  const [expandedd, setExpandedd] = useState(false);
  const [show, setShow] = useState(false);

  const childrenArray = React.Children.toArray(children);


  const onMouseEnter = () => setHovered(true);
const onMouseLeave = () => setHovered(false);

  // Memoize filtered results to avoid recalculating on each render
  const filteredResults = useMemo(() => {
    return childrenArray.filter((child) =>
      child.props?.label?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [childrenArray, searchTerm]);

  const isTitleMatch = useMemo(() => 
    title?.toLowerCase().startsWith(searchTerm.toLowerCase()), 
    [title, searchTerm]
  );
  
  const showSection = useMemo(() => isTitleMatch || searchTerm === '', [isTitleMatch, searchTerm]);

  // Ref to track the AccordionDetails for scrolling
  const accordionDetailsRef = useRef(null);

  // Scroll into view when expanded state changes
  useEffect(() => {
    if (!small) {
    if (expanded || expandedd) {
      accordionDetailsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',  // Scroll to the top of the section
      });
    }
  }
  }, [expanded, expandedd, small]);

  return (
    <div style={{ display: "flex",  flexDirection: 'column', justifyContent: "start", margin:'0  auto', alignItems: "center", width: !toolbox ? small ? 'fit-content' : auth ? authChild ? '100%' : '97%' : '97%' : '100%'}}>
      {
        <div
        className={`${small ? 'w-[fit-content]' : 'w-full'}`}
        ref={accordionDetailsRef}
        >
          {!toolbox && showSection ? (
            <Item authParent={authParent} authChild={authChild} auth={auth} small={small} title={title} expanded={expanded} onOpen={setExpanded} onClose={onClick} />
          ) : (
            filteredResults.map((child, index) => (
              <Item small={small} key={index} title={child.props.label} expanded={expanded} onOpen={setExpanded} />
            ))
          )}
        </div>
      }

      <Accordion
        style={{
          minWidth: "0px", width: "100%", overflow: "visible", padding: "0px", paddingRight: "0px", height: 'fit-content', background: isDarkMode ? '#111F3E' : '#FFF',
        }}
        expanded={true || expanded || expandedd} classes={panelClasses}
      >

        <AccordionSummary style={{
          width: "100%", minHeight: "0px", background: colorTheme + '40' , borderRadius:'1vmin',  height: toolbox ? "fit-content" : "0vh", marginTop: "0px", margin: "0px", padding: !toolbox?  '0' : ".5vmin 0",
        }} classes={summaryClasses}>
          {!toolbox ? null : (
            <div
              style={{ width: "100%", display: "flex"}}
              className="flex flex-row justify-center items-center"
              id="BannerRoot"
              onClick={() => {
                setExpanded ? setExpanded(!expanded) : setExpandedd(!expandedd)
              }}
            >

              {/* <div style={{ width: "3.5Vmin",  }} className={` ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#161717]'}`}>
                {icon ? icon : null}
              </div> */}
              <div 
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={{ fontSize: '2.2vmin', width:'100%', textAlign:'center', borderRadius:'.5vmin', background: expanded ? isDarkMode ? hovered ? colorTheme + '70' : '#0867FC20' : '#0867FC50' : hovered ? colorTheme + '70' : colorTheme + '50',}} 
              className={`font-['Inter'] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#FFF]'}  ${isDarkMode ? 'bg-[#0867FC20]' : 'bg-[#0867FC50]'}  hover:bg-[#0867FC70]`}> {title}</div>
            </div>
          )}
        </AccordionSummary>

        <AccordionDetails
          style={{
            padding: '1vmin 0',
            margin:0,
            height: '100%',
            color: isDarkMode ? '#FFFFFF' : '#161717',
            display: expanded ? 'flex' : 'none',
            flexDirection: 'column',
            background:'transparent',
            transform: expanded ? 'scale(1)' : 'scale(0.1)',
          }}
        >
          {!toolbox ? (
            <Grid
              className='blured'
              container
              style={{
                width: transformScale ? 'fit-content' : '100%',
                boxShadow: 'none',
                justifyContent: 'center',
                alignItems: 'center',
                border: authParent ? isDarkMode ? '.25vmin solid #e9e9e950' : '.25vmin solid #e9e9e9' : '',
                padding: small ? '' : expanded ? auth ? '.5vmin' : '1vmin .15vmin' : '0',
                position: small ? 'absolute' : 'relative',
                maxWidth: !transformScale && '100%',
                background: isDarkMode? auth ? authChild ? '#e9e9e930' : '#111f3e10' : '#0867FC70' : auth ? authChild ? '#0867FC50' : '#FFFFFF' : '#0867FC70',
                borderRadius: '1vmin',
                minWidth: small ? 'max-content' : '100%',
                zIndex: 999999999999,
                visibility: expanded ? 'visible' : 'hidden',
                opacity: expanded ? 1 : 0,
                transform: expanded ? 'scale(1)' : 'scale(0.1)',
                transition: 'opacity 0.2s ease, transform 0.3s ease, visibility .3s ease',
              }}
            >
              <div
                style={{
                  overflowY: 'scroll',
                  height: 'fit-content',
                  padding: auth ? authChild ? '.7vmin' : '' : '.5vmin',
                  position:'relative',
                  width:'100%',
                }}
              >
                {filteredResults.map((child, index) => (
                  <React.Fragment key={index}>{child}</React.Fragment>
                ))}
                {filteredResults.length < 1 && React.Children.map(children, (child, index) => (
                  <React.Fragment key={index}>{child}</React.Fragment>
                ))}
              </div>
            </Grid>
          ) : (
            <Grid container>{children}</Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
