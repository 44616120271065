/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import {AyccLogo } from '../Editor-icons/EditorSvg.js';
import '../ToolBox.css'
import { Box, Modal,Typography, FormControl, FormControlLabel, FormGroup, Checkbox } from '@mui/material';

import StarRating from '../StarRating/StarRating.js';



export const ConfirmModal = (props) => {


    const {
        open,
        onClose,
        setOpen,
        text
    } = props

     const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height:'fit-content',
    transform: 'translate(-50%, -50%)',
    width: '60Vmin',
    bgcolor: 'background.paper',
    borderRadius: '2.5Vmin',
    boxShadow: 24,
    padding:'3Vmin',
    // transition: 'all .3s ease-in-out',
    overflow:'auto',
    scrollbarWidth: 'none',
    zIndex:"8888",
    scrollbarColor: 'transparent transparent',
  };


    return (
        <Modal
        open={open}
        onClose={setOpen}
        >
        <Box sx={style}>
          <>
            <div>
              <div className='flex flex-col justify-items-center px-[1.5Vmin] mt-[1Vmin]'>
                      <div className='text-center w-full text-[#000]' style={{fontWeight:'700', color:'#000', fontFamily:'Inter', fontSize:'3.6Vmin'}}>{text}</div>
                  </div>
        </div>
        <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center', height:'fit-content', padding:'.8Vmin 0' }}>
          <div className='bg-[transparent] cursor-pointer hover:bg-[#0867FC] text-[#0867FC] hover:text-[#fff]' onClick={() => setOpen(false)}
          style={{ border: '.25Vmin solid #0867FC', width: '17Vmin', height: '6Vmin', margin: '1.5Vmin', display: 'flex', borderRadius: '3Vmin' }}>
            <div className='text-[2Vmin]' style={{ fontWeight: '600', margin: 'auto auto' }}>Cancel</div>
          </div>
          <div className='bg-[#0867FC] cursor-pointer hover:bg-[#0156DC]' style={{ width: '22Vmin', height: '6Vmin', margin: '1.5Vmin', display: 'flex', borderRadius: '3Vmin' }}>
            <div className='text-[2Vmin] text-[#FFF]'
              onClick={() => onClose()}
              style={{ fontWeight: '600', margin: 'auto auto' }}>Confirm</div>
          </div>
        </div>
      </>
    </Box>
  </Modal>
)
}