/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarItem, ToolbarSection } from '../components/editor'
import { TabOption } from '../components/editor/Viewport/Sidebar/TabOption';
import { weightDescription } from '../components/selectors/basic/Text/text';
import { capitalize } from '@mui/material';
import { imagesArr } from '../components/editor/Viewport/Pop-up/ImageArray';
import { category, imgsCate } from '../components/selectors/basic/Image/ImageSettings';
import SelectionBox from '../components/editor/Viewport/Pop-up/Images-Icons-Modal';
import { useActiveIdContext } from '../ActiveIdContext';
import { useAppProps } from '../DarkModeContext';

export const LoginSettings = () => {
  const {isDarkMode} = useAppProps()
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const [activeTab, setActiveTab] = useState("Style Manager");
  const [iconsSquare, setIconsSquare] = useState(false)
    const [images, setImages] = useState(imagesArr)
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectImage, setSelectImage] = useState(null);

    const [imgsCategory, setImgsCategory] = useState(imgsCate)
    const [imageSelect, setImageSelect] = useState(true)
    const [iconSelect, setIconSelect] = useState(false)
    const [expanded, setExpanded] = useState('Label')


    const handleSelect = (selected) => {
      if (selected === 'image') { 
        setIconSelect(false)
        setImageSelect(true)
      } else if (selected === 'icon') {
        setIconSelect(true)
        setImageSelect(false)
      } else {
        setIconSelect(false)
        setImageSelect(false)
      }
    }



    const handleIconIconSelect = (selectedUrl) => {
        if(Array.isArray(selectedUrl) && selectedUrl.length) {
          setSelectedIcon(selectedUrl[0]);
          console.log(selectedUrl)

        } else {
          setSelectedIcon(selectedUrl)
          console.log(selectedUrl)
        }
        setIconsSquare(false);
      };

      const handleIconImageSelect = (selectedUrl) => {
        if(Array.isArray(selectedUrl) && selectedUrl.length) {
          setImageSelect(selectedUrl[0]);
          console.log(selectedUrl)

        } else {
          setImageSelect(selectedUrl)
          console.log(selectedUrl)
        }
        setIconsSquare(false);
      };




  return (
    <div className='flex flex-col component-settings' style={{ height: '100%', zIndex: 9999999 }}>
      
      <div className='w-full absolute left-0 top-0 p-[1%] flex flex-column justify-center z-[999999999]'>
      <div style={{ borderRadius: '1.5vmin', width:'100%', backdropFilter: 'blur(10px)', background:'#e9e9e930'}} className={`shadow-sm`}>

<div className='w-full text-center text-[2.2vmin] my-[.5vmin] text-[#0867FC80]'
    style={{ fontWeight: '700', userSelect: 'none' }}>
    CUSTOMIZE
  </div>
  </div>
  <div className='w-full pt-[2vmin] text-center text-[1.7vmin] mx-[auto] text-[#98919180]'
        style={{ fontWeight: '600', userSelect: 'none' }}>
        THIS IS THE AUTH CONTAINER
      </div>
    </div>
     
     <div style={{ width: "100%" }}>

      <div style={{ display: 'flex', margin: 'auto auto', justifyContent: 'center', alignItems: 'center', overflowY:'scroll', minWidth:'100%', maxHeight:'75vmin',  flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%' }}>

       
          <ToolbarSection
          auth
            expanded={expanded === 'Label'}
            setExpanded={() => setExpanded(expanded === 'Label' ? null : 'Label')}
            title="Label"
          >
              <ToolbarItem
              full={true}
              propKey="welcomeHeader"
              type="header Auth"
              placeholder='Change the Welcome Message'
            />
            <ToolbarItem
              full={true}
              loginPropKey="loginContents"
              LogoutPropKey="logoutContents"
              RegisterPropKey="registerContents"
              RegisterUserPropKey="registerUserContents"
              ErrorMessagePropKey="errorMessageContents"
              ResetPasswordPropKey="resetPasswordContents"
              VerifyEmailPropKey="verifyEmailContents"
              LoginVerifyUserCodePropKey="loginVerifyUserCodeContents"
              LoginAuthGrantPropKey="loginAuthGrantContents"
              LoginOtpPropKey="loginOtpContents"
              LoginUsernamePropKey="loginUsernamecontents"
              LoginPasswordPropKey="loginPasswordContents"

              LoginUpdatePasswordContnetsPropKey="loginUpdatePasswordContnets"
              LoginLinkConfirmPropKey="loginLinkConfirm"
              LoginpageExpiredContentsPropKey="loginpageExpiredContents"
              LoginConfigTotpContentsPropKey="loginConfigTotpContents"
              LoginUpdateProfileContentsPropKey="loginUpdateProfileContents"

              UpdateUserProfilePropKey="updateUserProfile"
              ReviewUserProfilePropKey="reviewUserProfile"
              UpdateEmailContentsPropKey="updateEmailContents"

              type="auth"
              label="Text"
            />
          </ToolbarSection>

        {activeTab === "Style Manager" ? (
          <>
          {imageSelect && <SelectionBox
                isImages={true}
                pozition='absolute top-0 right-0'    
                images={images}
                setImgsCategory={setImgsCategory}
                imgsCategory={imgsCategory}
                onSelect={handleIconImageSelect}
                iconsSquare={iconsSquare}
                setIconsSquare={setIconsSquare}
                setImages={setImages}
                />}

          {iconSelect && (
            <SelectionBox
              pozition='absolute top-0 right-0'
              category={category}
              setIconsSquare={setIconsSquare}
              iconsSquare={iconsSquare}
              onSelect={handleIconIconSelect}
            />
          )}

          
        {activeId.name !== 'Login' ? null : <ToolbarSection auth
          expanded={expanded === 'Image & Icon Template'}
          setExpanded={() => setExpanded(expanded === 'Image & Icon Template' ? null : 'Image & Icon Template')}
          title="Image & Icon Template"
          
         >
         <div className={`w-[100%] ml-auto flex flex-column gap-[1vmin] overflow-hidden h-full justify-center ${isDarkMode ? 'bg-[#e9e9e940]' : 'bg-[#0867FC50]'} ${imageSelect || iconSelect ? 'pb-[1vmin]' : ''} rounded-[1vmin]`}>
         <div className='flex w-fullh-full justify-around'>
         <div className='py-[.5vmin] px-[.8vmin] text-[#FFF] h-full w-full flex-1 flex justify-center' onClick={() => handleSelect('image')} style={{background: imageSelect ? isDarkMode? '#e9e9e9' : '#0867FC40' : '' , fontFamily:'Inter', fontWeight:'500', cursor:'pointer', fontSize:'1.8vmin', alignContent:'center', alignItems:'center'}}>Image</div>
          <div className='py-[.5vmin] px-[.8vmin] text-[#FFF] h-full w-full flex-1 flex justify-center' onClick={() => handleSelect('icon')} style={{background: iconSelect ? isDarkMode? '#e9e9e9' : '#0867FC40' : '' , fontFamily:'Inter', fontWeight:'500', cursor:'pointer', fontSize:'1.8vmin', alignContent:'center', alignItems:'center'}}>Icon</div>
          
         </div>
          { imageSelect && <div>
          <ToolbarItem
              imageSelect={imageSelect}
              IconsSquare={iconsSquare}
              setIconsSquare={setIconsSquare}
              full={true}
              propKey="src"
              type="src"
              placeholder='Add Image Url / OR'
            />
          </div>}
          
          { iconSelect && <div className='w-full'>
          <ToolbarItem
              noinput
              iconSearch
              selectedIcon={selectedIcon}
              IconsSquare={iconsSquare}
              setIconsSquare={setIconsSquare}
              full={true}
              propKey="icon"
              type="src"
              placeholder='Select Another Logo Icon'
            />
          </div>}
          </div>
         </ToolbarSection>
}
            
            <ToolbarSection
            auth
             expanded={expanded === 'Background'}
             setExpanded={() => setExpanded(expanded === 'Background' ? null : 'Background')}
             title="Background"
            >
              <div className={`w-full h-full ${isDarkMode ? 'bg-[#e9e9e940]' : 'bg-[#0867FC50]'} rounded-[1vmin] p-[.5vmin]`}>
              <ToolbarItem
                full={true}
                label='Background'
                propKey="background"
                type="bg"
              />
              </div>
              
             
            </ToolbarSection>
          </>
        ) : null}
      </div>
</div>
    </div>

  )
}