import { CreateHandlerOptions, DefaultEventHandlers, NodeId, Node, Element } from "@craftjs/core";
import { Container } from "./components/Container";
import React from 'react';

export default class CustomEventhandlers extends DefaultEventHandlers {
  
  handlers() {
    const defaultEventHandlers = super.handlers();
    const { store: { query, actions } } = this.options;

    return {
      ...defaultEventHandlers,

      drop: (
        el: HTMLElement,
        targetId: NodeId,
        options?: Partial<CreateHandlerOptions>
      ) => {
        if (!query.node(targetId)?.isDroppable()) return () => {};

        const unbindDragover = this.addCraftEventListener(el, "dragover", (e) => {
          e.craft.stopPropagation();
          e.preventDefault();

          if (!this.positioner || !query.node(targetId)?.get()) return;

          const indicator = this.positioner.computeIndicator(targetId, e.clientX, e.clientY);
          if (!indicator) return;

          let dropTargetId = indicator.placement.parent.id;
          if (!query.node(dropTargetId).isDroppable()) return;
          let dropTarget: Node | null = query.node(dropTargetId).get();

          let index = indicator.placement.index + (indicator.placement.where === "after" ? 1 : 0);

          if (this.dragTarget.type === "new") {
            // Handle new element creation
            let nodeTree = this.dragTarget.tree;
            const newElementId = nodeTree.rootNodeId;

            actions.addNodeTree(nodeTree, dropTargetId, index);
            actions.setNodeEvent("dragged", newElementId);

            // After the new element is added, check if it's the Layout Grid component
            if (nodeTree.rootNodeId) {
              const node = query.node(newElementId).get();
              if (node && node.data.name === "Layout Grid") {
                // Show modal to set props for "Layout Grid"
                this.showLayoutGridModal(newElementId);
              }
            }

            // Update the internal dragTarget
            this.dragTarget.type = "existing";
            this.dragTarget.nodes = [newElementId];
            this.dragTarget.tree = null;
          } else {
            // Handle move operation (same as existing logic)
            const node = query.node(this.dragTarget.nodes[0]).get();

            // Prevent dropping an element into itself
            while (dropTarget && dropTarget.id !== "ROOT") {
              if (dropTarget.id === node.id) return;
              dropTarget = dropTarget.data.parent ? query.node(dropTarget.data.parent).get() : null;
            }

            actions.move(node, dropTargetId, index);
          }
        });

        const unbindDragenter = this.addCraftEventListener(el, "dragenter", (e) => {
          e.craft.stopPropagation();
          e.preventDefault();
        });

        return () => {
          unbindDragenter();
          unbindDragover();
        };
      },

      drag: (el: HTMLElement, id: NodeId) => {
        if (!query.node(id)?.isDraggable()) return () => {};

        const unbindDefaultDragHandlers = defaultEventHandlers.drag(el, id);

        // Make sure the element is correctly set to not being dragged anymore
        const unbindDragEnd = this.addCraftEventListener(el, "dragend", (e) => {
          e.craft.stopPropagation();
          actions.setNodeEvent("dragged", []);
        });

        return () => {
          el.setAttribute("draggable", "false");
          unbindDefaultDragHandlers();
        };
      },
    };
  }

  // Function to show the modal and apply properties to the "Layout Grid" element
  showLayoutGridModal(nodeId: NodeId) {
    
    // Display the modal

    // On modal save, update the "Layout Grid" component properties
    // const applyChanges = () => {
    //   setProp(nodeId, (props: any) => {
    //     props.isBasic = isBasic; // Set the isBasic property
    //     return props;
    //   });
    //   setModalVisible(false); // Close the modal after applying changes
    //   refreshNode(nodeId); // Refresh the node to apply the changes
    // };

    // Modal content and functionality
      return (
        <div className="modal">
          <div className="modal-content">
            <h2>Set Layout Grid Properties</h2>
            <label>
              <input
                type="checkbox"
              />
              Is Basic
            </label>
            <button >Apply</button>
            <button >Cancel</button>
          </div>
        </div>
      );
    }

}
