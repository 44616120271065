import React from 'react';

const GridLayout = ({style}) => {
  const count = 6;


  return (
    <div
      style={{
        display: 'grid',
        height:'100%',
        width:'100%',
        gridTemplateColumns: style,
        zoom:'30%',
        border: '.25vmin solid #e9e9e990', // Border for the entire grid container
        padding: '.5vmin',
      }}
    >
      
       {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          style={{
          border: '.25vmin solid #e9e9e990', // Border for each grid item
          padding: '.5vmin',
          textAlign: 'center',
        }}
      >
      </div>

    ))}
     </div>
  );
};

export default GridLayout;
