/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Button } from '../Button';


import { Element, useNode } from '@craftjs/core';
import React from 'react';
import { Text } from '../Text';
import { Container } from  '../../../Container/index.js';
export const Layout4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div style={{display:"flex", flexDirection:"column", width:"100%"}} ref={connect}>
    <div style={{width:"100%", display:"flex"}}>
    <Element
    id="Container11111i-11"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    height="fit-content"
    width="99%"
    >
    </Element>
    <Element
    id="Container11111i-22"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    height="fit-content"
    width="99%"
    >
    </Element>
    <Element
    id="Container11111i-33"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    height="fit-content"
    width="99%"
    >
    </Element>
    <Element
    id="Container11111i-39"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    height="fit-content"
    width="99%"
    >
    </Element>
    </div>
    <div style={{width:"100%", display:"flex"}}>
    <Element
    id="Container11111i-14"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    height="fit-content"
    width="99%"
    >
    </Element>
    <Element
    id="Container11111i-25"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    height="fit-content"
    width="99%"
    >
    </Element>
    <Element
    id="Container11111i-36"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    height="fit-content"
    width="99%"
    >
    </Element>
    </div>
    <div style={{width:"100%", display:"flex"}}>
    <Element
    id="Container11111i-17"
    canvas
    is={Container}
    child={true}
    background='#e9e9e9'
    color='#000000'
    // height="fit-content"
    width="99%"
    height="700px"
    >
    </Element>
    </div>
      </div>;
};
Layout4.craft = {
  displayName: "Layout 4x3x1"
};