import '@progress/kendo-theme-default/dist/all.css';
import '../Stepper.css';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../../components/land/Svg';
import { useOptionsContext } from '../../OptionsContext';
import '../layout.css'
import Footer from '../ViewArea/ViewBottom'
import TopArea from '../ViewArea/ViewTop';
import SidesLR from '../ViewArea/SidesLR'
import { SidesIcon } from '../iconsSvg';
import BackgroundBanner from './BackgroundBanner';
import { useSidesContext } from '../../SideContext';

export const AppSetup = ({
  outStepper,
  children,
  enable,
  enabled,
}) => {

  const { 
    walksFor,
    appSettings, 
    pageSettings, 
    setPageSettings,
    widgets, styleWrIcon,
    styleRMIcon, bottomArea, topArea, rightArea, leftArea,
    review, setReview, setSelectedItem, setMenuHover, menuHover, 
    } = useSidesContext();

  const [hiddenAlert, setHiddenAlert] = useState(false)

  const handleClick = (id) => {
    setSelectedItem(id);
  };

  
  const [backgroundStyle, setBackgroundStyle] = useState({});

  const isColor = (value) => {
    const colorPattern = /^(#([0-9a-fA-F]{3}){1,2}|rgb(a)?\(\d{1,3}%?,\s*\d{1,3}%?,\s*\d{1,3}%?(,\s*\d?\.?\d+)?\)|hsl(a)?\(\d{1,3}°,\s*\d+%,\s*\d+%(,\s*\d?\.?\d+)?\)|[a-zA-Z]+)$/;
    return typeof value === 'string' || colorPattern.test(value);
  };

  const identifyValue = (value) => {
    if ((typeof value === 'string' && (value.startsWith('blob:') ))) {
      return 'Blob';
    } else if (typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'))) {
      return 'URL';
    } else if (isColor(value)) {
      return 'Color';
    } else {
      return 'Unknown';
    }
  };

  useEffect(() => {
    if (enabled) {
      setReview(true)
    }
  }, [enabled, review])

  useEffect(() => {
    const backgroundValue = appSettings?.background?.value;
    const backgroundSetup = identifyValue(backgroundValue);

    if (backgroundSetup === 'Blob') {
        setBackgroundStyle({
          backgroundImage: `url(${backgroundValue})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        });
    } else if (backgroundSetup === 'URL') {
      setBackgroundStyle({
        backgroundImage: `url(${backgroundValue})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      });
    } else if (backgroundSetup === 'Color') {
      setBackgroundStyle({ background: backgroundValue });
    } else {
      setBackgroundStyle({});
    }
  }, [appSettings?.background?.value]);

  useEffect(() => {

    if (walksFor.mainApp.value === 1) {
      setMenuHover(true)
      setPageSettings((prevSettings) => ({
        ...prevSettings,
        sidebar: {
          ...prevSettings.sidebar,
          position: 'left',
        },
      }));
    } else if (walksFor.mainApp.value === 3) {
      setMenuHover(true)
      setPageSettings((prevSettings) => ({
        ...prevSettings,
        sidebar: {
          ...prevSettings.sidebar,
          position: 'right',
        },
      }));
    }

  }, [walksFor.mainApp.value])

  const dynamicStyles = {
    border: !review && !outStepper && `.5vmin solid #e9e9e9`, 

  };

  const combinedStyles = { ...dynamicStyles, ...backgroundStyle, };


  return (
    <div className={`relative ${outStepper? '' : 'overflow-scroll'} min-h-[100%] text-[1.5vmin] w-full max-h-[100%] flex flex-col justify-between pb-[5vh]`}
    style={{...combinedStyles, zoom:'100%', height:'max-content',}}>
    {review && !outStepper && <div onMouseOver={() => setHiddenAlert(true)} onMouseLeave={() => setHiddenAlert(false)} className='w-full h-[2vmin] bg-[transparent] absolute top-0 left-0' style={{ zIndex: 99999999999999 }}>
      {hiddenAlert && !outStepper && <div
        // style={{ transition: 'all 0.3s ease-in-out' }}
        onClick={enable}
        className='w-full h-full pt-[2vmin]'>
        <div className={`cursor-pointer hover:bg-[#0078D3A1] bg-[#0078d352] border-[.25vmin] border-[#fff] w-[fit-content] mx-auto rounded-full p-[2vmin]`}>
          <CloseIcon fill='#fff' height='3vmin' width='3vmin' />
        </div>
      </div>
      }
    </div> 
    }
  {/* Fixed Top Area */}
  {topArea && <div className="flex-shrink-0">
    <TopArea
      outStepper={outStepper}
      handleClick={handleClick}
    />
  </div>
}

<div className="flex flex-grow min-h-[100%] relative">
    {outStepper ? (
      <div style={{ width: '100%', height:'100%', position: 'relative'}}>
        <div
          className={`${pageSettings.sidebar.position === 'right' ? 'right-0' : 'left-0'}`}
          style={{height:'100%', position: 'absolute', top: 0, bottom:0, width: 'fit-content' }}
        >
          <div className="flex flex-row justify-between items-center h-full">
            {(rightArea || leftArea) && (
              <div
                style={{ zIndex: 99999999 }}
                className={`h-full items-center flex ${pageSettings.sidebar.position === 'right' ? 'absolute right-0' : 'absolute left-0'}`}
              >
                {(!pageSettings.sidebar.openWay && (pageSettings.sidebar.visibility || widgets) && !menuHover) && (
                  <div
                    style={{ zIndex: 99999999, border: '1px solid transparent'}}
                    className="h-full"
                    onMouseMove={(e) => {
                      e.stopPropagation();
                      setMenuHover(true);
                    }}
                  />
                )}
                <SidesLR
                  outStepper={outStepper}
                  handleClick={handleClick}
                />
              </div>
            )}
          </div>
        </div>
        <div className='min-h-[100vh] w-[100%]'>{children}</div> 
      </div>
    ) : (
      <div className="relative w-full flex flex-column items-center justify-center h-full">
        <div
          className={`${pageSettings.sidebar.position === 'right' ? 'right-0' : 'left-0'}`}
          style={{ height: '100%', position: 'absolute', top: 0, width: 'fit-content' }}
        >
          <div className="relative flex flex-row justify-between items-center h-full">
            {walksFor.main.value >= 1 && walksFor.mainApp.value >= 1 && (
              <div
                style={{ zIndex: 999999 }}
                className={`h-full items-center flex ${pageSettings.sidebar.position === 'right' ? 'absolute right-0' : 'absolute left-0'}`}
              >
                {(!pageSettings.sidebar.openWay && (pageSettings.sidebar.visibility || widgets) && !menuHover) && (
                  <div
                    style={{ zIndex: 9999, border: '1px solid transparent' }}
                    className="h-full"
                    onMouseMove={(e) => {
                      e.stopPropagation();
                      setMenuHover(true);
                    }}
                  />
                )}
                <SidesLR 
                  outStepper={outStepper}
                  handleClick={handleClick}
                />
              </div>
            )}
          </div>
        </div>
        <BackgroundBanner />
         <div className="flex-shrink-0 w-full">
  {walksFor.mainApp.value < 2 ?
    null : walksFor.main.value >= 1 && (
      <Footer
        outStepper={outStepper}
        handleClick={handleClick}
        styleWrIcon={styleWrIcon}
        styleRMIcon={styleRMIcon}
      />
    )}
  </div>
        
      </div>
      
    )}
    
  </div>
  <div className="flex-shrink-0">
  {bottomArea && (
      <Footer 
      outStepper={outStepper}
      handleClick={handleClick}
      />
    )}
  </div>

</div>
  )
}
