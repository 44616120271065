import React, { useState, useMemo, useCallback } from 'react';
import { useNode } from '@craftjs/core';
import { ToolbarItem, ToolbarSection } from '../../../editor/index.js';
import { ArrowToRight } from '../../../editor/Viewport/Editor-icons/svgArray.js';
import { StatesItem } from './HookStates.js';
import { useAppProps } from '../../../../DarkModeContext.js';

export const ColumnLayoutSettings = () => {
  const [expanded, setExpanded] = useState(null)
  const [searchTerm, setSearchTerm] = useState('');
  const [customize, setCustomize] = useState({})

  const {
    options,
  } = useNode((node) => ({
    options: node.data.props,
  }));

  const {
    direction,
    layouts,
    node,
    displayName,
    actions: { setProp },
  } = useNode((node) => ({
    node: node,
    direction: node.data.props['direction'],
    layouts: node.data.props['layouts'],
    displayName: node.data.displayName
  }));

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCustomize = (group) => {
    setCustomize((prev) => ({
      ...prev,
      [group]: !prev[group], // Toggle the current group's state
    }));
  };

  const {savedProperties, setSavedProperties, storedProperties, setStoredProperties, confirmCopiedProperties, setConfirmCopiedProperties} = useAppProps()

  const handleConfirm = () => {
    setConfirmCopiedProperties(true)
    setStoredProperties(options)
  }

  const defaultProps = options
  
  const pasteProperties = () => {
    const updateProperties = (original, newProps) => {
      // Define the list of valid states that you want to process
      const validStates = ['default', 'hovered', 'active'];
    
      // Loop through the valid states only (default, hovered, active)
      validStates.forEach(stateType => {
        // Check if this state exists in both original and newProps
        if (original.hasOwnProperty(stateType) && newProps.hasOwnProperty(stateType)) {
          const stateProperties = newProps[stateType];  // Get the properties for this state
    
          // Iterate over properties inside this state
          Object.keys(stateProperties).forEach(propertyName => {
            // Update the property in the original object
            setProp((props) => {
              props[stateType][propertyName] = stateProperties[propertyName];

            })
          });
        }
      });
    
      return original;
    };
    
    const updatedObject = updateProperties(defaultProps, storedProperties);
  };
  
  return (
    <div className='flex flex-col component-settings' style={{ height: '100%', zIndex: 9999999, width:'100%' }}>
      <div>
        <div style={{ borderRadius: '1.5vmin', width: '100%', backdropFilter: 'blur(10px)' }} className={`shadow-sm absolute left-0 top-0 z-[999999999]`}>
          <input
            placeholder="Search by title..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              display: 'flex',
              margin: '1vmin auto',
              padding: '1vmin 1.4vmin',
              fontFamily: 'Inter, sans-serif',
              fontWeight: '400',
              fontSize: '2vmin',
              color: '#98919190',
              backgroundColor: 'transparent',
              border: '.25vmin solid #0867FC70',
              borderRadius: '2vmin',
              outline: 'none',
              width: '80%',
              height: '4vmin'

            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#007bff';
              e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#0867FC70';
              e.target.style.boxShadow = 'none';
            }}

          />
          <div className='flex w-full relative'>
          <div className='text-center w-full text-[2.2vmin] my-[.5vmin] text-[#0867FC80]'
            style={{ fontWeight: '700', userSelect: 'none' }}>
            CUSTOMIZE
          </div>
          <div className='flex absolute text-[1.2vmin] w-[25%] cursor-pointer items-center justify-center h-full top-0 left-0'>
            <div 
            onClick={handleConfirm}
            className={`rounded-[1vmin] ${(savedProperties?.length === 0 ||savedProperties === undefined) ? 'bg-[#98919130]' : 'bg-[#0867FC40] hover:bg-[#0867fC50]'} text-[#FFF] p-[.5vmin]`}>
              Copy
            </div>
          </div>
          <div className='flex absolute text-[1.2vmin] w-[25%] cursor-pointer items-center justify-center h-full top-0 right-0'>
            <div onClick={pasteProperties} className={`rounded-[1vmin] ${(storedProperties?.length === 0 ||storedProperties === undefined) ? 'bg-[#98919130]' : 'bg-[#0867FC40] hover:bg-[#0867fC50]'} text-[#FFF] p-[.5vmin]`}>
              Paste
            </div>
          </div>
          </div>
         
        </div>
          
         <div style={{ width: "100%" }}>
                    <div className='w-full mb-[1vmin] flex flex-row justify-between'>
                      <StatesItem currentState="default" actionState={options.actionState} setProp={setProp} />
                      <StatesItem currentState="hovered" actionState={options.actionState} setProp={setProp} />
                      <StatesItem currentState="active" actionState={options.actionState} setProp={setProp} />
                    </div>
                    <div className='w-full text-center text-[1.7vmin] mb-[5%] mx-[auto] text-[#98919180]'
            style={{ fontWeight: '600', userSelect: 'none' }}>
            THIS IS A {displayName.toLocaleUpperCase()}
          </div>
                    {/* <Toggle toggleIsImg={toggleIsSvg} on={svg} first={'Svg'} second={'Text'} /> */}
                    
        <div style={{ display: 'flex', margin: '0 auto', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap:'wrap', overflowY:'scroll', minWidth:'100%', maxHeight:'68vmin', paddingBottom: expanded && '1vh', height: '100%' }}>
          {/* <div className='w-[94%] py-[3%] px-[2%] rounded-[1.5vmin] mb-[.7vmin] bg-[#0867FC30]'>
            <div className='overflow-scroll h-[15vmin]'>
              <ToolbarItem propKey="childrenWidth" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Children Width" />
              <ToolbarItem propKey="childrenHeight" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Children Height" />
            </div>
          </div> */}
          


          <ToolbarSection
            searchTerm={searchTerm}
            expanded={expanded === 'Quick Style'}
            setExpanded={() => setExpanded(expanded === 'Quick Style' ? null : 'Quick Style')}
            title="Quick Style"
          >
            <ToolbarItem full={true} label='Background' propKey="background" type="bg" />

            <ToolbarItem
              full={true}
              propKey="flexDirection"
              optionsDrop={['row', 'column']}
              type="select-drop"
              label="Direction"
            />
            <ToolbarItem
              full={true}
              propKey="justifyContent"
              optionsDrop={['start', 'center', 'end', 'space-between', 'space-around', 'flex-start', 'flex-end']}
              type="select-drop"
              label="Justify content"
            />
            <ToolbarItem propKey="padding" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Padding" />
          </ToolbarSection>

          <ToolbarSection
            expanded={expanded === 'Appearance'}
            setExpanded={() => setExpanded(expanded === 'Appearance' ? null : 'Appearance')}
            title="Appearance"
          >
            <ToolbarItem full={true} label='Background' propKey="background" type="bg" />

          </ToolbarSection>


          <div style={{ display: options['actionState'] !== 'default' ? 'none' : 'flex', alignItems: 'center', width: '100%', flexDirection: 'column', }}>

            <ToolbarSection
              expanded={expanded === 'Flex Layout'}
              setExpanded={() => setExpanded(expanded === 'Flex Layout' ? null : 'Flex Layout')}
              title="Flex Layout"
            >
              <ToolbarItem
                full={true}
                propKey="display"
                optionsDrop={['flex', 'grid', 'block', 'none', 'inline-block', 'inline-flex']}
                type="select-drop"
                label="Display"
              />

              <ToolbarItem
                full={true}
                propKey="flexDirection"
                optionsDrop={['row', 'column']}
                type="select-drop"
                label="Direction"
              />

              <ToolbarItem
                full={true}
                propKey="flexWrap"
                optionsDrop={['wrap', 'noWrap']}
                type="select-drop"
                label="Flex Wrap"
              />
              <ToolbarItem
                full={true}
                propKey="justifyContent"
                optionsDrop={['start', 'center', 'end', 'space-between', 'space-around', 'flex-start', 'flex-end']}
                type="select-drop"
                label="Justify content"
              />

              <ToolbarItem
                full={true}
                propKey="justifyItems"
                optionsDrop={['start', 'center', 'end', 'stretch', 'flex-start', 'flex-end', 'self-start', 'self-end']}
                type="select-drop"
                label="Justify Items"
              />

              <ToolbarItem
                full={true}
                propKey="alignContent"
                optionsDrop={['start', 'center', 'end', 'space-between', 'space-around', 'flex-start', 'flex-end']}
                type="select-drop"
                label="Align Content"
              />

              <ToolbarItem
                full={true}
                propKey="alignItems"
                optionsDrop={['start', 'center', 'end', 'stretch', 'flex-start', 'flex-end', 'self-start', 'self-end']}
                type="select-drop"
                label="Align Items"
              />
            </ToolbarSection>
            <ToolbarSection
              searchTerm={searchTerm}
              expanded={expanded === 'Classes'}
              setExpanded={() => setExpanded(expanded === 'Classes' ? null : 'Classes')}
              title="Classes"
            >
              <ToolbarItem full={true} propKey="className" type="classes" label="Classes" />

            </ToolbarSection>
            <ToolbarSection searchTerm={searchTerm}
              expanded={expanded === 'Size'}
              setExpanded={() => setExpanded(expanded === 'Size' ? null : 'Size')}
              title="Size"
            >
              <ToolbarItem
                full={true}
                propKey='width'
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Width"
              />
              <ToolbarItem
                full={true}
                propKey="height"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Height"
              />
              <ToolbarItem
                full={true}
                propKey="minWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Min-Width"
              />
              <ToolbarItem
                full={true}
                propKey="maxWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Max-Width"
              />
            </ToolbarSection>

            <ToolbarSection
              expanded={expanded === 'margin'}
              setExpanded={() => setExpanded(expanded === 'margin' ? null : 'margin')}
              title="margin"
            >
              <div className='w-full'>
                <>
                  {customize['margin'] ? (
                    <>
                      <ToolbarItem propKey="marginTop" type="slider"
                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                        label="Top" />
                      <ToolbarItem propKey="marginRight" type="slider"
                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                        label="Right" />
                      <ToolbarItem propKey="marginBottom" type="slider"
                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                        label="Bottom" />
                      <ToolbarItem propKey="marginLeft" type="slider"
                        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                        label="Left" /></>
                  ) : (
                    <ToolbarItem propKey="margin" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Margin" />
                  )}
                  <div
                    onClick={() => handleCustomize('margin')}
                    style={{ borderRadius: '0 0 1vmin 1vmin', border: '.25vmin solid', zIndex: 999999, height: 'fit-content', width: 'fit-content' }}
                    className=' text-[#fff] hover:bg-[#0867FC40] absolute top-[-.15vmin] min-w-[6vmin] text-[1.8vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer p-[.5vmin]'>
                    {customize['margin'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>

                </>
              </div>
            </ToolbarSection>


            <ToolbarSection
              expanded={expanded === 'padding'}
              setExpanded={() => setExpanded(expanded === 'padding' ? null : 'padding')}
              title="Padding"
              props={['padding']}
            >
              <div className='w-full'>
                {customize['padding'] ? (
                  <>
                    <ToolbarItem propKey="paddingTop" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Top" />
                    <ToolbarItem propKey="paddingRight" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Right" />
                    <ToolbarItem propKey="paddingBottom" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Bottom" />
                    <ToolbarItem propKey="paddingLeft" type="slider"
                      unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                      label="Left" />
                  </>
                ) : (
                  <ToolbarItem propKey="padding" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Padding" />
                )}

                <div
                  onClick={() => handleCustomize('padding')}
                  style={{ borderRadius: '0 0 1vmin 1vmin', border: '.25vmin solid', zIndex: 999999, height: 'fit-content', width: 'fit-content' }}
                  className=' text-[#fff] hover:bg-[#0867FC40] absolute top-[-.15vmin] min-w-[6vmin] text-[1.8vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer p-[.5vmin]'>
                  {customize['padding'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>

              </div>
            </ToolbarSection>
            <ToolbarSection
              expanded={expanded === 'Transition'}
              setExpanded={() => setExpanded(expanded === 'Transition' ? null : 'Transition')}
              title="Transition"
            >
              <ToolbarItem label='will seen only in preview mode' type='heading' />
              <ToolbarItem
                full={true}
                optionsDrop={['all', 'transform', 'box-shadow', 'color', 'background-color', 'border-color', 'fill', 'filter', '']}
                propKey="transitionProperty"
                type="select-drop"
                label="Transition Property"
              />

              <ToolbarItem
                full={true}
                propKey="transitionDuration"
                type="slider"
                unitOptions={['ms']}
                label="Transition Duration"
              />

              <ToolbarItem
                full={true}
                optionsDrop={['ease-linear', 'ease-in', 'ease-out', 'ease-in-out']}
                propKey="transitionTimingFunction"
                type="select-drop"
                label="Transition Timing"
              />

              <ToolbarItem
                full={true}
                propKey="transitionDelay"
                type="slider"
                unitOptions={['ms']}
                label="Transition Delay"
              />

            </ToolbarSection>

          </div>
          <ToolbarSection
            searchTerm={searchTerm}
            expanded={expanded === 'Transform'}
            transformScale
            setExpanded={() => setExpanded(expanded === 'Transform' ? null : 'Transform')}
            title="Transform"
          >
            <ToolbarItem
              full={true}
              selectBoxes={['rotateXYZ', 'translate', 'scale', 'skew']}
              propKey="transformStyle"
              propKey2="positionsRotate"
              propKey3="positionsTranslate"
              propKey4="positionsScale"
              propKey5="positionsRotateXYZ"
              propKey6="positionsSkew"
              propKey7="positionsOrigin"
              type="select-box"
              label="Transform Style"
              action={true}
            />
          </ToolbarSection>
          {/* <ToolbarSection
            expanded={expanded === 'Shapes'}
            setExpanded={() => setExpanded(expanded === 'Shapes' ? null : 'Shapes')}
            title="Shapes"
          >

            <ToolbarItem
              full={true}
              optionsDrop={['Top', 'Bottom']}
              propKey="shapePos"
              type="Switch"
              label="Shapes Position"
            />

            <ToolbarItem
              full={true}
              optionsDrop={['Curve', 'Triangle', 'Wave', 'Waves Opacity', 'Triangle Asymmetrical', 'Tilt', 'Arrow', 'Split', 'Book']}
              propKey="shapeStyle"
              type="Switch"
              style
              label="Shape Style"
            />

            <ToolbarItem
              full={true}
              propKey="shapeHeight"
              type="Switch"
              number
              label="Shape Height"
            />
            <ToolbarItem
              full={true}
              propKey="shapeWidth"
              type="Switch"
              number
              label="Shape Width"
            />

            <ToolbarItem
              full={true}
              optionsDrop={['Underneath Section', 'On Top The Section']}
              propKey="shapeArrangement"
              type="Switch"
              style
              label="Shape Arrangement"
            />

            <ToolbarItem
              full={true}
              propKey="shapeColor"
              type="Switch"
              color
              label="Shape Color"
            />

          </ToolbarSection> */}

          <ToolbarSection searchTerm={searchTerm}
            expanded={expanded === 'Border'}
            setExpanded={() => setExpanded(expanded === 'Border' ? null : 'Border')}
            title="Border"
          >
            <ToolbarItem
              full={true}
              propKey="borderStyle"
              label="Border Style"
              optionsDrop={['inset', 'solid', 'dotted', 'dashed',]}
              type="select-drop"
            />

            <ToolbarItem
              full={true}
              propKey="borderWidth"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Border Width"
            />

            <ToolbarItem
              full={true}
              propKey="borderColor"
              type="bg"
              label="Border Color"
            />

            <ToolbarItem
              full={true}
              propKey="borderRadius"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Rounded"
            />
          </ToolbarSection>
          <ToolbarSection
            expanded={expanded === 'Shadow'}
            setExpanded={() => setExpanded(expanded === 'Shadow' ? null : 'Shadow')}
            title="Shadow"
          >
            <ToolbarItem
              full={true}
              propKey="shadowColor"
              type="color"
              label="Shadow Color"
            />
            <ToolbarItem
              full={true}
              propKey="shadowY"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Y offset"
            />

            <ToolbarItem
              full={true}
              propKey="shadowX"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="X offset"
            />

            <ToolbarItem
              full={true}
              propKey="shadowBlur"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Blur"
            />

          </ToolbarSection>

          <ToolbarSection
            expanded={expanded === 'Extra'}
            setExpanded={() => setExpanded(expanded === 'Extra' ? null : 'Extra')}
            title="Extra"
          >

            <ToolbarItem
              full={true}
              propKey="cursor"
              optionsDrop={['help', 'wait', 'crosshair', 'not-allowed', 'zoom-in', 'grab', 'pointer']}
              type="select-drop"
              label="Cursor"

            />

            <ToolbarItem
              full={true}
              propKey="filterOptions"
              optionsDrop={['Grayscale', 'Blur', 'Brightness', 'Contrast', 'Saturate', 'Invert', 'Opacity', 'Sepia']}
              type="select-drop"
              label="Filter option"
            />

            <ToolbarItem
              full={true}
              propKey="filterValue"
              type="slider"
              action={true}
              unitOptions={['px', '%', 'deg', 'em', 'in', 'rad']}
              label="Filter Value"
            />

          </ToolbarSection>



        </div>
      </div>
      </div>
    </div>
  );
};
