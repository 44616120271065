import React, { useState, useMemo, useCallback } from "react";
import { ToolbarSection } from "../../Toolbar";
import { Item } from "./Item";
import { useAppProps } from "../../../../DarkModeContext";

const Group = ({ key, node, setProp, noWrap, icon, name, components, full, ...props }) => {
  const [opens, setOpens] = useState(false);

  // Memoize the toggleExpanded function to prevent unnecessary re-creations
  const toggleExpanded = useCallback(() => {
    props.setExpanded(props.expanded.includes(name) ? [] : [name]);
  }, [name, props]);

  // Memoize renderComponentItem to avoid unnecessary re-creations on each render
  const renderComponentItem = useCallback(
    (x) => {
      return (
        <Item
          name={name}
          opens={opens}
          setOpens={setOpens}
          index={x}
          img={x.url}
          key={x.title}
          title={x.title}
          dragRef={(ref) => props.create(ref, x.component)}
        />
      );
    },
    [opens, name, props.create]
  );

  return (
    <div key={key} className="w-full mx-auto h-[fit-content]">
      {noWrap ? (
        <div className="flex h-full justify-center flex flex-column w-[100%]" >
          <div className="my-[1vmin]">
            <div style={{color:props.colorTheme + '90'}} className={`mb-[1vmin] font-bold text-[1.9vmin]`}>Layout</div>
            <div className="flex h-full justify-center w-full" style={{ flexDirection: 'column' }}>
              {components.filter(component => component.specific === 'parent').map(renderComponentItem)}
            </div>
          </div>
          <div>
            <div style={{color:props.colorTheme + '90'}} className={`font-bold mb-[1vmin] text-[1.9vmin]`}>Basic Elements</div>
            <div className="flex h-full justify-center w-full" style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
              {components.filter(x => x.specific !== 'parent').map(renderComponentItem)}
            </div>
          </div>
        </div>
      ) : (
        <div className="max-h-[fit-content]">
          <ToolbarSection
            expanded={props.expanded.includes(name)}
            setExpanded={toggleExpanded}
            toolbox={true}
            title={name}
            icon={icon}
          >
            {components.map(renderComponentItem)}
          </ToolbarSection>
        </div>
      )}
    </div>
  );
};

export default React.memo(Group); // Memoize the entire component to avoid unnecessary re-renders
