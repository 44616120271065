import React, { useMemo } from 'react';
import { useNode, useEditor, Element } from '@craftjs/core';
import { Button } from '@progress/kendo-react-buttons';
import { Parent } from '../../Parent';
import { BasicLink } from '../Link';
import  { ImageC }  from '../Image';
import { Heading } from '../Heading';
import { Text } from '../Text';

export const BasicNavbar = ({
    style = {},
    text = 'Click here',
    href,
    color = '#000',
    width,
    height,
    background,
    fontSize = '16px',
    borderColor,
    borderWidth,
    borderRadius,
    textAlign,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
fontWeight,
fontStyle,
textDecoration,
textTransform,
fontFamily,
lineHeight,
    isExternal,
    isLink,
}) => {
    const {
        connectors: { connect },
        actions: { setProp },
    } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),
    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, fontFamily, background, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign, marginTop, marginRight, marginBottom, marginLeft]);

      const navLinkStyle = {
        color: '#fff',
        textDecoration: 'none',
        fontSize: '16px',
        transition: 'color 0.3s',
    };
    

    return (
        <div ref={connect} style={{ display: 'inline-block', width:'100%' }}>
            <Element canvas is={Parent} id='random-id-400'  
            className='py-[10px] px-[20px]'
            style={{ backgroundColor: '#007bff', width:'100%', display: 'flex',
            alignItems: 'center', justifyContent: 'space-between', borderRadius: '0 0 8px 8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
            <Element canvas is={Parent} id='random-id-402' style={{ display: 'flex', alignItems: 'center' }}>
                <ImageC src="https://file.rendit.io/n/hgQeGwikg5Hh6qeQRkUN.svg" 
                alt="Logo" 
                style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                <Text text='Site Name' style={{ color: '#fff', fontSize:'24px', margin: 0 }} />
            </Element>
            
            <Element canvas is={Parent} id='random-id-401' style={{ display: 'flex', gap: '20px' }}>
                <BasicLink href="#home" style={navLinkStyle}>Home</BasicLink>
                <BasicLink href="#about" style={navLinkStyle}>About</BasicLink>
                <BasicLink href="#services" style={navLinkStyle}>Services</BasicLink>
                <BasicLink href="#contact" style={navLinkStyle}>Contact</BasicLink>
            </Element>
            </Element>
        </div>
    );
};

BasicNavbar.craft = {
    displayName: 'Navbar',
    props: {
        text: 'Click here',
        href: null,
        color: '#000',
        width:null,
        height:null,
        background:null,
        fontSize: '16px',
        shadowX: null,
        shadowY: '1px',
        shadowColor:null,
        shadowBlur: '1px',
        marginTop: null,
        marginRight: null,
        marginBottom: null,
        marginLeft: null,
  borderRadius: null,
        background:null,
        borderColor:null,
        borderWidth:null,
        textAlign:null,
        fontWeight:null,
        textTransform:null,
        fontStyle:null,
        textDecoration:null,
        fontFamily:null,
        textTransform:null,
        lineHeight:null,
        isNavbar:true
    },
    related: {
        toolbar: '', // Create a LinkSettings component for custom options
    },
};
