import React, { useState, useRef } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useNode, useEditor } from '@craftjs/core';
import { CheckBoxSettings } from './CheckBoxSettings';
import { ResizableWrapper } from '../../ResizableWrapper';
import MainElement from '../MainElements/MainElement';

const defaultProps = {
  actionState: 'default',
  isCheckBox:true,
        style : {},
        default : {
          backgroundColor:'#e9e9e9',
          borderColor:'#e9e9e990',
          borderRadius:'1vmin',
          borderWidth:'.25vmin',
          fontSize : '16px',
          label:'Checkbox',
          width:'16px',
          labelPosition: 'after',
          color:'#0867FC',
          backgroundColor: '#989191',
          borderColor : '#0867FC',
          borderWidth:'.25vmin',
          borderRadius:'.5vmin',
          className:''
        },
        hovered : {},
        active: {}
}

export const CheckBoxInput = ({
        style,
        ...defaultProps
}) => {

      const {
          node,
          connectors: { connect },
          selected,
          isDragged,
        } = useNode((node) => ({
          node:node,
          selected: node.events.selected,
          isDragged: node.events.dragged,
      
        }));

        const {
          fontSize,
          label,
          width,
          labelPosition,
          color,
          backgroundColor,
          borderColor,
          borderWidth,
          borderRadius,
          className,
        } = defaultProps.default
      
        const { enabled } = useEditor((state) => ({
          enabled: state.options.enabled,
        }));


        const [checked, setChecked] = useState(true);
        const [isHovered, setIsHovered] = useState(true);
          const mergedRef = useRef(null);
          const [activeBackground, setActiveBackground] = useState(true);
        

        const handleMouseEnter = (e) => {
          setIsHovered(true);
        };
    
      const handleMouseLeave = (e) => {
          setIsHovered(false);
      };

        const handleChange = (event) => {
          setChecked(event.target.checked);
        };
        
        const handleProperty = (value, propertyName, split) => {
            if (value !== null && !split) {
            return { [propertyName]: value?.split(" ").join("") }
            }

            if (value !== null && split === 'splitBg') {
            return { [propertyName]:  value !== null ? value : '' }
            } else {
            return;
            }
        };


        const styleProps = {
        
              ...(color !== null && {
                color: color?.startsWith('#') ? color : 'transparent',
                backgroundImage: !color?.startsWith('#') ? color : undefined,
                WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
                backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
              }),
          
            ...handleProperty(width, 'width'),
            ...handleProperty(fontSize, 'fontSize'),

           
          };

    const CustomStyle = `
    background-color: ${backgroundColor} !important;
    border-color: ${borderColor} !important;
    border-radius: ${borderRadius};
    width: ${fontSize};
    height: ${fontSize};
    border: ${borderWidth} solid;
  `;

  const memoizedProps = {
    padding:'10px 0', alignItems:'center', display:'flex'
  }

  const randomId = Math.floor(Math.random() * 1000);

        
  return ( 
    <MainElement
activeBackground={activeBackground}
connect={connect}
enabled={enabled}
selected={selected}
mergedRef={mergedRef}
defaultProps={defaultProps['default']}
hoverProperties={defaultProps['hovered']}
activeProperties={defaultProps['active']}
isDragged={isDragged}
isHovered={isHovered}
onMouseOver={handleMouseEnter}
onMouseOut={handleMouseLeave}
memoizedProps={memoizedProps}
  className={`${(enabled && 'helloworldsParentChildren')}`}
  >
    <Checkbox
    defaultChecked={checked}
    onChange={handleChange}
    labelPlacement={labelPosition}
    id={`checkbox${randomId}`} 
    value={checked}
    style={{...defaultProps}}
    />
 <ResizableWrapper
        selected={selected} 
        childRef={mergedRef} 
        setActiveBackground={setActiveBackground}
        setIsHovered={setIsHovered} 
        isHovered={isHovered}/> 
    </MainElement>
    );
};

CheckBoxInput.craft = {
    displayName: 'CheckBox',
    props: defaultProps,
    related: {
      toolbar: CheckBoxSettings,
    },
  };