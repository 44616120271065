import React, { useRef } from 'react';
import { useSidesContext } from '../SideContext';

const AreaEl = ({ 
    style = {},
    className,
    children,
    id,
    onClick,
    outStepper,
    ...restProps
}) => {

    const {
        review,
        open,
        closePanel,
        styles
    } = useSidesContext()
    
    const divRef = useRef(null);

    return (
        <div
            ref={divRef}
            onClick={onClick}
            id={id}
            style={{...styles[id], ...style, position: className === undefined && 'relative', borderStyle: 'dashed' }}
            className={`${className} ${!review ? (open === id && closePanel) ? 'border-[.25vmin] border-[#16171770] rounded-[1vmin]' : 'border-none hover:border-[.25vmin] hover:border-[#16171740]' : ''}`} 
            {...restProps}
        >
            {children}
        </div>
    );
};

export default AreaEl;
