import { useNode, Element, useEditor } from '@craftjs/core';
import React, { useState, useEffect, useRef } from 'react';
import { NewWhyUsSettings } from './newWhyusSetting';
import { Text } from '../../basic/Text';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const WhyUs_1 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [

        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[white] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-400' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-402' className='w-full h-full flex  flex-wrap justify-center' >

                        <Element is={Parent} canvas id='random-id-403' className='w-full h-full flex mx-auto my-auto flex-col p-8' style={{ flex: '49%', minWidth: '300px' }}>


                            <Element is={Parent} canvas id='random-id-404' className={`mb-5 text-[#100103] text-[38px]`} style={{ fontWeight: '700', fontFamily: 'Rubik' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-1'  >
                                    <Text text='Why Us' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#B20312' }} id='random-id-2'  >
                                    <Text text='.' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`text-[#000000] text-[16px]`} id='random-id-3'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>


                        </Element>

                        <Element is={Parent} canvas id='random-id-405' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-406' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-407' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#B20312]' id='random-id-4'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-408' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-5'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-6'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-409' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#B20312]' id='random-id-7'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-410' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-8'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-9'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>


                            <Element is={Parent} canvas id='random-id-411' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random-id-412' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#B20312]' id='random-id-10'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-413' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-11'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-12'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>


                                <Element is={Parent} canvas id='random-id-414' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#B20312]' id='random-id-13'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-415' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-14'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-15'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>


                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto  py-10" style={{ background: '#121826' }}>
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-416' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent', }}>
                    <Element is={Parent} canvas id='random-id-418' className='w-full h-full flex  flex-wrap justify-center' >


                        <Element is={Parent} canvas id='random-id-419' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col sm:text-center md:text-center lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Rubik' }} className={`mb-5 IncreaseConversion2 text-[38px]`} id='random-id-16'  >
                                <Text text='Why Us' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`text-[#FFFFFF] text-[16px]`} id='random-id-17'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>


                        <Element is={Parent} canvas id='random-id-420' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-421' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random-id-422' className='m-2 flex  min-h-[100px]' style={{ border: '1px solid #FFFFFF1A', flex: '30%', minWidth: '200px' }}>
                                    <Element is={Parent} canvas className='text-[#4BDBA2] mx-4 my-auto ' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} id='random-id-18'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-423' className='mx-auto my-auto flex flex-col ' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#4BDBA2' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-19'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#FFF' }} className='mx-auto my-auto' id='random-id-20'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-424' className='m-2 flex  min-h-[100px]' style={{ border: '1px solid #FFFFFF1A', flex: '30%', minWidth: '200px' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#4BDBA2] my-auto mx-4' id='random-id-21'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-425' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#4BDBA2' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-22'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#FFF' }} className='mx-auto my-auto' id='random-id-23'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>


                            <Element is={Parent} canvas id='random-id-426' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random-id-427' className='m-2 flex  min-h-[100px]' style={{ border: '1px solid #FFFFFF1A', flex: '30%', minWidth: '200px' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#4BDBA2] my-auto mx-4' id='random-id-24'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-428' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#4BDBA2' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-25'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#FFF' }} className='mx-auto my-auto' id='random-id-26'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>


                                <Element is={Parent} canvas id='random-id-429' className='m-2 flex  min-h-[100px]' style={{ border: '1px solid #FFFFFF1A', flex: '30%', minWidth: '200px' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#4BDBA2] my-auto mx-4' id='random-id-27'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-430' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#4BDBA2' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-28'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#FFF' }} className='mx-auto my-auto' id='random-id-29'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>


                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#213156] py-10" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-431' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-433' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-434' className='w-full h-full flex flex-col my-auto  mx-auto sm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Open Sans' }} className={`mb-5 text-[#EA6EF4] text-[38px]`} id='random-id-30'  >
                                <Text text='Why Us' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '35.2px' }} className={`text-[#FFFFFF] text-[16px]`} id='random-id-31'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-435' className='w-[100%] h-full justify-center mx-auto my-auto items-center  flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas id='random-id-436' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-437' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFFFFF1A' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#EA6EF4]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Open Sans' }} id='random-id-32'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-438' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', lineHeight: '22.4px', color: '#EA6EF4' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-33'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '19.2px', color: '#FFFFFF' }} id='random-id-34'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-439' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFFFFF1A' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#EA6EF4]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Open Sans' }} id='random-id-35'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-440' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', lineHeight: '22.4px', color: '#EA6EF4' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-36'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '19.2px', color: '#FFFFFF' }} id='random-id-37'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-441' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random-id-442' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFFFFF1A' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#EA6EF4]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Open Sans' }} id='random-id-38'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-443' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', lineHeight: '22.4px', color: '#EA6EF4' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-39'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '19.2px', color: '#FFFFFF' }} id='random-id-40'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-444' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFFFFF1A' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#EA6EF4]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Open Sans' }} id='random-id-41'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-445' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', lineHeight: '22.4px', color: '#EA6EF4' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-42'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Open Sans', lineHeight: '19.2px', color: '#FFFFFF' }} id='random-id-43'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,



        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#DFFFF9] py-10" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-446' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-448' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-449' className='w-full h-full flex flex-col my-auto  mx-auto sm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className={`mb-5 text-[#31A993] text-[38px]`} id='random-id-44'  >
                                <Text text='Why Us' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Montserrat', lineHeight: '35.2px' }} className={`text-[#031815] text-[16px]`} id='random-id-45'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-450' className='w-[100%] h-full justify-center mx-auto my-auto items-center  flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas id='random-id-451' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-452' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '30px', border: '1px solid #31A99333' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#31A993]' style={{ fontSize: '26px', fontWeight: '700', fontFamily: 'Montserrat', display: 'inline-block' }} id='random-id-46'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-453' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Montserrat', lineHeight: '22.4px', color: '#31A993' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-47'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Montserrat', lineHeight: '19.2px', color: '#031815' }} id='random-id-48'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-454' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '30px', border: '1px solid #31A99333' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#31A993]' style={{ fontSize: '26px', fontWeight: '700', fontFamily: 'Montserrat', display: 'inline-block' }} id='random-id-49'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-455' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Montserrat', lineHeight: '22.4px', color: '#31A993' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-50'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Montserrat', lineHeight: '19.2px', color: '#031815' }} id='random-id-51'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-456' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random-id-457' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '30px', border: '1px solid #31A99333' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#31A993]' style={{ fontSize: '26px', fontWeight: '700', fontFamily: 'Montserrat', display: 'inline-block' }} id='random-id-52'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-458' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Montserrat', lineHeight: '22.4px', color: '#31A993' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-53'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Montserrat', lineHeight: '19.2px', color: '#031815' }} id='random-id-54'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-459' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '30px', border: '1px solid #31A99333' }}>
                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#31A993]' style={{ fontSize: '26px', fontWeight: '700', fontFamily: 'Montserrat', display: 'inline-block' }} id='random-id-55'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-460' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Montserrat', lineHeight: '22.4px', color: '#31A993' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-56'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Montserrat', lineHeight: '19.2px', color: '#031815' }} id='random-id-57'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto bg-[white] py-10" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-461' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-463' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-464' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Inter' }} className={`mb-5 text-[#0078D3] text-[38px]`} id='random-id-58'  >
                                <Text text='Why Us.' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Inter', lineHeight: '35.2px' }} className={`text-[#000000] text-[16px]`} id='random-id-59'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-465' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-466' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-467' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#0078D3]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Inter' }} id='random-id-60'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-468' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Inter', lineHeight: '22.4px', color: '#0078D3' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-61'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '19.2px', color: '#000000' }} id='random-id-62'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-469' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#0078D3]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Inter' }} id='random-id-63'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-470' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Inter', lineHeight: '22.4px', color: '#0078D3' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-64'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '19.2px', color: '#000000' }} id='random-id-65'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-471' className='flex  flex-wrap w-full justify-center mx-auto' >
                                <Element is={Parent} canvas id='random-id-472' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#0078D3]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Inter' }} id='random-id-66'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-473' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Inter', lineHeight: '22.4px', color: '#0078D3' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-67'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '19.2px', color: '#000000' }} id='random-id-68'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-474' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#0078D3]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Inter' }} id='random-id-69'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-475' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Inter', lineHeight: '22.4px', color: '#0078D3' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-70'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Inter', lineHeight: '19.2px', color: '#000000' }} id='random-id-71'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto bg-[white] py-10" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-476' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-478' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-479' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Voces' }} className={`mb-5 text-[#020212] uppercase text-[38px]`} id='random-id-72'  >
                                <Text text='Why Us' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Voces', lineHeight: '35.2px' }} className={`text-[#020212] text-[16px]`} id='random-id-73'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-480' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-481' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-482' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A', background: '#F8F8F8' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#020212]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-74'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-483' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#020212' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-75'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#020212' }} id='random-id-76'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-484' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A', background: '#F8F8F8' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#020212]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-77'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-485' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#020212' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-78'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#020212' }} id='random-id-79'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-486' className='flex  flex-wrap w-full justify-center mx-auto' >
                                <Element is={Parent} canvas id='random-id-487' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A', background: '#F8F8F8' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#020212]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-80'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-488' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#020212' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-81'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#020212' }} id='random-id-82'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-489' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #0000000A', background: '#F8F8F8' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#020212]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-83'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-490' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#020212' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-84'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas className='mx-auto my-auto' style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#020212' }} id='random-id-85'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto bg-[#FBFAFF] py-10" >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-491' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-493' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-494' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Voces' }} className={`mb-5 text-[#6750A4] text-[38px]`} id='random-id-86'  >
                                <Text text='Why Us' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Voces', lineHeight: '35.2px' }} className={`text-[#020009] text-[16px]`} id='random-id-87'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-495' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-496' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-497' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '40px', background: '#6750A4' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#D0BCFF]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-88'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-498' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#D0BCFF' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-89'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#D0BCFF' }} className='mx-auto my-auto' id='random-id-90'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-499' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '40px', background: '#6750A4' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#D0BCFF]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-91'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-500' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#D0BCFF' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-92'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#D0BCFF' }} className='mx-auto my-auto' id='random-id-93'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-501' className='flex  flex-wrap w-full justify-center mx-auto' >
                                <Element is={Parent} canvas id='random-id-502' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '40px', background: '#6750A4' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#D0BCFF]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-94'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-503' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#D0BCFF' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-95'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#D0BCFF' }} className='mx-auto my-auto' id='random-id-96'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-504' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', borderRadius: '40px', background: '#6750A4' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#D0BCFF]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Voces' }} id='random-id-97'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-505' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Voces', lineHeight: '22.4px', color: '#D0BCFF' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-98'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Voces', lineHeight: '19.2px', color: '#D0BCFF' }} className='mx-auto my-auto' id='random-id-99'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto bg-[#2A2A2A] py-10" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-506' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-508' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-509' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Rum Raisin' }} className={`mb-5 text-[#FFD600] text-[38px]`} id='random-id-100'  >
                                <Text text='Why Us.' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px' }} className={`text-[#FFFFFF] text-[16px]`} id='random-id-101'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-510' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-511' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-512' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFD6001A', background: '#FFD600', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} id='random-id-102'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-513' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#2A2A2A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-103'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#2A2A2A' }} className='mx-auto my-auto' id='random-id-104'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-514' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFD6001A', background: '#FFD600', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} id='random-id-105'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-515' iv className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#2A2A2A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-106'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#2A2A2A' }} className='mx-auto my-auto' id='random-id-107'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-516' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random-id-517' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFD6001A', background: '#FFD600', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} id='random-id-108'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-518' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#2A2A2A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-109'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#2A2A2A' }} className='mx-auto my-auto' id='random-id-110'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-519' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #FFD6001A', background: '#FFD600', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} id='random-id-111'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-520' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#2A2A2A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-112'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#2A2A2A' }} className='mx-auto my-auto' id='random-id-113'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto bg-[#F7FBFE] py-10" >
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-40910' className='max-w-[1300px] h-full '>
                    <Element is={Parent} canvas id='random-id-40012' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-40023' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas id='random-id-40034' className={`mb-5 text-[#6064D2] text-[38px]`} style={{ fontWeight: '700', fontFamily: 'Rubik' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-1'  >
                                    <Text text='Why Us' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#B20312' }} id='random-id-2'  >
                                    <Text text='.' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '35.2px', color: '#000' }} className={` text-[16px]`} id='random-id-3329'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-40045' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-40056' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-40077' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #6064D21A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#6064D2]' id='random-id-429'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-40088' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-52387'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-632938'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-40099' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #6064D21A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#B20312]' id='random-id-73283'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-4010' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-8230'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-92387'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-40111' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random--id-40212' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #6064D21A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#B20312]' id='random-id-13230'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-40313' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-12381'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-12322'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-40414' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', border: '1px solid #6064D21A' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Rubik' }} className='my-auto mx-4  text-[#B20312]' id='random-id-1433'  >
                                        <Text text='+22' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-40515' className='mx-auto my-auto flex flex-col' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Rubik', lineHeight: '22.4px', color: '#B20312' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-1324'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Rubik', lineHeight: '19.2px', color: '#100103' }} className='mx-auto my-auto' id='random-id-1325'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>
                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto bg-[#FEFBF7] py-10" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-506' className='max-w-[1300px] h-full ' style={{ border: '1px solid transparent' }}>
                    <Element is={Parent} canvas id='random-id-508' className='w-full h-full flex  flex-wrap justify-center' >
                        <Element is={Parent} canvas id='random-id-509' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left p-8' style={{ flex: '49%', minWidth: '300px' }}>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Ruluko' }} className={`mb-5 text-[#A25738] text-[38px]`} id='random-id-100'  >
                                <Text text='Why Us.' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Sansation', lineHeight: '35.2px' }} className={`text-[#FFFFFF] text-[16px]`} id='random-id-101'  >
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-510' className='w-[100%] h-full justify-center mx-auto my-auto items-center flex flex-col flex-wrap' style={{ flex: '49%', minWidth: '300px' }} >
                            <Element is={Parent} canvas id='random-id-511' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >

                                <Element is={Parent} canvas id='random-id-512' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', background: '#A25738', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Sansation' }} id='random-id-102'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-513' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Sansation', lineHeight: '22.4px', color: '#ffffff' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-103'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Sansation', lineHeight: '19.2px', color: '#ffffff' }} className='mx-auto my-auto' id='random-id-104'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>

                                <Element is={Parent} canvas id='random-id-514' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', background: '#A25738', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Sansation' }} id='random-id-105'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-515' iv className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Sansation', lineHeight: '22.4px', color: '#ffffff' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-106'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Sansation', lineHeight: '19.2px', color: '#ffffff' }} className='mx-auto my-auto' id='random-id-107'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-516' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                                <Element is={Parent} canvas id='random-id-517' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', background: '#A25738', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Sansation' }} id='random-id-108'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-518' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Sansation', lineHeight: '22.4px', color: '#ffffff' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-109'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '400', fontFamily: 'Sansation', lineHeight: '19.2px', color: '#ffffff' }} className='mx-auto my-auto' id='random-id-110'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-519' className='m-2 flex  min-h-[100px]' style={{ flex: '30%', minWidth: '200px', background: '#A25738', borderRadius: '10px' }}>

                                    <Element is={Parent} canvas className='my-auto mx-4  text-[#2A2A2A]' style={{ display: 'inline-block', fontSize: '26px', fontWeight: '700', fontFamily: 'Sansation' }} id='random-id-111'  >
                                        <Text text='+22' />
                                    </Element>

                                    <Element is={Parent} canvas id='random-id-520' className='mx-auto my-auto flex flex-col flex-col flex' style={{ fontSize: '14px', fontWeight: '700', fontFamily: 'Sansation', lineHeight: '22.4px', color: '#ffffff' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-112'  >
                                            <Text text='Lorem ipsum' />
                                        </Element>

                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '12px', fontWeight: '700', fontFamily: 'Sansation', lineHeight: '19.2px', color: '#ffffff' }} className='mx-auto my-auto' id='random-id-113'  >
                                            <Text text='Dolor sit amet, consectetur adipiscing elit' />
                                        </Element>

                                    </Element>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])

    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    )
}

WhyUs_1.craft = {
    displayName: "WhyUs 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewWhyUsSettings,
    },
};
