// Define an asynchronous function to fetch an authentication token
const fetchToken = async () => {
  try {
      // Send a GET request to the specified API endpoint
      const response = await fetch("https://25.36.172.239:443/api/get-token", {
          credentials: "include", // Include session cookies in the request
      });

      // Check if the HTTP response status is OK (status code 200-299)
      if (response.ok) {
          // Parse the JSON response
          const data = await response.json();
          console.log("Token retrieved:", data);

          // Check if the response contains a token
          if (data.token) {
              return true; // Return true if a token is present
          } else {
              return false; // Return false if no token is present
          }
      } else {
          // Log an error message if the response status is not OK
          console.error("Error while fetching the token");
      }
  } catch (error) {
      // Catch and log any network or runtime errors
      console.error("Error:", error);
  }

  // Return undefined if an error occurs or no token is retrieved
  return;
};

// Export the function as the default export of this module
export default fetchToken;
