import React, { memo, useEffect, useMemo, useState } from 'react';
import { CloseIcon2 } from '../Viewport/Editor-icons/svgArray';
import { useEditor } from '@craftjs/core';
import { useAppProps } from '../../../DarkModeContext';

export * from './ToolbarItem';
export * from './ToolbarSection';
export * from './ToolbarTextInput';
export * from './ToolbarDropdown';


export const Toolbar = () => {
  const { smartMenu} = useAppProps();  

  // Using useEditor hook to get the active node and related content
  const { active, related, selected} = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first();
    return {
      active: currentlySelectedNodeId,
      selected : state.events.selected,
      related:
        currentlySelectedNodeId && state.nodes[currentlySelectedNodeId]?.related,
    };
  });

  console.log(smartMenu)
  return (
    <>
      {related?.toolbar && (
          React.createElement(related.toolbar)
        )}
    </>
  );
};
