import { useSidesContext } from "../../SideContext";

const SmallWidgets = ({ check, review, color, h3Styles}) => {
    // Function to handle reset of selected widget
      const { 
        footerProps, setFooterProps, 
        navbarProps, setNavbarProps,
        styleWrIcon, styleRMIcon,
        } = useSidesContext();

    const handleReset = (name, e) => {
        e.stopPropagation();

        if (check === 'footer') {
            setFooterProps(prev =>
                prev?.map(widget =>
                    widget.name?.toLowerCase() === name?.toLowerCase()
                        ? { ...widget, selected: false }
                        : widget
                )
            );
        } else {
            setNavbarProps(prev =>
                prev?.map(widget =>
                    widget.name?.toLowerCase() === name?.toLowerCase()
                        ? { ...widget, selected: false }
                        : widget
                )
            );
        }
    };

    // Function to render SVG icon
    const widgetComponents = (widget) => {
        const iconColor = color || widget?.icon?.fill || '#000'; // fallback color to black if not defined
        const iconPath = widget?.icon?.path || ''; // Ensure there's a fallback for the path
        const iconViewBox = widget?.icon?.viewBox || '0 0 24 24'; // Provide default viewBox

        return (
            <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                viewBox={iconViewBox}
                width="4vmin"
                height="4vmin"
                fill={iconColor}
            >
                <path d={iconPath} />
            </svg>
        );
    };

    const Msg = ({footerProps}) => {
        const isSelected = footerProps.every(widget => widget.selected !== true)

        if (isSelected) {
            return <div className='min-w-[100%] text-center'>
                <p>You must choose even one widget to appear here!</p>
            </div>
        }
        return null;
    };


    // Function to render the icon
    const renderIcon = (widget) => widgetComponents(widget);

    // Widgets selected from footerProps or navbarProps based on the 'check' prop
    const widgets = check === 'footer' ? footerProps?.filter(widget => widget.selected) : navbarProps?.filter(widget => widget.selected);

    return (
        <>
            <Msg footerProps={footerProps} />
            <div style={{ ...h3Styles, display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                {widgets?.map((widget, index) => (
                    <div
                        style={{ display: 'flex', justifyContent: 'center', width: 'fit-content', margin: '0 2px' }}
                        className={styleWrIcon}
                        key={widget.name}
                    >
                        {!review && (
                            <div
                                onClick={(e) => handleReset(widget.name, e)}
                                className={styleRMIcon(check)}
                            >
                                ❌
                            </div>
                        )}
                        <a href={review && widget?.url} target='_blank' rel="noopener noreferrer">
                            <div style={{ display: widget.widgetdisplay !== 'icon' && widget.widgetdisplay !== 'both' ? 'none' : '' }}>
                                {renderIcon(widget)}
                            </div>
                        </a>
                        <div
                            style={{
                                margin: '0 5px',
                                display: widget.widgetdisplay !== 'name' && widget.widgetdisplay !== 'both' ? 'none' : ''
                            }}
                            className="mx-[.5vmin]"
                        >
                            {typeof widget.name === 'string' ? widget.name.replace('Vis', '') : ''}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default SmallWidgets;
