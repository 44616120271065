import { useAppProps } from "../../../DarkModeContext";

const ToolbarSelectDrop = ({
    isDarkMode,
    props,
    noinput,
    optionsDrop,
    value,
    onChange,
    setProp,
    node,
    propKey,
    mainProps,
    options,
    small,
    labelAndValue
}) => {

  const currentstate = options.actionState

  const {setSavedProperties} = useAppProps()

    return (
        <div className={`text-[#FFF] h-full w-full my-[.5vmin]`} style={{ padding: props.label === undefined ? '' : noinput ? '' : '2% 0' }}>
            {props.label === 'Transition' ? <div style={{display:'inline-block', paddingBottom:'2%'}} className=' w-full text-[1.7vmin] text-[#e9e9e9] text-center px-[.25Vmin]'>You can add a transition</div> : props.label === 'Display' ? <div style={{display:'inline-block', paddingBottom:'2%', }} className=' w-full text-[1.7vmin] text-[#e9e9e9] text-center px-[.25Vmin]'>Flexbox layout mode</div> : props.label === 'Direction' ? <div style={{display:'inline-block', paddingBottom:'2%', }} className=' w-full text-[1.7vmin] text-[#e9e9e9] text-center px-[.25Vmin]'>Set the layout direction</div> : null}
            <div className={`flex ${small ? 'flex-column' : 'flex-row'} px-[2%] items-center justify-center w-full`}>
            {props?.label && <div className="text-left w-[fit-content] flex flex-1 items-center" style={{ fontSize: '1.6vmin', fontWeight: '500', textTransform:'capitalize', textAlign:small ?'center' : 'left', fontFamily: 'Inter', marginBottom: small && '1vmin'}}>{props.label}</div>}
            <select
            className="bluredContent"
              style={{
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                padding: '.7vmin 3vmin .7vmin 1vmin',
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
                letterSpacing:'.5px',
                fontSize: '1.5vmin',
                color: '#FFF',
                backgroundColor: isDarkMode ? '#111f3e60' :  '#e9e9e940',
                border: '.25vmin solid #e9e9e9',
                borderRadius: '2vmin',
                outline: 'none',
                width:props.label === undefined ? '' : !small ? '55%' : '100%',
                margin:'0 1.5%',
                boxShadow:'none'
                // transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
              }}

              value={value || ''}

              onFocus={(e) => {
                e.target.style.borderColor = '#007bff';
                e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
            }}
            onBlur={(e) => {
                e.target.style.borderColor = '#ccc';
                e.target.style.boxShadow = 'none';
            }}
              onChange={(e) => {
                const selectedValue = e.target.value;
                onChange ? onChange(selectedValue) : 
                setProp(node.id, (props) => {
                  if(mainProps) {
                  props[currentstate][propKey] = onChange ? onChange(selectedValue) : selectedValue;
                  // props['isBasic'] = true

                  }
                  props[currentstate][propKey] = onChange ? onChange(selectedValue) : selectedValue;
                  // props['isBasic'] = true

                });


              }}
            >
              <option value={undefined}>default</option>

              {labelAndValue ? (
                 optionsDrop.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                    className="bluredContainer border-none-force"
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '2.2vmin',
                      color: '#0867FC',
                      lineHeight: 'auto',
                      background: 'rgb(140, 184, 253)',
                    }}
                  >
                    {option.label}
                  </option>
                ))
              ) : (
              optionsDrop.map((option) => (
                <option
                className="bluredContainer border-none-force"
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '500',
                    fontSize: '2.2Vmin',
                    color: '#0867FC',
                    lineHeight: 'auto',
                    background:'rgb(140, 184, 253)'
                  }}
                  key={option} value={option}>
                  {option}
                </option>
              ))
              )}
              
            </select>
            </div>
          </div>
    )
}

export default ToolbarSelectDrop;