/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { withStyles } from '@mui/styles';
// import { UndoIcon } from '../../Viewport/EditorSvg';
import { Slider as Small } from '@mui/material';
import { UndoIcon } from '../../../Viewport/Editor-icons/EditorSvg';
import { useState } from 'react';



const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#e9e9e9',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const SliderType = ({
    has,
    isDarkMode,
    property,
    el,
    setProp,
    node,
    propKey,
    shadow,
    value2,
    value,
    propValue,

    options
}) => {

    
    const unitOptions = ['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']
    const [unit, setUnit] = useState('em')

   const handleUnit = (unit) => {
      setUnit(unit)
    }

    const handleSliderChange = (el, has, newValue, unit) => {
        setProp(node.id, (props) => {
            props[propKey][el][has].value = `${newValue}px`;
        });
      };

      const handleInputChange = (el, has, newValue) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = `${newValue}px`;
        });
      };
      
      const handleReset = (el, has) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = null;
        });
      };

      function addSpaceBeforeCapitalLetters(name) {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2');
      }
      
      const isValuePresent = options[propKey][el][has].value && options[propKey][el][has].value !== null;
        const iconColor = isValuePresent ? '#0867FC' : '#989191';
        const textColor = isValuePresent ? 'text-[#0867FC]' : 'text-[#989191]';


    return (
        <div className='w-[100%] flex flex-column justify-between rounded-[1vmin]' style={{padding:'1vh 0'}}>
            <div 
            className={` items-center flex justify-start text-left w-[fit-content]`}
            style={{ fontSize: '1.8vmin', fontWeight: '500', fontFamily: 'Inter', textTransform:'capitalize', color: isDarkMode? '#b0b0b0' : '#FFF', marginBottom:'1vmin' }}>
                {addSpaceBeforeCapitalLetters(has)}
            </div>
            {!shadow && <div className='mx-auto flex flex-row justify-between flex-wrap' style={{ width: '100%' }}>

                <SliderStyled
                    className='mx-auto my-auto justify-center flex flex-1 max-w-[60%] items-center'
                    style={{ border: '.25vmin solid transparent' }}
                    min={0}
                    max={1000}
                    value={parseInt(property.value) || 0}
                    onChange={(e) => {
                        let newValue = e.target.value
                        handleSliderChange(el, has, newValue)
                      }}
                />
                <div className='w-full flex flex-row justify-between shadow-md p-[.5vmin] rounded-[2.5vmin]' style={{ color: isDarkMode ? '#fff' : '#000' }}>
                    <div className={`text-[#989191] my-auto mx-auto flex px-[1vmin] text-center text-center rounded-[1.5vmin]`} style={{ justifyContent: 'center', width: '50%', border:'.25vmin solid #e9e9e960' }}>
                        <input className={`text-[#989191]`} style={{ fontSize: '1.7vmin', textAlign: 'center' }}
                        value={parseInt(property.value) || 0}

                            placeholder='0' 
                            onChange={(e) => {
                                let newValue = e.target.value
                                handleInputChange(el, has, newValue)
                            }}
                        />
                        <div>px</div>
                    </div>

                    <div className='flex flex-1 cursor-pointer flex-row justify-center items-center' onClick={() => handleReset(el, has)}>
                    <UndoIcon width='2vmin' height='2vmin' fill={iconColor} />
    <div className={`${textColor} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
      Reset
    </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

