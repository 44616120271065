import React, { useCallback, useMemo, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import styled, { keyframes, css } from 'styled-components';
import { ROOT_NODE } from '@craftjs/utils';
import { DeleteNameIcon } from '../../editor/Viewport/Editor-icons/EditorSvg';
import { useAppProps } from '../../../DarkModeContext';
import { ToolbarItem, ToolbarSection } from '../../editor';
import { BoldIcon, SettingsIcon } from '../../editor/Viewport/Editor-icons/svgArray';
import { HelpersText } from './HelpersText';


const defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  background: { r: 255, g: 255, b: 255, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
};

const IndicatorDiv = styled.div`
  position: absolute;
  z-index: 0;
  color: white;
  white-space: nowrap;
`;

const ResizingCounter = styled.div`
  position: absolute;
  z-index: 0;
  color: white;
  white-space: nowrap;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5vmin;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 0.5vmin 1vmin;
`;


export const ResizableWrapper = (props) => {

  const { id } = useNode();

  const { actions, enabled, query, selected, isActive, actions : {setProp, selectNode} } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),
    selected: state.events.selected,
    enabled: state.options.enabled
  }));

  const {
    name,
    moveable,
    deletable,
    node,
    parent,
    options,
    connectors: { drag },
  } = useNode((node) => ({
    name: node.data.props.nameOf || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    options: node.data.props,
    node: node,
  }));


  const { isHovered, setActiveBackground, childRef, hoveron } = props
  const { isDarkMode, setConnect} = useAppProps();


  const startCornerResize = (e) => {
    e.stopPropagation()

    setActiveBackground(true);
    const handleMouseMove = (event) => handleResize(event);

    const handleMouseUp = () => {
      setActiveBackground(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleResize = useCallback((e) => {
    const rect = childRef.current.getBoundingClientRect();
    const parentRect = childRef.current.parentElement.getBoundingClientRect(); // Parent container size
  
    // Calculate the new width as a percentage of the parent container's width
    let newWidth = ((e.clientX - rect.left) / parentRect.width) * 100;
  
    // Ensure the width doesn't exceed 100% and is at least 0%
    newWidth = Math.min(Math.max(newWidth, 0), 100);  // Clamp the width between 0% and 100%
  
    // Calculate the new height in pixels
    let newHeight = e.clientY - rect.top;
    newHeight = Math.max(newHeight, 0); // Ensure height doesn't go below 0px
  
    // Only update width if it has changed
    if (newWidth !== parseFloat(options['width'])) {
      setProp(node.id, (props) => {
        props['default'].width = `${newWidth}%`; // Set the width, ensuring it is between 0% and 100%
      });
    }
  
    // Only update height if it has changed
    if (newHeight !== parseFloat(options['height'])) {
      setProp(node.id, (props) => {
        props['default'].height = `${newHeight}px`; // Set the height
      });
    }
  }, [node?.id, options]);
  

  const indicatorStyle = useMemo(() => ({
      display: isHovered ? 'flex' : isActive && enabled ? 'flex' : 'none',
      position: 'absolute',
      top: '-4.7vmin',
      right: '0',
      zIndex: node.id && isActive ? 999999999 : 99999,
      width: 'fit-content',
    }), [isActive, isHovered, node]);
  

  return (
    <>
        <div
          onMouseDown={startCornerResize}
          style={{
            display: enabled && isActive ? 'flex' : 'none',
            position: 'absolute',
            right: '0',
            bottom: '0',
            width: '10px',
            height: '10px',
            cursor: 'se-resize',
            zIndex: 3,
            userSelect: 'none',
            background: 'transparent',
          }}
        />

          <HelpersText childRef={childRef} name={name} isActive={isActive}/>

        {/* Corner dots */}
        <div className={`${isActive && 'corner-dot-child'} top-left`} style={{ cursor: 'nw-resize', userSelect:'none' }} onMouseDown={startCornerResize} />
        <div className={`${isActive && 'corner-dot-child'} top-right`} style={{ cursor: 'ne-resize', userSelect:'none' }} onMouseDown={startCornerResize} />
        <div className={`${isActive && 'corner-dot-child'} bottom-left`} style={{ cursor: 'sw-resize', userSelect:'none' }} onMouseDown={startCornerResize} />
        <div className={`${isActive && 'corner-dot-child'} bottom-right`} style={{ cursor: 'se-resize', userSelect:'none', background: 'green' }} onMouseDown={startCornerResize} />


        <IndicatorDiv
          contentEditable={false}
          style={indicatorStyle}
        >
          <div
            style={{
              position: "relative",
              maxWidth: '35vmin',
              borderRadius: "1vmin",
              overflow: "hidden",
            }}
            id="EditRoot"
          >
            <div
              style={{
                padding: '3%',
                borderRadius: '1vmin',
                background:'#0867FC80',
                userSelect:'none'

              }}
              className={`border-solid border-[#e9e9ea] shadow-sm flex flex-row justify-around gap-2 h-[fit-content] shrink-0 items-center`}
            >
             
              {<div
                title="Block Name"
                style={{ fontSize: '1.7vmin', textTransform:'none', fontWeight:500, userSelect: 'none' }}
                className="py-[5%] rounded-[2.8Vmin] flex mx-auto"
                id="Label"
              >
                <div className={`text-[#FFF] font-bold item-center mx-auto justify-center text-center font-['Inter'] leading-[1.5vmin]`}>
                  {name}
                </div>
              </div>}

              {id !== ROOT_NODE && (
                <div
                onClick={() => selectNode(parent)}
                style={{ cursor: "pointer", userSelect:'none'}}
                className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                id="IconButton2"
                title="Detect Parent"
              >
                <img
                alt="icons"
                src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/7.2.0/png/iconmonstr-arrow-up-alt-filled.png&r=255&g=255&b=255"
                className="w-[2.3vmin] h-[2.3vmin]"
                style={{userSelect:'none'}}
              />
              </div>
              )}

              <div
              onClick={() => setConnect(true)}
                style={{ cursor: "pointer", fontWeight:700, userSelect:'none' }}
                className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} uppercase hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                title='Properties'
              >
                <SettingsIcon width='100%' height='100%' fill='#FFF'/>
              </div>

              {moveable && (
                <div
                  style={{ cursor: "pointer", userSelect:'none' }}
                  ref={drag}
                  className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                  id="IconButton2"
                >
                  <img
                    alt="icons"
                    src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2013/png/iconmonstr-cursor-24.png&r=255&g=255&b=255"
                    className="w-[2.3vmin] h-[2.3vmin]"
                    id="Icons2"
                    title="Move Item"
                  />
                </div>
              )}

              {deletable && (
                <div
                  style={{ cursor: "pointer",  userSelect:'none'}}
                  onMouseDown={(e) => {
                    actions.delete(id);
                  }}
                  className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                  id="IconButton3"
                  title="Delete"
                >
                  <DeleteNameIcon width='100%' height={'100%'} fill='#FFF'/>
                </div>
              )}

            </div>
          </div>
        </IndicatorDiv>
    </>
  );
};
