// SideContext.js

import React, { createContext, useState, useContext } from 'react';

// Create a Context
const SideContext = createContext();

// Create a Provider Component
export const SidesProvider = ({ children }) => {
    const [start, setStart] = useState(false)
    const [review, setReview] = useState(false);

    
    const [styles, setStyles] = useState({
        row1: { background:'#FFFFFF', color:'#111f3e', padding:'5px 0', borderRadius: '10px' },
        row2: { background:'#FFFFFF', color:'#111f3e', padding:'5px 0' },
        row3: { background:'#FFFFFF', color:'#111f3e', padding:'5px 0' },
        proud: { background:'#FFFFFF', color:'#111f3e', padding:'5px 0' },
        6: { background:'#FFFFFF', color:'#111827' },
        5: { background:'#FFFFFF', color:'#111827' },
        1: { background:'#FFFFFF', color:'#111827' },
        4: { background:'#FFFFFF', color:'#111827' },
        3: { background:'#FFFFFF', color:'#111827' },
        2: { background:'#FFFFFF', color:'#111827' },
        sideLR: { background:'#FFFFFF', color:'#111f3e', height:'100%', padding:'5px 0'},
        bottom1: { background: '#FFFFFF', color:'#111f3e', padding:'5px 0' },
        bottom2: { background: '#FFFFFF', color:'#111f3e', padding:'5px 0' },
        bottom3: { background: '#FFFFFF', color:'#111f3e', padding:'5px 0', borderRadius: '10px' },

      });
  
    const [walksFor, setWalksFor] = useState({
        main: { value: 0 },
        mainTheme: {value: 0},
        mainApp: { value: 0 },
        mainPage: { value: 0 },
        final: { value: 0 }
      })

      
  const [appSettings, setAppSettings] = useState({
    background: { value: '' },
    color: {value: '#B20312'},
    fonts: {
      h1: {
          fontSize: '28px',  
          fontWeight: 'bold',
          fontFamily: 'Inter',  
          textTransform: 'uppercase'
      },
      h2: {
          fontSize: '20px', 
          fontWeight: 'bold',
          fontFamily: 'Inter',  
          textTransform: 'uppercase'
      },
      h3: {
          fontSize: '16px', 
          fontWeight: 'bold',
          fontFamily: 'Inter',  
          textTransform: 'capitalize'
      },
      h4: {
          fontSize: '14px',  
          fontWeight: 'bold',
          fontFamily: 'Inter',  
          textTransform: 'none'
      },
  },
    appTitle: { value: 'AYCC' },
    siteLogo: { 
      value: {
      name: 'AYCC Logo',
      viewBox: "0 0 30 28",
      path: "M18.9076 23.6176C16.8834 22.8537 15.0841 23.3929 14.4375 23.758L15.1403 19.4894L27.0043 18.3942L29.7875 23.6176C29.9749 25.0498 29.6413 27.9311 26.8075 27.9985C23.2652 28.0827 21.4378 24.5724 18.9076 23.6176ZM0.0714144 23.8985C-0.445875 21.4947 1.97376 17.2055 3.24825 15.3614C3.17328 16.2881 3.25389 18.3044 4.17602 18.956C5.32867 19.7704 8.14003 19.2087 9.63004 18.8156C11.1201 18.4224 12.8631 16.9621 16.9958 15.6984C21.1285 14.4347 24.2772 14.0977 27.7914 17.2429C30.6028 19.7591 30.1436 24.1138 29.5626 25.9767C28.7191 22.9718 26.7793 20.4444 23.5181 19.9108C20.257 19.3772 15.9275 22.6348 15.056 23.4211C14.1844 24.2075 9.23645 27.1281 6.03151 27.802C2.82657 28.476 0.718026 26.9034 0.0714144 23.8985ZM14.4093 11.1488C15.676 11.1488 16.7708 13.358 17.1644 14.3784C20.2457 12.918 23.8648 13.5452 25.2892 14.0414C24.4739 12.6279 22.3935 8.98087 20.5942 5.7008C18.3451 1.60072 17.1644 0 14.8591 0C12.5537 0 11.1481 2.10621 8.81465 5.64463C6.94791 8.47538 4.38209 13.358 3.24819 15.4174C2.32606 20.4274 7.09975 19.789 9.60185 18.8435C10.6795 16.2225 12.4975 11.1488 14.4093 11.1488Z"
    } }
  })
    
  const [navbarProps, setNavbarProps] = useState([
    { 
      id: 1,
      selected: true,
      event: '',
      name:'menuVis',
      icon:{
        path: 'M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z',
        viewBox:'0 0 24 24',
        name:'menu'
      },
      widgetdisplay: 'icon',
    },
    {
      id: 2,
      selected: true,
      event:'',
      name:'AYCCVis',
      icon:{
        viewBox: "0 0 30 28",
        path: "M18.9076 23.6176C16.8834 22.8537 15.0841 23.3929 14.4375 23.758L15.1403 19.4894L27.0043 18.3942L29.7875 23.6176C29.9749 25.0498 29.6413 27.9311 26.8075 27.9985C23.2652 28.0827 21.4378 24.5724 18.9076 23.6176ZM0.0714144 23.8985C-0.445875 21.4947 1.97376 17.2055 3.24825 15.3614C3.17328 16.2881 3.25389 18.3044 4.17602 18.956C5.32867 19.7704 8.14003 19.2087 9.63004 18.8156C11.1201 18.4224 12.8631 16.9621 16.9958 15.6984C21.1285 14.4347 24.2772 14.0977 27.7914 17.2429C30.6028 19.7591 30.1436 24.1138 29.5626 25.9767C28.7191 22.9718 26.7793 20.4444 23.5181 19.9108C20.257 19.3772 15.9275 22.6348 15.056 23.4211C14.1844 24.2075 9.23645 27.1281 6.03151 27.802C2.82657 28.476 0.718026 26.9034 0.0714144 23.8985ZM14.4093 11.1488C15.676 11.1488 16.7708 13.358 17.1644 14.3784C20.2457 12.918 23.8648 13.5452 25.2892 14.0414C24.4739 12.6279 22.3935 8.98087 20.5942 5.7008C18.3451 1.60072 17.1644 0 14.8591 0C12.5537 0 11.1481 2.10621 8.81465 5.64463C6.94791 8.47538 4.38209 13.358 3.24819 15.4174C2.32606 20.4274 7.09975 19.789 9.60185 18.8435C10.6795 16.2225 12.4975 11.1488 14.4093 11.1488Z",
        name: 'logo'
      },
      widgetdisplay: 'both'
    },
    {
      id: 3,
      selected: false,
      event:'',
      name:'langVis',
      icon: {
        path: "M28.2998 0H12.2998V5H16.2998V7H3.29981C1.59981 7 0.299805 8.3 0.299805 10V21C0.299805 22.7 1.59981 24 3.29981 24H4.29981V29.1L10.5998 24H17.2998V17H28.2998C29.9998 17 31.2998 15.7 31.2998 14V3C31.2998 1.3 29.9998 0 28.2998 0ZM11.0998 19.9L10.5998 18.3H7.49981L6.8998 19.9H4.4998L7.79981 11H10.1998L13.4998 19.9H11.0998ZM26.2998 12V14C24.9998 14 23.5998 13.6 22.3998 13C21.1998 13.6 19.7998 13.9 18.3998 14L18.2998 12C18.9998 12 19.6998 11.9 20.3998 11.7C19.4998 10.8 18.8998 9.7 18.5998 8.5H20.6998C20.9998 9.4 21.5998 10.1 22.2998 10.7C23.3998 9.8 24.0998 8.5 24.1998 7H18.1998V5H21.1998V3H23.1998V5H26.4998L26.5998 6C26.6998 8.1 25.8998 10.2 24.3998 11.7C25.0998 11.9 25.6998 12 26.2998 12Z",
        viewBox:'0 0 32 30',
        name:'lang'
      },
      widgetdisplay: 'icon'
    },
    {
      id: 4,
      selected: false,
      event:'',
      name:'mapVis',
      icon: {
        path: "M12 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 16l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 4-9 3.96-1.584c.38.516.741 1.08 1.061 1.729l-3.523 1.41-1.725 3.88 2.672-1.01 1.506-2.687-.635 3.044 4.189 1.789.495-2.021.465 2.024 4.15-1.89-.618-3.033 1.572 2.896 2.732.989-1.739-3.978-3.581-1.415c.319-.65.681-1.215 1.062-1.731l4.021 1.588 3.936 9z",
        viewBox:'0 0 24 24',
        name:'map'
      },
      widgetdisplay: 'icon'
    },
    {
      id: 5,
      selected: true,
      event:'',
      name:'alertVis',
      icon: {
        path: "M28.75 36.417a3.833 3.833 0 0 1-3.546 3.823l-.287.01h-3.834a3.833 3.833 0 0 1-3.823-3.546l-.01-.287zM23 3.833A13.417 13.417 0 0 1 36.409 16.79l.008.46v7.214l3.492 6.985a2.108 2.108 0 0 1-1.666 3.04l-.22.011H7.977a2.108 2.108 0 0 1-1.974-2.85l.088-.201 3.492-6.985V17.25A13.417 13.417 0 0 1 23 3.833"        ,
        viewBox:'0 0 46 46',
        name:'alert'
      },
      widgetdisplay: 'icon'
    },
    {
      id: 6,
      selected: true,
      event:'',
      name:'userVis',
      icon: {
        path: "M9.00018 1.875C9.88226 1.87473 10.7412 2.15722 11.4509 2.681C12.1606 3.20479 12.6838 3.94228 12.9435 4.78524C13.2033 5.6282 13.186 6.53223 12.8942 7.36464C12.6024 8.19704 12.0514 8.91399 11.3222 9.41025C12.5879 9.8745 13.6858 10.7069 14.4744 11.8004C15.263 12.8939 15.7063 14.1984 15.7472 15.546C15.751 15.6208 15.7399 15.6956 15.7144 15.766C15.6889 15.8365 15.6496 15.9011 15.5988 15.9562C15.548 16.0112 15.4868 16.0556 15.4186 16.0866C15.3505 16.1177 15.2768 16.1348 15.2019 16.137C15.1271 16.1392 15.0525 16.1264 14.9826 16.0994C14.9128 16.0724 14.849 16.0317 14.7951 15.9797C14.7412 15.9278 14.6981 15.8656 14.6686 15.7967C14.639 15.7279 14.6235 15.6539 14.6229 15.579C14.5783 14.1176 13.9663 12.731 12.9168 11.7131C11.8673 10.6951 10.4626 10.1258 9.00056 10.1258C7.53847 10.1258 6.13383 10.6951 5.0843 11.7131C4.03478 12.731 3.42285 14.1176 3.37818 15.579C3.37371 15.7282 3.31015 15.8695 3.2015 15.9718C3.09284 16.0741 2.94799 16.1291 2.79881 16.1246C2.64962 16.1201 2.50833 16.0566 2.406 15.9479C2.30368 15.8393 2.24871 15.6944 2.25318 15.5452C2.29424 14.1978 2.7376 12.8935 3.5262 11.8001C4.3148 10.7068 5.41256 9.87445 6.67818 9.41025C5.94895 8.91399 5.39799 8.19704 5.10618 7.36464C4.81438 6.53223 4.79708 5.6282 5.05684 4.78524C5.3166 3.94228 5.83973 3.20479 6.54945 2.681C7.25918 2.15722 8.11811 1.87473 9.00018 1.875ZM6.00018 6C6.00018 6.79565 6.31625 7.55871 6.87886 8.12132C7.44147 8.68393 8.20453 9 9.00018 9C9.79583 9 10.5589 8.68393 11.1215 8.12132C11.6841 7.55871 12.0002 6.79565 12.0002 6C12.0002 5.20435 11.6841 4.44129 11.1215 3.87868C10.5589 3.31607 9.79583 3 9.00018 3C8.20453 3 7.44147 3.31607 6.87886 3.87868C6.31625 4.44129 6.00018 5.20435 6.00018 6Z",
        viewBox:'0 0 18 18',
        name:'user'
      },
      widgetdisplay: 'icon'
    },
   
    {
      id: 7,
      selected: false,
      event:'',
      name:'searchVis',
      icon: {
        path:"M14.516 4.489a9.733 9.733 0 1 1-9.725 9.733 9.78 9.78 0 0 1 9.724-9.733m0-1.822a11.555 11.555 0 1 0 0 23.11 11.555 11.555 0 0 0 0-23.11m16.596 26.924-6.55-6.596-1.263 1.254 6.551 6.595a.888.888 0 0 0 1.454-.964.9.9 0 0 0-.192-.289",
        viewBox: "0 0 32 32",
        name:'search'
      },
      widgetdisplay: 'both',
      value: '',
      placeholder: 'Search Bar'
    },

   
    
  ])

  const [footerProps, setFooterProps] = useState([
    {
      id:1,
      selected: true,
      url:'',
      name:'FacebookVis',
      icon:{
        path : "M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z",
        viewBox:'0 0 24 24',
        name: 'facebook'
      },
      widgetdisplay: 'icon'
    },
    {
      id:2,
      selected: true,
      url:'',
      name:'InstaVis',
      icon: {
        path: "M11.984 16.815c2.596 0 4.706-2.111 4.706-4.707 0-1.409-.623-2.674-1.606-3.538-.346-.303-.735-.556-1.158-.748-.593-.27-1.249-.421-1.941-.421s-1.349.151-1.941.421c-.424.194-.814.447-1.158.749-.985.864-1.608 2.129-1.608 3.538 0 2.595 2.112 4.706 4.706 4.706zm.016-8.184c1.921 0 3.479 1.557 3.479 3.478 0 1.921-1.558 3.479-3.479 3.479s-3.479-1.557-3.479-3.479c0-1.921 1.558-3.478 3.479-3.478zm5.223.369h6.777v10.278c0 2.608-2.114 4.722-4.722 4.722h-14.493c-2.608 0-4.785-2.114-4.785-4.722v-10.278h6.747c-.544.913-.872 1.969-.872 3.109 0 3.374 2.735 6.109 6.109 6.109s6.109-2.735 6.109-6.109c.001-1.14-.327-2.196-.87-3.109zm2.055-9h-12.278v5h-1v-5h-1v5h-1v-4.923c-.346.057-.682.143-1 .27v4.653h-1v-4.102c-1.202.857-2 2.246-2 3.824v3.278h7.473c1.167-1.282 2.798-2 4.511-2 1.722 0 3.351.725 4.511 2h7.505v-3.278c0-2.608-2.114-4.722-4.722-4.722zm2.722 5.265c0 .406-.333.735-.745.735h-2.511c-.411 0-.744-.329-.744-.735v-2.53c0-.406.333-.735.744-.735h2.511c.412 0 .745.329.745.735v2.53z",
        viewBox:'0 0 24 24',
        name:'insta'
      },
      widgetdisplay: 'icon'
    },
    {
      id:3,
      selected: true,
      url:'',
      name:'Twitter',
      icon: {
        path: "M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z",
        viewBox:'0 0 24 24',
        name:'twitter'
      },
      widgetdisplay: 'icon'
    },
    {
      id:4,
      selected: true,
      url:'',
      name:'GoogleVis',
      icon: {
        path: "M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z",
        viewBox:'0 0 24 24',
        name:'google'
      },
      widgetdisplay: 'icon'
    },
    
  ])

  const [pageSettings, setPageSettings] = useState({
    navbarProps: { visibility: true, props: navbarProps, name:'header', displayChildren:'basic' },
    sidebar: { visibility: true, openWay: false, position: 'left', },
    bottomSide: { visibility: true, props: footerProps, },
    copyright: {visibility: true, content: ''}
  })

  const [selectedTask, setSelectedTask] = useState([
    {
      selected: false,
      event: '',
      name: 'Profile',
      icon: null
    },
    {
      selected: false,
      event: '',
      name: 'Location',
      icon: null
    },
    {
      selected: false,
      event: '',
      name: 'SignIn',
      icon: null
    },
    {
      selected: false,
      event: '',
      name: 'SignUp',
      icon: null
    },
    {
      selected: false,
      event: '',
      name: 'Forget Password',
      icon: null
    },
    {
      selected: false,
      event: '',
      name: 'Our Services',
      icon: null
    }
  ])

    const [open, setOpen] = useState('')
    const [closePanel, setClosePanel] = useState(false)
    const [menuHover, setMenuHover] = useState(true)
    const [selectedItem, setSelectedItem] = useState(null);
  
  const [widgets, setWidgets] = useState(false)
  const [stepper, setStepper] = useState(0);
  
  const [stepper2, setStepper2] = useState(null);
  const [select, setSelect] = useState('background');
  const [selectedFile, setSelectedFile] = useState(null);
  const [width, setWidth] = useState('70%')
  const [finish, setFinish] = useState(false)
  const [topArea, settopArea] = useState(false)
  const [bottomArea, setBottomArea] = useState(false)
  const [rightArea, setRightArea] = useState(false)
  const [leftArea, setLeftArea] = useState(false)



  const [rows, setRows] = useState(1)
  const [theOptionRow2, setTheOptionRow2] = useState('Navigation Pages')
  const [theOptionRow3, setTheOptionRow3] = useState('Content with a Link')
  const [theOptionRow, setTheOptionRow] = useState('Small widgets')

  const [contents, setContents] = useState({
    content1: {text: '', href: ''},
    content2: {text: '', href: ''},
    content3: {text: '', href: ''}
  })

 const [bottomRows, setbottomRows] = useState(1)
  const [theBottomOptionRow2, setTheBottomOptionRow2] = useState('Navigation Pages')
  const [theBottomOptionRow3, setTheBottomOptionRow3] = useState('Content with a Link')
  const [theBottomOptionRow, setTheBottomOptionRow] = useState('Small widgets')

  const [bottomContents, setBottomContents] = useState({
    content1: {text: '', href: ''},
    content2: {text: '', href: ''},
    content3: {text: '', href: ''}
  })


  
  
  const TopProps = {
    setStart,
    navbarProps,
    setNavbarProps,
    setTheOptionRow,
    theOptionRow,
    theOptionRow2,
    setTheOptionRow2,
    theOptionRow3,
    setTheOptionRow3,
    rows,
    setRows,
    stepper2,
    setStepper2,
    select,
    contents,
    setContents,
    walksFor,
    setWalksFor,
    pageSettings,
    setPageSettings,
  }
  
  const BottomProps = {
    setStart,
    footerProps,
    setFooterProps,
    bottomContents,
    setBottomContents,
    navbarProps,
    setNavbarProps,
    pageSettings,
    setPageSettings,
    stepper2,
    setStepper2,
    setWalksFor,
    select,
    bottomRows,
    setbottomRows,
    theBottomOptionRow2,
    setTheBottomOptionRow2,
    theBottomOptionRow3,
    setTheBottomOptionRow3,
    theBottomOptionRow,
    setTheBottomOptionRow,
  }


  const handleWidth = (newWidth) => {
    setWidth(newWidth)
  }

  const styleWrIcon = 'cursor-pointer w-[fit-content] min-w-[4vmin] p-[1vmin] relative rounded-[.5vmin] flex justify-center items-center';
  
  const styleRMIcon = (check) => {
    const baseClasses = 'absolute top-[-1vmin] right-[.7vmin] hover:scale-150 transform-gpu';
    let conditionalClasses = '';
  
    if (check === 'header') {
      conditionalClasses = !topArea ? 'd-none' : 'z-[999999]';
    } else {
      conditionalClasses = !bottomArea ? 'd-none' : '';
    }
  
    return `${baseClasses} ${conditionalClasses} `;
  };


  const [expandedFonts, setExpandedFonts] = useState(null);
  const fontTypes = ['h1', 'h2', 'h3', 'h4'];
  const [show, setShow] = useState(false);


  return (
    <SideContext.Provider value={{
    walksFor, setWalksFor, rows, setRows,
    start, setStart, styleWrIcon,
    styleRMIcon, finish, setFinish, 
    topArea, settopArea,
bottomArea, setBottomArea,
rightArea, setRightArea,
leftArea, setLeftArea,
    appSettings, setAppSettings,
    navbarProps, setNavbarProps,
    footerProps, setFooterProps,
    pageSettings, setPageSettings,
    selectedTask, setSelectedTask,
    open, setOpen, styleWrIcon,
    styleRMIcon,
    closePanel, setClosePanel,
    menuHover, setMenuHover,
    selectedItem, setSelectedItem,
    widgets,
    setWidgets,
    stepper,
    setStepper,
    stepper2,
    setStepper2,
    setSelect,
    selectedFile,
    setSelectedFile,
    width,
    TopProps,
    BottomProps,
    select,
    handleWidth,
    expandedFonts,
    setExpandedFonts,
    fontTypes,
    show,
    setShow,
    styles,
setStyles,
review,
setReview, theOptionRow2, setTheOptionRow2,
theOptionRow3, setTheOptionRow3,
theOptionRow, setTheOptionRow,
contents, setContents
    }}>
      {children}
    </SideContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useSidesContext = () => useContext(SideContext);
