import { useState, useEffect } from "react";
import { useAppProps } from "../../../DarkModeContext";
import { ToolbarItem, ToolbarSection } from "../../editor";
import { SettingsIcon } from "../../editor/Viewport/Editor-icons/svgArray";
import { category } from "../basic/Image/ImageSettings";
import SelectionBox from "../../editor/Viewport/Pop-up/Images-Icons-Modal";

export const HelpersText = (props) => {
  const [expanded, setExpanded] = useState(null);
  const { isActive, childRef, name } = props;
    const [iconsSquare, setIconsSquare] = useState(false)
    const [selectedIcon, setSelectedIcon] = useState(null);
  

  const rect = childRef?.current?.getBoundingClientRect();
  const rectHeight = rect?.height;
  const rectWidth = rect?.width;

  const shouldHide = rectHeight > 70 || rectWidth > 260;

  const handleIconImageSelect = (selectedUrl) => {

    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcon(selectedUrl[0]);
    } else {
      setSelectedIcon(selectedUrl)
    }

    setIconsSquare(false);
  };


  // Helper function to render the proper settings for each element type
  const renderElementSettings = () => {
    switch (name) {
      case "Text":
      case "Heading":
      case "Text Area":
      case "Link":
      case "Quote":
        return (
          <>
            <ToolbarSection
              small
              expanded={expanded === 'B'}
              setExpanded={() => setExpanded(expanded === 'B' ? null : 'B')}
              title="B"
            >
              <ToolbarItem
                full={true}
                small
                propKey="fontWeight"
                optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
                type="select-drop"
              />
            </ToolbarSection>

            <ToolbarSection
              small
              expanded={expanded === 'A'}
              setExpanded={() => setExpanded(expanded === 'A' ? null : 'A')}
              title="A"
            >
              <ToolbarItem
              small

                full={true}
                propKey="textTransform"
                optionsDrop={['uppercase', 'lowercase', 'capitalize']}
                type="select-drop"
              />
            </ToolbarSection>

            <ToolbarSection
              small
              full={true}
              expanded={expanded === '='}
              setExpanded={() => setExpanded(expanded === '=' ? null : '=')}
              title="="
            >
              <ToolbarItem
                full={true}
                small
                propKey="textAlign"
                optionsDrop={['left', 'center', 'right']}
                type="select-drop"
              />
            </ToolbarSection>
          </>
        );

      case "Button":
        return (
          <>
            <ToolbarSection
              small
              expanded={expanded === 'S'}
              setExpanded={() => setExpanded(expanded === 'S' ? null : 'S')}
              title="Width"
            >
              <ToolbarItem
                full={true}
                propKey="width"
                labelAndValue
                optionsDrop={[
                  { value: '100px', label: 'Small' },
                  { value: '50%', label: 'Medium' },
                  { value: '100%', label: 'Large' }
                ]}
                type="select-drop"
              small
            
              />
            </ToolbarSection>

            <ToolbarSection
              small
              expanded={expanded === 'C'}
              setExpanded={() => setExpanded(expanded === 'C' ? null : 'C')}
              title="Cursor"
            >
              <ToolbarItem
              full={true}
              propKey="cursor"
              optionsDrop={['help', 'wait', 'crosshair', 'not-allowed', 'zoom-in', 'grab', 'pointer']}
              type="select-drop"
              small
              
            />
            </ToolbarSection>

            <ToolbarSection
              small
              expanded={expanded === 'A'}
              setExpanded={() => setExpanded(expanded === 'A' ? null : 'A')}
              title="Background"
            >
              <ToolbarItem
                small
                full={true}
                propKey="background"
                // optionsDrop={['#FF0000', '#00FF00', '#0000FF']}
                type="bg"
              />
            </ToolbarSection>
          </>
        );

      case "Image":
        return (
          <>
            <ToolbarSection
              small
              expanded={expanded === 'B'}
              setExpanded={() => setExpanded(expanded === 'B' ? null : 'B')}
              title="Size"
            >
              <ToolbarItem
                full={true}
                small
                propKey="width"
                labelAndValue
                optionsDrop={[
                  { value: 'auto', label: 'Small' },
                  { value: '50%', label: 'Medium' },
                  { value: '100%', label: 'Full' }
                ]}
                type="select-drop"
              />
            </ToolbarSection>

            <ToolbarSection
              small
              expanded={expanded === 'A'}
              setExpanded={() => setExpanded(expanded === 'A' ? null : 'A')}
              title="Fit"
            >
              <ToolbarItem
                full={true}
                small
                propKey="objectFit"
                optionsDrop={['cover', 'contain', 'fill']}
                type="select-drop"
              />
            </ToolbarSection>
          </>
        );

      case "Input":
        return (
          <>
            <ToolbarSection
              small
              expanded={expanded === 'B'}
              setExpanded={() => setExpanded(expanded === 'B' ? null : 'B')}
              title="Placeholder"
            >
              <ToolbarItem
                full={true}
                propKey="placeholder"
                optionsDrop={['Enter text...', 'Search...', 'Email address']}
                type="select-drop"
              />
            </ToolbarSection>

            <ToolbarSection
              small
              expanded={expanded === 'A'}
              setExpanded={() => setExpanded(expanded === 'A' ? null : 'A')}
              title="Size"
            >
              <ToolbarItem
                full={true}
                propKey="size"
                labelAndValue

                optionsDrop={[
                  { value: '100px', label: 'Small' },
                  { value: '50%', label: 'Medium' },
                  { value: '100%', label: 'Large' }
                ]}
                type="select-drop"
              />
            </ToolbarSection>
          </>
        );

        case "Svg":
          return (
            <>
             <ToolbarSection
                small
              expanded={expanded === 'Fill Color'}
              setExpanded={() => setExpanded(expanded === 'Fill Color' ? null : 'Fill Color')}
              title="Fill"
            >
              <ToolbarItem
              small
              full={true}
              propKey="fillColor"
              type="bg"
              preview={false}
            />
            </ToolbarSection>
              <ToolbarSection
                small
                expanded={expanded === 'l'}
                setExpanded={() => setExpanded(expanded === 'l' ? null : 'l')}
                title="Icons"
              >
                <ToolbarItem
                  selectedIcon={selectedIcon}
                  IconsSquare={iconsSquare}
                  setIconsSquare={setIconsSquare}
                  full={true}
                  propKey="src"
                  type="src"
                  small
                  noinput
                />
                {iconsSquare && (
                  <SelectionBox
                    category={category}
                    setIconsSquare={setIconsSquare}
                    iconsSquare={iconsSquare}
                    onSelect={handleIconImageSelect}
                  />
                )}
              </ToolbarSection>
  
            </>
          );
  
      default:
        return null;
    }
  };

  return (
    <div
      contentEditable={false}
      className={'hovered-inside bluredContent'}
      style={{
        display: isActive ? (shouldHide ? 'flex' : 'none') : 'none',
       
      }}
    >
      {renderElementSettings()}
    </div>
  );
};

