/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { CloseIcon2 } from '../Viewport/Editor-icons/svgArray';
import Add from '@mui/icons-material/Add';
import { useAppProps } from '../../../DarkModeContext';

const GradientSettings = ({
  node,
  setProp,
  propKey,
  el,
  has,
  onChange,
  setSelected,
  type,
  property,
  preview,
  }) => {
  const [colors, setColors] = useState(['#ffffff', '#000000']);
  const {isDarkMode} = useAppProps()
  
  const handleColorChange = (index, color) => {
    const newColors = [...colors];
    newColors[index] = color; 
    setColors(newColors); 
    updateSelectedGradient(newColors); 
 };

  const addColorStop = () => {
    const newColors = [...colors, '#0867FC']; 
    setColors(newColors); 
    updateSelectedGradient(newColors); 
  };

  const removeColorStop = (index) => {
    if (colors.length > 2) {
      const newColors = [...colors]; 
      newColors.splice(index, 1); 
      setColors(newColors); 
      updateSelectedGradient(newColors); 
    }
  };

  const updateSelectedGradient = (newColors) => {
    const colorStops = colors.map((color, index) => {
        const percent = `${(index / (colors.length - 1)) * 100}%`;
        return `${color} ${percent}`;
      });
    const gradient = `linear-gradient(to right, ${colorStops.join(', ')})`;
    onChange ? onChange(gradient) :  setProp(node.id, (props) => {
      props[propKey][el][has].value = gradient
    })
   
    
  };

  const generateGradient = () => {
    const colorStops = colors.map((color, index) => {
      const percent = `${(index / (colors.length - 1)) * 100}%`;
      return `${color} ${percent}`;
    });
    return `linear-gradient(to right, ${colorStops.join(', ')})`;
  };



  return (
    <div style={{width:'100%', color:isDarkMode? '#fff' : '#000'}}>
      <div style={{fontSize:'2.2vmin', marginBottom:'1vmin'}}>Gradient Settings</div>
      {(type === 'color' || (property && property?.type === 'color')) && <div className='text-[#00000070] bg-[#e9e9e9] p-[.7vmin]'>
        If the Text is Gradient Color, will remove the Background.
      </div>}
      {colors.map((color, index) => (
        <div key={index} className="flex flex-row justify-between align-items-center relative">
          <div style={{fontSize:'1.9vmin'}}>{`Color ${index + 1}:`}</div>
          
        <div style={{fontSize:'1.9vmin'}}>
            {color}
        </div>
          <div className='relative my-[1vmin] min-w-[3vmin] max-w-[3vmin] mx-[.5vmin] h-[3vmin] flex justify-center align-items-center rounded-full overflow-hidden' style={{border:'.25vmin solid #e9e9e9'}}>
             <input style={{ cursor: 'pointer', minWidth: '200%', minHeight: '200%' }} type='color' value={color} onChange={(e) => handleColorChange(index, e.target.value)} />
           </div>

           {index > 1 && (
            <button className='absolute left-[-.5vmin] top-[-.8vmin]' style={{justifyContent:'flex-end', width:'fit-content', color:'gray'}} type="button" onClick={() => removeColorStop(index)}>
              <CloseIcon2  fill='gray'/>
            </button>
          )}
        </div>
      ))}
      <div className='w-full flex align-items-center justify-between'>
      <button type="button" className='shadow-md text-[#fff]' style={{background:'#0867FC', borderRadius:'2.5vmin', padding:'.5vmin', fontSize:'1.9vmin'}} onClick={addColorStop}>
        <Add style={{width:'4vmin', height:'4vmin'}}/>
      </button>
      <button type="button" className='shadow-md text-[#fff]' style={{background:'#0867FC', borderRadius:'2.5vmin', padding:'1vmin', fontSize:'1.9vmin'}} onClick={updateSelectedGradient}>
        Generate
      </button>
      </div>

{!preview && <div className="gradient-preview" style={{
        width: '100%',
        height: '200px',
        background: generateGradient(),
        marginTop: '20px',
        borderRadius: '5px',
      }}>
      </div>}
    

    </div>
  );
};

export default GradientSettings;
