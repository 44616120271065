/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageCSettings } from './ImageSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { Text } from '../Text';
import { useInView } from 'react-hook-inview';
import { ResizableWrapper } from '../../ResizableWrapper/index.js';
import { Container } from '../../../Container/index.js';
import MainElement from '../MainElements/MainElement.js';

const defaultProps = {
    actionState:'default',
    isBasic: false,
    style: {},
    overflowWrap:'anywhere',

  default : {
    href:null,
    src:'',
    className : '',
    width: '100%',
    maxWidth:'100%',
    minWidth:null,
    maxHeight:'100%',
    height:'100%',
    background: null,
    display: null,
    color: null,
    margin:  null,
    marginTop: null,
    marginBottom:null,
    marginLeft:  null,
    marginRight: null,
    padding:null ,
    paddingTop:  null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight:null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: null,
    borderRadius: null,
    overflow: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor:null,
    shadowBlur: '1px',
    objectFit: 'cover',
    convertToTxt: false,
  },
  hovered: {
    shadowX: null,
      shadowY: null,
      shadowColor:null,
      shadowBlur: null,
  },
  active: {
    shadowX: null,
      shadowY: null,
      shadowColor:null,
      shadowBlur: null,
  }
};

export const ImageC = ({style = {}, isBasic = false, convertToTxt = false, isSvg, text, tagName, ...defaultProps}) => {
 
  
  const {
      node,
      connectors: { connect },
      selected,
      isDragged,
    } = useNode((node) => ({
      node:node,
      selected: node.events.selected,
      isDragged: node.events.dragged,
  
    }));
  

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

    const { nodes } = useEditor((state, query) => ({ nodes: state.nodes }));
  

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  const [inViewRef, inView] = useInView()
  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const mergedRef = useRef(null)


  const {
    href,
    src,
    className,
  } = defaultProps?.default

  const handleClick = useCallback((e) => {
    e.preventDefault();
        if (!enabled) {
          if (href && href._id && href._id.includes("www")) {
          // If href exists and href._id includes "www", open a new tab with the URL
          window.open("https://" + href.name, "_blank");
        } else if (href && href._id && href.name === 'dom') {
          const element = Object.values(nodes).find((node) => node.id === href._id);
  
          if (element) {
  
            if (element.dom) {
              element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
              console.log("DOM Element not found for ID:", element.id);
            }
          } else {
            console.log("Element with ID not found:", href.id);
          }
          }
          else if (href && href.name !== 'dom') {
            // Find the item in treeData based on href._id
            const parentItem = treeData.find((x) => x?._id === href?._id);
            if (parentItem) {
              // Set activeId to the parent item if found
              setActiveId(parentItem);
            } else {
              // If parent item not found, find the child item in treeData
              let childItem = null;
              for (const parent of treeData) {
                const child = parent.children.find((child) => child._id === href._id);
                if (child) {
                  childItem = child;
                  break;
                }
              }
              // Set activeId to the child item if found
              setActiveId(childItem);
            }
          }
        }
    }, [enabled, href, treeData]);

  const memoizedProps = useMemo(() => ({
    ...style,
    cursor: href && !enabled ? 'pointer' : '',
  }), [enabled, href]);
 
const handleMouseEnter = (e) => {
  e.stopPropagation()
  setIsHovered(true);
};

const handleMouseLeave = (e) => {
e.stopPropagation()
  setIsHovered(false);
};

  return (
    <>
    {convertToTxt? (
    <Element canvas is={Container} id='parentId12text' className="w-auto h-auto">
        <Text text='Text'/>
    </Element>
    ) : (
      <MainElement
      activeBackground={activeBackground}
connect={connect}
enabled={enabled}
selected={selected}
mergedRef={mergedRef}
defaultProps={defaultProps['default']}
hoverProperties={defaultProps['hovered']}
activeProperties={defaultProps['active']}
isDragged={isDragged}
isHovered={isHovered}
onMouseOver={handleMouseEnter}
onMouseOut={handleMouseLeave}
memoizedProps={memoizedProps}

        className={`${className} ${(enabled && 'helloworldsParentChildren')} `}
        val={href?.name} 
        
        _id="sidebarItemx"
        onClick={handleClick}
      >
      <img
        className='w-full h-full max-w-[100%] max-h-[100%]'
        style={{objectFit: defaultProps.default?.objectFit, pointerEvents:'none'}}
        src={src || 'https://i.ibb.co/72vs9kd/2.jpg'}
        alt='img'
      />
      {enabled && <> <ResizableWrapper 
        selected={selected} 
        childRef={mergedRef} 
        setActiveBackground={setActiveBackground}
        setIsHovered={setIsHovered} 
        isHovered={isHovered}/> </> }
      </MainElement>
    )}
    </>
  );
};

ImageC.craft = {
  displayName: 'Image',
  props: defaultProps,
  related: {
    toolbar: ImageCSettings,
  },
};

