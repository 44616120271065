import React, { useEffect, useState } from 'react';
import { Stepper } from '@progress/kendo-react-layout';
import { bordersAllIcon,foregroundColorIcon, applyFormatIcon, dataWebIcon

,  tableAlignMiddleCenterIcon, positionTopIcon, fileAddIcon

,positionBottomIcon, positionLeftIcon, positionRightIcon, brushIcon, eyeIcon, mapMarkerIcon, trackChangesAcceptIcon } from '@progress/kendo-svg-icons';
import { useOptionsContext } from '../OptionsContext';
import './Stepper.css'
import { useSidesContext } from '../SideContext';
import { useAppProps } from '../DarkModeContext';

const items = [
{ label: 'Main App Settings',
  svgIcon: brushIcon
}
,{
  label: 'Main App Layout',
  svgIcon: bordersAllIcon
}, {
  label: 'Finish Setup',
  svgIcon: trackChangesAcceptIcon
}];

const mainPageProperties = [
  {
    label: 'Top',
    svgIcon: positionTopIcon
  }, {
    label: 'Left',
    svgIcon: positionLeftIcon
  }, {
    label: 'Bottom',
    svgIcon: positionBottomIcon
  }, {
    label: 'Right',
    svgIcon: positionRightIcon
  }, 
  {
    label: "Pages Count",
    svgIcon: fileAddIcon
  }
]

const mainAppSettings = [{
  label: 'App Title',
  svgIcon: foregroundColorIcon
}, {
  label: "Site's Logo",
  svgIcon: dataWebIcon
}, {
  label: 'Main Theme',
  svgIcon: foregroundColorIcon
}, {
  label: 'Background',
  svgIcon: applyFormatIcon
}, ]

const Tuturial = ({setTuturial}) => {

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { 
    walksFor, setWalksFor,
    start, setStart,
    show,
    setShow, } = useSidesContext();


  const { options: treeData } = idState;

  const subItems = [treeData];



  const onClose = () => {
    setTuturial(false)
    // localStorage.clear()
    // window.location.reload(false);
  }

const handleChangeMain = e => {
  setWalksFor((prev) => ({
    ...prev,
    main: { value: e.value }
  }));
};


const handleChangeMainApp = (e) => {
  setWalksFor((prev) => ({
    ...prev,
    mainApp: { value: e.value }
  }));
  console.log(e.value)
};


const handleChangeMainTheme = (e) => {
  setWalksFor((prev) => ({
    ...prev,
    mainTheme: { value: e.value }
  }));
};


// const handleChangeMainPage = (e) => {
//   setWalksFor((prev) => ({
//     ...prev,
//     mainPage: { value: e.value }
//   }));
// };

useEffect(() => {
  if (start) {
    setTimeout(() => setShow(true), 50); 
  } else {
    setShow(false);
  }
}, [start]);

const percentage = Math.min((walksFor.main.value / 2) * 100, 100);

  
  return show ? <div className={`slide-transition flex flex-row items-center relative ${!start ? 'slide-up' : 'slide-down'}`}>
    <div className='flex-1 items-center justify-start flex' style={{zIndex:22}}>
    <div className='hover:bg-[#0867FC50] bg-[#0867FC] text-[#FFF] hover:text-[#0867FC]' 
        style={{ padding: '.35vmin 1.6vmin', borderRadius: '0 1.3vmin 0 1.3vmin', cursor:'pointer',
        fontWeight: '500', fontFamily: 'Rubik', width: 'fit-content', fontSize: '2vmin'}} 
        onClick={onClose}>Exit Tutorial</div>
        <div 
        onClick={() => setStart(false)}
        className='mx-[.5vmin] text-[2vmin] text-[#0867FC] font-bold cursor-pointer'>Show the steps</div>
    </div>
    <div className='flex-1 absolute pt-[.5vmin] top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
          <div className='font-[500] text-[5.5vmin] text-[#0867FC]' style={{userSelect:'none', fontFamily:'Rubik'}}>LAM</div>
    </div>
      {
      walksFor.main.value < 1 && walksFor.main.value !== null && <div style={{width:'29%', background:'#F3F5F9', padding:'1vmin 0', borderRadius:'0 0 1.5vmin 1.5vmin'}}>
        <Stepper value={walksFor.mainTheme.value} onChange={handleChangeMainTheme} items={mainAppSettings} />
      </div>
      }
      {
      (walksFor.main.value >= 1 && walksFor.main.value < 2)  && walksFor.main.value !== null && <div style={{ width:'29%', background:'#F3F5F9', padding:'1vmin 0', borderRadius:'0 0 1.5vmin 1.5vmin'}}>
        <Stepper value={walksFor.mainApp.value} onChange={handleChangeMainApp} items={mainPageProperties} />
      </div>
      }
    </div>
  :
  <div
      className={`slide-transition shadow-md rounded-[1.5vmin] ${start ? 'slide-up' : 'slide-down'}`}
      style={{
        margin: '.5vmin 0',
        minWidth: '100%',
        height: !start? 'max-content' : '0px',
        border: '.25vmin solid #0867FC50',
        background: '#e9e9e930',
        position: 'relative',
        zIndex: 1, 
      }}
    >
        <Stepper style={{ padding:'1vmin 0'}} value={walksFor.main.value} onChange={handleChangeMain} items={items} />
        <div className='mx-auto' style={{ display:'flex', justifyContent:'space-around', width:'100%'}}>
          {
          walksFor.main.value < 1 && walksFor.main.value !== null && <div style={{width:'50%'}}>
            <Stepper value={walksFor.mainTheme.value} onChange={handleChangeMainTheme} items={mainAppSettings} />
          </div>
          }
          {
          (walksFor.main.value >= 1 && walksFor.main.value < 2)  && walksFor.main.value !== null && <div style={{ width:'50%'}}>
            <Stepper value={walksFor.mainApp.value} onChange={handleChangeMainApp} items={mainPageProperties} />
          </div>
          }
        </div>
      <div className='hover:bg-[#0867FC50] bg-[#0867FC] text-[#FFF] hover:text-[#0867FC]' 
            style={{ padding: '.35vmin 1.6vmin', borderRadius: '0 1.3vmin 0 1.3vmin', cursor:'pointer',
            fontWeight: '500', fontFamily: 'Rubik', width: 'fit-content', fontSize: '2vmin', lineHeight: '140%' }} 
            onClick={onClose}>Exit Tutorial</div>
</div>

};
export default Tuturial;
