import React, { useMemo } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DatePickerSettings } from './DatePickerSettings';


export const BasicDatePicker = ({
    style = {},
    text = 'Click here',
    href,
    color = '#000',
    width,
    height,
    background,
    fontSize = '16px',
    borderColor,
    borderWidth,
    borderRadius,
    textAlign,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,

    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    padding,
    margin,
fontWeight,
fontStyle,
textDecoration,
textTransform,
fontFamily,
lineHeight,
    isExternal,
    isDatePicker,
}) => {
    const {
        connectors: { connect },
        actions: { setProp },
    } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),

    ...handleProperty(paddingTop, 'paddingTop'),
    ...handleProperty(paddingRight, 'paddingRight'),
    ...handleProperty(paddingBottom, 'paddingBottom'),
    ...handleProperty(paddingLeft, 'paddingLeft'),

    ...handleProperty(padding, 'padding'),
    ...handleProperty(margin, 'margin'),


    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, padding, margin, fontFamily, background, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign, marginTop, marginRight, marginBottom, marginLeft, paddingTop, paddingRight, paddingBottom, paddingLeft]);

      const CustomStyle = `
      background: #000 !important;
      border-color: ${borderColor} !important;
      width: ${width?.split(" ").join("")};
      height: ${height?.split(" ").join("")};
      border-radius: ${borderRadius?.split(" ").join("")};
      border: ${borderWidth?.split(" ").join("")} solid;
    `;

    return (
        <div ref={connect} style={{ display: 'inline-block', background:'red' }}>
            <form className="k-form bg-[#000] w-[100%]" onSubmit={(e) => e.preventDefault()}>
                <fieldset style={{width:'100%'}}>
                  <DatePicker placeholder="Choose a date..." />
                </fieldset>
              </form>
        </div>
    );
};

BasicDatePicker.craft = {
    displayName: 'Date Picker',
    props: {
        text: 'Click here',
        href: null,
        color: '#000',
        width:null,
        height:null,
        background:null,
        fontSize: '16px',
        shadowX: null,
        shadowY: '1px',
        shadowColor:null,
        shadowBlur: '1px',
        marginTop: null,
        marginRight: null,
        marginBottom: null,
        marginLeft: null,
        padding:null,
        margin:null,
        paddingTop: null,
        paddingRight: null,
        paddingBottom: null,
        paddingLeft: null,
        borderRadius: null,
        background:null,
        borderColor:null,
        borderWidth:null,
        textAlign:null,
        fontWeight:null,
        textTransform:null,
        fontStyle:null,
        textDecoration:null,
        fontFamily:null,
        textTransform:null,
        lineHeight:null,
        isExternal: false,
        isDatePicker: true,
    },
    related: {
        toolbar: DatePickerSettings,
    },
};
