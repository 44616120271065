/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { useNode } from '@craftjs/core';
import Toggle from '../../../editor/Toolbar/Toggle';
import { StatesItem } from '../MainLayouts/HookStates';

export const CheckBoxSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");
    const [expanded, setExpanded] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
    const [customize, setCustomize] = useState({})
  
    const {
        node,
        options,
        actions: { setProp }
      } = useNode((node) => ({
        props: node.data.props,
        options: node.data.props,
        node: node,
      }));


      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
    
      const handleCustomize = (group) => {
        setCustomize((prev) => ({
          ...prev,
          [group]: !prev[group], // Toggle the current group's state
        }));
      };
    
    
  return (
   <div className='flex flex-col component-settings' style={{ height: '100%', zIndex: 9999999 }}>
         <div>
           <div style={{ borderRadius: '1.5vmin', width: '100%', backdropFilter: 'blur(10px)', background: '#e9e9e930' }} className={`shadow-sm absolute left-0 top-0 z-[999999999]`}>
             <input
               placeholder="Search by title..."
               value={searchTerm}
               onChange={handleSearchChange}
               style={{
                 alignContent: 'center',
                 alignItems: 'center',
                 display: 'flex',
                 margin: '1vmin auto',
                 padding: '1vmin 1.4vmin',
                 fontFamily: 'Inter, sans-serif',
                 fontWeight: '400',
                 fontSize: '2vmin',
                 color: '#98919190',
                 backgroundColor: 'transparent',
                 border: '.25vmin solid #0867FC70',
                 borderRadius: '2vmin',
                 outline: 'none',
                 width: '80%',
                 height: '4vmin'
   
               }}
               onFocus={(e) => {
                 e.target.style.borderColor = '#007bff';
                 e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
               }}
               onBlur={(e) => {
                 e.target.style.borderColor = '#0867FC70';
                 e.target.style.boxShadow = 'none';
               }}
   
             />
             <div className='w-full text-center text-[2.2vmin] my-[.5vmin] text-[#0867FC80]'
               style={{ fontWeight: '700', userSelect: 'none' }}>
               CUSTOMIZE
             </div>
           </div>
   

               
        <div style={{ width: "100%" }}>
        <div className='w-full mb-[1vmin] flex flex-row justify-between'>
                 <StatesItem currentState="default" actionState={options.actionState} setProp={setProp} />
                 <StatesItem currentState="hovered" actionState={options.actionState} setProp={setProp} />
                 <StatesItem currentState="active" actionState={options.actionState} setProp={setProp} />
               </div>
               <div style={{ display: 'flex', margin: 'auto auto', justifyContent: 'center', alignItems: 'center', overflowY:'scroll', minWidth:'100%', maxHeight:'75vmin', paddingBottom: expanded && '3vh', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%' }}>

          <ToolbarSection
                          searchTerm={searchTerm}
                          expanded={expanded === 'Content'}
                          setExpanded={() => setExpanded(expanded === 'Content' ? null : 'Content')}
                          title="Content"
                        >
                          
                           <ToolbarItem
              full={true}
              propKey="fontSize"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Size"
            />

            <ToolbarItem
            full={true}
            propKey="backgroundColor"
            type="color"
            label='Checkbox Color'
            />
            
                          <ToolbarItem
                            full={true}
                            propKey="fontSize"
                            type="slider"
                            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          
                            label="Font Size"
                          />
                          <ToolbarItem
                            full={true}
                            propKey="lineHeight"
                            type="slider"
                            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          
                            label="Line Height"
                          />
                          <ToolbarItem
                            full={true}
                            propKey="fontFamily"
                            optionsDrop={['Rubik', 'Open Sans', 'Inter', 'Roboto', 'Voces', 'Poppins', 'Sansation', 'Prompt', 'Almarai', 'Chivo',]}
                            type="select-drop"
                            label="Font Family"
                          />
                          <ToolbarItem
                            full={true}
                            propKey="fontWeight"
                            optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
                            type="select-drop"
                            label="Weight"
                          />
                          <ToolbarItem
                            full={true}
                            propKey="textTransform"
                            optionsDrop={['uppercase', 'lowercase', 'capitalize']}
                            type="select-drop"
                            label="Transform"
                          />
                          <ToolbarItem
                            full={true}
                            propKey="textDecoration"
                            optionsDrop={['underline', 'overline', 'line-through', 'blink']}
                            type="select-drop"
                            label="Text Decoration"
                          />
                          <ToolbarItem
                            full={true}
                            propKey="textAlign"
                            optionsDrop={['left', 'center', 'right']}
                            type="select-drop"
                            label="Text Align"
                          />
          
                        </ToolbarSection>
          <ToolbarSection
          searchTerm={searchTerm}
          title="Checkbox Border"
          expanded={expanded === 'Checkbox Border'}
          setExpanded={() => setExpanded(expanded === 'Checkbox Border' ? null : 'Checkbox Border')}
        >
          <ToolbarItem
            full={true}
            propKey="borderWidth"
            type="slider"
            max
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            label="Border Width"
          />

            <ToolbarItem
            full={true}
            propKey="borderColor"
            type="color"
            label="Border Color"
          />
        </ToolbarSection>

      

<ToolbarSection
          searchTerm={searchTerm}
         title="Checkbox"
         expanded={expanded === 'Checkbox'}
         setExpanded={() => setExpanded(expanded === 'Checkbox' ? null : 'Checkbox')}
        >
        
        <ToolbarItem
            full={true}
            propKey="fontSize"
            type="slider"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            label="Size"
          />

        <ToolbarItem
            full={true}
            propKey="label"
            type="text"
            label="Label"
          />

        <ToolbarItem
            full={true}
            propKey="labelPosition"
            optionsDrop={['before', 'after']}
            type="select-drop"
            label="Label Position"
          />

          <ToolbarItem
          full={true}
          propKey="color"
          type="color"
          label='Label Color'
          />

          </ToolbarSection>

          </div>


      </div>
      </div>
    </div>
  );
};
