export const TabOption = ({ currentTab, setCurrentTab }) => {
    // Array of tab names (can be dynamically generated or based on your settings)
    const tabs = ["Style", "Advanced"];
  
    // Function to go to the previous tab
    const goBack = () => {
      if (currentTab > 0) {
        setCurrentTab(currentTab - 1);
      }
    };
  
    // Function to go to the next tab
    const goNext = () => {
      if (currentTab < tabs.length - 1) {
        setCurrentTab(currentTab + 1);
      }
    };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Back button */}
       {currentTab !== 0 && <button onClick={goBack}>
        {`<`}
        </button>}
  
        {/* Current Tab */}
        <div style={{ margin: '0 10px' }}>
          {tabs[currentTab]}
        </div>
  
        {/* Next button */}
        {currentTab !== tabs.length - 1 && <button onClick={goNext}>
          {`>`}
        </button>}
      </div>
    );
  };
  