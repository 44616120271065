import { useState } from "react";
import {
    AddPageIcon, EditNameIcon, EyeIcon, LangTool, LogoSite
} from "../../components/editor/Viewport/Editor-icons/EditorSvg";
import { useOptionsContext } from "../../OptionsContext";
import AreaEl from "../AreaEl";
import { CloseIcon, MapIcon } from "../../components/land/Svg";
import { IconPerson, MenuIcon3, SearchIcon } from "../../components/editor/Viewport/Editor-icons/svgArray";
import { SvgIcon } from "../../components/selectors/basic/Svg";
import SmallWidgets from "./TopWidgets";
import { useSidesContext } from "../../SideContext";
import { useActiveIdContext } from "../../ActiveIdContext";

const TopArea = ({ outStepper }) => {
    const {
        appSettings,
        rows,
        setRows,
        styles,
        selectedItem,
        setSelectedItem,
        setStyles,
        theOptionRow,
        theOptionRow2,
        theOptionRow3,
        contents,
        pageSettings,
        review,
        open,
        setOpen,
        closePanel,
        setClosePanel,
        styleWrIcon,
        styleRMIcon
    } = useSidesContext();

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;
    const { state, dispatch: setActiveId } = useActiveIdContext();
    const { activeId } = state;

    const color = appSettings?.color?.value;
    const h1Styles = appSettings?.fonts?.h1;
    const h2Styles = appSettings?.fonts?.h2;
    const h3Styles = appSettings?.fonts?.h3;
    const h4Styles = appSettings?.fonts?.h4;

    const handleClick = (node) => {
        setActiveId({ _id: node._id, name: node.name });
    };
   

    const checkHeader = pageSettings.navbarProps.visibility;

    if (checkHeader) {
        return (
            <div style={{ height: "fit-content", width: "100%", gap:'1vmin', display:'flex', flexDirection:'column', borderBottom: `.25vmin solid ${color}`}}>
                {theOptionRow && (
                    <AreaEl
                        outStepper={outStepper}
                        onClick={() => {
                            setClosePanel(true);
                            setOpen("row1");
                        }}
                        id="row1"
                    >
                        {theOptionRow === "Small widgets" ? (
                            <div
                                style={{
                                    ...h4Styles,
                                    alignContent: "center",
                                    height: "100%",
                                    alignItems: "center",
                                    zIndex: 99,
                                }}
                                className="flex items-center"
                            >
                            <SmallWidgets
                            h3Styles={h3Styles}
                            h4Styles={h4Styles}
                            color={color}
                            outStepper={outStepper}
                            setActiveId={setActiveId}
                            handleClick={handleClick}
                            checkPos="header"
                            />
                            </div>
                        ) : theOptionRow === "Basic Header" ? (
                            <div
                                style={{
                                    alignContent: "center",
                                    height: "100%",
                                    alignItems: "center",
                                    zIndex: 99,
                                }}
                                className="flex items-center"
                            >
                                <SmallWidgets
                                    h3Styles={h3Styles}
                                    h4Styles={h4Styles}
                                    color={color}
                                    handleClick={handleClick}
                                    outStepper={outStepper}
                                    setActiveId={setActiveId}
                                    styles={styles}
                                    checkPos="header"
                                    basic
                                />
                            </div>
                        ) : theOptionRow === "Navigation Pages" ? (
                            <div className="sitemap-container" style={{display:'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))', justifyContent:'center', textAlign:'center', maxWidth:'90%'}}>
                                {treeData.map((node, id) => (
                                    <div
                                        className="flex flex-column h-full text-center w-full cursor-pointer"
                                        key={id}
                                    >
                                        <div
                                            style={{
                                                ...h3Styles,
                                                color: activeId._id === node._id && color,
                                                borderBottom:
                                                    activeId._id === node._id &&
                                                    `.25vmin solid ${color}`,
                                            }}
                                            className="sitemap-item cursor-pointer"
                                            onClick={() => handleClick(node)}
                                        >
                                            {node.name.length > 11
                                                ? node.name.slice(0, 10) + "..."
                                                : node.name}
                                        </div>
                                        {node?.children && node.children.length > 0 && (
                                            <div>
                                                {node.children.map((child) => (
                                                    <div
                                                        key={child._id}
                                                        onClick={() => handleClick(child)}
                                                        className="sitemap-item cursor-pointer text-center my-[1vmin]"
                                                    >
                                                        <div
                                                            style={{
                                                                ...h3Styles,
                                                                color:
                                                                    activeId._id === child._id && color,
                                                                borderBottom:
                                                                    activeId._id === child._id &&
                                                                    `.25vmin solid ${color}`,
                                                            }}
                                                        >
                                                            {child.name.length > 11
                                                                ? child.name.slice(0, 10) + "..."
                                                                : child.name}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={h3Styles} className="link-container">
                                <div className="content-text">
                                    {contents.content1.text || "Content With a link"}
                                </div>
                                {contents.content1.href && (
                                    <a
                                        className="content-link"
                                        style={{ color: color }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={contents.content1.href}
                                    >
                                        Click
                                    </a>
                                )}
                            </div>
                        )}
                    </AreaEl>
                )}

                {rows >= 2 && theOptionRow2 && (
                    <AreaEl
                        outStepper={outStepper}
                        onClick={() => {
                            setClosePanel(true);
                            setOpen("row2");
                        }}
                        id="row2"
                    >
                        {theOptionRow2 === "Small widgets" ? (
                            <div
                                style={{
                                    alignContent: "center",
                                    height: "100%",
                                    alignItems: "center",
                                    zIndex: 99,
                                }}
                                className="flex justify-between items-center"
                            >
                            <SmallWidgets 
                            h3Styles={h3Styles}
                            h4Styles={h4Styles}
                            color={color}
                            outStepper={outStepper}
                            setActiveId={setActiveId}
                            handleClick={handleClick}
                            checkPos="header"
                            check="header" 
                            />
                            </div>
                        ) : theOptionRow === "Basic Header" ? (
                            <div
                                style={{
                                    alignContent: "center",
                                    height: "100%",
                                    alignItems: "center",
                                    zIndex: 99,
                                }}
                                className="flex items-center"
                            >
                                <SmallWidgets
                                    h3Styles={h3Styles}
                                    h4Styles={h4Styles}
                                    color={color}
                                    handleClick={handleClick}
                                    outStepper={outStepper}
                                    setActiveId={setActiveId}
                                    styles={styles}
                                    checkPos="header"
                                    basic
                                />
                            </div>
                        ) : theOptionRow2 === "Navigation Pages" ? (
                            <div className="sitemap-container" style={{display:'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))', justifyContent:'center', textAlign:'center', maxWidth:'90%'}}>
                                {treeData.map((node, id) => (
                                    <div
                                        className="flex flex-column h-full text-center w-full cursor-pointer"
                                        key={id}
                                    >
                                        <div
                                            style={{
                                                ...h3Styles,
                                                color: activeId._id === node._id && color,
                                                borderBottom:
                                                    activeId._id === node._id &&
                                                    `.25vmin solid ${color}`,
                                            }}
                                            className="sitemap-item cursor-pointer"
                                            onClick={() => handleClick(node)}
                                        >
                                            {node.name.length > 11
                                                ? node.name.slice(0, 10) + "..."
                                                : node.name}
                                        </div>
                                        {node?.children && node.children.length > 0 && (
                                            <div>
                                                {node.children.map((child) => (
                                                    <div
                                                        key={child._id}
                                                        onClick={() => handleClick(child)}
                                                        className="sitemap-item cursor-pointer text-center my-[1vmin]"
                                                    >
                                                        <div
                                                            style={{
                                                                ...h3Styles,
                                                                color:
                                                                    activeId._id === child._id && color,
                                                                borderBottom:
                                                                    activeId._id === child._id &&
                                                                    `.25vmin solid ${color}`,
                                                            }}
                                                        >
                                                            {child.name.length > 11
                                                                ? child.name.slice(0, 10) + "..."
                                                                : child.name}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={h3Styles} className="link-container">
                                <div className="content-text">
                                    {contents.content2.text || "Content With a link"}
                                </div>
                                {contents.content2.href && (
                                    <a
                                        className="content-link"
                                        style={{ color: color }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={contents.content2.href}
                                    >
                                        Click
                                    </a>
                                )}
                            </div>
                        )}
                    </AreaEl>
                )}
                {(rows === 3 || rows === '3') && theOptionRow3 && (
                    <AreaEl
                        outStepper={outStepper}
                        onClick={() => {
                            setClosePanel(true);
                                setOpen("row3");
                        }}
                        id="row3"
                    >
                        {theOptionRow3 === "Small widgets" ? (
                            <div
                                style={{
                                    alignContent: "center",
                                    height: "auto",
                                    alignItems: "center",
                                    zIndex: 99,
                                }}
                                className="flex justify-between items-center"
                            >
                            <SmallWidgets  
                            h3Styles={h3Styles}
                            h4Styles={h4Styles}
                            color={color}
                            outStepper={outStepper}
                            setActiveId={setActiveId}
                            handleClick={handleClick}
                            checkPos="header" check="header" />
                            </div>
                        ) : theOptionRow3 === "Navigation Pages" ? (
                            <div className="sitemap-container" style={{display:'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))', justifyContent:'center', textAlign:'center', maxWidth:'90%'}}>
                                {treeData.map((node, id) => (
                                    <div
                                        className="flex flex-column h-full text-center w-full cursor-pointer"
                                        key={id}
                                    >
                                        <div
                                            style={{
                                                ...h3Styles,
                                                color: activeId._id === node._id && color,
                                                borderBottom:
                                                    activeId._id === node._id &&
                                                    `.25vmin solid ${color}`,
                                            }}
                                            className="sitemap-item cursor-pointer"
                                            onClick={() => handleClick(node)}
                                        >
                                            {node.name.length > 11
                                                ? node.name.slice(0, 10) + "..."
                                                : node.name}
                                        </div>
                                        {node?.children && node.children.length > 0 && (
                                            <div>
                                                {node.children.map((child) => (
                                                    <div
                                                        key={child._id}
                                                        onClick={() => handleClick(child)}
                                                        className="sitemap-item cursor-pointer text-center my-[1vmin]"
                                                    >
                                                        <div
                                                            style={{
                                                                ...h3Styles,
                                                                color:
                                                                    activeId._id === child._id && color,
                                                                borderBottom:
                                                                    activeId._id === child._id &&
                                                                    `.25vmin solid ${color}`,
                                                            }}
                                                        >
                                                            {child.name.length > 11
                                                                ? child.name.slice(0, 10) + "..."
                                                                : child.name}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={h3Styles} className="link-container">
                                <div className="content-text">
                                    {contents.content3.text || "Content With a link"}
                                </div>
                                {contents.content3.href && (
                                    <a
                                        className="content-link"
                                        style={{ color: color }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={contents.content3.href}
                                    >
                                        Click
                                    </a>
                                )}
                            </div>
                        )}
                    </AreaEl>
                )}

                {(rows < "3" || rows < 3) && !review && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            
                            if (rows === '1' || rows === 1) {
                                setRows(2);  
                            } else if (rows === '2' || rows === 2) {
                                setRows(3);  
                            } else {
                                console.log('error with set rows');
                            }
                        }}
                        className="w-full flex justify-center items-center cursor-pointer"
                        title="Add Row"
                    >
                        <AddPageIcon height="4vmin" width="4vmin" />
                    </div>
                )}
            </div>
        );
    }

    return null;
};

export default TopArea;
