import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import { useNode, useEditor, Element } from '@craftjs/core';
import { useInView } from 'react-hook-inview';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { ResizableWrapper } from '../../ResizableWrapper';
import { ButtonSettings } from './ButtonSettings';
import { Text } from '../Text';
import MainElement from '../MainElements/MainElement';
import { Container } from '../../../Container';

const defaultProps = {
  actionState:'default',
  isBasic: true,
  style: {},

  default : {
    overflowWrap:'anywhere',
    href:null,
    className : '',
    style: {},
    width:'200px',
    maxWidth:'100%',
    maxHeight:'100%',
    minWidth:null,
    height:'40px',
    background:  '#0867FC50',
    display: null,
    color: null,
    margin:  null,
    marginTop: null,
    marginBottom:null,
    marginLeft:  null,
    marginRight: null,
    padding:null ,
    paddingTop:  null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight:null,
    border: null,
    borderWidth: '1px',
    borderColor: '#98919190',
    borderStyle: 'solid',
    borderRadius:'1.5vmin',
    overflow: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor:null,
    shadowBlur: '1px',
    headingNum: null,
    },
    hovered: {
      shadowX: null,
    shadowY: '1px',
    shadowColor:null,
    shadowBlur: '1px',

    }, 
    active: {
      shadowX: null,
    shadowY: '1px',
    shadowColor:null,
    shadowBlur: '1px',

    }
};

export const Button = ({ style={}, direction, children, isBasic = false, ...defaultProps}) => {
  const { nodes, actions: { setProp } } = useEditor((state, query) => ({ nodes: state.nodes }));

  const {
    node,
    connectors: { connect },
    selected,
    isDragged,
  } = useNode((node) => ({
    node:node,
    selected: node.events.selected,
    isDragged: node.events.dragged,

  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    href, 
    isSvg,
    classn,
    className,
  } = defaultProps.default

  const { state: activeIdState } = useActiveIdContext();
  const { state, dispatch: setActiveId } = useActiveIdContext();

  const { state: optionsState } = useOptionsContext();
  const treeData = optionsState.options;
  const [opens, setOpens] = useState('')
  const [isEditing, setIsEditing] = useState(false);
  const [activeBackground, setActiveBackground] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [inViewRef] = useInView();
  const mergedRef = useRef(null);

  const handleClick = useCallback((e) => {
    e.preventDefault();
        if (!enabled) {
          if (href && href._id && href._id.includes("www")) {
          // If href exists and href._id includes "www", open a new tab with the URL
          window.open("https://" + href.name, "_blank");
        } else if (href && href._id && href.name === 'dom') {
          const element = Object.values(nodes).find((node) => node.id === href._id);
  
          if (element) {
  
            if (element.dom) {
              element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
              console.log("DOM Element not found for ID:", element.id);
            }
          } else {
            console.log("Element with ID not found:", href.id);
          }
          }
          else if (href && href.name !== 'dom') {
            // Find the item in treeData based on href._id
            const parentItem = treeData.find((x) => x?._id === href?._id);
            if (parentItem) {
              // Set activeId to the parent item if found
              setActiveId(parentItem);
            } else {
              // If parent item not found, find the child item in treeData
              let childItem = null;
              for (const parent of treeData) {
                const child = parent.children.find((child) => child._id === href._id);
                if (child) {
                  childItem = child;
                  break;
                }
              }
              // Set activeId to the child item if found
              setActiveId(childItem);
            }
          } 
        }
    }, [enabled, href, treeData]);

  
  const memoizedProps = useMemo(() => ({
    ...style,
    // cursor: href && !enabled ? 'pointer' : undefined,
  }), [ href, enabled]);
  
  const handleDoubleClick = (e) => {
    e.stopPropagation()
    if (enabled) {
      setIsEditing(true);
    }
  };

  const handleBlur = (e) => {
    setIsEditing(false);
  };

  const handleMouseEnter = (e) => {
      setIsHovered(true);
    };

  const handleMouseLeave = (e) => {
      setIsHovered(false);
  };
      
  return (
        <> 
<MainElement
activeBackground={activeBackground}
connect={connect}
enabled={enabled}
selected={selected}
mergedRef={mergedRef}
defaultProps={defaultProps['default']}
hoverProperties={defaultProps['hovered']}
activeProperties={defaultProps['active']}
isDragged={isDragged}
isHovered={isHovered}
onMouseOver={handleMouseEnter}
onMouseOut={handleMouseLeave}
memoizedProps={memoizedProps}

        val={href}
        href={href && !enabled && `/${href}`}
        onDoubleClick={handleDoubleClick}
        onBlur={handleBlur}
        contentEditable={isEditing}
        suppressContentEditableWarning={true}
        disabled={!enabled}
        onClick={handleClick}
        className={`${classn} ${className} px-[1%] ${(enabled && 'helloworldsParentChildren')}`}
        >
          {children || (
            <Element canvas id='text' notMain is={Container}>
              <Text isBasic={false} style={{width:'100%', textAlign:'center', margin:'auto 0'}} text='Button'/>
            </Element>
          )}

        
{enabled && <>  <ResizableWrapper 
          selected={selected} 
          childRef={mergedRef} 
          setActiveBackground={setActiveBackground}
          setIsHovered={setIsHovered} 
          isHovered={isHovered}/>  </> }
        </MainElement>
          
    </>
  );
};

Button.craft = {
  displayName: 'Button',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ButtonSettings,
  },
};

