import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import styled from 'styled-components';
import { ROOT_NODE } from '@craftjs/utils';
import { DeleteNameIcon, Dots } from '../../../editor/Viewport/Editor-icons/EditorSvg';
import { useAppProps } from '../../../../DarkModeContext';
import { SettingsIcon } from '../../../editor/Viewport/Editor-icons/svgArray';

const defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  background: { r: 255, g: 255, b: 255, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
};

const IndicatorDiv = styled.div`
  position: absolute;
  z-index: 0;
  color: white;
  white-space: nowrap;
`;

const ResizingCounter = styled.div`
  position: absolute;
  user-select: none;
  z-index: 0;
  color: white;
  white-space: nowrap;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  background: #0867FC80;
  backdrop-filter: blur(10px);
  box-shadow: 0px 0 10px rgba(32, 31, 31, 0.3);
  border-radius: 5px;
  padding: 0.5vmin 1vmin;
`;

export const ResizableContainer = (props) => {
  const { id } = useNode();
  const { actions, enabled, query, selected, isActive, actions : {selectNode} } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),
    selected: state.events.selected,
    enabled: state.options.enabled
  }));

  const {colorTheme} = useAppProps()

  const {
    name,
    moveable,
    deletable,
    node,
    parent,
    options,
    actions: {setProp},
    connectors: { drag, connect },
  } = useNode((node) => ({
    name: node.data.props.nameOf || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    node: node,
    options: node.data.props,

  }));

    const [DotsClicked, setDotsClicked] = useState(false);
    const DotsRef = useRef(null);
    const DotsContain = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (DotsRef.current && !DotsRef.current.contains(event.target) && DotsContain.current && !DotsContain.current.contains(event.target)) {
            setDotsClicked(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    
      }, [DotsRef]);
    

  const { isHovered, width, height, activeBackground, setActiveBackground, childRef, originalProperties } = props;
  const { isDarkMode, setConnect, storedProperties, setConfirmCopiedProperties, setStoredProperties, savedProperties, setSavedProperties } = useAppProps();
  // handle connect variable
  useEffect(() => {
    if (!isActive && connect) {
      setConnect(false)
    }
  }, [isActive, setConnect])

  // State to manage debouncing the resize event

    const handleConfirm = () => {
    setConfirmCopiedProperties(true)
    setStoredProperties(originalProperties)
    setDotsClicked(false)
  }

      const handlePropertiesPaste = () => {
      const updateProperties = (original, newProps) => {
      // Define the list of valid states that you want to process
      const validStates = ['default', 'hovered', 'active'];
    
      // Loop through the valid states only (default, hovered, active)
      validStates.forEach(stateType => {
        // Check if this state exists in both original and newProps
        if (original.hasOwnProperty(stateType) && newProps.hasOwnProperty(stateType)) {
          const stateProperties = newProps[stateType];  // Get the properties for this state
    
          // Iterate over properties inside this state
          Object.keys(stateProperties).forEach(propertyName => {
            // Update the property in the original object
            setProp((props) => {
              props[stateType][propertyName] = stateProperties[propertyName];

            })
          });
        }
      });
    
      return original;
    };
    setDotsClicked(false)

    const updatedObject = updateProperties(originalProperties, storedProperties);
  };



  const handleResize = useCallback((e, direction) => {
    const rect = childRef.current.getBoundingClientRect();
    const parentRect = childRef.current.parentElement.getBoundingClientRect(); // Parent container size
    
    // Calculate the width as a percentage based on the parent container
    let newWidth = ((e.clientX - rect.left) / parentRect.width) * 100;
  
    // Restrict the width to a maximum of 100%
    if (newWidth > 100) {
      newWidth = 100;
    }
  
    let newHeight = e.clientY - rect.top; // Height in pixels
  
    // Restrict height to the parent's height (100% of the parent container)
  //  if (name !== 'Column') {
  //   if (newHeight > parentRect.height) {
  //     newHeight = parentRect.height;
  //   }
  //  }
  
    // Resizing from the left side
    if (direction === 'left') {
      if (newWidth > 0) setProp((props) => {
        props['default']['width'] = `${newWidth}%`;
      });
    }
  
    // Resizing from the right side
    else if (direction === 'right') {
      if (newWidth > 0) setProp((props) => {
        props['default']['width'] = `${newWidth}%`;
      });
    }
  
    // Resizing from the top/bottom (corner)
    else {
      if (newWidth > 0) setProp((props) => {
        props['default']['width'] = `${newWidth}%`;
      });
      if (newHeight > 0) setProp((props) => {
        props['default'].height = `${newHeight}px`; // Set the height in pixels
      });
    }
  }, [node?.id]);
  
  const startCornerResize = (e, direction) => {
    e.stopPropagation()
    setActiveBackground(true);

    const handleMouseMove = (event) => {
      handleResize(event, direction)
    };

    const handleMouseUp = () => {
      setActiveBackground(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const truncatedWidth = Math.floor(parseFloat(width));
  const truncatedHeight = Math.floor(parseFloat(height));


  // Memoize style objects to avoid unnecessary recalculations
  const leftHandleStyle = useMemo(() => ({
    display: enabled && isActive ? 'flex' : 'none',

    position: 'absolute',
    left: '0',
    top: '0',
    bottom: '0',
    width: '10px',
    cursor: 'ew-resize',
    zIndex: 3,
    userSelect: 'none',
    background: 'transparent',
  }), [enabled, isActive]);

  const rightHandleStyle = useMemo(() => ({
    display: enabled && isActive ? 'flex' : 'none',

    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0',
    width: '10px',
    cursor: 'ew-resize',
    zIndex: 3,
    userSelect: 'none',
    background: 'transparent', 
  }), [enabled, isActive]);

  const indicatorStyle = useMemo(() => ({
    display: isHovered ? 'flex' : isActive && enabled ? 'flex' : 'none',
    position: 'absolute',
    top: name === 'Column' ? '-4.3vmin' : '-3.8vmin',
    right: '0',
    zIndex: node.id && isActive ? 999999999 : selected.size > 0 ?  99999 : 99999999,
    width: 'fit-content',
  }), [isActive, isHovered, name, node, selected]);

  const resizingCounterStyle = useMemo(() => ({
    zIndex: 999999,
    display: activeBackground ? 'flex' : 'none',
    flexDirection:'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '17px',
    background: '#0867FC80',
    backdropFilter: 'blur(10px)',
    boxShadow: '0px 0 10px rgba(32, 31, 31, 0.3)',
    borderRadius: '5px',
    padding: '0.5vmin 1vmin',
  }), [activeBackground]);

  const truncatedWidthDisplay = useMemo(() => (isNaN(truncatedWidth) ? 'Start Resize' : `${truncatedWidth}%`), [truncatedWidth]);
  const truncatedHeightDisplay = useMemo(() => (isNaN(truncatedHeight) ? '' : `${truncatedHeight}px`), [truncatedHeight]);

  return (
    <>
      {/* Left resize handle */}
      <div
        onMouseDown={(e) => startCornerResize(e, 'left')}
        style={leftHandleStyle}
      />

      {/* Right resize handle */}
      <div
        onMouseDown={(e) => startCornerResize(e, 'right')}
        style={rightHandleStyle}
      />

      <ResizingCounter show={activeBackground} style={resizingCounterStyle}>
        {truncatedWidthDisplay && <div>Width: {truncatedWidthDisplay}</div>}
        {truncatedHeightDisplay && <div>Height: {truncatedHeightDisplay}</div>}
      </ResizingCounter>

      {/* Corner dots */}
      <div className={`${ isActive && 'corner-dot-child'} top-left`} style={{ cursor: 'nw-resize' , userSelect:'none'}} onMouseDown={(e) => startCornerResize(e, 'corner')} />
      <div className={`${ isActive && 'corner-dot-child'} top-right`} style={{ cursor: 'ne-resize' , userSelect:'none'}} onMouseDown={(e) => startCornerResize(e, 'corner')} />
      <div className={`${ isActive && 'corner-dot-child'} bottom-left`} style={{ cursor: 'sw-resize' , userSelect:'none'}} onMouseDown={(e) => startCornerResize(e, 'corner')} />
      <div className={`${ isActive && 'corner-dot-child'} bottom-right`} style={{ cursor: 'se-resize', background: 'green' , userSelect:'none'}} onMouseDown={(e) => startCornerResize(e, 'corner')} />

      <IndicatorDiv style={indicatorStyle}>
        <div
          style={{
            position: 'relative',
            maxWidth: '35vmin',
            borderRadius: '1vmin',
            overflow: 'hidden',
          }}
          id="EditRoot"
        >
          <div
            style={{
              padding: '3%',
              borderRadius: '1vmin',
              background: `${colorTheme + '80'}`,
            }}
            className="border-solid bluredContent border-[.25vmin] border-[#e9e9e970] shadow-sm flex flex-row justify-around gap-2 h-[fit-content] shrink-0 items-center"
          >
            <div
              title="Block Name"
              style={{ fontSize: '1.7vmin', userSelect: 'none' }}
              className="py-[5%] rounded-[2.8Vmin] flex mx-auto"
              id="Label"
            >
              <div className={`text-[#FFF] font-bold item-center mx-auto justify-center text-center font-['Inter'] leading-[1.5vmin]`}>
                {name}
              </div>
            </div>

            {/* Other controls (e.g. move, delete) */}
            {id !== ROOT_NODE && (
              <div
                onClick={() => selectNode(parent)}
                style={{ cursor: "pointer", userSelect:'none'}}
                className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                title="Detect Parent"
              >
                <img
                  alt="icons"
                  src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/7.2.0/png/iconmonstr-arrow-up-alt-filled.png&r=255&g=255&b=255"
                  className="w-[2.3vmin] h-[2.3vmin]"
                />
              </div>
            )}
            
              <div
              onClick={() => setConnect(true)}
                style={{ cursor: "pointer", fontWeight:700, userSelect:'none' }}
                className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} uppercase hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                title='Properties'
              >
                <SettingsIcon width='100%' height='100%' fill='#FFF'/>
              </div>
            
            {moveable && (
              <div
                style={{ cursor: "pointer", userSelect:'none' }}
                ref={drag}
                className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                id="IconButton2"
              >
                <img
                  alt="icons"
                  src="https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../releases/preview/2013/png/iconmonstr-cursor-24.png&r=255&g=255&b=255"
                  className="w-[2.3vmin] h-[2.3vmin]"
                  id="Icons2"
                  title="Move Item"
                />
              </div>
            )}

            {deletable && (
              <div
                style={{ cursor: "pointer" , userSelect:'none'}}
                onMouseDown={(e) => {
                  actions.delete(id);
                }}
                className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                id="IconButton3"
                title="Delete"
              >
                <DeleteNameIcon width='100%' height={'100%'} fill='#FFF' />
              </div>
            )}

              <div
                ref={DotsContain}
                onClick={(e) => {
                  e.stopPropagation()
                  setDotsClicked(!DotsClicked)
                }}
                style={{ cursor: "pointer" , userSelect:'none'}}
               
                className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20] flex flex-col justify-center w-[2.3vmin] shrink-0 h-[2.3vmin] p-[2%] items-center rounded-[2.3vmin]`}
                id="IconButton3"
                title="More"
              >
                <Dots width='100%' height={'100%'} fill='#FFF' />
              </div>

          </div>
        </div>
        <div ref={DotsRef}
              style={{overflow:'hidden'}}
                className={`bluredContent  ${isDarkMode? 'bg-[#111f3e60]' : 'bg-[#e9e9e960] '} flex flex-col shadow-sm rounded-[1vmin] w-[fit-content] top-[4vmin] right-[1vmin] dot-container ${DotsClicked ? 'dots-expanded' : 'dots-collapsed'} ${DotsClicked ? 'opacity-100' : 'opacity-0'}`}>
                <div style={{overflow:'hidden', background : `${colorTheme + '80'}`}} className={`w-full items-center flex flex-column h-full`}>
                  <div className={`cursor-pointer text-[1.2vmin] w-full h-full p-[.5vmin] ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20]`}>
                    <div
                    onClick={handleConfirm}
                    >Copy Properties</div>
                  </div>
                  <div className={`cursor-pointer text-[1.2vmin] w-full h-full p-[.5vmin] ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e9e9e930]'} hover:bg-[#0867fc20]`}>
                    <div
                    onClick={handlePropertiesPaste}
                    >Paste Properties</div>
                  </div>
                </div>
              </div>
      </IndicatorDiv>
    </>
  );
};

export default ResizableContainer;
