import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useInView } from "react-hook-inview";
import { useEditor, useNode } from '@craftjs/core';
import { ParentSettings } from './ParentSettings';
import {
  ArrowDivider, BookDivider, CurveDivider, 
  IconPerson, SplitDivider, Tilt, 
  TiltDivider, TriangleAsymmetrical, 
  TriangleAsymmetricalDivider, TriangleDivider, 
  WaveDivider, WavesDivider 
} from "../../editor/Viewport/Editor-icons/svgArray";



const defaultProps = {
  isParent: true,
  actionState: 'Default',
  actionSelect: {
    background: { hover: null, scroll: null },
    color: { hover: null, scroll: null },
    width: { hover: null, scroll: null },
    height: { hover: null, scroll: null },
    minWidth: { hover: null, scroll: null },
    flex: { hover: null, scroll: null },
    borderRadius: { hover: null, scroll: null },
    maxWidth: { hover: null, scroll: null },
    border: { hover: null, scroll: null },
    flexDirection: { hover: null, scroll: null },
    paddingTop: { hover: null, scroll: null },
    paddingRight: { hover: null, scroll: null },
    paddingBottom: { hover: null, scroll: null },
    paddingLeft: { hover: null, scroll: null },
    marginTop: { hover: null, scroll: null },
    marginRight: { hover: null, scroll: null },
    marginBottom: { hover: null, scroll: null },
    marginLeft: { hover: null, scroll: null },
    padding: { hover: null, scroll: null },
    margin: { hover: null, scroll: null },

    transformStyle: { hover: null, scroll: null },
    cursor: { hover: null, scroll: null },
    filterOptions: { hover: null, scroll: null },
    filterValue: { hover: null, scroll: null },
    justifyContent: { hover: null, scroll: null },
    justifyItems: { hover: null, scroll: null },
    alignItems: { hover: null, scroll: null },
    alignContent: { hover: null, scroll: null },
    shapeStyle: { hover: null, scroll: null },
    shapePos: { hover: null, scroll: null },
    
  },
  style: {},
  className: '',
    width: null,
    height: null,
    minWidth: null,
    maxWidth: null,

    background: null,
    color: null,
    
    display: null,
    flexWrap: null,

    borderRadius: null,
    border: null,
    borderWidth:null,
    borderColor:null,


    padding:null,
    paddingTop:null,
    paddingRight:null,
    paddingBottom:null,
    paddingLeft:null,

    margin:null,
    marginTop:null,
    marginRight:null,
    marginBottom:null,
    marginLeft:null,
    cursor:null,
    filterOptions:null,
    filterValue:null,

    justifyContent:null,
    justifyItems:null,
    alignItems:null,
    alignContent:null,
    
    transformStyle:null,
    positionsTranslate:null,
    positionsRotate:null,
    positionsRotateXYZ:null,
    positionsScale:null,
    positionsSkew:null,
    isRotateXYZ:null,
    isSkew:null,
    isTranslate:null,
    isScale:null,
    shapePos:null,
    shadowBlur:null,
    shadowX:null,
    shadowY:null,
    shadowColor:null,

    transitionFor:null,
    transition:null,
    transitionDuration:null,
    transitionTime:null,
    transitionDelay:null,
  isParent:true,
  shapes : {
      Right: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#B20312', shapeArrangement: 'Underneath Section'},
      Left: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#000', shapeArrangement: 'Underneath Section'},
      Bottom: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
      Top: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
    },
}


export const Parent = ({
    row = false,
    style = { },
    className = '',
    actionSelect = { },
    shapes = { },
    onClick,
    children,
    id,
    ...defaultProps
}) => {
  const {
    connectors: { connect },
    actions: { setProp },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [hovered, setHovered] = useState(false);
  const [inViewRef, inView] = useInView();
  const mergedRef = useRef(null);

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, [connect, inViewRef]);
  
const memoizedProps = useMemo(() => ({
  ...defaultProps,
  ...style,
}), [defaultProps, enabled]);
 

  return (
    <div
      ref={setRef}
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      id={id}
      style={memoizedProps}
    >
      {children}
    </div>
  );
};

Parent.craft = {
  displayName: 'Parent',
  props: defaultProps,
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: ParentSettings,
  },
};
