/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR falseS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useMemo } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { Slider, SliderLabel } from "@progress/kendo-react-inputs";
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { SliderSettings } from './SliderSettings';

export const BasicSlider = ({ 
  style = {},
  background,
  lineHeight,
  textDecoration,
  fontFamily,
  textTransform,
  fontSize = '20px',
  textAlign,
  fontWeight,
  width= '100px',
  color = '#000',
  padding,
  margin,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  height,
  minWidth,
  maxWidth,
  borderColor,
  borderWidth,
  borderRadius,
  shadowX,
  shadowY,
  shadowBlur,
  shadowColor,
  fontStyle,
  className,


  min = 1,
  max = 10,
  defaultValue = 7,
  Buttons= 'false',
  vertical = 'false',
}) => {
  const {
    connectors: { connect },
    actions: {setProp},
  } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

   
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(minWidth, 'minWidth'),
    ...handleProperty(maxWidth, 'maxWidth'),

    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),

    ...handleProperty(padding, 'padding'),
    ...handleProperty(margin, 'margin'),



    ...handleProperty(paddingTop, 'paddingTop'),
    ...handleProperty(paddingRight, 'paddingRight'),
    ...handleProperty(paddingBottom, 'paddingBottom'),
    ...handleProperty(paddingLeft, 'paddingLeft'),

    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),

    ...handleProperty(borderRadius, 'borderRadius'),
    ...handleProperty(borderColor, 'borderColor', 'splitBg'),
    ...handleProperty(borderWidth, 'borderWidth'),


    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, fontFamily, background, padding, margin, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign,paddingTop, paddingRight, paddingBottom, paddingLeft, marginTop, marginRight, marginBottom, marginLeft]);


  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  return (
    <div
    ref={connect}
    className={className}
    style={{...styleProps, ...style, padding:'10px 0', justifyContent:'center', alignItems:'center', display:'flex'}}
    >
     <Slider style={{width:'100%'}} buttons={Buttons === 'false' ? false : true} vertical={vertical === 'false' ? true : false} step={1} defaultValue={defaultValue} min={min} max={max}></Slider>
      
    </div>
  );
};

BasicSlider.craft = {
  displayName: 'Slider',
  props: {
    color:'#000',
    background: null,
    lineHeight:null,
    textDecoration:null,
    textTransform:null,
    fontFamily:null,
    fontSize: '20px',
    textAlign: null,
    fontWeight: null,
    shadowX: null,
    shadowY: '1px',
    shadowColor:null,
    shadowBlur: '1px',
    borderRadius: null,
    borderColor:null,
    borderWidth:null,
    paddingTop: null,
    paddingRight: null,
    paddingBottom: null,
    paddingLeft: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    padding:null,
    margin:null,
    width:'100px',
    height:null,
    minWidth: null,
    maxWidth: null,

    isSlider:true,
    min : 1,
    max : 10,
    defaultValue : 7,
    vertical: 'false',
    Buttons: 'false',
    
  },
  related: {
    toolbar: SliderSettings,
  },
};